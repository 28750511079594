import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { useDashboardStore } from '@/store/dashboardStore';

export async function fetchChartData({ reportName }: { reportName: number }) {
  const agencyId = useApplicationStore().getCurrentAgency;
  const result = await apiGet(`/dashboard/report/user-def/${reportName}/${agencyId}`);
  /* console.log(
    `%cDASHBOARD API: dashboard/report/user-def/${reportName}/${agencyId}`,
    'background: #222; color: white',
    result
  ); */
  let reportData = result.data;
  if (reportData === null) {
    reportData = [];
  }
  useDashboardStore().setReportData({ reportName, reportData });
}
