import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { getUser } from './getUser';
import { getDarkModeSetting } from './getDarkModeSettings';
import { getAppSettings } from './getAppSettings';
import { getNumUpcomingServices } from './getNumUpcomingServices';
import { getNumUpcomingTasks } from './getNumUpcomingTasks';
import { getFavorites } from './getFavorites';
import { getWhiteBoards } from './getWhiteBoards';
import { getSavedInfoForWeb } from './getSavedInfoForWeb';
import { getGlobalSettings } from './getGlobalSettings';
import { getFeatureCodes } from './getFeatureCodes';
import { getVarcharColumns } from './getVarcharColumns';
import { getAgencySettings } from './getAgencySettings';
import { getLastClientsUsed } from './getLastClientsUsed';
import { getPendingActivities } from './getPendingActivities';
import { getTabSequence } from './getTabSequence';
import { getWorklineStatements } from './getWorklineStatements';
import { getSqlScripts } from './getSqlScripts';
import { getUsers } from './getUsers';
import { getTeams } from './getTeams';
import { getOwnTerminals } from './getOwnTerminals';
import { getOwnHarbours } from './getOwnHarbours';
import { getVesselTypes } from './getVesselTypes';
import { getDocumentLabels } from './getDocumentLabels';
import { getClientTypes } from './getClientTypes';
import { getCurrencies } from './getCurrencies';
import { getCountries } from './getCountries';
import { getDocumentTemplates } from './getDocumentTemplates';
import { getAllHelpers } from './getAlleHelpers';
import { getDynaWebTabs } from './getDynaWebTabs';
import { getDynaFields } from './getDynaFields';
import { getTarifs } from './getTarifs';
import { getDashboardGroups } from './getDashboardGroups';
import { getAllGatGridViews } from './getAllGridViews';
import { getBerthedPortcalls } from './getBerthedPortcalls';
import { getInAttendance } from './getInAttendance';
import { getAttendanceCodes } from './getAttendanceCodes';
import { getLastPortCalls } from './getLastPortCalls';

export async function initializeApp() {
  const applicationStore = useApplicationStore();
  const helperStore = useHelperStore();
  helperStore.initFixedValues();
  /* const user =  */ await getUser();
  const promises: Promise<void>[] = [];
  getDarkModeSetting();
  promises.push(getAppSettings());
  promises.push(getPendingActivities());

  promises.push(getDashboardGroups());
  promises.push(getAllGatGridViews());
  if (applicationStore.userRight('AT.WEB_ACCESS')) {
    promises.push(getBerthedPortcalls());
    promises.push(getInAttendance());
    promises.push(getAttendanceCodes());
  }
  promises.push(getLastPortCalls());
  promises.push(getLastClientsUsed());
  promises.push(getTarifs());
  const getAgencySettingsPromise = getAgencySettings().then(() => {
    if (!applicationStore.agencySettings?.length) {
      window.messageBus.$emit('showMessage', { type: 'error', text: 'Limited access', subText: 'You have no access to agencies. Therefore, you will only have limited access to the application.', icon: true });
    } else if (
      applicationStore.agencySettings &&
      applicationStore.agencySettings.length > 0 &&
      applicationStore.agencySettings[0].GROUP_NAME == 'crm'
    ) {
      applicationStore.setIsCrm(true);
    }
    // else {
    //   document.title = 'GAT-Ship Web Module';
    // }
  });
  promises.push(getAgencySettingsPromise);
  promises.push(getVarcharColumns());
  promises.push(getDynaFields());
  promises.push(getDynaWebTabs());
  promises.push(getAllHelpers());
  promises.push(getDocumentTemplates());
  promises.push(getCountries());
  promises.push(getCurrencies());
  promises.push(getClientTypes());
  promises.push(getDocumentLabels());
  // const getGlobalSettings = getGlobalSettings().then(() => {
  promises.push(getGlobalSettings());
  promises.push(getFeatureCodes());
  promises.push(getVesselTypes());
  promises.push(getOwnHarbours());
  promises.push(getOwnTerminals());
  promises.push(getTeams());
  promises.push(getUsers());
  promises.push(getSqlScripts());
  promises.push(getWorklineStatements());
  promises.push(getTabSequence());
  promises.push(getSavedInfoForWeb());
  promises.push(getWhiteBoards(applicationStore.agencyIdSelected!));
  promises.push(getFavorites());
  // If external client, see if any upcoming tasks for your own firm
  if (applicationStore.user && !applicationStore.user.internal) {
    if (applicationStore.userRight('CRCH.ACCESS') || applicationStore.userRight('CRCH.ACCESS_OWN_TASK')) {
      if (!applicationStore.user.upcomingTasks.accommodation && !applicationStore.user.upcomingTasks.transport) {
        promises.push(getNumUpcomingTasks(applicationStore.user.externalClientId!));
      }
    }

    if (applicationStore.userRight('SER.ACCESS')) {
      if (!applicationStore.user.upcomingTasks.service) {
        promises.push(getNumUpcomingServices(applicationStore.user.externalClientId!));
      }
    }
  }
  Promise.allSettled(promises).finally(() => {
    if (applicationStore.debugMode) console.log('%cApp initialization complete.', 'background: #222; color: green');
    applicationStore.setAppCriticalInitFinished(true);
    applicationStore.setAppIsInitialized(true);
  });
  // });
}
