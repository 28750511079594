import Client from "@/views/clients/Client.vue";
import TabDashboard from "@/views/dashboard/TabDashboard.vue";
import Documents from "@/app-components/Documents.vue";
import EmailView from "@/views/EmailView.vue";
import EmailList from "@/views/EmailList.vue";
import EmailItem from "@/views/EmailItem.vue";
import EmailEditor from "@/views/EmailEditor.vue";
import FollowUpList from "../components/FollowUpList.vue";
import ContactDetails from "../components/ContactDetails.vue";
import ContactList from "../components/ContactList.vue";
import ClientDetails from "../components/ClientDetails.vue";
import Clients from "../components/ClientList.vue";

const clientRoutes = [
  {
    path: "/clients",
    name: "Clients",
    meta: { pageTitle: "Client search" },
    component: Clients,

    props: (route) => ({
      query: route.query,
      favoritesOnly: false,
    }),
  },

  {
    path: "/client/starred",
    name: "FavoritesClients",
    component: Clients,
    meta: { pageTitle: "Starred clients" },
    props: (route) => ({
      query: route.query,
      favoritesOnly: true,
    }),
  },
  {
    path: "/client/:clientId",
    component: Client,
    meta: { pageTitle: "Client" },
    props: true,
    children: [
      {
        name: "clientDetails",
        path: "",
        component: ClientDetails,
        alias: "details",
        props: (route) => ({
          clientId: route.params.clientId,
          clientList: route.params.clientList,
        }),
      },
      {
        name: "clientContacts",
        path: "contacts",
        component: ContactList,
        props: true,
      },
      {
        name: "clientDocs",
        path: "docs",
        component: Documents,
        props: (route) => ({
          docTypeFilter: 20,
          applicationStatus: { clientId: route.params.clientId },
        }),
      },
      {
        name: "clientFollowUps",
        path: "followup",
        component: FollowUpList,
        props: (route) => ({ clientId: route.params.clientId }),
      },
      {
        name: "clientDashboard",
        path: "dashboard",
        component: TabDashboard,
        props: (route) => ({ clientId: +route.params.clientId, tabType: 3 }),
      },
      {
        name: "clientEmailList",
        path: "e-mail",
        component: EmailList,
        props: (route) => ({
          clientId: +route.params.clientId,
          location: "client",
          dirty: route.params.dirty || 0,
        }),
      },
      {
        name: "",
        path: "e-mail/:emailId",
        component: EmailView,
        props: (route) => ({
          emailId: +route.params.emailId,
          clientId: +route.params.clientId,
          location: "client",
        }),
        children: [
          {
            name: "clientEmailItem",
            path: "",
            component: EmailItem,
            props: (route) => ({
              emailId: +route.params.emailId,
              clientId: +route.params.clientId,
              location: "client",
            }),
          },
          {
            name: "clientEmailReply",
            path: "reply",
            component: EmailEditor,
            props: (route) => ({
              emailId: +route.params.emailId,
              clientId: +route.params.clientId,
              location: "client",
              content: route.params.content,
              mailStyle: route.params.mailStyle || "",
              subject: route.params.subject,
              to: route.params.to,
              cc: route.params.cc,
              bcc: route.params.bcc,
              attachments: route.params.attachments,
              onLoadError: route.params.onLoadError,
            }),
          },
          {
            name: "clientEmailNew",
            path: "new",
            component: EmailEditor,
            props: (route) => ({
              emailId: +route.params.emailId,
              clientId: +route.params.clientId,
              location: "client",
              content: route.params.content || "",
              mailNo: route.params.mailNo,
              subject: route.params.subject,
              mailFrom: route.params.mailFrom,
              to: route.params.to,
              cc: route.params.cc,
              bcc: route.params.bcc,
              attachments: route.params.attachments,
              onLoadError: route.params.onLoadError,
              isDraft: route.params.isDraft || false,
              createdBy: route.params.createdBy || undefined,
            }),
          },
        ],
      },
    ],
  },
  {
    path: "/newclient",
    name: "ClientNew",
    meta: { pageTitle: "New client" },
    component: ClientDetails,
    props: (route) => ({
      value: route.params.value,
      isNew: true,
    }),
  },
  {
    path: "/contacts",
    name: "Contacts",
    meta: { pageTitle: "Contact search" },
    component: ContactList,
    props: (route) => ({
      search: route.query.search,
      maxRows: route.query.maxRows,
    }),
  },
  {
    path: "/contact/:contactId",
    name: "Contact",
    props: true,
    meta: { pageTitle: "Contact" },
    component: ContactDetails,
  },
  {
    path: "/contact/new/:clientId",
    name: "NewContact",
    props: true,
    component: ContactDetails,
  },
  {
    path: "/exipred-followups",
    name: "ExpiredFollowUps",
    component: FollowUpList,
    meta: { pageTitle: "Follow up" },
  },
];

export default clientRoutes;
