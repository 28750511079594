import { apiGet } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function getVisitors({ callId, showAll = false }: { callId: string | number; showAll: boolean }) {
  const portcallStore = usePortcallStore();
  if (showAll) {
    const result = await apiGet('/visitor/gate-keeper');
    portcallStore.setVisitorsExtended(result);
  } else {
    const result = await apiGet(`/visitor/call/${callId}`);
    portcallStore.setVisitors(result);
  }
}
