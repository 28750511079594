<template>
  <v-card :elevation="elevation">
    <div v-if="title" class="d-flex align-center pl-3 pt-2" @contextmenu="onRightClick">
      <span v-if="title" class="text-h6 grey--text text--darken-1">{{ title }} </span>
      <span v-if="reloadBtn">
        <gat-reload-btn compClass="grid-reload" hint="Reload data" @click="$emit('reload-clicked')" />
      </span>

      <!-- grid menu show when right-clicking above the rows -->
      <v-menu v-model="showMenu" absolute offset-y :position-x="menu_x" :position-y="menu_y">
        <v-list dense>
          <v-list-item @click="copyGridToClipBoard()">
            <v-list-item-icon>
              <v-icon>mdi-content-copy</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Copy all to clipboard</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="d-flex flex-grow-1">
        <div class="ml-auto">
          <slot name="btns" />
        </div>
      </div>

      <span v-if="viewGroupName && $vuetify.breakpoint.smAndUp" class="right pr-2">
        <gat-grid-view-menu
          :orgColumns="columns"
          :currentView="view"
          :items="items"
          :viewGroupName="viewGroupName"
          @viewchosen="viewChosen" />
      </span>
    </div>
    <!-- if no titlerow -->
    <div v-else>
      <span v-if="viewGroupName && $vuetify.breakpoint.smAndUp" class="viewNoTitleRow">
        <gat-grid-view-menu
          :orgColumns="columns"
          :currentView="view"
          :items="items"
          :viewGroupName="viewGroupName"
          @viewchosen="viewChosen"
          :iconOnly="true" />
      </span>
    </div>

    <v-data-table
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :items="visibleItems"
      :headers="headers"
      :show-select="selectable"
      :class="stickyFirstColumn ? 'sticky' : ''"
      class="elevation-0 gatGridClass"
      :group-by="GroupedBy"
      :group-desc="groupDesc"
      :dense="dense"
      :height="height"
      :disable-sort="disableSort"
      :fixed-header="fixedheader"
      :loading="isLoading"
      hide-default-footer
      disable-pagination
      single-line
      mobile-breakpoint="100"
      @click:row="rowClicked"
      @toggle-select-all="toggleSelectAll">
      <template slot="item" slot-scope="props">
        <gat-grid-item
          :props="props"
          :visibleColumns="visibleColumns"
          :checkboxValues="checkboxValues"
          :isFavorite="isFavorite(props.item)"
          :tight="tight"
          :rowClass="rowclassfunc(props.item)"
          :rowBarClass="rowbarclassfunc(props.item)"
          :cols-class="colsClass"
          :keyName="keyName"
          :selectable="selectable"
          :favorites="favorites"
          :visibleItems="visibleItems"
          :rowMenu="rowMenu"
          :gridName="gridName"
          :noPadding="noPadding"
          :stickyFirstColumn="stickyFirstColumn"
          :rowBar="rowBar"
          :dense="dense"
          :groupedBy="GroupedBy"
          :draggable="draggableRows"
          :allowHtml="allowHtml"
          :options="options"
          @rowclicked="rowClicked"
          @fav-clicked="favClicked"
          @row-menu-clicked="$emit('rowMenuClicked', $event)"
          @on-checkbox-change="onCheckboxChange($event.selected, $event.item)"
          @copy-grid-clicked="copyGridToClipBoard()">
          <template slot="cell" slot-scope="props">
            <slot name="cell" :column="props.column" :row="props.row" :value="props.value" />
          </template>
        </gat-grid-item>
      </template>
      <!-- <template v-slot:header>
          <thead>
            <tr>
            <template v-for="header in headers" >
            <td v-if="header.value === 'row-bar'" style="width: 8px !important; max-width: 8px !important; padding:0px !important; margin: 0px !important;"></td>
              <td v-else :key="header.field" :class="header.class" :style="`width: ${header.width}`">{{header}}</td>
            </template>
            </tr>
          </thead>
        </template> -->
      <template slot="header.data-table-select">
        <div v-if="selectable && rowBar" class="row-bar-header"></div>
        <v-checkbox
          v-else-if="selectable && !noSelectAll"
          v-model="selectAll"
          dense
          class="d-flex justify-center align-center"
          @change="toggleSelectAll({ value: $event })"
          style="margin-top: 10px; margin-bottom: -10px; margin-left: 12px"
          :indeterminate="
            internalSelectedKeys.length > 0 && visibleItems.length != internalSelectedKeys.length
          "></v-checkbox>
      </template>
      <template slot="header.row-bar">
        <v-checkbox
          v-if="selectable && !noSelectAll"
          v-model="selectAll"
          dense
          class="d-flex justify-center align-center"
          @change="toggleSelectAll({ value: $event })"
          style="margin-top: 10px; margin-bottom: -10px; margin-left: 14px"></v-checkbox>
      </template>
      <template slot="body.append" v-if="!noFooter">
        <gat-grid-footer
          :columns="visibleColumns"
          :items="visibleItems"
          :selectable="selectable"
          :favorites="favorites"
          :tight="tight"
          :noPadding="noPadding" />
      </template>
      <template v-slot:group.header="grpHeaderProps">
        <slot name="group.header" v-bind="grpHeaderProps">
          <gat-grid-group-header
            :props="grpHeaderProps"
            :columns="visibleColumns"
            :items="grpHeaderProps.items"
            @wasToggled="groupToggled"
            :groupStatus="groupStatus"
            :selectable="selectable"
            :favorites="favorites"
            :tight="tight"
            :noGroupHeaderToggle="noGroupHeaderToggle" />
        </slot>
      </template>
      <template v-slot:group.summary="summaryProps">
        <template v-if="!noFooter && summaryProps.isOpen">
          <td
            v-for="(col, index) in visibleColumns"
            style="background: white; font-weight: 500"
            :class="colClass(col, {}, colsClass, index, stickyFirstColumn, noPadding)"
            :style="selectable && index === 0 ? 'width:1px; max-width:1px;' : colStyle(col, tight)">
            <GatGridGroupFooter
              :col="col"
              :columns="visibleColumns"
              :items="summaryProps.items"
              :group="summaryProps.group"
              :groupedBy="GroupedBy"
              :selectable="selectable"
              :favorites="favorites"
              :groupStatus="groupStatus"
              :stickyFirstColumn="stickyFirstColumn"
              :tight="tight"
              :noPadding="noPadding" />
          </td>
        </template>
      </template>
      <!-- <template slot="group.summary" slot-scope="props">
        <template v-if="!noFooter && groupStatus.find((item) => item.group === props.group)?.isOpen">
          <GatGridGroupFooter
            :columns="visibleColumns"
            :items="props.items"
            :group="props.group"
            :groupedBy="GroupedBy"
            :selectable="selectable"
            :favorites="favorites"
            :groupStatus="groupStatus"
            :tight="tight"
            :noPadding="noPadding" />
        </template>
      </template> -->
    </v-data-table>
    <slot></slot>
  </v-card>
</template>

<script>
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { useGatComponentsStore } from '@/store/gatComponentsStore';
import GatReloadBtn from '../GatReloadBtn.vue';
import GatGridViewMenu from './GatGridViewMenu.vue';
import GatGridGroupHeader from './GatGridGroupHeader.vue';
import GatGridFooter from './GatGridFooter.vue';
import GatGridGroupFooter from './GatGridGroopFooter.vue';
import { GatGridMixin } from './GatGridMixin';
import GatGridItem from './GatGridItem.vue';

export default {
  name: 'GatGrid',

  props: {
    items: Array,
    columns: Array,
    gridName: String,
    title: String,
    keyName: String,
    noCellWrap: Boolean,
    selectable: Boolean,
    favorites: Array,
    rowclass: [String, Function],
    rowBarClass: [String, Function],
    noPadding: Boolean,
    colsClass: String,
    selectedKeys: Array,
    hideEmptyColumns: Boolean,
    dense: Boolean,
    orgSortBy: Array,
    reloadBtn: Boolean,
    height: String,
    viewGroupName: String,
    fixedheader: Boolean,
    defaultView: String,
    rowMenu: Array,
    loading: Boolean,
    noSelectAll: Boolean,
    elevation: Number,
    tight: Boolean,
    stickyFirstColumn: Boolean,
    rowBar: Boolean,
    draggableRows: Boolean,
    noFooter: {
      type: Boolean,
      default: false,
    },
    groupDesc: {
      type: Boolean,
      default: false,
    },
    noGroupHeaderToggle: {
      type: Boolean,
      default: false,
    },
    disableSort: {
      type: Boolean,
      default: false,
    },
    allowHtml: Boolean,
    options: {
      type: Object,
    },
  },
  components: { GatGridViewMenu, GatGridFooter, GatGridItem, GatReloadBtn, GatGridGroupFooter, GatGridGroupHeader },
  mixins: [GatGridMixin],
  setup() {
    const gatCopmponentsStore = useGatComponentsStore();
    return {
      gatCopmponentsStore,
    };
  },
  data() {
    return {
      opt: {},
      selectedItem: {},
      checkboxValues: {},
      isLoading: null,
      internalSelectedKeys: [],
      menu_x: 0,
      menu_y: 0,
      selectedView: null,
      showMenu: false,
      sortBy: [],
      sortDesc: [],
      noViewSelected: false,
      groupStatus: [],
      selectAll: false,
    };
  },

  created() {
    this.gatComponentsMessageBus.$on('gatgrid-rowmenu-activated', () => {
      if (this.showMenu) {
        this.showMenu = false;
      }
    });
  },

  //* ** Computed properties/values ***
  computed: {
    GroupedBy() {
      let result;

      // eslint-disable-next-line array-callback-return, consistent-return
      const col = this.visibleColumns.find((item) => {
        if (item.groupedBy) {
          return true;
        }
      });
      if (col) {
        result = col.field;
      }

      return result;
    },

    view() {
      let result = null;
      if (!this.noViewSelected) {
        if (this.selectedView && this.selectedView.viewGroupName == this.viewGroupName) {
          result = this.selectedView;
        } else {
          // TODO: Should be moved outside of gat-component.
          if (this.defaultView && this.gatCopmponentsStore) {
            const v = this.gatCopmponentsStore.gridViews.find(
              (item) => item.viewGroupName == this.viewGroupName && item.name == this.defaultView
            );
            if (v) {
              return v;
            }
          }
          // TODO: Should be moved outside of gat-component.
          if (this.gatCopmponentsStore) {
            // personal default
            let defaultView = this.gatCopmponentsStore.gridViews.find(
              (item) =>
                item.viewGroupName == this.viewGroupName &&
                Object.prototype.hasOwnProperty.call(this.gatCopmponentsStore.defaultGridViews, item.viewGroupName) &&
                this.gatCopmponentsStore.defaultGridViews[item.viewGroupName] == item.name
            );
            // global default?
            if (!defaultView) {
              // TODO: Should be moved outside of gat-component.
              defaultView = this.gatCopmponentsStore.gridViews.find(
                (item) => item.viewGroupName == this.viewGroupName && item.isGlobalDefault
              );
            }

            if (defaultView) {
              result = defaultView;
            }
          }
        }
      }

      return result;
    },

    visibleItems() {
      let result = this.items;
      if (this.view) {
        if (
          this.view.filters &&
          ((this.view.filters.and && this.view.filters.and.length > 0) ||
            (this.view.filters.or && this.view.filters.or.length > 0))
        ) {
          result = this.items.filter((rowItem) => this.isFiltered(rowItem, this.view.filters));
        }
      }
      this.applyViewSorting(result, this.view);
      return result;
    },

    // headers
    headers() {
      const result = [];
      if (this.columns) {
        if (this.rowBar) {
          result.push({
            value: 'row-bar',
            sortable: false,
            width: '8px !important',
            class: this.stickyFirstColumn
              ? this.getStickyHeaderClass(this.selectable ? 'selectable' : 'rowBar')
              : 'pa-0',
          });
        }

        if (this.favorites) {
          result.push({
            sortable: false,
            width: '10px',
            class: this.stickyFirstColumn ? this.getStickyHeaderClass('favorites') : 'pa-0',
          });
        }

        // eslint-disable-next-line array-callback-return
        this.visibleColumns.map((column, index) => {
          if (!column.hide) {
            const header = {};
            header.text = column.header;
            if (column.unSortable) {
              header.sortable = false;
            } else {
              header.sortable = true;
            }

            header.column = column;
            if (column.groupValue) {
              header.value = column.groupValue;
            } else {
              header.value = column.field;
            }

            if (column.dataType == 'number' || column.dataType == 'currency') {
              header.align = 'end';
            } else {
              // header.align='center';
            }

            // eslint-disable-next-line no-multi-assign
            header.class = header.class = `nowrap ${column.class}`;

            if (!this.noPadding) {
              header.class += ' px-4';
            }
            if (this.$vuetify.breakpoint.xsOnly) {
              if (!this.noPadding) {
                header.class += ' pr-2 pl-1';
              }
            }

            if (index == 0 && this.stickyFirstColumn) {
              header.class = `${header.class} sticky-column${this.getStickyHeaderClass()}`;
            }

            if (this.noCellWrap) {
              // eslint-disable-next-line no-param-reassign
              column.nowrap = true;
            }

            if (column.width) {
              header.width = column.width;
            } else {
              // header.width ="1%"
            }
            // if(!(this.$vuetify.breakpoint.xsOnly && column.hideOnPhone)){
            result.push(header);
            // }
          }
        });
      }
      return result;
    },

    // primaryKeyName
    primaryKeyName() {
      let result = this.keyName;
      if (!result) {
        result = 'ID';
      }
      return result;
    },

    // selectedKey
    selectedKey() {
      if (this.selectedItem && Object.prototype.hasOwnProperty.call(this.selectedItem, this.primaryKeyName)) {
        return this.selectedItem[this.primaryKeyName];
      }
      return -1;
    },

    visibleColumns() {
      let cols = [];
      if (this.columns) {
        // eslint-disable-next-line array-callback-return
        this.columns.map((item) => {
          const colCopy = GlobalHelperFunctions.duplicateViaJson(item);
          if (Object.prototype.hasOwnProperty.call(item, 'calculated')) {
            colCopy.calculated = item.calculated; // funksjoner ikke kopiert via json
          }
          if (Object.prototype.hasOwnProperty.call(item, 'calculatedClass')) {
            colCopy.calculatedClass = item.calculatedClass; // funksjoner ikke kopiert via json
          }

          cols.push(colCopy);
        });

        // view
        this.applyView(cols, this.view);

        // col hidden propery
        cols = cols.filter((item) => !item.hide);
        // empty col filter
        if (this.hideEmptyColumns && this.items) {
          cols = cols.filter((item) => item.neverHide || this.colHasData(item));
        }
        // if mobile...
        if (this.$vuetify.breakpoint.xsOnly) {
          // ..hide "hideOnPhone"
          cols = cols.filter((item) => !item.hideOnPhone);
        } else {
          // else if not mobile hide "phoneOnly ""
          cols = cols.filter((item) => !item.phoneOnly);
        }
      }
      return cols;
    },
  },

  //* ** watchers  ****
  watch: {
    // items changed
    items(values) {
      this.selectedItem = {};
      if (this.keyName && values != undefined) {
        this.internalSelectedKeys = this.internalSelectedKeys.filter(
          (selKey) =>
            values.findIndex((valueItem) => {
              const key = valueItem[this.keyName];
              return key == selKey;
            }) > 0
        );
      } else this.internalSelectedKeys = [];
    },
    // selectedItem changed
    selectedItem(newItem, oldItem) {
      if (newItem != oldItem) {
        this.$emit('item-changed', newItem);
      }
    },
    loading: {
      handler(val) {
        this.isLoading = val;
      },
      immediate: true,
    },

    orgSortBy: {
      handler(val, oldValue) {
        if (JSON.stringify(val) != JSON.stringify(oldValue)) {
          if (!this.view) {
            this.setSortFromOrgSort(val);
          }
        }
      },
      immediate: true,
    },

    selectedKeys(keys) {
      if (Array.isArray(keys) && Array.isArray(this.visibleItems)) {
        if (keys.length != this.visibleItems.length) {
          this.selectAll = false;
        }
        if (this.visibleItems.length === keys.length) {
          this.selectAll = true;
        }
        // selection changed?
        const diff = keys
          .filter((x) => !this.internalSelectedKeys.includes(x))
          .concat(this.internalSelectedKeys.filter((x) => !keys.includes(x)));
        if (diff.length > 0) {
          this.internalSelectedKeys = this.makeCopyViaJson(keys);
        }
        // Set the select-all checkbox to false if no keys are selected.
        if (this.internalSelectedKeys.length === 0) {
          this.selectAll = false;
        }
      }
    },

    internalSelectedKeys(keys) {
      const checkValues = {};
      // eslint-disable-next-line array-callback-return
      keys.map((key) => {
        checkValues[key] = true;
      });
      this.checkboxValues = checkValues;

      // selection changed?
      if (this.selectedKeys) {
        const diff = keys
          .filter((x) => !this.selectedKeys.includes(x))
          .concat(this.selectedKeys.filter((x) => !keys.includes(x)));
        if (diff.length > 0) {
          this.$emit('selectionChanged', this.makeCopyViaJson(keys));
        }
      } else {
        this.$emit('selectionChanged', this.makeCopyViaJson(keys));
      }
    },

    defaultView(viewName) {
      // if there is a default view, set the selected view.
      if (viewName) {
        this.selectedView = viewName;
      }
    },
  },

  //* ** methods  ****
  methods: {
    // apply view
    applyView(columns, view) {
      if (view) {
        // eslint-disable-next-line array-callback-return
        columns.map((col) => {
          // find col in view
          const viewCol = view.columns.find((item) => {
            let id = 'name';
            if (!Object.prototype.hasOwnProperty.call(col, id)) {
              id = 'field';
            }
            return Object.prototype.hasOwnProperty.call(item, id) && item[id] == col[id];
          });
          // if col doesn't exist in view, hide it
          if (!viewCol) {
            // eslint-disable-next-line no-param-reassign
            col.hide = true;
            // eslint-disable-next-line no-param-reassign
            col.index = 99;
          } else {
            // if col exists in view, apply view settings to col
            // eslint-disable-next-line no-param-reassign
            col.hideOnPhone = viewCol.hideOnPhone;
            // eslint-disable-next-line no-param-reassign
            col.hide = false;
            // eslint-disable-next-line no-param-reassign
            col.index = viewCol.index;
            // eslint-disable-next-line no-param-reassign
            col.groupedBy = viewCol.groupedBy;
          }

          // footer
          if (view.footerCols) {
            // eslint-disable-next-line no-param-reassign
            col.footerFunction = undefined;
            const footerCol = view.footerCols.find((item) => col.field == item.field);
            if (footerCol) {
              // eslint-disable-next-line no-param-reassign
              col.footerFunction = footerCol.footerFunction;
            }
          }
        });

        // sort
        columns.sort((a, b) => a.index - b.index);
      }
    },

    applyViewSorting(items, view) {
      if (view && view.sortedBy) {
        //  console.log("apply view sorting");
        const sortBy = [];
        const sortDesc = [];
        // eslint-disable-next-line array-callback-return
        view.sortedBy.map((sortCol) => {
          sortBy.push(sortCol.field);
          if (sortCol.desc) {
            sortDesc.push(true);
          } else {
            sortDesc.push(false);
          }
        });
        this.sortBy = sortBy;
        this.sortDesc = sortDesc;
        // items.sort((a,b)=>{
        //   return this.sortCompare(a,b,view.sortedBy,1);
        // });
      }
    },

    log(props) {
      console.log(props);
      return 'heisamm';
    },

    colHasData(col) {
      if (col) {
        if (col.linkUrl) {
          return true;
        }
        for (let index = 0; index < this.visibleItems.length; index++) {
          const item = this.visibleItems[index];

          const value = this.formatValue(item[col.field], col.dataType);
          if (value !== '') {
            return true;
          }
        }
      }
      return false;
    },

    // change sort
    // changeSort(column) {
    //   console.log("changeSort");
    //         if (this.pagination.sortBy === column) {
    //           this.pagination.descending = !this.pagination.descending
    //         } else {
    //           this.pagination.sortBy = column
    //           this.pagination.descending = false
    //         }
    //       },

    compareFilterValue(filter, cellValue) {
      const { comparer } = filter;
      let valueToMatch = filter.matchValue;
      let value = cellValue;
      if (filter.colType == 'date') {
        valueToMatch = moment(valueToMatch).format('YYYY-MM-DD');
        value = moment(cellValue).format('YYYY-MM-DD');
      } else if (filter.colType == 'datetime') {
        valueToMatch = moment(valueToMatch).format('YYYY-MM-DDTHH:mm');
        value = moment(cellValue).format('YYYY-MM-DDTHH:mm');
      }
      const isEmpty = value === null || typeof value == 'undefined' || value === '';
      const bool = value == 1 || value === true || value === 'checked';
      switch (comparer) {
        case '=':
          return value == valueToMatch;
        case '<>':
          return value != valueToMatch;
        case '>':
          return value > valueToMatch;
        case '<':
          return value < valueToMatch;
        case 'not empty':
          return !isEmpty;
        case 'is empty':
          return isEmpty;
        case 'is true':
          return bool;
        case 'is false':
          return !bool;
        case 'is yesterday':
          return moment(value).format('YYYY-MM-DD') == moment().add(-1, 'days').format('YYYY-MM-DD');
        case 'is today':
          return moment(value).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
        case 'is tomorrow':
          return moment(value).format('YYYY-MM-DD') == moment().add(1, 'days').format('YYYY-MM-DD');
        case 'is last 7 days':
          return value >= moment().add(-7, 'days').format('YYYY-MM-DD') && value <= moment().format('YYYY-MM-DD');
        case 'is last x days':
          return (
            value >=
              moment()
                .add(filter.matchValue * -1, 'days')
                .format('YYYY-MM-DD') && moment(value).format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')
          );
        case 'is last month':
          return moment(value).format('YYYY-MM') == moment().add(-1, 'months').format('YYYY-MM');
        case 'is last year':
          return moment(value).format('YYYY') == moment().add(-1, 'years').format('YYYY');
        case 'is past':
          if (filter.colType == 'datetime') {
            return value < valueToMatch;
          }
          return value < moment().format('YYYY-MM-DD');

        case 'is this week':
          return moment(value).week() == moment().week();
        case 'is this month':
          return moment(value).format('YYYY-MM') == moment().format('YYYY-MM');
        case 'is next 7 days':
          return value < moment().add(7, 'days').format('YYYY-MM-DD') && value >= moment().format('YYYY-MM-DD');
        case 'is next x days':
          return (
            value < moment().add(filter.matchValue, 'days').format('YYYY-MM-DD') &&
            value >= moment().format('YYYY-MM-DD')
          );
        case 'is next month':
          return moment(value).format('YYYY-MM') == moment().add(1, 'month').format('YYYY-MM');
        case 'is next year':
          return moment(value).format('YYYY') == moment().add(1, 'year').format('YYYY');
        case 'is future':
          if (filter.colType == 'datetime') {
            return value > valueToMatch;
          }
          return value > moment().format('YYYY-MM-DD');

        default:
          return true;
      }
    },

    copyGridToClipBoard() {
      let result = '';
      for (let rowNo = 0; rowNo < this.items.length; rowNo++) {
        const rowItem = this.items[rowNo];
        let line = '';
        let tab = '';
        for (let colNo = 0; colNo < this.visibleColumns.length; colNo++) {
          const column = this.visibleColumns[colNo];
          if (!column.ignoreOnCopy) {
            if (rowNo === 0) {
              // add header
              let header = `${column.header}`;
              if (header == 'null' || header == 'undefined') {
                header = '';
              }
              result = result + tab + header;
            }
            let fieldName = column.field;
            if (column.fieldWhenCopying) {
              fieldName = column.fieldWhenCopying;
            }
            let value = rowItem[fieldName];

            if (Object.prototype.hasOwnProperty.call(column, 'calculated') && !column.fieldWhenCopying) {
              value = column.calculated(rowItem);
            } else if (column.linkUrl && !column.fieldWhenCopying) {
              value = this.replaceFields(column.linkText, rowItem); // GatGridMixin.js
            } else if (column.dataType == 'datetime') {
              value = moment(value).format('YYYY-MM-DD HH:mm');
            } else if (column.dataType == 'date') {
              value = moment(value).format('YYYY-MM-DD');
            } else {
              value = this.formatValue(rowItem[fieldName], column.dataType, false, column); // GatGridMixin.js
            }

            const strValue = `${value}`;
            if (strValue == 'null' || strValue == 'undefined' || strValue == 'Invalid date') {
              value = '';
            } else {
              // remove linebreaks
              value = strValue.replaceAll('\n', ' ');
              value = value.replaceAll('\r', ' ');
            }

            line = line + tab + value;
            tab = '\t';
          }
        }
        result = `${result}\n${line}`;
      }
      // copy text to clipboard
      navigator.clipboard.writeText(result);
    },

    getStickyHeaderClass(colName) {
      const style = ' sticky-column left-';
      let num = 0;
      if (colName === 'rowBar') {
        return style + num;
      }
      if (this.rowBar) {
        num = 8;
      }
      if (colName === 'selectable') {
        return style + num;
      }
      if (this.selectable) {
        num += 46;
      }
      if (colName === 'favorites') {
        return style + num;
      }
      if (this.favorites) {
        num += 36;
      }
      return style + num;
    },

    groupToggled(param) {
      const grpIndex = this.groupStatus.findIndex((item) => item.group == param.group);
      if (grpIndex >= 0) {
        this.groupStatus.splice(grpIndex, 1, param);
      } else {
        this.groupStatus.push(param);
      }
    },

    isFavorite(rowItem) {
      if (this.favorites) {
        let { keyName } = this;
        if (!keyName) {
          keyName = 'ID';
        }
        const idx = this.favorites.findIndex((item) => item.FAV_KEY == rowItem[keyName]);
        return idx >= 0;
      }
      return false;
    },

    isFiltered(rowItem, filters) {
      let result = false;
      const andFiltersOK = this.filtersMatch(rowItem, filters.and, 'and');
      const orFiltersOK = this.filtersMatch(rowItem, filters.or, 'or');
      result = andFiltersOK && orFiltersOK;
      return result;
    },

    favClicked(rowItem) {
      this.$emit('favClick', rowItem);
    },

    filterMatch(rowItem, filter) {
      const value = rowItem[filter.columnName];
      const match = this.compareFilterValue(filter, value);
      return match;
    },

    filtersMatch(rowItem, filters, filterType) {
      let result = true;
      for (let index = 0; index < filters.length; index++) {
        const filter = filters[index];
        const match = this.filterMatch(rowItem, filter);
        if (filterType == 'and') {
          if (!match) {
            return false;
          }
        } else if (filterType == 'or') {
          if (match) {
            return true;
          }
          result = false;
        }
      }
      return result;
    },

    makeCopyViaJson(obj) {
      let result = {};
      if (obj) {
        const jsonStr = JSON.stringify(obj);
        result = JSON.parse(jsonStr);
      }
      return result;
    },

    onCheckboxChange(selected, item) {
      if (this.keyName) {
        if (!this.internalSelectedKeys) {
          this.internalSelectedKeys = [];
        }
        const key = item[this.keyName];
        if (selected) {
          this.internalSelectedKeys.push(key);
        } else {
          const idx = this.internalSelectedKeys.indexOf(key);
          this.internalSelectedKeys.splice(idx, 1);
        }
        // update checkboxValues
        const checkValues = [];
        // eslint-disable-next-line array-callback-return
        this.internalSelectedKeys.map((keyValue) => {
          const obj = {};
          obj[keyValue] = true;
          checkValues.push(obj);
        });
        this.checkboxValues = checkValues;
      }
    },

    onRightClick(event) {
      this.gatComponentsMessageBus.$emit('gatgrid-rowmenu-activated', { menuItem: this.rowMenu });
      this.menu_x = event.clientX;
      this.menu_y = event.clientY;
      event.preventDefault();
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },

    setSortFromOrgSort(val) {
      // console.log("setSortFromOrgSort")
      const sortBy = [];
      const sortByDesc = [];
      if (val) {
        // eslint-disable-next-line array-callback-return
        val.map((item) => {
          if (item.field) {
            sortBy.push(item.field);
            sortByDesc.push(item.desc === true);
          } else {
            const col = this.columns.find((c) => c.header == item.header);
            if (col) {
              sortBy.push(col.field);
              sortByDesc.push(item.desc === true);
            }
          }
        });
      }
      this.sortBy = sortBy;
      this.sortDesc = sortByDesc;
    },

    sortCompare(a, b, sortBy, sortSequence) {
      if (sortSequence > sortBy.length) {
        return 0;
      }
      let aValue;
      if (Object.prototype.hasOwnProperty.call(a, sortBy[sortSequence - 1].field)) {
        aValue = a[sortBy[sortSequence - 1].field];
      }
      let bValue;
      if (Object.prototype.hasOwnProperty.call(b, sortBy[sortSequence - 1].field)) {
        bValue = b[sortBy[sortSequence - 1].field];
      }
      let desc = 1;
      if (sortBy[sortSequence - 1].desc) {
        desc = -1;
      }
      if (aValue > bValue) {
        return 1 * desc;
      }
      if (aValue < bValue) {
        return -1 * desc;
      }
      return this.sortCompare(a, b, sortBy, sortSequence + 1);
    },

    rowbarclassfunc(rowItem) {
      let result = '';
      if (typeof this.rowBarClass == 'string') {
        result = this.rowBarClass;
      } else if (typeof this.rowBarClass == 'function') {
        result = this.rowBarClass(rowItem);
      }
      return result;
    },

    rowclassfunc(rowItem) {
      let result = '';
      if (typeof this.rowclass == 'string') {
        result = this.rowclass;
      } else if (typeof this.rowclass == 'function') {
        result = this.rowclass(rowItem);
      }
      return result;
    },

    rowClicked(rowItem) {
      // eslint-disable-next-line no-restricted-globals
      if (!event.target.href) {
        if (this.selectable) {
          this.selectedItem = rowItem;
        }
        this.$emit('row-clicked', rowItem);
      }
    },

    viewChosen(view) {
      this.selectedView = view;
      if (view === null) {
        this.noViewSelected = true;
        // console.log("view = null (viewChosen)");
        this.setSortFromOrgSort(this.orgSortBy);
      } else {
        this.noViewSelected = false;
      }
    },

    toggleSelectAll(selected) {
      if (!selected.value) {
        this.internalSelectedKeys = [];
      } else if (this.keyName) {
        const keys = [];
        for (let index = 0; index < this.visibleItems.length; index++) {
          const item = this.visibleItems[index];
          const key = item[this.keyName];
          keys.push(key);
        }
        this.internalSelectedKeys = keys;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.row-bar-header {
  width: 8px;
  height: 100%;
}
td {
  font-size: 13px !important;
}

.gatGridClass {
  cursor: default;
}

.rowSelected {
  background-color: lightblue;
}

.cardTitle {
  color: var(--gatGroupCaption);
}
.gridLink {
  color: var(--gatLinkColor);
}

.viewNoTitleRow {
  position: absolute;
  right: -1px;
  top: -1px;
}

/* left-x is used to set a position for sticky colmuns. (should be refactored later) */
::v-deep .left-8 {
  left: 8px !important;
}
::v-deep .left-36 {
  left: 36px !important;
}
::v-deep .left-44 {
  left: 44px !important;
}
::v-deep .left-46 {
  left: 46px !important;
}
::v-deep .left-54 {
  left: 54px !important;
}
::v-deep .left-82 {
  left: 82px !important;
}
::v-deep .left-90 {
  left: 90px !important;
}

// sticky class is used to solve a problem regarding the first column header not being sticky if selectable = true (can't set a css class to the header.)
::v-deep .sticky {
  th:nth-of-type(1) {
    position: sticky;
    left: 0;
    z-index: 4 !important;
  }
}

//sticky-column
::v-deep th {
  z-index: 3 !important;
}
::v-deep td {
  z-index: 3 !important;
}
::v-deep tr {
  th.sticky-column {
    background: white !important;
    position: sticky !important;
    left: 0;
    z-index: 4 !important;
  }
  td.sticky-column {
    background: white;
    position: sticky !important;
    left: 0;
    z-index: 2 !important;
  }
}
::v-deep tr:hover {
  td.sticky-column {
    background: inherit;
  }
}

::v-deep .theme--dark {
  tr:hover {
    td.sticky-column {
      background: inherit;
    }
  }
}
::v-deep .theme--dark {
  tr {
    th.sticky-column {
      background: #1e1e1e !important;
    }
    td.sticky-column {
      background: #1e1e1e;
    }
  }
}
</style>

<style>
.nowrap {
  white-space: nowrap;
}

.right {
  float: right !important;
}

.gridLink {
  cursor: pointer;
  text-decoration: none;
  color: var(--gatLinkColor) !important;
}

.gridMainLink {
  cursor: pointer;
  text-decoration: none;
  color: var(--gatLinkColor) !important;
  font-weight: 500;
}

.reloadBtn {
  margin-top: -15px !important;
}

.v-data-table-header v-data-table-header-mobile {
  display: none !important;
}

.inlineEditCol {
  padding-top: 14px !important;
  padding-bottom: 0px !important;
}
</style>
