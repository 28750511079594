import { apiGet } from '@/store/api';
import { useHelperStore } from '@/store/helperStore';

export async function getAllHelpers() {
  const helperStore = useHelperStore();
  const data = await apiGet('helper');
  helperStore.setCallTypes(data.callTypes);
  helperStore.setWebAccessGroups(data.webAccessGroups);
  helperStore.setServiceTypes(data.serviceTypes);
  helperStore.setCargoActions(data.cargoActions);
  helperStore.setCargoTypes(data.cargoTypes);
  helperStore.setUnitTypes(data.unitTypes);
  helperStore.setPackageTypes(data.packageTypes);
  helperStore.setHotlistTemporaryTypes(data.hotlistTemporaryTypes);
  helperStore.setCrewChangeTypes(data.crewChangeTypes);
  helperStore.setcrewChangeRanks(data.crewChangeRanks);
}
