<template>
  <v-layout>
    <v-dialog v-model="showDialog" persistent max-width="400">
      <v-card class="pa-2">
        <v-card-title primary-title>
          <v-container fill-height>
            <v-layout row wrap align-center>
              <v-flex class="text-xs-center">
                <LoginLogo />
              </v-flex>
            </v-layout>
          </v-container>
          <v-alert class="ml-2 mr-2" :text="true" :type="'error'">
            The GAT-Ship Web module is not activated, please contact your system administrator.
          </v-alert>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mb-1 mr-1" color="primary" block primary right v-on:click="refresh">Refresh</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  name: 'NoLicense',
  props: [],
  components: {
    LoginLogo: () => import('./LoginLogo.vue'),
  },
  data() {
    return {
      showDialog: true,
    };
  },

  computed: {},

  created() {},

  watch: {},

  methods: {
    refresh() {
      console.log('refresh');
      this.$router.push('/');
      this.$router.go();
    },
  },
};
</script>

<style scoped></style>
