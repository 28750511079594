<template>
  <gat-form ref="gatform" :readonly="!userCanEditService" class="pb-12" :isLoading="isLoading">
    <!-- {{service}} -->
    <GatGroup title="Service details" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field :value="currentServiceType.STY_NAME" label="Type" />
          <gat-compact-field
            :class="currentServiceStatus.iconClass"
            :value="currentServiceStatus.NAME"
            label="Status"></gat-compact-field>
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-select
          size="m"
          res="servicetype"
          v-model="service.SER_TYPE_ID"
          :items="serviceTypes"
          label="Service type"
          textFieldName="NameAndAgencyFilter"
          codeFieldName="STY_ID"
          required>
        </gat-select>
        <gat-select
          size="m4"
          v-model="service.SER_STATUS"
          :items="helperStore.serviceStatuses"
          label="Status"
          textFieldName="NAME"
          codeFieldName="ID"
          :useIcons="true"
          :disabled="isNew && !applicationStore.user.internal">
        </gat-select>
        <gat-client-select
          v-show="!(isNew && !applicationStore.user.internal)"
          label="Supplier"
          v-model="service.SER_SUPPLIER_ID"
          category="1"
          :role="currentServiceType.STY_ROLE_ID"
          :showAll="currentServiceType.STY_ROLE_ID > 0"
          :disabled="isNew && !applicationStore.user.internal" />
        <gat-edit
          v-show="!(isNew && !applicationStore.user.internal)"
          v-model="service.SER_SORT_ORDER"
          label="Sort order"
          dataType="number"
          size="xs"
          :disabled="isNew && !applicationStore.user.internal">
        </gat-edit>
      </v-layout>
    </GatGroup>

    <gat-group
      title="Description and remarks"
      size="xs12"
      :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || editMode">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field v-model="service.SER_DESCRIPTION" label="Description" size="l" />
          <gat-compact-field
            v-if="webmodulUserType !== 3"
            v-model="service.SER_WEB_COMMENT"
            :label="!applicationStore.user.internal ? 'Comments' : 'External comments'"
            :size="splitSize([service.SER_DESCRIPTION, service.SER_WEB_COMMENT, service.SER_OUR_COMMENT], 2)" />
          <gat-compact-field
            v-if="applicationStore.user.internal"
            v-model="service.SER_OUR_COMMENT"
            :label="webmodulUserType === 3 ? 'Comments' : 'Internal comments'"
            :size="splitSize([service.SER_DESCRIPTION, service.SER_WEB_COMMENT, service.SER_OUR_COMMENT], 2)" />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-edit v-model="service.SER_DESCRIPTION" label="Description" size="lg6 md6 sm12" :rows="3" :maxChars="0">
        </gat-edit>
        <gat-edit
          v-if="webmodulUserType !== 3"
          v-model="service.SER_WEB_COMMENT"
          :label="applicationStore.user.internal ? 'External comments' : 'Comments'"
          size="lg6 md6 sm12"
          :rows="3"
          :maxChars="0">
        </gat-edit>
        <gat-edit
          v-if="applicationStore.user.internal"
          v-model="service.SER_OUR_COMMENT"
          :label="webmodulUserType === 3 ? 'Comments' : 'Internal comments'"
          size="lg6 md6 sm12"
          :rows="3"
          :maxChars="0">
        </gat-edit>
      </v-layout>
    </gat-group>

    <GatGroup title="Source / origin" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || editMode">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field
            :value="globalFormatDateTime(service.SER_ORDER_TIME)"
            label="Time of request"
            size="lg2 md2 sm4 xs12" />
          <!-- Requested date -->
          <gat-compact-field :value="service.SER_ORDER_BY" label="Requested by" size="lg2 md2 sm4 xs12" />
          <gat-compact-field :value="service.SER_USER" label="Created by" size="lg2 md2 sm4 xs12" />
        </v-layout>
      </template>
      <v-layout wrap>
        <!-- <gat-edit v-model="service.SER_ORDER_BY" label="Requested by"> </gat-edit>  -->
        <gat-edit
          v-model="service.SER_ORDER_TIME"
          label="Time of request"
          dateTimeEdit
          :disabled="isNew && !applicationStore.user.internal"
          size="lg3 md4 sm6 xs12" />
        <gat-select
          v-model="service.SER_ORDER_BY"
          label="Requested by"
          :items="hotlist"
          :combobox="true"
          textFieldName="NAME"
          codeFieldName="NAME"
          :disabled="isNew && !applicationStore.user.internal"
          size="lg3 md4 sm6 xs12" />
        <gat-edit
          v-model="service.SER_USER"
          label="Created by"
          :maxChars="100"
          :disabled="isNew && !applicationStore.user.internal"
          size="lg3 md4 sm6 xs12">
        </gat-edit>
      </v-layout>
    </GatGroup>

    <GatGroup title="Time of service" :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || editMode">
      <!-- Delivery time -->
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field
            v-if="service.SER_TIME_TYPE == 0"
            :value="globalFormatDateTime(service.SER_REQUESTED_DELIVERY_DATE)"
            label="Spesific time" />
          <gat-compact-field
            v-else
            :value="globalFormatDateTime(calculatedDeliveryTime)"
            :label="
              service.SER_TIME_HOURS ? service.SER_TIME_HOURS + ' ' + calculationLabel : ' ' + calculationLabel
            " />
        </v-layout>
      </template>
      <v-layout wrap>
        <gat-select
          v-model="service.SER_TIME_TYPE"
          :items="serviceCalcTypes"
          label="Calculation type"
          textFieldName="NAME"
          codeFieldName="ID"
          size="lg3 md4 sm6 xs12" />
        <gat-edit
          v-model="service.SER_TIME_HOURS"
          v-if="service.SER_TIME_TYPE > 0"
          :label="calculationLabel"
          dataType="number"
          :required="service.SER_TIME_TYPE != 0"
          size="lg3 md4 sm6 xs12"
          :validatorRules="[validateTimeOfService]" />
        <gat-edit
          v-model="service.SER_REQUESTED_DELIVERY_DATE"
          v-if="service.SER_TIME_TYPE == 0"
          label="Date"
          dateTimeEdit
          :required="service.SER_TIME_TYPE == 0"
          size="lg3 md4 sm6 xs12" />
        <gat-edit
          :value="calculatedDeliveryTime"
          :readonly="true"
          :disabled="true"
          v-if="service.SER_TIME_TYPE > 0"
          label="Calculated date"
          dateTimeEdit
          size="lg3 md4 sm6 xs12" />
      </v-layout>
    </GatGroup>

    <GatGroup
      v-show="getDynaFieldIds && getDynaFieldIds.length > 0"
      title="User defined"
      :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || !editMode">
      <template slot="compact">
        <gat-dyna-fields
          :compact="true"
          v-model="service.dynaValues"
          componentName="pDynaFields.ServicesDyna"
          container="pDynaFields"
          :filterByIds="getDynaFieldIds"></gat-dyna-fields>
      </template>
      <gat-dyna-fields
        v-model="service.dynaValues"
        componentName="pDynaFields.ServicesDyna"
        container="pDynaFields"
        :filterByIds="getDynaFieldIds"
        :disabled="isNew && !applicationStore.user.internal"></gat-dyna-fields>
    </GatGroup>

    <GatGroup
      v-if="currentServiceType.STY_USE_DOC"
      title="Documents"
      :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || editMode">
      <template slot="compact">
        <gat-compact-field
          v-if="isNew"
          value="The service must be created in order to add documents."
          label=""
          size="xxl" />
        <gat-compact-field
          v-else-if="!userCanEditService"
          value="Additional user rights needed to add documents."
          label=""
          size="xxl" />
        <embedded-doc-list v-else location="service" :parentId="serviceId" :compact="true" />
      </template>
      <span v-if="isNew" class="pb-2">The service must be created in order to add documents.</span>
      <span v-else-if="!userCanEditService" class="pb-2">Additional user rights needed to add documents.</span>
      <embedded-doc-list v-else location="service" :parentId="serviceId" />
    </GatGroup>
    <GatGroup
      v-if="applicationStore.userRight('SER.SHOW_EXPENSES')"
      title="Expense"
      :collapsed="isNew ? !expandedIfNew : $vuetify.breakpoint.mdAndDown || editMode">
      <template slot="compact">
        <v-layout wrap>
          <gat-compact-field
            v-if="isNew"
            value="The service must be created in order to add an expense."
            label=""
            size="xxl" />
          <template v-else>
            <gat-compact-field
              v-for="exp in expenseList"
              :key="exp.EXP_ID"
              :value="formatNumber(exp.EXP_LOCAL_SUM)"
              :label="exp.EXP_TEXT"
              size="m" />
          </template>
        </v-layout>
      </template>
      <span v-if="isNew" class="pb-2">The service must be created in order to add an expense.</span>
      <embedded-exp-list
        v-else-if="userCanSeeExpenses"
        :items="expenseList"
        :showAddBtn="true"
        :serviceId="service.SER_ID"
        :expenseTemplateId="service.STY_EXP_TEMPLATE_ID"
        :callId="callId"
        @expense-added="expenseAdded()" />
    </GatGroup>

    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title">Delete expense</v-card-title>

        <v-card-text class="text-center">Do you want to delete the expense connected to the service?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteClicked">No</v-btn>
          <v-btn text color="primary" @click="deleteServiceAndExpense()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <GatGroup title="data" class="pb-4"  :collapsed=true>
          <v-layout wrap>
              <record-presenter :value="this.portcallStore.hotlist"  caption="data" />
              <record-presenter :value="this.portcallStore"  caption="data2" />
          </v-layout>
      </GatGroup> -->

    <gat-bottom-panel
      :orgValue="orgService"
      :newValue="service"
      itemDescription="service"
      :saveBtn="true"
      :deleteBtn="true"
      :buttonsEnabled="!saving"
      :deleteDisabled="!service || !service.SER_ID || !applicationStore.userRight('SER.DELETE')"
      @saveClicked="saveClicked"
      @deleteClicked="hasExpense ? (showDialog = true) : deleteClicked()"
      :stopNavigate="true">
    </gat-bottom-panel>
  </gat-form>
</template>

<script>
import GatClientSelect from '@/app-components/GatClientSelect.vue';
import GatUtils from '@/app-components/GatUtils';
import EmbeddedExpList from '@/views/portcall/expenses/EmbeddedExpList.vue';
import EmbeddedDocList from '@/app-components/EmbeddedDocList.vue';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import moment from 'moment';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { getServiceTypes } from '@/services/api/api-calls/getServiceTypes';
import { getServices } from '@/services/api/api-calls/getServices';
import { getPendingActivities } from '@/services/api/api-calls/getPendingActivities';
import { deleteServiceAndExpense } from '@/services/api/api-calls/deleteServiceAndExpense';

export default {
  name: 'Service',
  props: {
    callId: {
      type: [String, Number],
    },
    serviceId: {
      type: [String, Number],
    },
    isNew: {
      type: Boolean,
    },
    accessGroup: {
      type: [Object],
    },
    expenseId: {
      type: [String, Number],
      default: null,
    },
  },
  components: { GatClientSelect, EmbeddedExpList, EmbeddedDocList, GatDynaFields },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      portcallStore,
      applicationStore,
      toolbarStore,
    };
  },
  data() {
    return {
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      saving: false,
      service: {},
      orgService: {},
      isLoading: false,
      showDialog: false,
      updatedExpenseId: 0,
    };
  },

  computed: {
    webmodulUserType(){
      return this.applicationStore.globalSettings.SGL_USE_WEBMODULE_2021;
    },
    appCriticalInitFinished() {
      return this.applicationStore.appCriticalInitFinished;
    },

    hasExpense() {
      if (this.service && this.service.SER_EXP_ID) {
        return true;
      }
      return false;
    },

    calculatedDeliveryTime() {
      let arrival = this.portcallStore.callDetails.ATA;
      if (!arrival) {
        arrival = this.portcallStore.callDetails.ETA;
      }
      let departure = this.portcallStore.callDetails.ATD;
      if (!departure) {
        departure = this.portcallStore.callDetails.ETD;
      }
      arrival = moment(arrival);
      departure = moment(departure);

      switch (this.service.SER_TIME_TYPE) {
        case 1:
          return arrival.add(-1 * this.service.SER_TIME_HOURS, 'hour');
        case 2:
          return arrival.add(this.service.SER_TIME_HOURS, 'hour');
        case 3:
          return departure.add(-1 * this.service.SER_TIME_HOURS, 'hour');
        case 4:
          return departure.add(this.service.SER_TIME_HOURS, 'hour');
        default:
          break;
      }
      return arrival;
    },
    calculationLabel() {
      let result = 'Hours';
      if (this.service.SER_TIME_TYPE > 0) {
        result = `Hours ${GlobalHelperFunctions.lookupCode(
          this.service.SER_TIME_TYPE,
          this.serviceCalcTypes,
          'ID',
          'NAME'
        ).toLowerCase()}`;
      }
      return result;
    },
    currentServiceType() {
      let serviceType = this.helperStore.serviceTypes.find((item) => item.STY_ID == this.service.SER_TYPE_ID);
      if (!serviceType) {
        serviceType = {};
      }
      return serviceType;
    },
    currentServiceStatus() {
      const status = this.helperStore.serviceStatuses.find((item) => item.ID == this.service.SER_STATUS);
      if (status) {
        return status;
      }
      return {};
    },
    editMode() {
      return this.service.SER_ID > 0;
    },
    expenseList() {
      const result = [];
      console.log(this.service);
      if (this.service.expense) {
        result.push(this.service.expense);
      }
      return result;
    },
    getDynaFieldIds() {
      const result = [];
      if (this.currentServiceType.dynaFields) {
        // eslint-disable-next-line array-callback-return
        this.currentServiceType.dynaFields.map((item) => {
          result.push(item.ID);
        });
      }
      return result;
    },
    hotlist() {
      const result = [];
      // eslint-disable-next-line array-callback-return
      this.portcallStore.hotlist.map((item) => {
        if (item.H_ID > 0) {
          result.push(item.C_NAME);
        }
      });
      return result;
    },

    serviceTypes() {
      let result = this.helperStore.serviceTypes;
      if (result.length == 0) {
        getServiceTypes();
      }
      const portcallAgencyId = this.portcallStore.callDetails.SETUP_ID;
      result = result.filter((item) => {
        if (item.agencyFilter.length === 0) {
          return true;
        }
        return item.agencyFilter.includes(portcallAgencyId);
      });
      console.log(portcallAgencyId);
      console.log(result);
      return result;
    },

    serviceCalcTypes() {
      return [
        { ID: 0, NAME: 'Specific time' },
        { ID: 1, NAME: 'Before arrival' },
        { ID: 2, NAME: 'After arrival' },
        { ID: 3, NAME: 'Before departure' },
        { ID: 4, NAME: 'After departure' },
      ];
    },
    totalExpAmount() {
      let result = 0;
      if (this.service.expense) {
        result = this.service.expense.EXP_LOCAL_SUM;
      }
      return result;
    },
    userCanEditService() {
      if (this.serviceId > 0) return this.applicationStore.userRight('SER.EDIT');
      return this.applicationStore.userRight('SER.NEW_AND_EDIT');
    },
    userCanSeeExpenses() {
      if (!this.applicationStore.user.internal) {
        return this.applicationStore.userRight('SER.SHOW_EXPENSES');
      }
      return true;
    },
    /* userCanAccessExpenses(){
              if(!this.applicationStore.user.internal){
                  return this.applicationStore.userRight('EX.ACCESS');
              }
              return true;
          }, */
    userCanAccessService() {
      if (!this.applicationStore.user.internal) {
        return this.applicationStore.userRight('SER.ACCESS');
      }
      return true;
    },
  },

  created() {},

  activated() {
    if (this.applicationStore.user.externalClientId && !this.callId) {
      this.toolbarStore.setBackNavigation([{ text: 'services', href: '/services' }]);
    } else {
      this.toolbarStore.setBackNavigation([
        { text: 'port call', href: `/call/${this.callId}` },
        { text: 'service list', href: `/call/${this.callId}/services` },
      ]);
    }
    this.expandedIfNew = this.isNew;
  },

  deactivated() {
    this.toolbarStore.setBackNavigation(undefined);
    this.expandedIfNew = !this.isNew;
  },

  watch: {
    expenseId: {
      handler(id) {
        this.updatedExpenseId = id;
      },
    },
    updatedExpenseId(id) {
      if (id != 0) {
        this.getServiceItem(this.serviceId);
        this.updatedExpenseId = 0;
      }
    },
    serviceId: {
      handler(id) {
        if (this.isNew) {
          this.createNewService();
        } else if (!this.expenseId) {
          // set isLoading to true to show progressbar if response is not immidiate
          this.isLoading = true;
          this.getServiceItem(id);
        }
      },
      immediate: true,
    },
    currentServiceType(newValue) {
      if (this.isNew) {
        this.service.dynaFields = newValue.dynaFields;
        this.orgService.dynaFields = newValue.dynaFields;
      }
    },
  },

  methods: {
    globalFormatDateTime(date, format) {
      return GlobalHelperFunctions.globalFormatDateTime(date, format);
    },
    formatNumber(value, format) {
      return GlobalHelperFunctions.formatNumber(value, format);
    },
    splitSize(fields, maxHorizontal) {
      return GlobalHelperFunctions.splitSize(fields, maxHorizontal);
    },
    validateTimeOfService(value) {
      return value >= 0 && value <= 2400 ? true : 'Max 100 days before or after (2400 hours)';
    },
    createNewService() {
      if (this.appCriticalInitFinished) {
        // needs the user data in order to use the API. if it's not loaded, try again.
        let service = { ...this.service };
        service = {
          SER_STATUS: 20,
          SER_PORTCALL_ID: this.callId,
          SER_ORDER_TIME: GlobalHelperFunctions.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss'),
          SER_USER: this.applicationStore.user.userName,
          dynaValues: [],
          SER_ORDER_BY: null,
        };

        service.SER_SORT_ORDER = this.getNextSortOrder();

        if (!this.applicationStore.user.internal) {
          // Set service data for external users.
          service.SER_STATUS = 10;
          apiGet(`clients/${this.applicationStore.user.externalClientId}`).then((client) => {
            service.SER_ORDER_BY = client.NAME;
            this.service = service;
            this.orgService = GatUtils.makeCopyViaJson(this.service);
          });
        } else {
          this.service = service;
          this.orgService = GatUtils.makeCopyViaJson(this.service);
        }
      } else {
        setTimeout(() => {
          this.createNewService();
        }, 500);
      }
    },
    expenseAdded() {
      this.getServiceItem(this.serviceId);
    },
    getNextSortOrder() {
      let next = 0;
      // eslint-disable-next-line array-callback-return
      this.portcallStore.services.map((item) => {
        if (item.SER_SORT_ORDER >= next) {
          next = item.SER_SORT_ORDER;
        }
      });
      // this.service.SER_SORT_ORDER = next+10;
      // this.orgService.SER_SORT_ORDER = next+10;
      return next + 10;
    },

    getServiceItem(id) {
      apiGet(`/service/${id}`).then((data) => {
        // remove progressbar
        this.isLoading = false;
        this.service = data;
        this.orgService = GatUtils.makeCopyViaJson(this.service);
      });
    },

    saveClicked() {
      const formValid = this.$refs.gatform.validate();
      if (!this.service.STY_NAME) {
        // Used in back-end to set history field.
        this.service.STY_NAME = this.currentServiceType.STY_NAME;
      }
      if (formValid) {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiPut('service', {
          value: this.service,
          orgValue: this.orgService,
          dynavalues: this.service.dynaValues,
          orgDynavalues: this.orgService.dynaValues,
        })
          .then((result) => {
            // remove progressbar

            if (result.resultCategory == 1) {
              this.service = result.payload;
              this.orgService = GatUtils.makeCopyViaJson(this.service);
              getServices(this.callId);
              // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
              this.$nextTick(() => {
                this.$router.push(`/call/${this.callId}/services`); // go back
                // setTimeout(()=>{this.$router.push('/call/'+this.callId+'/services');}, 1000); //go back
              });

              this.isLoading = false;
            }
          })
          .finally(() => {
            getPendingActivities();
          });
      }
    },

    deleteClicked() {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`service/${this.service.SER_ID}`)
        .then((result) => {
          // remove progressbar
          this.isLoading = false;
          getServices(this.callId);
          this.saving = false;
          if (result.resultCategory == 1) {
            this.$router.push(`/call/${this.callId}/services`); // go back
          }
          this.showDialog = false;
          this.showExpenseDialog = false;
        })
        .finally(() => {
          getPendingActivities();
        });
    },

    deleteServiceAndExpense() {
      deleteServiceAndExpense({
        serviceId: this.service.SER_ID,
        expenseId: this.service.EXP_ID,
      });
      this.$nextTick(() => {
        getPendingActivities();
        this.$router.push(`/call/${this.callId}/services`); // go back
      });
    },
  },
};
</script>

<style scoped></style>
