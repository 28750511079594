
const Bowser = require("bowser"); // CommonJS

export function isValidBrowser(){
    const browser = Bowser.getParser(window.navigator.userAgent);

    const valid = browser.satisfies({      
        safari:">=13",
        chrome: ">=52",
        firefox: ">=61",
        opera: ">=40",
        android: '>=52',
        samsung_internet: '>=6',
        edge: '>= 40'
      });

    console.log(`The current browser name is "${browser.getBrowserName()}"`);
    console.log(`The current browser version is "${browser.getBrowserVersion()}"`);

    // alert(browserName() + ' ' + browserVersion() + ' '+browserPlatform() + ' '+ browserOs());

    return valid;
}

export function browserName(){
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserName();
}

export function browserVersion(){
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getBrowserVersion();
}

export function browserPlatform(){
    const userAgent = Bowser.parse(window.navigator.userAgent);
    if (userAgent && userAgent.platform && userAgent.platform.type){
        return userAgent.platform.type;
    }
    
        return 'unknown';
    
}

export function browserOs(){
    const userAgent = Bowser.parse(window.navigator.userAgent);
    if (userAgent && userAgent.os && userAgent.os.name){
        const os = userAgent.os.name;
        let osVersion = '';
        if (userAgent.os.versionName){
            osVersion = userAgent.os.versionName;
        }

        return `${os} ${osVersion}`;
    }
    
        return 'unknown';
    
}