<template>
  <embedded-dashboard location="expenses" title="Summary" @dataRetrieved="dataRetrieved">
    <template slot="compact">
      <v-layout wrap>
        <gat-compact-field
          :value="dashData.ExpenseAmounts['Balance due']"
          size="s"
          label="Balance due"
          :isCurrency="true" />
        <gat-compact-field :value="dashData.ExpenseRevenue['Total']" size="s" label="Revenue" :isCurrency="true" />
        <gat-compact-field
          :value="
            extractExpenseLineCount('Ready for invoicing') +
            ' of ' +
            (extractExpenseLineCount('Ready for invoicing') + extractExpenseLineCount('Budget'))
          "
          size="s"
          label="Ready for invoicing" />
        <gat-compact-field
          :value="
            extractExpenseLineCount('Invoiced') +
            ' of ' +
            (extractExpenseLineCount('Ready for invoicing') +
              extractExpenseLineCount('Budget') +
              extractExpenseLineCount('Invoiced'))
          "
          size="s"
          label="Invoiced" />
      </v-layout>
    </template>
  </embedded-dashboard>
</template>

<script>
import EmbeddedDashboard from '@/modules/dashboard/components/EmbeddedDashboard.vue';

export default {
  name: 'ExpenseDashBoard',
  props: ['callId'],
  components: { EmbeddedDashboard },
  data() {
    return {
      dashData: { ExpenseAmounts: {}, ExpenseLineStatuses: {}, ExpenseRevenue: {} },
    };
  },

  computed: {
    dashItems() {
      const result = [];
      result.push({
        title: 'Amounts',
        reportName: 'ExpenseAmounts',
        dataUrl: `expense/reports/Amounts/${this.callId}`,
        availableForExternalUser: 1,
        sorting: 1,
        columns: [
          { header: 'Category', field: 'col_1' },
          { header: 'Amount', field: 'col_2', dataType: 'currency', width: '120px' },
        ],
        settings: {
          chartType: 'Table',
          size: { width: 0, height: 0 },
          hidden: false,
          hiddenOnPhone: false,
          options: {
            isStacked: true,
            legend: { position: 'none' },
          },
        },
      });
      result.push({
        title: 'Line statuses',
        reportName: 'ExpenseLineStatuses',
        dataUrl: `expense/reports/LineCount/${this.callId}`,
        availableForExternalUser: 1,
        sorting: 2,
        settings: {
          chartType: 'Table',
          size: { width: 0, height: 0 },
          hidden: false,
          hiddenOnPhone: false,
          options: {
            isStacked: false,
            legend: { position: 'top' },
          },
        },
      });
      result.push({
        title: 'Revenue',
        reportName: 'ExpenseRevenue',
        dataUrl: `expense/reports/Revenue/${this.callId}`,
        availableForExternalUser: 1,
        sorting: 3,
        columns: [
          { header: 'Group', field: 'col_1' },
          { header: 'Amount', field: 'col_2', dataType: 'currency', width: '120px' },
        ],
        settings: {
          chartType: 'Table',
          size: { width: 0, height: 0 },
          hidden: false,
          hiddenOnPhone: false,
          options: {
            isStacked: true,
            legend: { position: 'none' },
          },
        },
      });
      result.push({
        title: 'Per client',
        reportName: 'PerClient',
        dataUrl: `expense/reports/PerClient/${this.callId}`,
        availableForExternalUser: 1,
        sorting: 4,
        columns: [
          { header: 'Client', field: 'col_1' },
          { header: 'Balance due', field: 'col_2', dataType: 'currency', width: '110px' },
          { header: 'Revenue', field: 'col_3', dataType: 'currency', width: '110px' },
        ],
        settings: {
          chartType: 'Table',
          size: { width: 0, height: 0 },
          hidden: false,
          hiddenOnPhone: false,
          options: {
            isStacked: true,
            legend: { position: 'none' },
          },
        },
      });
      // eslint-disable-next-line array-callback-return
      result.map((item) => {
        // eslint-disable-next-line no-param-reassign
        item.location = 1; // 1 = expenses
      });
      return result;
    },
  },

  created() {},
  activated() {},

  methods: {
    extractExpenseLineCount(statusName) {
      let result = 0;
      const no = this.dashData.ExpenseLineStatuses[statusName];
      if (no) {
        result = no;
      }
      return result;
    },

    dataRetrieved(data) {
      this.dashData[data.reportName] = this.flattenDataTable(data.data);
    },
    flattenDataTable(orgData) {
      const result = {};
      for (let index = 1; index < orgData.length; index++) {
        const element = orgData[index];
        // eslint-disable-next-line prefer-destructuring
        result[element[0]] = element[1];
      }

      return result;
    },
  },
};
</script>

<style scoped></style>
