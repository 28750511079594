import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { useDashboardStore } from '@/store/dashboardStore';

export async function fetchAllChartData({ dashboardGroupId }: { dashboardGroupId: string | number }) {
  if (!dashboardGroupId) {
    console.error('group name not present.');
    return;
  }
  // eslint-disable-next-line no-param-reassign
  dashboardGroupId = +dashboardGroupId;
  const agencyId = useApplicationStore().getCurrentAgency;
  const result = await apiGet(`/dashboard/group-data/${dashboardGroupId}/${agencyId}`);
  // console.log(
  //   `%cDASHBOARD API: dashboard/group-data/${dashboardGroupId}/${agencyId}`,
  //   'background: #222; color: white'
  // );
  result.forEach((report: any) => {
    const { reportName, reportData } = report;
    useDashboardStore().setReportData({ reportName, reportData });
  });
}
