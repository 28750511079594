import { useApplicationStore } from '@/store/applicationStore';
import { apiGet } from '../../../store/api';

export async function getLastClientsUsed() {
  const data = await apiGet('savedinfo/web_last_clients');
  if (data.length > 0) {
    const lastClients = JSON.parse(data[0].MEMOVALUE1);
    useApplicationStore().setLastClientsUsed(lastClients);
  }
}
