<template>
  <div>
    <email-drag-drop
      v-model="isDragging"
      @parsed-email-list="processParsedEmails"
      :disabled="!canSend || sednaApi?.isSednaIntegration"
      :emails="emails"
      :location="location"
      :parent-id="locationId">
      <template slot="content">
        <email-search
          v-if="!sednaApi?.isSednaIntegration"
          :labels="emailLabels"
          @email-filter-parameters-changed="($event) => filterdEmails($event)"
          :maxRowCount="emailFilterParameters.maxRowCount" />
        <gat-grid
          title="E-Mails"
          class="table-spacing elevation-0"
          dense
          :items="emailItems"
          :hide-empty-columns="true"
          :columns="columns"
          :fixedheader="true"
          :rowMenu="rowMenu"
          height="calc(100vh - 135px)"
          width="100%"
          reloadBtn
          @reload-clicked="getEmails"
          @row-clicked="gotoEmailItem"
          @rowMenuClicked="rowMenuClicked"
          keyName="ID"
          :loading="isLoading"
          :selectable="canSelect"
          viewGroupName="emailList"
          gridName="emailCompGrid"
          :selectedKeys="selectedEmailIds"
          @selectionChanged="selectedEmailIds = $event">
          <!-- :orgSortBy="[{field:'SENT', desc:true}]" -->
          <template slot="cell" slot-scope="props">
            <grid-label v-if="props.column.field == 'LABEL_ID' && props.value" :labelId="props.row.LABEL_ID" />
            <a
              v-else-if="props.column.field == 'EXTMAIL2_NO' && props.value"
              :href="'callmark:OpenDocument?' + props.value"
              target="_blank"
              class="gridLink">
              {{ props.value }}
            </a>
            <div v-else-if="props.column.field == 'FROM_'">
              <!-- style="white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important; max-width: 150px;" -->
              <a
                v-if="props.value.includes('@') && emailLink"
                :href="`mailto:${props.value}`"
                class="gridLink"
                @click.stop>
                {{ props.value }}
              </a>
              <span v-else>
                {{ props.value }}
              </span>
            </div>
            <div
              v-else-if="props.column.field == 'TO_' || props.column.field == 'CC'"
              class="d-flex justify-start align-center">
              <!-- style="white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important; max-width: 100%;" -->

              <!-- <div v-if="formatEmailTo(props.value).emails && formatEmailTo(props.value).emails.length >= 1">(+{{formatEmailTo(props.value).emails.length}})</div> -->
              <a
                v-if="formatEmailTo(props.value).email.includes('@') && emailLink"
                :href="`mailto:${formatEmailTo(props.value).email}`"
                class="gridLink"
                @click.stop>
                {{
                  formatEmailTo(props.value).email.length >= 50
                    ? formatEmailTo(props.value).email.substring(0, 50) + '...'
                    : formatEmailTo(props.value).email
                }}
              </a>
              <span v-else>
                {{
                  formatEmailTo(props.value).email.length >= 50
                    ? formatEmailTo(props.value).email.substring(0, 50) + '...'
                    : formatEmailTo(props.value).email
                }}
              </span>
              <div
                v-if="formatEmailTo(props.value).emails && formatEmailTo(props.value).emails.length >= 1"
                @click.stop>
                <v-tooltip
                  bottom
                  v-if="formatEmailTo(props.value).emails && formatEmailTo(props.value).emails.length >= 1">
                  <template v-slot:activator="{ on, attrs }">
                    <!-- <div v-bind="attrs" v-on="on">+{{formatEmailTo(props.value).emails.length}}</div> -->
                    <div v-bind="attrs" v-on="on" class="ml-1">
                      <v-chip x-small color="blue" class="pa-1 white--text" label
                        >+{{ formatEmailTo(props.value).emails.length }}</v-chip
                      >
                    </div>
                  </template>
                  <div v-for="people in formatEmailTo(props.value).emails" :key="people">
                    <span>{{ people }}</span>
                  </div>
                </v-tooltip>
              </div>
            </div>
            <div
              v-else-if="props.column.field == 'SUBJECT'"
              style="white-space: nowrap !important; overflow: hidden !important; text-overflow: ellipsis !important">
              <span v-if="props.value && props.value.length > subjectStringLen">
                {{ props.value.substring(0, subjectStringLen) + '...' }}
              </span>
              <span v-else-if="props.value">{{ props.value }}</span>
            </div>
            <div v-else-if="props.column.field == 'NUM_ATTACHMENTS'" class="d-flex justify-center">
              <div v-if="props.value > 0" style="position: relative">
                <v-icon>
                  <!-- style="transform: rotate(90deg);" -->
                  mdi-attachment
                </v-icon>
                <!-- <div class="icon-counter elevation-1">{{props.value}}</div>
                      <div v-if="props.value > 9" style="position: absolute; top: -10px; right: -15px; text-align: cetner; line-height: 18px; font-weight: 400; font-size: 14px;" >+</div> -->
              </div>
            </div>

            <div v-else-if="props.column.field == 'WEB_ACCESS' && props.value" class="d-flex flex-nowrap">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip small v-bind="attrs" v-on="on" @click.stop="showProperties(props.row)">{{
                    getAccessGroupNames(props.value).displayName
                  }}</v-chip>
                </template>
                <div v-for="accesGroups in getAccessGroupNames(props.value).content" :key="accesGroups">
                  {{ accesGroups }}
                </div>
              </v-tooltip>
            </div>

            <div
              v-else-if="
                (applicationStore.user.internal && props.column.field == 'CONTEXT_MENU') ||
                ($vuetify.breakpoint.xs && props.column.field == 'CONTEXT_MENU')
              "
              class="d-flex align-center justify-center">
              <v-btn icon @click.stop="showContextMenu($event, props.row.ID)">
                <v-icon color="primary">mdi-dots-vertical</v-icon>
              </v-btn>
            </div>
          </template>
          <template slot="btns">
            <wm-button-group
              class="right mr-1"
              :buttons="wmButtonGroup"
              @newEmailClick="newEmailClick"
              @openSednaClick="openSednaClick"
              @createEmailClick="createEmailClick"
              @deleteClick="showDeleteDialog = true" />
          </template>
        </gat-grid>
      </template>
    </email-drag-drop>
    <gat-dialog
      v-if="!sednaApi?.isSednaIntegration"
      v-model="emailItemDialogVisible"
      :cancel-button-disabled="false"
      ok-button-disabled
      fullscreen
      title="Email preview"
      @cancelClick="closeEmailItemDialog">
      <email-item
        v-if="selectedEmailItemId"
        :email-id="selectedEmailItemId"
        :portcall-id="portcallId"
        :vessel-id="vesselId"
        :client-id="clientId"
        :location="location" />
    </gat-dialog>
    <EmailRecipientsDialog
      v-if="showEmailReciepientsDialog"
      v-model="showEmailReciepientsDialog"
      :portcallId="portcallId"
      :location="location"
      @recipients-selected="recipientsSelected" />
    <v-overlay :value="isLoadingOverlay" :absolute="true" opacity="0.3" style="z-index: 3 !important">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="showDeleteDialog" persistent max-width="340">
      <v-card>
        <v-card-title class="title">Delete</v-card-title>
        <v-card-text class="text-center"
          >Are you sure you want to delete <span v-if="selectedEmailIds.length >= 1"></span
          >{{
            selectedEmailIds.length > 1 ? selectedEmailIds.length + ' e-mails' : selectedEmailIds.length + ' e-mail'
          }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteEmails()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <email-generator
      :portcallId="portcallId"
      :location="location"
      :guid="emailTemplateGuid"
      @documentCreated="getEmails"
      @refreshEmail="getEmails" />
    <email-properties
      :showForm="showPropertiesForm"
      v-model="email"
      :webAccessGroups="webAccessGroups"
      :location="location"
      @close="propertiesFormClosed" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiPut, apiDelete } from '@/store/api';
import GridLabel from '@/app-components/GridLabel.vue';
import { isValidEmail } from '@/modules/email/emailFunctions';
import EmailProperties from '@/app-components/EmailProperties.vue';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import EmailSearch from '@/app-components/EmailSearch.vue';
import EmailDragDrop from '@/app-components/EmailDragDrop.vue';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { getDeviceIdFromCookie } from '@/store/cookieStorage';
import EmailRecipientsDialog from '../modules/email/EmailRecipientsDialog.vue';
import SednaApiService from '../services/api/external/sednaAPI';
import EmailItem from './EmailItem.vue';

export default {
  name: 'EmailList',
  props: {
    location: String,
    portcallId: Number,
    clientId: Number,
    vesselId: Number,
    dirty: {
      type: Number,
      default: 0,
    },
  },
  components: {
    GridLabel,
    EmailRecipientsDialog,
    /* EmailStatus, */
    EmailGenerator,
    EmailProperties,
    EmailSearch,
    EmailDragDrop,
    EmailItem,
  },
  setup() {
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    const appStatusStore = useAppStatusStore();
    return {
      initSednaJobId: '-1',
      helperStore,
      appStatusStore,
      portcallStore,
      applicationStore,
    };
  },
  data() {
    return {
      vessel: {},
      client: {},
      email: {},
      showPropertiesForm: false,
      isDragging: false,
      emails: [],
      emailFilterParameters: {
        maxRowCount: 50,
      },
      emailTemplates: [],
      isLoading: false,
      showEmailReciepientsDialog: false,
      isLoadingOverlay: false,
      emailTemplateGuid: undefined,
      /* showEmailStatusDialog: false, */
      showDeleteDialog: false,
      selectedEmailIds: [],
      /** set to true to show links in the email list. */
      emailLink: false,
      sednaApi: {},
      emailItemDialogVisible: false,
      selectedEmailItemId: null,
    };
  },

  created() {
    this.sednaApi = new SednaApiService(this.agencyId);
  },
  activated() {
    if (this.isDirty) {
      this.getEmails();
      this.isDirty = false;
    }
  },
  deactivated() {
    this.isDirty = true;
  },

  watch: {
    agencyIdSelected: {
      handler() {
        this.getEmails();
      },
    },
    dirty: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getEmails();
        }
      },
    },
    portcallId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getEmails();
        }
      },
    },
    clientId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadClientDetail(val).then(() => {
            this.getEmails();
          });
        }
      },
    },
    vesselId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.loadVesselDetail(val).then(() => {
            this.getEmails();
          });
        }
      },
    },
    agencyId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.sednaApi = new SednaApiService(val);
        }
      },
    },
  },

  computed: {
    webAccessGroups() {
      return this.helperStore.webAccessGroups;
    },
    agencyIdSelected() {
      return this.applicationStore.agencyIdSelected;
    },
    agencyId() {
      if (this.location === 'portcall') {
        return this.portcallStore.callDetails.SETUP_ID;
      }
      return this.agencyIdSelected;
    },
    applicationStatus() {
      return this.appStatusStore.getAppStatus;
      /* let result = {};
                  result.PortCall = {};
                  result.PortCall.Id = this.callId;
                  result.PortCall.CcmTaskIds = this.selectedItemKeys
                  return result; */
    },
    isDirty: {
      get() {
        return this.applicationStore.emailListIsDirty;
      },
      set(val) {
        this.applicationStore.setEmailListIsDirty(val);
      },
    },
    /** If the user can delete emails. */
    canDelete() {
      /* if(this.hasSelectedOtherUsersDraft){
                      return false;
                  } */
      return this.applicationStore.userRight('EM.DELETE');
    },
    canSelect() {
      return !this.sednaApi?.isSednaIntegration && this.canDelete;
    },
    /** If the user has selected another users draft, the result will be a string/error message. Otherwise returns undeinfed */
    hasSelectedOtherUsersDraft() {
      if (Array.isArray(this.selectedEmailIds) && this.selectedEmailIds.length > 0) {
        const selectedEmailIds = [...this.selectedEmailIds];
        for (let i = 0; i < selectedEmailIds.length; i++) {
          const id = selectedEmailIds[i];
          const found = this.emails.find(
            (item) =>
              item.ID === id &&
              item.DRAFT === 1 &&
              item.SENT_BY !== this.applicationStore.user.userNameLong.toUpperCase()
          );
          if (found) {
            return 'You cannot delete a draft that is not yours';
          }
        }
      }
      return undefined;
    },
    /** If the user can create or update emails. */
    canEdit() {
      return this.applicationStore.userRight('EM.EDIT');
    },
    canSend() {
      return this.applicationStore.userRight('EM.SEND');
    },
    /** True if the user has selected any items in the grid. */
    hasSelectedRowsInGrid() {
      return Array.isArray(this.selectedEmailIds) && this.selectedEmailIds.length > 0;
    },
    askForRecipientsOnNewEmail() {
      return this.applicationStore.globalSettings.SGL_MAIL_ASK_ADDRESS === 1;
    },

    wmButtonGroup() {
      const result = [];
      // if (this.applicationStore.user.internal) {
      if (this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 && this.canEdit) {
        if (Array.isArray(this.emailTemplateItems) && this.emailTemplateItems.length > 0) {
          result.push({
            btnType: 'CUSTOM',
            mainAction: true,
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
          });
        }
      }
      const sednaJobId = this.getSednaJobIdForLocation();
      if (this.sednaApi?.isSednaIntegration) {
        result.push({
          btnType: 'CUSTOM',
          caption: 'SEDNA',
          icon: 'mdi-open-in-new',
          eventName: 'openSednaClick',
          disabled: !(sednaJobId && sednaJobId !== this.initSednaJobId),
        });
      } else if (this.canDelete) {
        result.push({
          btnType: 'DELETE',
          eventName: 'deleteClick',
          disabled: !this.hasSelectedRowsInGrid && !this.hasSelectedOtherUsersDraft,
        });
      }
      // }
      return result;
    },

    emailTemplateItems() {
      const newEmailItem = {};
      newEmailItem.caption = 'New email';
      newEmailItem.eventName = 'newEmailClick';
      newEmailItem.icon = 'mdi-plus';
      const templates = [...this.emailTemplates];
      let result = [];
      if (
        (this.location === 'portcall' || this.location === 'vessel' || this.location === 'client') &&
        this.locationId
      ) {
        if (Array.isArray(templates) && templates.length > 0) {
          const ignoreGroupMinCount = this.applicationStore.hasFeatureCode('IGNORE_DOCUMENT_GROUPING_MIN_COUNT');
          if (ignoreGroupMinCount ? true : templates.length > 10) {
            result = this.emailTemplatesGroupedByLabels(templates, false);
          } else {
            result = templates.map((item) => ({
              caption: item.TITLE,
              eventName: 'createEmailClick',
              icon: 'mdi-file-outline',
              ID: item.ID,
            }));
          }
        }
      }
      result.unshift(newEmailItem);

      return result;
    },

    // wmButtonGroup() {
    //   const result = [];
    //   // if (this.applicationStore.user.internal) {
    //   if (this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 && this.canEdit) {
    //     if (Array.isArray(this.emailTemplateItems) && this.emailTemplateItems.length > 0) {
    //       result.push({
    //         btnType: 'CUSTOM',
    //         mainAction: true,
    //         caption: 'Create email',
    //         icon: 'mdi-email',
    //         menuItems: this.emailTemplateItems,
    //       });
    //     }
    //   }
    //   if (this.canDelete) {
    //     result.push({
    //       btnType: 'DELETE',
    //       eventName: 'deleteClick',
    //       disabled: (!this.hasSelectedRowsInGrid && !this.hasSelectedOtherUsersDraft) || this.sednaApi?.isSednaIntegration,
    //     });
    //   }
    //   // }
    //   return result;
    // },

    // emailTemplateItems() {
    //   const newEmailItem = {};
    //   newEmailItem.caption = 'New email';
    //   newEmailItem.eventName = 'newEmailClick';
    //   newEmailItem.icon = 'mdi-plus';

    //   const templates = [...this.emailTemplates];
    //   let result = [];
    //   if (this.location === 'portcall' && this.locationId) {
    //     if (Array.isArray(templates) && templates.length > 0) {
    //       result = templates.map((item) => ({
    //         caption: item.TITLE,
    //         eventName: 'createEmailClick',
    //         icon: 'mdi-file-outline',
    //         ID: item.ID,
    //       }));
    //     }
    //   }

    //   result.unshift(newEmailItem);
    //   return result;
    // },

    subjectStringLen() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 140;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        return 40;
      }
      return 80;
    },
    rowMenu() {
      const result = [];
      if (this.applicationStore.user.internal) {
        if (this.location === 'portcall') {
          result.push({
            name: 'changeProperties',
            caption: 'Web access',
            icon: 'mdi-playlist-edit',
            enabled: this.applicationStore.userRight('EM.EDIT') && this.location === 'portcall',
          });
        }
        result.push({
          name: 'remove label',
          caption: 'Remove label',
          icon: 'mdi-label-off',
          enabled: this.applicationStore.userRight('DOC.NEW_AND_EDIT'),
          separatorBelow: true,
        });
        let subHeader = 'Labels';
        for (let index = 0; index < this.helperStore.documentLabels.length; index++) {
          const label = this.helperStore.documentLabels[index];
          let locationFilter = null;
          switch (this.location) {
            case 'portcall':
              locationFilter = 0;
              break;
            case 'client':
              locationFilter = 1;
              break;
            case 'vessel':
              locationFilter = 2;
              break;
            case 'proforma': // not yet implemented
              locationFilter = 3;
              break;
            case 'purchaseInvoice': // not yet implemented
              locationFilter = 4;
              break;
            case 'expense':
              locationFilter = 5;
              break;
            case 'service':
              locationFilter = 7;
              break;
            default: // all labels.
              locationFilter = -1;
          }

          if (
            (label.DLA_TYPE_EMAIL == 1 && label.DLA_LOCATION_FILTER == null) ||
            (label.DLA_TYPE_EMAIL == 1 && label.DLA_LOCATION_FILTER == locationFilter)
          ) {
            result.push({
              name: 'setLabel',
              caption: label.DLA_LABEL,
              icon: 'label',
              enabled: this.applicationStore.userRight('EM.EDIT'),
              subHeader,
              label,
              iconColor: `#${this.delphiColorToHex(label.DLA_COLOR_BACKGROUND)}`,
            });
            subHeader = null;
          }
        }
      }

      return result;
    },
    locationId() {
      if (this.location === 'portcall') {
        return this.portcallId;
      }
      if (this.location === 'client') {
        return this.clientId;
      }
      if (this.location === 'vessel') {
        return this.vesselId;
      }
      return null;
    },
    columns() {
      const result = [];
      result.push({ header: 'Mail No', field: 'MAIL_NO', hide: true });
      result.push({
        header: 'Label',
        field: 'LABEL_ID',
        width: '1%',
        nowrap: true,
        calculated: (rowData) => this.getLabelTitle(rowData.LABEL_ID),
      });
      result.push({
        header: 'Sent',
        field: 'SENT',
        dataType: 'datetime',
        width: '1%',
        nowrap: true,
        calculatedClass: (rowData) => (rowData.SENT === null || rowData.DRAFT === 1 ? 'red--text' : ''),
        calculated: (rowData) =>
          rowData.SENT === null || rowData.DRAFT === 1 ? '' : this.formatEmailDate(rowData.SENT),
      });
      result.push({ header: '', field: 'NUM_ATTACHMENTS', width: '1%' });
      result.push({ header: 'From', field: 'FROM_', nowrap: true });
      result.push({ header: 'Subject', field: 'SUBJECT', nowrap: true, calculatedClass: () => 'font-weight-medium' });
      result.push({ header: 'To', field: 'TO_', nowrap: true });
      result.push({ header: 'Cc', field: 'CC', nowrap: true });
      result.push({ header: 'reMark ref', field: 'EXTMAIL2_NO', nowrap: true });
      result.push({ header: 'Comment', field: 'EMAIL_COMMENT' });
      result.push({ header: 'Created by', field: 'SENT_BY', hide: true });
      // result.push({ header: 'Draft', field: 'DRAFT', hide: true });
      if (this.applicationStore.user.internal && this.location === 'portcall') {
        result.push({
          header: 'Web access',
          field: 'WEB_ACCESS',
          hideOnPhone: true,
          custom: true,
          width: '1%',
          class: 'px-2',
        });
      }
      if (this.applicationStore.user.internal) {
        result.push({
          header: '',
          field: 'CONTEXT_MENU',
          custom: true,
          hideOnPhone: false,
          ignoreOnCopy: true,
          width: '1%',
          class: 'px-0',
        });
      }

      return result;
    },
    emailItems() {
      if (Array.isArray(this.emails) && this.emails.length > 0) {
        let emails = [...this.emails];
        if (!this.applicationStore.user.internal && !this.applicationStore.userRight('EM.EDIT')) {
          emails = emails.filter((item) => item.DRAFT === null || item.DRAFT === 0);
        }
        if (emails.length > 0 && this.emailFilterParameters) {
          const { dateTo, selectedLables, dateFrom, searchText, maxRowCount } = this.emailFilterParameters;
          const maxEmails = maxRowCount ? emails.slice(0, Math.min(emails.length, maxRowCount)) : emails;
          const filtredEmailsBySearchText = searchText
            ? maxEmails.filter(
                (eml) =>
                  eml.SUBJECT?.toLowerCase().includes(searchText) ||
                  // eslint-disable-next-line no-underscore-dangle
                  eml.FROM_?.toLowerCase().includes(searchText) ||
                  // eslint-disable-next-line no-underscore-dangle
                  eml.TO_?.toLowerCase().includes(searchText)
              )
            : maxEmails;
          const filteredEmailsByLabels =
            selectedLables?.length > 0
              ? filtredEmailsBySearchText.filter((eml) => selectedLables.includes(eml.LABEL_ID))
              : filtredEmailsBySearchText;
          const filtredEmailsByFromDate = dateFrom
            ? filteredEmailsByLabels.filter((eml) => new Date(eml.SENT).getTime() >= dateFrom)
            : filteredEmailsByLabels;
          const filteredEmailsByToDate = dateTo
            ? filtredEmailsByFromDate.filter((eml) => new Date(eml.SENT).getTime() <= dateTo)
            : filtredEmailsByFromDate;
          return filteredEmailsByToDate;
        }
        return emails;
      }
      return [];
    },
    emailLabels() {
      return this.emails
        .filter((eml) => eml.LABEL_ID)
        .map((eml) => ({ ID: eml.LABEL_ID, TEXT: this.getLabelTitle(eml.LABEL_ID) }))
        .filter((lbl) => lbl.TEXT);
    },
  },

  methods: {
    emailTemplatesGroupedByLabels(emailTemplates, disabled) {
      const result = [];
      const labels = this.helperStore.documentLabels.slice().sort((x, y) => x.DLA_PARENT_ID - y.DLA_PARENT_ID);

      // Loop over hver label
      labels
        .filter((x) => x.DLA_TYPE_EMAIL === 1)
        .forEach((label) => {
          // hent alle templates med denne labelen.
          const templatesWithThisLabel = emailTemplates.filter((tmpl) => tmpl.LABEL_ID === label.DLA_ID);

          if (templatesWithThisLabel && templatesWithThisLabel.length > 0) {
            const menuItems = [];
            // "Barna" til labelen.
            templatesWithThisLabel.forEach((item) => {
              menuItems.push({
                caption: item.TITLE,
                icon: this.getIcon(item),
                ID: item.ID,
                disabled,
                eventName: 'createEmailClick',
              });
            });

            // Lag label med children.
            const labelAndMenuItems = {
              ID: label.DLA_ID,
              name: 'setLabel',
              caption: label.DLA_LABEL,
              icon: 'label',
              disabled,
              label,
              iconColor: `#${GlobalHelperFunctions.delphiColorToHex(label.DLA_COLOR_BACKGROUND)}`,
              menuItems,
            };

            if (label.DLA_PARENT_ID == null) {
              // Legg inn labels med children
              result.push(labelAndMenuItems);
            } else {
              // Hvis vi har parent label på labelen, finn parent i result, og legg labelen inn på parent som menuItem!
              const parent = result.find((x) => x.ID === label.DLA_PARENT_ID);
              if (parent) {
                parent.menuItems.push(labelAndMenuItems);

                // Sorter slik at labels kommer øverst
                parent.menuItems.sort((a) => {
                  if (a.icon === 'label') return -1;
                  return 0;
                });
              }
            }
          }
        });

      // Legg inn alle templates som ikke har label.
      const templatesWithoutLabel = emailTemplates.filter((tmpl) => !tmpl.LABEL_ID);
      // eslint-disable-next-line array-callback-return
      templatesWithoutLabel.map((item) => {
        const menuItem = {
          caption: item.TITLE,
          // icon: this.getIcon(item),
          eventName: 'createEmailClick',
          icon: 'mdi-file-outline',
          ID: item.ID,
          disabled,
        };
        result.push(menuItem);
      });

      return result;
    },

    getIcon(item) {
      let result = 'mdi-file-outline';

      if (item.FILENAME && item.FILENAME.toUpperCase().endsWith('.DOCX')) {
        result = 'mdi-file-word-outline';
      }
      if (item.MAKE_PDF) {
        result = 'mdi-file-pdf-outline';
      }
      return result;
    },

    loadVesselDetail(vesselId) {
      return apiGet(`/vessel/${vesselId}`).then((data) => {
        const newData = data;
        if (!newData.VESSEL_PREFIX) {
          newData.VESSEL_PREFIX = '';
        }
        this.vessel = newData;
      });
    },
    loadClientDetail(clientId) {
      return apiGet(`clients/${clientId}`).then((data) => {
        this.client = data;
      });
    },
    getSednaJobIdForLocation() {
      let sednaJobId = this.initSednaJobId;
      if (this.location === 'portcall') {
        if (this.portcallStore.callDetails?.ID) {
          sednaJobId = this.portcallStore.callDetails.SEDNA_JOB_ID;
        }
      } else if (this.location === 'vessel') {
        if (this.vessel?.ID) {
          sednaJobId = this.vessel.SEDNA_JOB_ID;
        }
      } else if (this.location === 'client') {
        if (this.client?.ID) {
          sednaJobId = this.client.SEDNA_JOB_ID;
        }
      }
      return sednaJobId;
    },
    setSednaJobIdForLocation(sednaJobId) {
      if (this.location === 'portcall') {
        this.portcallStore.updateSednaJobId({ id: this.portcallId, sednaJobId });
      } else if (this.location === 'vessel') {
        this.vessel.SEDNA_JOB_ID = sednaJobId;
      } else if (this.location === 'client') {
        this.client.SEDNA_JOB_ID = sednaJobId;
      }
    },
    deleteEmails() {
      const emails = [...this.emails];
      const promises = [];
      this.showDeleteDialog = false;
      this.isLoading = true;
      this.selectedEmailIds.forEach((id) => {
        promises.push(
          apiDelete(`mail/${id}`).then((result) => {
            if (result.resultCategory == 1) {
              const idx = emails.findIndex((item) => item.ID === id);
              if (idx >= 0) {
                emails.splice(idx, 1);
              }
            }
          })
        );
      });
      Promise.allSettled(promises).finally(() => {
        this.emails = emails;
        this.selectedEmailIds = [];
        this.isLoading = false;
      });
    },
    showContextMenu(event, rowItemId) {
      // console.log('showContextMenu', event, rowItemId);
      // used for the context button on mobile devices.
      this.gatComponentsMessageBus.$emit('gatgrid-rowmenu-show-context-menu', {
        event,
        rowItemId,
        gridName: 'emailCompGrid',
      });
    },
    recipientsSelected({ to, cc, bcc }) {
      const params = {
        to,
        cc,
        bcc,
      };
      this.openNewSednaEmail(params);
    },
    async openNewSednaEmail(params) {
      let sednaJobId = this.getSednaJobIdForLocation();
      if (sednaJobId !== this.initSednaJobId) {
        this.sednaApi = new SednaApiService(this.agencyId);
        if (sednaJobId) {
          this.sednaApi.composeNewSednaEmail(sednaJobId, [], params).then((composeEmailResponse) => {
            this.sednaApi.openNewSednaEmailUrl(composeEmailResponse);
          });
        } else {
          sednaJobId = await this.sednaApi.createSednaJobRef(this.locationId, this.location);
          if (sednaJobId) {
            this.setSednaJobIdForLocation(sednaJobId);
            this.sednaApi.composeNewSednaEmail(sednaJobId, [], params).then((composeEmailResponse) => {
              this.sednaApi.openNewSednaEmailUrl(composeEmailResponse);
            });
          }
        }
      }
    },
    async openNewEmail() {
      this.isLoadingOverlay = true;
      const result = await apiGet('mail/new-email');
      if (result && result.payload) {
        const emailId = result.payload.ID;
        const mailNo = result.payload.MAIL_NO;

        this.isLoadingOverlay = false;
        if (emailId && mailNo) {
          if (this.location === 'portcall') {
            this.$router.push({
              name: 'prortcallEmailNew',
              params: { callId: this.portcallId, emailId, to: [], bcc: [], cc: [], mailNo },
            });
          } else if (this.location === 'vessel') {
            this.$router.push({
              name: 'vesselEmailNew',
              params: { vesselId: this.vesselId, emailId, to: [], bcc: [], cc: [], mailNo },
            });
          } else if (this.location === 'client') {
            this.$router.push({
              name: 'clientEmailNew',
              params: { clientId: this.clientId, emailId, to: [], bcc: [], cc: [], mailNo },
            });
          }
        }
      }
    },
    newEmailClick() {
      // create new email - empty
      this.sednaApi = new SednaApiService(this.agencyId);
      if (this.askForRecipientsOnNewEmail && this.location === 'portcall') {
        // The if-statement for location should be removed when recipients dialog can be used for client & vessel.
        this.showEmailReciepientsDialog = true;
      } else if (this.sednaApi?.isSednaIntegration) {
        this.openNewSednaEmail();
      } else {
        this.openNewEmail();
      }
    },
    openSednaClick() {
      const sednaJobId = this.getSednaJobIdForLocation();
      if (sednaJobId !== this.initSednaJobId) {
        this.sednaApi = new SednaApiService(this.agencyId);
        if (sednaJobId) {
          this.sednaApi.openSednaUrlForJobTag(sednaJobId, true, 'all').then((sednaUrl) => {
            if (sednaUrl) {
              window.open(sednaUrl, '_blank');
            }
          });
        } else {
          this.sednaApi.createSednaJobRef(this.locationId, this.location).then((sednaJbId) => {
            if (sednaJbId) {
              this.setSednaJobIdForLocation(sednaJbId);
            }
          });
        }
      }
    },
    createEmailClick(item) {
      // create new email with template
      if (this.portcallId) {
        const guid = GlobalHelperFunctions.GUID();
        const templateId = item.ID;
        const { location } = this;
        const foreignKey = this.portcallId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
            /* this.showEmailStatusDialog = true; */
          }
        });
      }
    },
    formatEmailTo(toStr) {
      let emails = null;
      let email = toStr;
      if (toStr) {
        emails = toStr.split(',');
        if (toStr.includes(';')) {
          emails = toStr.split(';');
        }
        if (typeof emails == 'object' && emails.length >= 2) {
          // eslint-disable-next-line prefer-destructuring
          email = emails[0];
        }
        emails.splice(0, 1);
      }
      return { email, emails };
    },
    formatEmailDate(date) {
      const today = new Date();
      // eslint-disable-next-line no-param-reassign
      date = new Date(date);
      let result = GlobalHelperFunctions.globalFormatDateTime(date, 'DD.MM YYYY HH:mm');

      const sameYear = today.getYear() === date.getYear();
      if (sameYear) {
        result = GlobalHelperFunctions.globalFormatDateTime(date, 'DD MMM HH:mm');
      }

      const sameDay = today.getDate() === date.getDate();
      if (sameDay) {
        result = GlobalHelperFunctions.globalFormatDateTime(date, 'HH:mm');
      }
      return result;
    },
    getLabelTitle(labelId) {
      const result = this.helperStore.documentLabels.find((label) => label.DLA_ID == labelId);
      if (result) {
        return result.DLA_LABEL;
      }
      return '';
    },
    filterdEmails(incomingEmailFilterParameters) {
      this.emailFilterParameters = incomingEmailFilterParameters;
    },
    getEmails() {
      if (!this.isLoading) {
        this.isLoading = true;
        this.emails = [];
        apiGet(`mail/all/${this.location}/${this.locationId}`)
          .then((result) => {
            if (result) {
              const gsEmails = result.map((eml) => ({
                ...eml,
                isGatshipEmail: true,
              }));
              gsEmails.sort((a, b) => new Date(b.SENT).getTime() - new Date(a.SENT).getTime());
              this.emails = gsEmails;
            }
          })
          .then(() => {
            apiGet(`mail/templates/${this.location}/${this.agencyId}`).then((templateResult) => {
              if (templateResult) {
                this.emailTemplates = templateResult;
              }
            });
          })
          .then(() => {
            this.sednaApi = new SednaApiService(this.agencyId);
            if (this.sednaApi?.isSednaIntegration) {
              const sednaJobId = this.getSednaJobIdForLocation();
              if (sednaJobId !== this.initSednaJobId) {
                if (sednaJobId) {
                  this.sednaApi
                    .getAllSednaEmail(sednaJobId)
                    .then((responses) => {
                      responses.forEach((response) => {
                        response.data?.forEach((eml) => {
                          const isDraft = !eml.attributes.messageId ? 1 : 0;
                          const newEmail = {
                            ID: eml.id,
                            SUBJECT: eml.attributes.subject,
                            isGatshipEmail: false,
                            FROM_: eml.attributes.from.join(','),
                            TO_: eml.attributes.to.join(','),
                            CC: eml.attributes.cc,
                            BCC: eml.attributes.bcc,
                            SENT: eml.attributes.sentAt,
                            RECEIVED: isDraft ? null : eml.attributes.receivedAt,
                            DRAFT: isDraft,
                            MAIL_HTML: eml.attributes.bodyHtml,
                            NUM_ATTACHMENTS: eml.attributes.hasAttachment ? eml.relationships.document.data.length : 0,
                          };
                          this.emails.push(newEmail);
                        });
                        this.emails = this.emails.sort(
                          (a, b) => new Date(b.SENT).getTime() - new Date(a.SENT).getTime()
                        );
                      });
                    })
                    .finally(() => {
                      this.isLoading = false;
                    });
                } else {
                  this.sednaApi
                    .createSednaJobRef(this.locationId, this.location)
                    .then((sednaJbId) => {
                      if (sednaJbId) {
                        this.setSednaJobIdForLocation(sednaJbId);
                      }
                    })
                    .finally(() => {
                      this.isLoading = false;
                    });
                }
              } else {
                this.isLoading = false;
              }
            } else {
              this.isLoading = false;
            }
          });
      }
    },
    createRecipients(recipientsArray) {
      const result = [];
      recipientsArray.forEach((item) => {
        if (typeof item === 'object') {
          result.push(item);
        } else if (typeof item === 'string') {
          result.push({
            NAME: item,
            EMAIL: item,
            CLIENT_NAME: item,
          });
        }
      });
      return result;
    },
    async parseEmail(emailToParse) {
      const formData = new FormData();
      formData.append('location', this.location);
      // https://developer.mozilla.org/en-US/docs/Web/API/File/lastModified
      let modifiedDate = new Date(emailToParse.lastModified);
      if (!modifiedDate) {
        // deprecated / fallback.
        // https://developer.mozilla.org/en-US/docs/Web/API/File/lastModifiedDate
        modifiedDate = emailToParse.lastModifiedDate;
      }
      modifiedDate = modifiedDate.toString().substring(0, 21);
      formData.append(`lastModified_${0}`, modifiedDate);
      formData.append(`file_${0}`, emailToParse);
      const headers = {
        'Content-Type': 'multipart/form-data',
        gstoken: this.applicationStore.user.apiToken,
        gsdevice: getDeviceIdFromCookie(),
      };
      const response = await apiPost(`mail/parse-msg`, formData, { headers });
      return response;
    },
    async saveImportedEmail(emailToImport) {
      const parseResponse = await this.parseEmail(emailToImport.emailFile);
      if (parseResponse.resultCategory == 1) {
        const newMailResult = await apiGet('mail/new-email');
        if (newMailResult && newMailResult.payload) {
          const emailId = newMailResult.payload.ID;
          const mailNo = newMailResult.payload.MAIL_NO;
          if (emailId && mailNo) {
            // create email form data
            const parsedEmail = parseResponse.payload;
            const emailTo = parsedEmail.TO.map((rcp) => ({
              NAME: rcp.displayName,
              EMAIL: rcp.email,
              CLIENT_NAME: rcp.email,
            }));
            const emailCc = parsedEmail.CC.map((rcp) => ({
              NAME: rcp.displayName,
              EMAIL: rcp.email,
              CLIENT_NAME: rcp.email,
            }));
            const emailBcc = parsedEmail.BCC.map((rcp) => ({
              NAME: rcp.displayName,
              EMAIL: rcp.email,
              CLIENT_NAME: rcp.email,
            }));
            const emailItemToImport = new FormData();
            emailItemToImport.append('TO_', JSON.stringify(this.createRecipients(emailTo)));
            emailItemToImport.append('CC', JSON.stringify(this.createRecipients(emailCc)));
            emailItemToImport.append('BCC', JSON.stringify(this.createRecipients(emailBcc)));
            emailItemToImport.append(
              'FROM_',
              JSON.stringify({ NAME: parsedEmail.FROM.displayName, EMAIL: parsedEmail.FROM.email })
            );
            emailItemToImport.append('BODY', parsedEmail.MAIL_HTML);
            emailItemToImport.append('SUBJECT', parsedEmail.SUBJECT);
            emailItemToImport.append(
              'SENT',
              GlobalHelperFunctions.globalFormatDate(
                new Date(emailToImport.email.messageDeliveryTime),
                'YYYY-MM-DDTHH:mm:ss'
              )
            );
            emailItemToImport.append('SENT_BY', emailToImport.email.senderName);
            emailItemToImport.append('IS_WM_IMPORTED', true);
            if (Array.isArray(parsedEmail.MAIL_ATTACHMENTS)) {
              const dbAttachments = parsedEmail.MAIL_ATTACHMENTS.map((att) => att.FILE_NAME);
              emailToImport.attachments
                .filter((att) => dbAttachments.includes(att.name))
                .forEach((file) => {
                  emailItemToImport.append('ATTACHMENTS', file);
                });
            }
            const importMailResult = await apiPost(
              `mail/save/${this.location}/${this.locationId}/${emailId}`,
              emailItemToImport
            );
            return importMailResult;
          }
        }
        return newMailResult;
      }
      return parseResponse;
    },
    async processParsedEmails(incomingParsedEmailsWithAttachments, failedEmailMessages) {
      if (!this.sednaApi?.isSednaIntegration) {
        this.isLoading = true;
        this.isLoadingOverlay = true;
        const uploadPromises = [];
        incomingParsedEmailsWithAttachments.forEach((emailToImport) => {
          uploadPromises.push(this.saveImportedEmail(emailToImport));
        });
        // await this.importEmails(incomingParsedEmailsWithAttachments);
        const uploadResult = await Promise.allSettled(uploadPromises);
        const errorMessage = uploadResult
          .filter((res) => res.resultCategory != 1)
          .map((err) => err.message)
          .join('\r\n');
        this.isLoadingOverlay = false;
        this.isLoading = false;
        this.getEmails();
        if (failedEmailMessages.length || errorMessage) {
          window.messageBus.$emit('showMessage', {
            type: 'warning',
            text: `Some emails could not be imported. Unsupported types.\r\n${errorMessage}`,
            subText: failedEmailMessages.map((msg) => `${msg.fileName}: ${msg.error}`).join(','),
            icon: true,
          });
        }
      }
    },
    gotoEmailItem(item) {
      if (item.EXTMAIL2_NO) return;

      const emailId = +item.ID;
      this.sednaApi = new SednaApiService(this.agencyId);
      if ((item.SENT === null || item.DRAFT === 1) && emailId && !item.isGatshipEmail) {
        // sedna draft
        const sednaUrl = this.sednaApi.createComposeEditSednaEmailUrl(emailId);
        window.open(sednaUrl, '_blank');
      } else if ((item.SENT === null || item.DRAFT === 1) && emailId && item.isGatshipEmail) {
        // outlook draft
        this.goToEditDraft(item);
      } else if (emailId) {
        if (!item.isGatshipEmail) {
          const sednaUrl = this.sednaApi.createReplySednaEmailUrl(emailId, 'open');
          if (sednaUrl) {
            window.open(sednaUrl, '_blank');
          }
        } else {
          // alternative 1
          this.selectedEmailItemId = emailId;
          this.emailItemDialogVisible = true;

          // alternative 2
          // const routeData = this.$router.resolve({ name: `${this.location}EmailItem`, params: { emailId } });
          // window.open(routeData.href, '_blank');

          // alternative 3
          // this.$router.push({
          //   name: `${this.location}EmailItem`,
          //   params: { emailId },
          // });
        }
      }
    },
    closeEmailItemDialog() {
      this.emailItemDialogVisible = false;
    },
    propertiesFormClosed(param) {
      this.showPropertiesForm = false;
      if (param.btn == 'save') {
        const webAccessObject = {
          ID: param.value.ID,
          WEB_ACCESS: param.value.WEB_ACCESS,
        };

        apiPut('/mail/webaccess', webAccessObject).then(() => {
          this.getEmails();
        });
      }

      this.email = {};
    },
    showProperties(rowItem) {
      if (!this.applicationStore.userRight('EM.EDIT')) {
        return;
      }

      if (this.location !== 'portcall') {
        return;
      }

      this.email = rowItem;
      this.showPropertiesForm = true;
    },
    async createRecipientsArray(recipientsArray) {
      const error = [];
      const result = [];
      const promises = [];
      if (Array.isArray(recipientsArray) && recipientsArray.length > 0) {
        recipientsArray.forEach((item) => {
          if (isValidEmail(item.email)) {
            result.push(item.email);
            // promises.push(apiGet(`portcall/emailRecipients/addManual/${item.email}`).then(res => {
            //     if(Array.isArray(res) && res.length > 0){
            //         result.push(res[0])
            //     } else {
            //         result.push(item.email);
            //     }
            // }));
          } else {
            error.push(`email ${item.email} is not a valid e-mail address`);
          }
        });
      }
      await Promise.allSettled(promises);
      return { error, result };
    },
    async goToEditDraft(emailItem) {
      this.isLoadingOverlay = true;
      const email = await this.getEmail(emailItem);
      if (email) {
        const promises = [];
        const toResult = await this.createRecipientsArray(email.TO);
        promises.push(toResult);
        const ccResult = await this.createRecipientsArray(email.CC);
        promises.push(ccResult);
        const bccResult = await this.createRecipientsArray(email.BCC);
        promises.push(bccResult);
        await Promise.allSettled(promises);
        this.isLoadingOverlay = false;
        const to = toResult.result;
        const cc = ccResult.result;
        const bcc = bccResult.result;
        // eslint-disable-next-line no-underscore-dangle
        const mailFrom = email.FROM_;
        const onLoadError = [...toResult.error, ...ccResult.error, ...bccResult.error];
        const content = email.MAIL_HTML;
        const subject = email.SUBJECT;
        const mailNo = email.MAIL_NO;
        const attachments = email.MAIL_ATTACHMENTS;

        const { location } = this.location;
        const { portcallId } = this;
        const { clientId } = this;
        const { vesselId } = this;
        const params = {
          emailId: emailItem.ID,
          location,
          content,
          mailNo,
          subject,
          mailFrom,
          to,
          cc,
          bcc,
          attachments,
          onLoadError,
          isDraft: true,
          createdBy: email.SENT_BY,
        };
        if (this.location === 'client' && this.clientId) {
          this.$router.push({
            name: 'clientEmailNew',
            params: {
              ...params,
              clientId,
            },
          });
        }
        if (this.location === 'portcall' && this.portcallId) {
          this.$router.push({
            name: 'prortcallEmailNew',
            params: {
              ...params,
              portcallId,
            },
          });
        }
        if (this.location === 'vessel' && this.vesselId) {
          this.$router.push({
            name: 'vesselEmailNew',
            params: {
              ...params,
              vesselId,
            },
          });
        }
      }
    },
    async getEmail(emailItem) {
      let result;
      try {
        if (emailItem.isGatshipEmail) {
          result = await apiGet(`mail/${+emailItem.ID}`);
        }
      } catch (error) {
        this.isLoadingOverlay = false;
        this.isLoading = false;
        // console.error(error);
      }
      return result;
    },

    delphiColorToHex(number) {
      let str = number.toString(16);
      str = str.padStart(6, '0');
      const R = str.substring(4, 6);
      const G = str.substring(2, 4);
      const B = str.substring(0, 2);
      // return str+':'+R+' '+G+' '+B;
      return R + G + B;
    },

    rowMenuClicked(event) {
      const mailId = event.rowItem.ID;
      if (event.menuItem.name == 'setLabel') {
        const labelId = event.menuItem.label.DLA_ID;
        apiPut(`/mail/setLabel/${mailId}/${labelId}`).then((result) => {
          if (result.resultCategory == 1) {
            this.emails.map((item) => {
              const newItem = { ...item };
              if (newItem.ID === mailId) {
                newItem.LABEL_ID = labelId;
              }
              return newItem;
            });
          }
        });
      } else if (event.menuItem.name == 'remove label') {
        apiPut(`/mail/setLabel/${mailId}/${-1}`).then((result) => {
          if (result.resultCategory == 1) {
            this.emails.map((item) => {
              const newItem = { ...item };
              if (newItem.ID === mailId) {
                newItem.LABEL_ID = null;
              }
              return newItem;
            });
          }
        });
      } else if (event.menuItem.name == 'changeProperties') {
        this.showProperties(event.rowItem);
      } else {
        /* console.log(event); */
      }
    },
    createWebAccessObjectFromIds(obj) {
      if (obj.WEB_ACCESS) {
        // eslint-disable-next-line no-param-reassign
        obj.WEB_ACCESS = obj.WEB_ACCESS.map((item) => ({
          WAD_DOCUMENT_ID: obj.ID,
          WAD_WMG_ID: item,
          WAD_WMVER: 1,
        }));
      }
      return obj;
    },
    getAccessGroupNames(ids) {
      const result = {
        displayName: '',
        content: [],
      };
      if (ids) {
        const idArr = ids.split(',');
        idArr.forEach((id) => {
          const accesGrp = this.webAccessGroups.find((item) => item.ID == id);
          if (accesGrp && accesGrp.NAVN) {
            result.content.push(accesGrp.NAVN);
          }
        });
        if (result.content.length > 0) {
          // eslint-disable-next-line prefer-destructuring
          result.displayName = result.content[0];
        }
        if (result.content.length > 1) {
          result.displayName += ` +${result.content.length - 1}`;
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep td {
  padding: 0 !important;
  flex-grow: 0 !important;
}

.icon-counter {
  padding: 2px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  top: -10px;
  right: -25px;
  background: rgb(0, 121, 191);
  font-weight: 400;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  font-size: 11px;
  text-align: center;
  color: white;
}
</style>
