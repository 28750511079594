<template>
  <v-flex v-if="!isHidden" v-bind:class="flexSize">
    <slot></slot>
  </v-flex>
</template>

<script>
export default {
  name: 'GatFlex',
  props: {
    size: String,
    hide: [String, Boolean],
  },

  //* ** Computed ***
  computed: {
    // hideOnDevice
    isHidden() {
      switch (this.hide) {
        case 'phone':
          return this.$vuetify.breakpoint.xs;
        case 'pad':
          return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md;
        case 'all':
          return true;
        default:
          return false;
      }
    },

    // flexSize
    flexSize() {
      let { size } = this;
      if (this.size == undefined) {
        size = 'm';
      }
      switch (size) {
        case 'xs':
          return 'xs3 sm2 md2 lg1 xl1';
        case 's':
          return 'xs6 sm3 md2 lg2 xl1';
        case 'sm':
          return 'xs9 sm4 md2 lg3 xl2';
        case 'm':
          return 'xs12 sm6 md4 lg4 xl3';
        case 'l':
          return 'xs12 sm9 md6 lg6 xl4';
        case 'xl':
          return 'xs12 sm12 md12 lg8 xl6';
        default:
          return this.size;
      }
    },
  },
};
</script>

<style scoped>
.pitteliten {
  font-size: 8px;
}
.tst {
  background-color: rgba(0, 0, 255, 0.02);
}
</style>
