// Pinia Store
import { defineStore } from 'pinia';
import { computed } from 'vue';

export const useConstantsStore = defineStore('constantsStore', () => {
  const checklistTypes = computed(() => [
    { value: 0, text: 'none' },
    { value: 1, text: 'Proforma created' },
    { value: 2, text: 'Statement of facts printed' },
    { value: 3, text: 'Manifest created' },
    { value: 4, text: 'B/L created' },
    { value: 5, text: 'Agency fee added' },
    { value: 6, text: 'DA complete' },
    { value: 7, text: 'Asked for advance' },
    { value: 8, text: 'Advance received' },
  ]);

  const checklistReminderTypes = computed(() => [
    { value: 20, text: 'ETA (Expected Time of Arrival)' },
    { value: 21, text: 'ATA (Actual Time of Arival )' },
    { value: 22, text: 'ETD (Expected Time of Departure)' },
    { value: 23, text: 'ATD (Actual time of Departure)' },
    { value: 24, text: 'ETB (Expected Time of Berth)' },
  ]);
  const clientTypeRanking = computed(() => [
    { value: 0, text: 'Not evaluated' },
    { value: 1, text: 'A - Very good' },
    { value: 2, text: 'B - Good' },
    { value: 3, text: 'C - Insufficient' },
    { value: 4, text: 'D - Poor' },
  ]);

  const portcallStatus = computed(() => [
    {
      value: 0,
      text: 'Expected',
      icon: 'mdi-checkbox-blank',
      iconClass: 'pc-status-expected',
    },
    {
      value: 1,
      text: 'Anchored',
      icon: 'mdi-checkbox-blank',
      iconClass: 'pc-status-anchored',
    },
    {
      value: 2,
      text: 'Berthed',
      icon: 'mdi-checkbox-blank',
      iconClass: 'pc-status-berthed',
    },
    {
      value: 3,
      text: 'Sailed',
      icon: 'mdi-checkbox-blank',
      iconClass: 'pc-status-sailed',
    },
    {
      value: 5,
      text: 'Archived',
      icon: 'mdi-checkbox-blank',
      iconClass: 'pc-status-archived',
    },
    {
      value: 4,
      text: 'Cancelled',
      icon: 'mdi-checkbox-blank',
      iconClass: 'pc-status-cancelled',
    },
  ]);

  const invoiceStatus = computed(() => [
    { value: -1, text: '' },
    { value: 0, text: 'In progress' },
    { value: 1, text: 'Ready for production' },
    { value: 2, text: 'Finished' },
    { value: 3, text: 'Reactivated' },
  ]);

  const vesselOrientations = computed(() => [
    { value: 0, text: '' },
    { value: 1, text: 'Port side' },
    { value: 2, text: 'Starboard side' },
    { value: 3, text: 'Masters choice' },
    { value: 5, text: 'Alongside other ship' },
    { value: 4, text: 'No preference' },
  ]);

  const voyageTypes = computed(() => [
    { value: 0, text: 'Other' },
    { value: 1, text: 'Last port' },
    { value: 2, text: 'Next port' },
    { value: 3, text: 'Last foreign port' },
    { value: 5, text: 'Next foreign port' },
    { value: 6, text: 'Discharging port' },
    { value: 7, text: '2nd Last port' },
    { value: 8, text: '3rd Last port' },
    { value: 9, text: '4th Last port' },
    { value: 10, text: '5th Last port' },
    { value: 11, text: '6th Last port' },
    { value: 12, text: '7th Last port' },
    { value: 13, text: '8th Last port' },
    { value: 14, text: '9th Last port' },
    { value: 15, text: '10th Last port' },
    { value: 16, text: '2nd Next port' },
  ]);

  const worklineStatuses = computed(() => [
    { value: 0, text: '' },
    { value: 1, text: 'Work' },
    { value: 2, text: 'Stop' },
    { value: 3, text: 'Prospect' },
  ]);
  /*
            case "portcall" : result = "green"; break;
            case "vessel" : result = "blue"; break;
            case "expense" : result = "orange"; break;
            case "checklist" : result = "green"; break;
            case "proforma" : result = "orange"; break;
            case "service" : result = "cyan"; break;
            case "enquiry" : result = "orange"; break;
            case "crewChangePerson" : result = "red"; break;
            case "crewChangeTask" : result = "red"; break;
            default: result = "";
          */

  const documentIcons = computed(() => [
    {
      docLocation: 'portcall',
      icon: 'mdi-inbox',
      color: 'secondary',
      hint: 'Portcall',
    },
    {
      docLocation: 'proforma',
      icon: 'mdi-help',
      color: 'orange',
      hint: 'Proforma',
    },
    {
      docLocation: 'billOfLading',
      icon: 'mdi-file-document-outline',
      color: 'default',
      hint: 'Bill of lading',
    },
    {
      docLocation: 'sof',
      icon: 'mdi-clock-outline',
      color: 'default',
      hint: 'Statement of facts',
    },
    {
      docLocation: 'generalDeclaration',
      icon: 'mdi-file-document-outline',
      color: 'default',
      hint: 'General declaration',
    },
    {
      docLocation: 'manifest',
      icon: 'mdi-file-document-outline',
      color: 'default',
      hint: 'Manifest',
    },
    {
      docLocation: 'visitorList',
      icon: 'mdi-clipboard-list-outline ',
      color: 'default',
      hint: 'Visitor list',
    },
    {
      docLocation: 'priorNotice',
      icon: 'mdi-file-document-outline',
      color: 'default',
      hint: 'Prior notice',
    },
    {
      docLocation: 'deliveries',
      icon: 'mdi-truck-delivery-outline ',
      color: 'default',
      hint: 'Deliveries',
    },

    {
      docLocation: 'expense',
      icon: 'mdi-currency-usd',
      color: 'orange',
      hint: 'Expense',
    },
    {
      docLocation: 'invoice',
      icon: 'mdi-currency-usd',
      color: 'blue',
      hint: 'Invoice',
    },
    {
      docLocation: 'enquiry',
      icon: 'mdi-help',
      color: 'orange',
      hint: 'Enquiry',
    },
    {
      docLocation: 'quay',
      icon: 'mdi-file-document-outline',
      color: 'blue',
      hint: 'Quay',
    },
    {
      docLocation: 'service',
      icon: 'mdi-handshake',
      color: 'blue',
      hint: 'Service',
    },
    {
      docLocation: 'prospects',
      icon: 'mdi-timetable ',
      color: 'default',
      hint: 'Prospects',
    },
    {
      docLocation: 'client',
      icon: 'mdi-account',
      color: 'default',
      hint: 'Client',
    },
    {
      docLocation: 'vessel',
      icon: 'mdi-ferry',
      color: 'blue',
      hint: 'Vessel',
    },
    {
      docLocation: 'attendance',
      icon: 'timer',
      color: 'default',
      hint: 'Attendance',
    },
    {
      docLocation: 'crewChangePerson',
      icon: 'mdi-account-switch',
      color: 'blue',
      hint: 'Crew change person',
    },
    {
      docLocation: 'crewChangeTask',
      icon: 'mdi-calendar-check',
      color: 'blue',
      hint: 'Crew change task',
    },
    {
      docLocation: 'checklist',
      icon: 'mdi-clipboard-check-outline',
      color: 'secondary',
      hint: 'Checklist',
    },
  ]);

  return {
    checklistTypes,
    checklistReminderTypes,
    clientTypeRanking,
    portcallStatus,
    invoiceStatus,
    vesselOrientations,
    voyageTypes,
    worklineStatuses,
    documentIcons,
  };
});
