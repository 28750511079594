<template>
  <div>
    <v-dialog v-model="dialogVisible" persistent max-width="600">
      <v-card class="pa-3">
        <v-layout v-if="webAccessIsAllowed" wrap>
          <gat-multi-select
            v-model="email.WEB_ACCESS"
            :items="webAccessSelect"
            label="Web access"
            textFieldName="NAME"
            codeFieldName="ID"
            size="xs12">
          </gat-multi-select>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="close('cancel')">Cancel</v-btn>
          <v-btn color="success" class="right" @click="close('save')">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'EmailProperties',
  props: ['value', 'showForm', 'webAccessGroups', 'location'],
  components: {},
  data() {
    return {
      dialogVisible: false,
      email: {},
      orgemail: {},
    };
  },

  mounted() {
    this.email = GlobalHelperFunctions.duplicateViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value(newValue) {
      if (!newValue.WEB_ACCESS) {
        // eslint-disable-next-line no-param-reassign
        newValue.WEB_ACCESS = [];
      }
      this.orgemail = GlobalHelperFunctions.duplicateViaJson(newValue);
      this.email = GlobalHelperFunctions.duplicateViaJson(newValue);
    },
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {
    webAccessIsAllowed() {
      if (this.value) {
        if (this.location === 'portcall') {
          return true;
        }
      }
      return false;
    },
    webAccessSelect() {
      let result = [];
      if (this.webAccessGroups) {
        const accessGroups = this.webAccessGroups;
        result = accessGroups.map((item) => ({
          NAME: item.NAVN,
          ID: item.ID,
        }));
      }
      return result;
    },
  },

  methods: {
    close(btn) {
      if (this.formIsValid()) {
        if (btn == 'cancel') {
          this.$emit('close', { btn, value: this.orgemail });
        } else if (btn == 'save') {
          this.$emit('close', { btn, value: this.email });
        }
      }
    },

    formIsValid() {
      const result = true;
      return result;
    },
  },
};
</script>

<style scoped></style>
