function StorageSupported() {
  if (typeof Storage !== 'undefined') {
    return true;
  }
  // eslint-disable-next-line no-alert
  alert('Browser does not support WebStorage...');
  return false;
}

export function saveLoginNameToWebStorage(username, db) {
  if (StorageSupported()) {
    localStorage.setItem('username', username);
    localStorage.setItem('db', db);
  }
}

// eslint-disable-next-line consistent-return
export function getLoginNameFromWebStorage() {
  if (StorageSupported()) {
    const result = {};
    result.username = localStorage.getItem('username');
    result.db = localStorage.getItem('db');
    return result;
  }
}

export function removeLoginFromWebStorage() {
  if (StorageSupported()) {
    localStorage.removeItem('username');
  }
}
