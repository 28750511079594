<template>
  <v-menu offset-y v-model="menuOpen">
    <template v-slot:activator="{ on }">
      <v-btn v-show="cardActive || menuOpen" small class="right" text icon v-on="on">
        <v-icon small>settings</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <div v-if="!maximized">
        <v-list-group :value="false" prepend-icon="mdi-arrow-expand-horizontal" @click.stop>
          <template v-slot:activator>
            <v-list-item-title>Width</v-list-item-title>
          </template>
          <v-list-item>
            <div class="d-flex align-center justify-space-around flex-grow-1 pa-0" style="width: 100%">
              <v-btn x-small class="ma-1 pa-3 size-btn" :class="getWidth == 0 ? 'primary ' : ''" @click="setWidth(0)"
                >S</v-btn
              >
              <v-btn x-small class="ma-1 pa-3 size-btn" :class="getWidth == 1 ? 'primary ' : ''" @click="setWidth(1)"
                >M</v-btn
              >
              <v-btn x-small class="ma-1 pa-3 size-btn" :class="getWidth == 2 ? 'primary ' : ''" @click="setWidth(2)"
                >L</v-btn
              >
              <v-btn x-small class="ma-1 pa-3 size-btn" :class="getWidth == 3 ? 'primary ' : ''" @click="setWidth(3)"
                >XL</v-btn
              >
            </div>
          </v-list-item>
        </v-list-group>
        <v-list-group :value="false" prepend-icon="mdi-arrow-expand-vertical" @click.stop>
          <template v-slot:activator>
            <v-list-item-title>Height</v-list-item-title>
          </template>

          <v-list-item>
            <div class="d-flex align-center justify-space-around flex-grow-1 pa-0" style="width: 100%">
              <v-btn x-small class="ma-1 pa-3 size-btn" :class="getHeight == 0 ? 'primary ' : ''" @click="setHeight(0)"
                >1</v-btn
              >
              <v-btn x-small class="ma-1 pa-3 size-btn" :class="getHeight == 1 ? 'primary ' : ''" @click="setHeight(1)"
                >2</v-btn
              >
              <v-btn x-small class="ma-1 pa-3 size-btn" :class="getHeight == 2 ? 'primary ' : ''" @click="setHeight(2)"
                >3</v-btn
              >
            </div>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>

        <v-list-item @click="hideOnPhone(!settings.hiddenOnPhone)"
          ><v-list-item-icon><v-icon v-if="settings.hiddenOnPhone">check</v-icon></v-list-item-icon>
          <v-list-item-title>Hide on phone</v-list-item-title>
        </v-list-item>
        <v-list-item @click="hide(!settings.hidden)"
          ><v-list-item-icon><v-icon v-if="!settings.hidden">check</v-icon></v-list-item-icon>
          <v-list-item-title>Visible</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
      </div>
      <v-list-item @click="setChartType('AreaChart')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'AreaChart'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Area chart</v-list-item-title></v-list-item
      >
      <v-list-item @click="setChartType('BarChart')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'BarChart'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Bar chart</v-list-item-title></v-list-item
      >
      <v-list-item @click="setChartType('ColumnChart')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'ColumnChart'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Column chart</v-list-item-title></v-list-item
      >
      <v-list-item @click="setChartType('GeoChart')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'GeoChart'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Geo chart</v-list-item-title></v-list-item
      >
      <v-list-item @click="setChartType('Histogram')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'Histogram'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Histogram</v-list-item-title></v-list-item
      >
      <v-list-item @click="setChartType('LineChart')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'LineChart'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Line chart</v-list-item-title></v-list-item
      >
      <v-list-item @click="setChartType('PieChart')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'PieChart'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Pie chart</v-list-item-title></v-list-item
      >
      <v-list-item @click="setChartType('Table')"
        ><v-list-item-icon><v-icon v-if="settings.chartType == 'Table'">check</v-icon></v-list-item-icon>
        <v-list-item-title>Table</v-list-item-title></v-list-item
      >
      <v-divider></v-divider>
      <v-list-item
        v-if="settings.chartType.toString().toLowerCase() === 'table'"
        @click="setDense(settings.options.dense ? !settings.options.dense : true)"
        ><v-list-item-icon><v-icon v-if="settings.options.dense">check</v-icon></v-list-item-icon>
        <v-list-item-title>Dense</v-list-item-title>
      </v-list-item>
      <v-list-item @click="toggleOption('isStacked')"
        ><v-list-item-icon
          ><v-icon v-if="settings.options && settings.options.isStacked">check</v-icon></v-list-item-icon
        >
        <v-list-item-title>Stacked</v-list-item-title></v-list-item
      >
      <v-divider></v-divider>
      <v-list-item dense @click="resetDashboard()"
        ><v-list-item-icon><v-icon>mdi-delete-restore</v-icon></v-list-item-icon
        ><v-list-item-title>Reset to default</v-list-item-title></v-list-item
      >
    </v-list>
  </v-menu>
</template>

<script>
import { useDashboardStore } from '@/store/dashboardStore';
import { updateChartSettings } from '@/services/api/api-calls/updateChartSettings';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'ChartSettingsMenu',
  props: {
    settings: [Object, Array],
    cardActive: [String, Number, Boolean],
    reportName: [String, Number],
    dashboardGroupName: String,
    maximized: [String, Number, Boolean],
    location: [String, Number],
  },
  components: {},
  setup() {
    const dashboardStore = useDashboardStore();
    return {
      dashboardStore,
    };
  },
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    getWidth() {
      if (this.settings && this.settings.size && this.settings.size.width >= 0) {
        return this.settings.size.width;
      }
      return 0;
    },
    getHeight() {
      if (this.settings && this.settings.size && this.settings.size.height >= 0) {
        return this.settings.size.height;
      }
      return 0;
    },
  },

  methods: {
    updateSettings(settings) {
      updateChartSettings({
        location: this.location,
        dashboardGroupName: this.dashboardGroupName,
        reportName: this.reportName,
        settings,
      });
    },
    resetDashboard() {
      this.updateSettings({});
      this.$emit('refresh', true);
    },
    hide(visible) {
      if (this.settings.hidden != visible) {
        const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
        newSettings.hidden = visible;
        this.updateSettings(newSettings);
      }
    },
    hideOnPhone(visible) {
      // this.$emit('visibleChanged',visible);
      if (this.settings.hiddenOnPhone != visible) {
        const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
        newSettings.hiddenOnPhone = visible;
        this.updateSettings(newSettings);
      }
    },
    setChartType(chartType) {
      if (this.settings.chartType != chartType) {
        const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
        newSettings.chartType = chartType;
        this.updateSettings(newSettings);
      }
    },
    setDense(dense) {
      const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
      newSettings.options.dense = dense;
      this.updateSettings(newSettings);
    },
    setSize(size) {
      if (this.settings.size != size) {
        const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
        newSettings.size = size;
        this.updateSettings(newSettings);
      }
    },
    setHeight(height) {
      if (this.settings) {
        const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
        if (!newSettings.size) {
          newSettings.size = {
            width: 0,
            height: 0,
          };
        } else if (newSettings.size && typeof newSettings.size != 'object') {
          newSettings.size = {}; // make sure old settings are not used.
        }
        newSettings.size.height = height;
        this.updateSettings(newSettings);
      }
    },
    setWidth(width) {
      if (this.settings) {
        const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
        if (!newSettings.size) {
          newSettings.size = {
            width: 0,
            height: 0,
          };
        } else if (newSettings.size && typeof newSettings.size != 'object') {
          newSettings.size = {}; // make sure old settings are not used.
        }
        newSettings.size.width = width;
        this.updateSettings(newSettings);
      }
    },
    toggleOption(propertyName) {
      const newSettings = GlobalHelperFunctions.duplicateViaJson(this.settings);
      if (!newSettings.options) {
        newSettings.options = {};
      }
      // bool
      if (propertyName == 'isStacked') {
        if (newSettings.options.isStacked === undefined) {
          newSettings.options.isStacked = true;
        } else {
          newSettings.options.isStacked = !newSettings.options.isStacked;
        }
      }
      this.updateSettings(newSettings);
    },
  },
};
</script>

<style scoped>
.size-btn {
  width: 25px !important;
  height: 25px !important;
  min-width: 25px !important;
  min-height: 25px !important;
}
</style>
