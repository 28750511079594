<template>
  <div>
    <!-- divider -->
    <!-- <v-divider v-if="value.divider"></v-divider> -->

    <!-- menu item without sub menu -->
    <v-subheader v-if="value.subHeader" :inset="value.inset">
      {{ value.subHeader }}
    </v-subheader>
    <v-list-item v-if="!value.children" @click="gotoMeuItem(value)" :to="getPath(value)">
      <!--  -->
      <!-- :to="getRoute(value)" -->
      <!-- icon -->
      <v-list-item-icon :class="subItem ? 'pl-3' : ''" v-if="!value.noIcon">
        <v-icon :small="subItem">{{ value.icon }}</v-icon>
      </v-list-item-icon>
      <!-- label -->
      <v-list-item-title v-if="!value.callNo">{{ value.text }}</v-list-item-title>
      <v-list-item-title v-else :class="value.noIcon ? 'pl-3' : ''">
        <div class="d-flex flex-column">
          <span style="font-size: 10px; margin-bottom: -4px">{{ value.callNo }}</span>
          <span>{{ value.text }}</span>
        </div>
      </v-list-item-title>
      <v-list-item-action class="ma-0" v-if="value.text === 'Attendance' && attendanceStore.inAttendance">
        <v-icon color="green">mdi-circle-medium</v-icon>
      </v-list-item-action>
      <v-list-item-action class="ma-0" v-if="value.text === 'Activities' && helperStore.pendingActivities">
        <v-badge :content="helperStore.pendingActivities.toString()" inline color="red" />
      </v-list-item-action>
    </v-list-item>

    <!-- menu item with sub menu -->
    <v-list-group v-else eager :value="openMenu" @click="$router.push(value.url)">
      <v-divider></v-divider>
      <template v-slot:activator>
        <!-- icon -->
        <!-- <v-icon slot="prependIcon">{{value.icon}}</v-icon> -->
        <v-list-item-icon>
          <v-icon>{{ value.icon }}</v-icon>
        </v-list-item-icon>
        <!-- label -->
        <v-list-item-title>{{ value.text }}</v-list-item-title>
      </template>

      <drawer-menu-item
        v-for="(item, index) in value.children"
        :key="index"
        v-show="!item.userRight || applicationStore.userRight(item.userRight)"
        :value="item"
        :subItem="true" />
      <v-divider></v-divider>
    </v-list-group>
  </div>
</template>

<script>
import { useHelperStore } from '@/store/helperStore';
import { useAttendanceStore } from '@/store/attendanceStore';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'DrawerMenuItem',
  props: ['value', 'subItem'],
  components: {},
  setup() {
    const helperStore = useHelperStore();
    const attendanceStore = useAttendanceStore();
    const applicationStore = useApplicationStore();
    return {
      attendanceStore,
      applicationStore,
      helperStore,
    };
  },
  data() {
    return {};
  },

  created() {},

  computed: {
    openMenu() {
      // assigned to v-list-group :value which is the open/close state of the group.
      const { children } = this.value;
      const currentRoute = this.$route.path;
      const isPortcallRoute = currentRoute.includes('/call');
      if (this.value.url == currentRoute || (isPortcallRoute && this.value.url == '/calls')) {
        return true;
      }
      for (let i = 0; children.length > i; i++) {
        if (children[i].url == currentRoute) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    getRoute(menuItem) {
      const result = {};
      if (menuItem.routeName) {
        result.name = menuItem.routeName;
      } else if (menuItem.url) {
        result.path = menuItem.url;
      } else {
        result.name = 'NotImplemented';
      }
      if (menuItem.routeParams) {
        result.params = menuItem.routeParams;
      }
      return result;
    },
    getPath(menuItem) {
      if (menuItem.toPath) {
        return menuItem.toPath;
      }
      if (menuItem.url) {
        return menuItem.url;
      }
      return '';
    },
    gotoMeuItem(menuItem) {
      if (menuItem.url) {
        this.$router.push(menuItem.url);
      } else if (menuItem.routeName) {
        if (menuItem.routeParams) {
          this.$router.push({ name: menuItem.routeName });
        } else {
          this.$router.push({ name: menuItem.routeName, params: menuItem.routeParams });
        }
      }
    },
  },
};
</script>

<style scoped></style>
