var render = function render(){var _vm=this,_c=_vm._self._c;return _c('embedded-dashboard',{attrs:{"location":"expenses","title":"Summary"},on:{"dataRetrieved":_vm.dataRetrieved}},[_c('template',{slot:"compact"},[_c('v-layout',{attrs:{"wrap":""}},[_c('gat-compact-field',{attrs:{"value":_vm.dashData.ExpenseAmounts['Balance due'],"size":"s","label":"Balance due","isCurrency":true}}),_c('gat-compact-field',{attrs:{"value":_vm.dashData.ExpenseRevenue['Total'],"size":"s","label":"Revenue","isCurrency":true}}),_c('gat-compact-field',{attrs:{"value":_vm.extractExpenseLineCount('Ready for invoicing') +
          ' of ' +
          (_vm.extractExpenseLineCount('Ready for invoicing') + _vm.extractExpenseLineCount('Budget')),"size":"s","label":"Ready for invoicing"}}),_c('gat-compact-field',{attrs:{"value":_vm.extractExpenseLineCount('Invoiced') +
          ' of ' +
          (_vm.extractExpenseLineCount('Ready for invoicing') +
            _vm.extractExpenseLineCount('Budget') +
            _vm.extractExpenseLineCount('Invoiced')),"size":"s","label":"Invoiced"}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }