
 import Vue from 'vue';
 import {apiGet } from '../../store/api';

    export default Vue.extend({
        name: 'PortcallRemainingDialog',
        data() {
            return	{
                showDialog: false,
                remaining: 0,
                message: null,
                category: null,
            }
        },

        activated(){
            this.checkRemainingCalls()
        },

        computed: {
            title() {
                return this.category === 2 ? 'Warning!' : 'Error!'
            }
        },

        methods:{
            async checkRemainingCalls() {
                this.$emit('isLoading', true)
                const result = await apiGet('portcall/remaining',)

                this.remaining = result.payload.remaining
                this.category = result.resultCategory
                
                // if warning (2) or error (3)
                if (result.resultCategory === 2 || result.resultCategory === 3) {
                    this.message = result.message
                    this.showDialog = true;
                } else {
                    this.showDialog = false
                }
                this.$emit('isLoading', false)
            },
            okClicked() {
                // If only a warning, ok continue
                if (this.category === 2) {
                    this.showDialog = false
                } else {
                    this.$router.push({name:'PortCalls'});
                }
            }
        },
    })
