import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getUser() {
  const applicationStore = useApplicationStore();
  const data = await apiGet('user');

  if (applicationStore.debugMode) {
    console.log(data);
  }
  applicationStore.setUser(data);
  applicationStore.setNewAgency(data.defaultAgencyId);
}
