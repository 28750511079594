<template>
  <GatGroup title="Internet vessel base matches" class="pb-2" :class="{ uthev: emp }" :collapsed="vessels.length > 12">
    <template slot="compact">
      <span v-if="vessels.length > 0">{{ vessels.length }} vessels matches</span>
    </template>
    <span v-show="applicationStore.userRight('VE.EDIT_AND_NEW')"
      >Click on a vessel in below list to import the vessel</span
    >
    <gat-grid class="searchGrid elevation-0" :items="vessels" :columns="columns" @row-clicked="rowClicked"></gat-grid>
    <div v-if="showProgress" class="text-xs-center vert-center">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <VesselDialog
      :showDialog="showDialog"
      :duplicateVesselNamesFound="duplicateVesselNamesFound"
      :duplicateVesselImoNumbersFound="duplicateVesselImoNumbersFound"
      @close-clicked="closeDialog"
      @save-clicked="rowClicked" />
  </GatGroup>
</template>

<script>
import { defineComponent } from 'vue';
import { apiPostExternal, apiGetExternal, apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import VesselDialog from './VesselDialog.vue';

export default defineComponent({
  name: 'VesselNetSearch',
  props: ['vesselName', 'callSign', 'IMO', 'emp', 'searchText'],
  components: { VesselDialog },
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      vessels: [],
      vesselToImport: {},
      showDialog: false,
      duplicateVesselNamesFound: null,
      duplicateVesselImoNumbersFound: null,
      showProgress: false,
      columns: [
        { header: 'Name', field: 'NAME' },
        { header: 'Call sign', field: 'CALLSIGN', hideOnPhone: false },
        { header: 'IMO no', field: 'LLOYDSNR', hideOnPhone: true },
        { header: 'DWT', field: 'DWT' },
        { header: 'GT', field: 'GT' },
        { header: 'LOA', field: 'LOA', hideOnPhone: true },
        { header: 'Beam', field: 'BEAM', hideOnPhone: true },
        { header: 'Homeport', field: 'HOMEPORT', hideOnPhone: true },
        { header: 'Flag', calculated: this.vesselFlag, hideOnPhone: true },
      ],
      timeoutSearch: undefined,
    };
  },

  created() {},

  watch: {
    vesselName(newValue) {
      if (newValue && newValue.length > 3) {
        this.getNewVesselSearch(newValue, this.callSign, this.IMO);
      } else {
        this.vessels = [];
        this.$emit('input', 0);
      }
    },
    callSign(newValue) {
      if (newValue && newValue.length > 2) {
        this.getNewVesselSearch(this.vesselName, newValue, this.IMO);
      } else {
        this.vessels = [];
        this.$emit('input', 0);
      }
    },
    IMO(newValue) {
      this.vessels = [];
      if (newValue && newValue.length > 2) {
        this.getNewVesselSearch(this.vesselName, this.callSign, newValue);
      } else {
        this.vessels = [];
        this.$emit('input', 0);
      }
    },

    searchText(newValue) {
      this.vessels = [];
      if (newValue && newValue.length > 2) {
        this.getSearch(newValue);
      } else {
        this.vessels = [];
        this.$emit('input', 0);
      }
    },
  },

  computed: {
    useVesselTracker() {
      // const today = new Date();
      // const releaseDate = new Date('01.01.2023');
      // if(today >= releaseDate){
      //   return true;
      // }
      // if(this.applicationStore.globalSettings.SGL_FEATURE_CODE && this.applicationStore.globalSettings.SGL_FEATURE_CODE.includes("USE_VESSELTRACKER_VESSEL_SEARCH")){
      //   return true;
      // }
      // return false;
      return true;
    },
  },

  methods: {
    checkForDuplicateVessels(rowItem, data) {
      // Check if the vessel IMO is already in the DB
      apiGet(`vessel/exists/imo/${rowItem.LLOYDSNR}`).then((result) => {
        // If the result object isnt empty, add it to duplicateVesselImoNumbersFound
        if (result.length > 0) {
          this.duplicateVesselImoNumbersFound = result;
        }
        // Check if the vessel NAME is already in the DB
        apiGet(`vessel/exists/name/${rowItem.NAME}`).then((newResult) => {
          if (newResult.length > 0) {
            this.duplicateVesselNamesFound = newResult;
          }
          // if nothing found, emit vessel-import
          if (this.duplicateVesselImoNumbersFound == null && this.duplicateVesselNamesFound == null) {
            this.$emit('vessel-import', data);
          } else {
            // if either vessel IMO or NAME is found, show dialog.
            this.vesselToImport = data;
            this.showDialog = true;
          }
        });
      });
    },

    closeDialog() {
      this.showDialog = false;
      this.duplicateVesselNamesFound = null;
      this.duplicateVesselImoNumbersFound = null;
      this.vesselToImport = {};
    },

    getSearch(param) {
      clearTimeout(this.timeoutSearch);
      this.vessels = [];
      this.showProgress = true;
      let url = 'https://coreservices.gatship.com/api/vessel/search';
      if (this.useVesselTracker) {
        url += '/vessel-tracker';
      }
      this.timeoutSearch = setTimeout(() => {
        apiPostExternal(url, { search: param }, { headers: { gsToken: '3feb8d27-4c34-4106-bf0a-2a3cb167b580' } }).then(
          (data) => {
            // apiPostExternal("http://localhost:44391/api/vessel/search/vessel-tracker",{ search: param },{ headers: { gstoken: "3feb8d27-4c34-4106-bf0a-2a3cb167b580" } }).then((data) => {
            this.showProgress = false;
            this.vessels = data;
            this.$emit('input', data.length);
          }
        );
      }, 500);
    },

    getNewVesselSearch(name, callsign, imo) {
      clearTimeout(this.timeoutSearch);
      this.vessels = [];
      this.showProgress = true;
      let url = 'https://coreservices.gatship.com/api/vessel/search';
      if (this.useVesselTracker) {
        url += '/vessel-tracker';
      }
      this.timeoutSearch = setTimeout(() => {
        apiPostExternal(
          url,
          { Name: name, CallSign: callsign, IMO: imo },
          { headers: { gsToken: '3feb8d27-4c34-4106-bf0a-2a3cb167b580' } }
        ).then((data) => {
          // apiPostExternal("http://localhost:44391/api/vessel/search/vessel-tracker",{ Name: name, CallSign: callsign, IMO: imo },{ headers: { gsToken: "3feb8d27-4c34-4106-bf0a-2a3cb167b580" } }).then((data) => {
          this.showProgress = false;
          this.vessels = data;
          this.$emit('input', data.length);
        });
      }, 500);
    },

    rowClicked(rowItem) {
      if (this.showDialog) {
        this.showDialog = false;
        this.$emit('vessel-import', this.vesselToImport);
      } else {
        this.showProgress = true;
        let url = 'https://coreservices.gatship.com/api/vessel/';
        if (this.useVesselTracker) {
          url += '/vessel-tracker/';
        }
        apiGetExternal(url + rowItem.LLOYDSNR, { headers: { gsToken: '3feb8d27-4c34-4106-bf0a-2a3cb167b580' } }).then(
          (data) => {
            // apiGetExternal("http://localhost:44391/api/vessel/vessel-tracker/" + rowItem.LLOYDSNR,{ headers: { gsToken: "3feb8d27-4c34-4106-bf0a-2a3cb167b580" } }).then((data) => {
            this.checkForDuplicateVessels(rowItem, data);
            // this.$emit("vessel-import",data);
            this.showProgress = false;
          }
        );
      }
    },
    vesselFlag(rowItem) {
      if (rowItem.COUNTRY) {
        return rowItem.COUNTRY;
      }
      if (rowItem.FLAG) {
        return rowItem.FLAG;
      }
      return '';
    },
  },
});
</script>

<style scoped>
.uthev {
  background-color: rgba(31, 204, 235, 0.56);
}
.vert-center {
  position: absolute;
  top: 45%;
  left: 45%;
}
</style>
