<template>
  <v-layout>
    <gat-flex size="xs8">
      <gat-date-edit
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :label="label"
        v-model="dateValue"
        :autofocus="autofocus"
        :disabled="disabled"
        :readonly="readonly"
        :hint="hint"
        :append-icon="appendIcon"
        :required="required"
        :futureDate="futureDate"
        :showWeekDay="showWeekDay"
        :error-messages="errorMessages"
        @blur="onBlurDateField"
        @input="updateValue()"
        @OnErrorState="handleErrorState"></gat-date-edit>
    </gat-flex>
    <gat-flex size="xs4" class="ml-1">
      <gat-time-edit
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        ref="timeField"
        :label="label2 || 'time'"
        v-model="timeValue"
        :autofocus="autofocus"
        @input="updateValue()"
        :disabled="disabled"
        :readonly="readonly"
        :hint="hint"
        :append-icon="appendIcon"
        :required="required"
        :error="isError"
        @blur="$emit('blur', $event)"
        @OnErrorState="handleErrorState"
        @focus="onFocus($event)"></gat-time-edit>
    </gat-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import GatTimeEdit from './GatTimeEdit.vue';
import GatDateEdit from './GatDateEdit.vue';
import GatFlex from '../GatFlex.vue';

export default {
  name: 'GatDateTimeEdit',
  props: {
    required: Boolean,
    value: [String, Object],
    label: String,
    disabled: Boolean,
    errorMessages: [String, Array],
    readonly: Boolean,
    hint: String,
    autofocus: Boolean,
    appendIcon: String,
    label2: String,
    futureDate: Boolean,
    showWeekDay: {
      type: String,
      default: 'auto', // auto use dd, ddd or dddd depending on field width when mounted
      validator: (value) => ['none', 'auto', 'dd', 'ddd', 'dddd'].indexOf(value) >= 0,
    },
    size: String,
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  components: { GatTimeEdit, GatDateEdit, GatFlex },
  data() {
    return {
      dateValue: null,
      timeValue: null,
      dataFormat: 'YYYY-MM-DDTHH:mm:ss',
      dateFormat: 'YYYY-MM-DD',
      timeFormat: 'HH:mm:ss',
    };
  },

  computed: {
    isError() {
      if (this.errorMessages) {
        return true;
      }
      return false;
    },
  },

  created() {},

  watch: {
    value: {
      handler(newValue) {
        this.dateValue = moment(newValue, this.dataFormat).format(this.dateFormat);
        if (this.timeIsNull(newValue)) {
          this.timeValue = null;
        } else {
          this.timeValue = moment(newValue, this.dataFormat).format(this.timeFormat);
        }
      },
      immediate: true,
    },
  },

  methods: {
    onFocus(event) {
      // if (event.relatedTarget === null) fixes the application freezing up the browser.
      if (event.relatedTarget === null) {
        this.$nextTick(() => {
          event.target.select();
        });
      }
    },
    focusTimeInput() {
      this.$refs.timeField.focus();
      /* this.$nextTick(() => {
        console.log(this.$refs.timeField);
      }); */
    },
    onBlurDateField($event) {
      this.focusTimeInput();
      this.$emit('blur', $event);
    },
    handleErrorState(e) {
      this.$emit('OnErrorState', e);
    },

    timeIsNull(value) {
      if (typeof value == 'string') {
        return !value.includes('T');
      }
      return false;
    },

    updateValue() {
      let value = null;

      if (this.dateValue != null) {
        if (this.timeValue == null) {
          value = moment(this.dateValue, this.dateFormat);
          if (value.isValid()) {
            value = value.format(this.dateFormat);
          } else {
            value = null;
          }
        } else {
          value = moment(`${this.dateValue}T${this.timeValue}`, this.dataFormat);
          if (value.isValid()) {
            value = value.format(this.dataFormat);
          } else {
            value = null;
          }
        }
      }

      if (value != this.value) {
        this.$emit('input', value);
      }
    },
  },
};
</script>

<style scoped></style>
