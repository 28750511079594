import { render, staticRenderFns } from "./ChartSettingsMenu.vue?vue&type=template&id=1f4ecdf4&scoped=true&"
import script from "./ChartSettingsMenu.vue?vue&type=script&lang=js&"
export * from "./ChartSettingsMenu.vue?vue&type=script&lang=js&"
import style0 from "./ChartSettingsMenu.vue?vue&type=style&index=0&id=1f4ecdf4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f4ecdf4",
  null
  
)

export default component.exports