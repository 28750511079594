import { apiGet } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function getLastPortCalls() {
  const portcallStore = usePortcallStore();
  const data = await apiGet('savedinfo/web_last_calls');

  if (data.length > 0) {
    const lastCalls = JSON.parse(data[0].MEMOVALUE1);
    portcallStore.setLastPortCalls(lastCalls);
  } else {
    portcallStore.setLastPortCalls([]);
  }
  return data[0];
}
