import { apiGet } from '@/store/api';
import { useHelperStore } from '@/store/helperStore';

export async function getPendingActivities() {
  const helperStore = useHelperStore();
  const data = await apiGet(`activity/pending`);
  helperStore.setPendingActivities(data);

  if (helperStore.activitiesTimeout) {
    clearTimeout(helperStore.activitiesTimeout);
  }
  // Runs every 20 minutes since last time it was ran.
  helperStore.activitiesTimeout = setTimeout(() => {
    getPendingActivities();
  }, 60000 * 20);
}
