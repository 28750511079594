<template>
  <div>
    <gat-form ref="form" :isLoading="isLoading" :readonly="!canAddVoyage">
      <gat-group title="Voyage" :collapsable="false">
        <v-layout wrap>
          <gat-harbour-select
            v-model="voyageItem.HARBOUR_ID"
            label="Port"
            size="m"
            @item-selected="HandlePortChanged"
            required></gat-harbour-select>
          <gat-select v-model="voyageItem.TYPE" :items="constantsStore.voyageTypes" label="Type" required size="m">
          </gat-select>
          <gat-select
            v-model="voyageItem.SEQURITY_LEVEL"
            :items="ISPS_levels"
            label="ISPS level"
            required
            size="s"
            hint="Interational Ship and Port facility Security level">
          </gat-select>
          <gat-edit label="Date" dateEdit v-model="voyageItem.LEFT_DATE" size="sm" />
          <gat-edit label="Time" timeEdit v-model="voyageItem.LEFT_TIME" />
        </v-layout>
      </gat-group>
      <br />
      <gat-bottom-panel
        :orgValue="orgVoyageItem"
        :newValue="voyageItem"
        :itemDescription="'Voyage item '"
        :saveBtn="true"
        :deleteBtn="true"
        :buttonsEnabled="!saving"
        :deleteDisabled="!voyageItem.ID || !canDeleteVoyage"
        :saveDisabled="!canAddVoyage"
        @saveClicked="saveItem"
        @deleteClicked="deleteItem"></gat-bottom-panel>
    </gat-form>
  </div>
</template>

<script>
import GatHarbourSelect from '@/app-components/GatHarbourSelect.vue';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiDelete, apiPut } from '@/store/api';
import { useConstantsStore } from '@/store/constantsStore';
import { useToolbarStore } from '@/store/toolbarStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';
import { getVoyage } from '@/services/api/api-calls/getVoyage';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'VoyageForm',
  props: {
    callId: [Number, String],
    voyageID: [Number, String],
    isNew: Boolean,
  },
  components: {
    GatHarbourSelect,
  },
  setup() {
    const constantsStore = useConstantsStore();
    const toolbarStore = useToolbarStore();
    const portcallStore = usePortcallStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      constantsStore,
      toolbarStore,
      portcallStore,
    };
  },
  data() {
    return {
      voyageItem: { LEFT_DATE: null },
      orgVoyageItem: {},
      saving: false,
      isLoading: false,
      ISPS_levels: [
        { value: 0, text: 1 },
        { value: 1, text: 2 },
        { value: 2, text: 3 },
      ],
    };
  },

  activated() {
    if (this.portcallStore.voyage && this.portcallStore.voyage.length === 0) {
      getVoyage(this.callId);
    }

    this.toolbarStore.setBackNavigation([
      { text: 'port call', href: `/call/${this.callId}` },
      { text: 'voyage list', href: `/call/${this.callId}/voyage` },
    ]);
    if (this.isNew) {
      this.voyageItem = { PORTCALL_ID: this.callId };
      this.orgVoyageItem = GlobalHelperFunctions.duplicateViaJson(this.voyageItem);
    } else {
      this.retrieveVoyageItem(this.voyageID);
    }
  },

  deactivated() {
    this.toolbarStore.setBackNavigation([]);
  },

  created() {},

  watch: {},

  computed: {
    canAddVoyage() {
      return this.applicationStore.userRight('RO.NEW_AND_EDIT');
    },
    canDeleteVoyage() {
      return this.applicationStore.userRight('RO.DELETE');
    },
  },

  methods: {
    saveItem() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        this.isLoading = true;
        if (this.isNew) {
          apiPost('/voyage/', this.voyageItem)
            .then((result) => {
              if (result.resultType === 2) {
                // 2=UPDATE_OK
                const callId = this.voyageItem.PORTCALL_ID;
                this.voyageItem = {};
                this.orgVoyageItem = {};
                this.portcallStore.addVoyageItem(result.payload);
                this.$nextTick().then(() => {
                  this.$router.push(`/call/${callId}/voyage/`);
                });
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        } else {
          apiPut(`/voyage/${this.voyageID}`, { Value: this.voyageItem, OrgValue: this.orgVoyageItem })
            .then((result) => {
              if (result.resultType === 2) {
                // 2=UPDATE_OK
                const callId = this.voyageItem.PORTCALL_ID;
                const newVoyageItem = {
                  ID: this.voyageItem.ID,
                  HarbourName: this.voyageItem.NAME,
                  TYPE: this.voyageItem.TYPE,
                  LEFT_DATE: this.voyageItem.LEFT_DATE,
                  LEFT_TIME: this.voyageItem.LEFT_TIME,
                  SEQURITY_LEVEL: this.voyageItem.SEQURITY_LEVEL + 1,
                };
                this.portcallStore.replaceVoyageItem(newVoyageItem);
                this.voyageItem = {};
                this.orgVoyageItem = {};
                this.$nextTick().then(() => {
                  this.$router.push(`/call/${callId}/voyage/`);
                });
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    deleteItem() {
      this.isLoading = true;
      apiDelete(`/voyage/${this.voyageID}`)
        .then((result) => {
          if (result.resultType === 4) {
            // 4==DeleteOK
            const callId = this.voyageItem.PORTCALL_ID;
            this.voyageItem = {};
            this.orgVoyageItem = {};
            this.portcallStore.removeVoyageItem(this.voyageID);
            this.$router.push(`/call/${callId}/voyage/`);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    HandlePortChanged(portItem) {
      this.voyageItem.NAME = portItem.NAME;
    },
    retrieveVoyageItem(voyageId) {
      this.isLoading = true;
      apiGet(`/voyage/${voyageId}`)
        .then((result) => {
          this.voyageItem = result;
          this.orgVoyageItem = GlobalHelperFunctions.duplicateViaJson(result);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
