<template>
  <span>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn small :class="compClass" text icon color="green" v-on="on" @click="clicked" :disabled="isDisabled">
          <v-icon>cached</v-icon>
        </v-btn>
      </template>
      <span>{{ hint }}</span>
    </v-tooltip>
  </span>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'GatReloadBtn',
  props: {
    hint: String,
    orgValue: [Object, Array],
    newValue: [Object, Array],
    compClass: String,
  },
  components: {},
  data() {
    return {};
  },

  computed: {
    isDisabled() {
      let result = false;
      if (this.orgValue && this.newValue) {
        result = !GlobalHelperFunctions.globalObjectsAreAlike(this.orgValue, this.newValue);
      }
      return result;
    },
  },

  created() {},

  watch: {},

  methods: {
    clicked() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
.grid-reload {
  margin-top: -3px;
}
</style>
