<template>
<keep-alive>
    <router-view/>
</keep-alive>
</template>

<script>
    export default {
        name: 'EmailView',
        props: [],
        components: {},
        data() {
            return	{
            }
        },


        created(){
        },

        watch:{
        },

        computed:{
        },

        methods:{
        },
    }
</script>

<style scoped>

</style>
