<template>
<span>
  <slot v-bind:user="user">
    {{ user.firstName }}
  </slot>
  <slot name="test" v-bind:user="user">
    {{ user.lastName }}
  </slot>
</span>
</template>

<script>
    export default {
        name: 'currentUser',
        props: [],
        components: {},
        data() {
            return	{
                user:{
                    firstName: 'Fredrik',
                    lastName: 'Granerud'
                }
            }
        },


        created(){
        },

        watch:{
        },

        computed:{
        },

        methods:{
        },
    }
</script>

<style scoped>

</style>
