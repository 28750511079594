<template>
  <gat-lookup-edit
    :label="label || 'Harbour'"
    v-model="model"
    :searchresult="items"
    @getItems="getItems($event)"
    textFieldName="NAME"
    codeFieldName="ID"
    @input="updateValue()"
    @itemNotFound="itemNotFound"
    :cashSearches="true"
    :size="size"
    :required="required"
    :readonly="readonly"></gat-lookup-edit>
</template>

<script>
import { apiCashedGet, apiPost } from '@/store/api';

export default {
  name: 'GatHarbourSelect',
  props: ['value', 'size', 'label', 'required', 'readonly'],
  components: {},
  data() {
    return {
      items: [],
      model: null,
    };
  },

  created() {},

  watch: {
    value(val) {
      this.model = val;
    },

    items() {},
  },

  methods: {
    getItems(searchText) {
      apiPost('helper/search/', { table: 'harbour', text: searchText }).then((data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          // eslint-disable-next-line no-param-reassign
          if (item.LOCODE) item.NAME = `${item.NAME} - ${item.LOCODE}`;
        });
        this.items = data;
      });
    },

    updateValue() {
      this.$emit('input', this.model);
      const selectedItem = this.items.find((item) => item.ID == this.model);
      if (selectedItem) {
        this.$emit('item-selected', selectedItem);
      }
    },

    itemNotFound(id) {
      apiCashedGet(`helper/harbour/id/${id}`).then((data) => {
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          // eslint-disable-next-line no-param-reassign
          if (item.LOCODE) item.NAME = `${item.NAME} - ${item.LOCODE}`;
        });
        this.items = data;
        if (data && data.length) {
          this.$emit('item-selected', data[0]);
        }
      });
      // }
    },
  },
};
</script>

<style scoped></style>
