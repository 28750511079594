import { v4 as uuid } from 'uuid';
import numeral from 'numeral';
import sanitizeHtml from 'sanitize-html';

function textToSafeHTML(htmlAsString: string) {
  // https://www.npmjs.com/package/sanitize-html
  const value = htmlAsString;
  const options: any = {
    disallowedTagsMode: 'escape',
    allowedTags: ['a', 'b', 'img', 'span'],
    transformTags: {
      a(tagName: any, attribs: any) {
        return {
          tagName,
          attribs: {
            ...attribs,
            class: 'gridLink',
          },
        };
      },
      span(tagName: any, attribs: any) {
        let classResult = '';
        if (attribs.class && attribs.class.includes(' ')) {
          const classes = attribs.class.split(' ');
          classes.forEach((item: any) => {
            if (item.includes('mdi')) {
              classResult += ` ${item}`;
            }
            if (item.includes('text')) {
              classResult += ` ${item}`;
            }
            if (item.includes('bg')) {
              classResult += ` ${item}`;
            }
            if (item.includes('darken')) {
              classResult += ` ${item}`;
            }
            if (item.includes('lighten')) {
              classResult += ` ${item}`;
            }
          });
        }
        // My own custom magic goes here
        return {
          tagName: 'span',
          attribs: {
            class: classResult.trim(),
          },
        };
      },
    },
    allowedAttributes: {
      a: ['href', 'class'],
      img: ['src', 'width', 'height'],
      span: ['class'],
    },
    allowedSchemesByTag: {
      a: [],
      img: ['https'],
    },
    parseStyleAttributes: false,
  };
  const result = sanitizeHtml(value, options);
  return result;
}

function duplicateViaJson(obj: any) {
  let result: any = {};
  if (obj) {
    const jsonStr = JSON.stringify(obj);
    result = JSON.parse(jsonStr);
  }
  return result;
}

function GUID(): string {
  return uuid();
}

function formatNumber(value: any, format: any) {
  let result = value;
  let formating = format;
  if (!format) {
    formating = '0,0.00';
  }
  if (value == null) {
    result = '';
  } else {
    result = numeral(value).format(formating);
  }
  return result;
}

function getObjectChanges(Org: any, New: any) {
  const result = [];
  if (Org == null) {
    // eslint-disable-next-line no-param-reassign
    Org = {};
  }
  if (New == null) {
    // eslint-disable-next-line no-param-reassign
    New = {};
  }
  if (typeof Org == 'object' && typeof New == 'object') {
    // finner det nye og endrede properties

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const prop in New) {
      const propOrg = Org[prop];
      const propNew = New[prop];
      if (typeof propOrg == 'object' && typeof propNew == 'object') {
        const propResult = getObjectChanges(propOrg, propNew);
        if (propResult.length > 0) {
          // eslint-disable-next-line array-callback-return
          propResult.map((item) => {
            result.push(item);
          });
        }
      } else {
        const value1 = JSON.stringify(propOrg);
        const value2 = JSON.stringify(propNew);
        if (value1 != value2) {
          result.push({ propertyName: prop, oldValue: value1, newValue: value2 });
        }
      }
    }
    // finner slettede properties
    // eslint-disable-next-line no-restricted-syntax
    for (const orgProp in Org) {
      if (!Object.prototype.hasOwnProperty.call(New, orgProp)) {
        result.push({ propertyName: orgProp, oldValue: JSON.stringify(Org[orgProp]), newValue: null });
      }
    }
  }
  return result;
}

function globalObjectsAreAlike(objA: any, objB: any) {
  let result = false;
  const v1 = JSON.stringify(objA);
  const v2 = JSON.stringify(objB);
  result = v1 == v2;

  return result;
}

function splitSize(fields: any, maxHorizontal: any) {
  let size = 's';
  let fieldsUsed = 0;
  // eslint-disable-next-line array-callback-return
  fields.forEach((field: any) => {
    if (field) {
      fieldsUsed += 1;
    }
  });
  if (fieldsUsed > maxHorizontal) {
    const rows = Math.ceil(fieldsUsed / maxHorizontal);
    fieldsUsed /= rows;
  }
  if (fieldsUsed > 0) {
    const colsPerField = 12 / fieldsUsed;
    size = `xs${colsPerField}`;
  }

  return size;
}

const GatComponentsHelperFunctions = {
  textToSafeHTML,
  splitSize,
  globalObjectsAreAlike,
  getObjectChanges,
  duplicateViaJson,
  GUID,
  formatNumber,
};

export default GatComponentsHelperFunctions;
