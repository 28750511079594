import { apiGet } from './api';

// UPDATE THIS VALUE TO REFLECT THE MIMIMUM DATABASE VERSION REQUIRED.
// ANY USERS WITH A DB LOWER THAN THIS VALUE WILL GET A WARNING AND WILL NOT BE ABLE TO USE THE WM.
const MINIMUMDBVERSION = 5874;

async function getCurrentDatabaseVersion() {
  const RESULT = await apiGet('helper/databaseVersion');
  const currentVersion = RESULT[0].DBVERSION;
  return currentVersion;
}

export async function checkDatabaseVersion() {
  const CURRENTVERSION = await getCurrentDatabaseVersion();
  return CURRENTVERSION >= MINIMUMDBVERSION;
}
