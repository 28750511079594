<template>
  <div>
    <div class="d-flex flex-row-reverse">
      <v-btn
        v-if="showAddBtn"
        color="primary"
        outlined
        small
        :disabled="addExpenseButtonDisabled"
        @click="addServiceExpense(serviceId, expenseTemplateId)">
        <v-icon small>add</v-icon>Add
      </v-btn>
    </div>
    <gat-grid
      :items="items"
      class="elevation-0"
      :class="isClickable ? '' : 'not-clickable'"
      :columns="columns"
      gridName="embeddedExpGrid"
      :rowMenu="contextMenu"
      @rowMenuClicked="contextMenuClicked"
      @row-clicked="rowClicked">
      <template v-if="props.column.custom" slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'CONTEXT_MENU'" class="d-flex align-center justify-center">
          <v-btn icon @click.stop="showContextMenu($event, props.row.EXP_ID)">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </div>
      </template>
    </gat-grid>
    <v-dialog v-model="showDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title">Delete expense</v-card-title>

        <v-card-text class="text-center">Do you want to delete the expense connected to this service?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteServiceExpense()">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { apiPut, apiDelete } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';
import { getServices } from '@/services/api/api-calls/getServices';

export default {
  name: 'EmbeddedExpList',
  props: {
    items: {
      type: Array,
    },
    showAddBtn: {
      type: Boolean,
      default: false,
    },
    serviceId: {
      type: Number,
      defalut: null,
    },
    expenseTemplateId: {
      type: Number,
      default: null,
    },
  },
  components: {},
  setup() {
    const portcallStore = usePortcallStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      portcallStore,
    };
  },
  data() {
    return {
      expenseId: null, // expenseId is set when a user clicks delete on the context menu.
      showDialog: false,
    };
  },

  created() {},

  computed: {
    isClickable() {
      if (!this.applicationStore.user.internal && this.serviceId && this.items.length == 1) {
        return this.applicationStore.user.externalClientId == this.items[0].CLIENT_ID && this.userCanAccessExpenses;
      }
      return true;
    },
    userCanAccessExpenses() {
      if (!this.applicationStore.user.internal) {
        return this.applicationStore.userRight('EX.ACCESS');
      }
      return true;
    },
    addExpenseButtonDisabled() {
      if (this.items.length == 1) {
        return true;
      }
      if (!this.applicationStore.user.internal) {
        if (!this.applicationStore.userRight('EX.NEW')) {
          return true;
        }
      } else if (!this.applicationStore.userRight('CH.NEW_AND_EDIT')) {
        return true;
      }

      return false;
    },
    callId() {
      return this.portcallStore.callDetails.ID;
    },
    columns() {
      const result = [];
      result.push({ header: 'Amount', field: 'EXP_AMOUNT', dataType: 'currency', width: '100px' });
      result.push({ header: 'Currency', field: 'EXP_CURRENCY_NAME', width: '50px' });
      result.push({ header: 'Description', field: 'EXP_TEXT' });
      result.push({ header: 'Group', field: 'EXP_GRP', hideOnPhone: true });
      result.push({ header: 'Debtor', field: 'CLIENT_NAME', hideOnPhone: true });
      if (this.applicationStore.user.internal) {
        result.push({ header: '', field: 'CONTEXT_MENU', custom: true, hideOnPhone: false });
      }
      return result;
    },
    // contextMenu
    contextMenu() {
      const result = [];
      if (this.applicationStore.user.internal) {
        result.push({
          name: 'edit expense',
          caption: 'Edit',
          icon: 'mdi-playlist-edit',
          enabled: this.applicationStore.userRight('EX.EDIT'),
        });
        result.push({
          name: 'delete expense',
          caption: 'Delete expense',
          icon: 'delete',
          enabled: this.applicationStore.userRight('EX.EDIT'),
        });
      }

      return result;
    },
  },

  watch: {},

  methods: {
    addServiceExpense(serviceId, expenseTemplateId) {
      if (serviceId) {
        this.$router.push({
          name: 'newServiceExpense',
          params: { serviceId, templateId: expenseTemplateId },
        });
      }
    },

    contextMenuClicked(event) {
      const expenseId = event.rowItem.EXP_ID;
      const { serviceId } = this;
      if (event.menuItem.name == 'edit expense') {
        if (expenseId) {
          this.goToExpense(expenseId);
        }
      } else if (event.menuItem.name == 'delete expense') {
        this.expenseId = expenseId;
        if (expenseId && serviceId) {
          this.showDialog = true;
        }
      } else if (this.applicationStore.debugMode) {
        console.log(event);
      }
    },

    deleteServiceExpense(serviceId, expenseId) {
      if (!serviceId) {
        // eslint-disable-next-line no-param-reassign
        serviceId = this.serviceId;
      }
      if (!expenseId) {
        // eslint-disable-next-line no-param-reassign
        expenseId = this.expenseId;
      }
      const service = {
        SER_ID: serviceId,
        SER_EXP_ID: null,
      };
      const orgService = {
        SER_ID: serviceId,
        SER_EXP_ID: expenseId,
      };
      apiPut('service', { value: service, orgValue: orgService, dynavalues: null, orgDynavalues: null }).then(
        (result) => {
          if (result.resultCategory == 1) {
            this.deleteExpenseItem(expenseId);
          }
        }
      );
    },

    deleteExpenseItem(id) {
      apiDelete(`expense/${id}`).then(() => {
        // remove progressbar
        this.$emit('expense-added');
        this.portcallStore.removeExpenseItem(id);
        getServices(this.callId);
        this.showDialog = false;
      });
    },

    goToExpense(id) {
      if (id) {
        this.$router.push({ name: 'callExpense', params: { expenseId: id, serviceId: this.serviceId } });
      }
    },
    rowClicked(rowItem) {
      if (!this.applicationStore.user.internal) {
        if (this.applicationStore.user.externalClientId == rowItem.CLIENT_ID && this.userCanAccessExpenses) {
          this.goToExpense(rowItem.EXP_ID);
        }
      } else {
        this.goToExpense(rowItem.EXP_ID);
      }
    },
    showContextMenu(event, rowItemId) {
      // used for the context button on mobile devices.
      this.gatComponentsMessageBus.$emit('gatgrid-rowmenu-show-context-menu', {
        event,
        rowItemId,
        gridName: 'embeddedExpGrid',
        targetRowId: 'EXP_ID',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// testing a not-allowed cursor if the external user should not be able to access an expense
::v-deep.not-clickable {
  tbody {
    tr:nth-of-type(1) {
      cursor: not-allowed;
      color: $colorDisabledText;
    }
  }
}
</style>
