<template>
  <v-card
    ref="groupcard"
    class="pl-2 pr-2 grpCard"
    :class="[isExpanded && !compact ? 'grpExpanded' : 'grpClosed']"
    :elevation="elevation">
    <v-card-title
      :class="{
        margins,
        title: isExpanded,
        'text-body-2': !isExpanded || compact,
        clickable: !compact && !(collapsable === false),
      }"
      class="mb-1 pa-0 pt-2 cardTitle"
      v-if="title"
      @click="isExpanded = compact || !isExpanded || collapsable === false">
      <div :class="{ titleTextLimit: isExpanded && $slots.btn }" class="text-truncate">{{ title }}</div>
      <div v-if="!(collapsable === false)" class="cardTitle expandedicon">
        <v-icon v-if="isExpanded && !compact">mdi-chevron-up</v-icon>
        <v-icon v-if="!isExpanded">mdi-chevron-down</v-icon>
      </div>
    </v-card-title>

    <div v-show="isExpanded">
      <slot></slot>
      <div class="slotBtn pt-1 pr-1">
        <slot name="btn"></slot>
      </div>
    </div>

    <div class="pl-0 pb-2 clickable" v-show="!isExpanded" @click="isExpanded = !isExpanded">
      <slot name="compact"></slot>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'GatGroup',
  props: {
    title: String,
    collapsed: {
      type: Boolean,
      default: true,
    },
    readonly: Boolean,
    compact: Boolean,
    collapsable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isExpanded: true,
      compName: 'GatGroup',
    };
  },

  created() {
    this.isExpanded = !this.collapsed;
  },

  watch: {
    isExpanded(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$emit('collapsed');
      }
      if (newValue && !oldValue) {
        this.$emit('expanded');
      }
    },
    collapsed() {
      this.isExpanded = !this.collapsed;
    },
  },

  computed: {
    isReadonly() {
      if (typeof this.readonly != 'undefined') {
        return this.readonly;
      }
      return this.getFormReadonly();
    },

    outlined() {
      if (this.compact) {
        return true;
      }
      return false;
    },

    elevation() {
      if (this.compact) {
        return undefined;
      }
      return 1;
    },

    margins() {
      let result = 'ml-3';
      // if phone
      if (this.$vuetify.breakpoint.xsOnly) {
        result = 'ml-2';
      }
      return result;
    },
  },

  methods: {
    getFormReadonly(obj) {
      if (!obj) {
        // eslint-disable-next-line no-param-reassign
        obj = this.$parent;
      }
      if (obj.$options.name == 'GatForm') {
        return obj.readonly;
      }
      if (obj != this.$root) {
        return this.getFormReadonly(obj.$parent);
      }

      return false;
    },

    getReadonly() {
      if (typeof this.readonly != 'undefined') {
        return this.readonly;
      }
      return this.getFormReadonly();
    },
  },
};
</script>

<style scoped>
.cardTitle {
  color: var(--gatGroupCaption);
}

.compactslot {
  padding-left: 40px;
}

.titleTextLimit {
  max-width: calc(100% - 130px);
}

.clickable {
  cursor: pointer;
}

.expandedicon {
  margin-left: 10px;
}

.grpExpanded {
  margin-top: 10px;
  margin-bottom: 10px;
}

.grpClosed {
  margin-top: 1px;
  margin-bottom: 1px;
}

.slotBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0px;
}
</style>
