<template>
  <div class="pb-5">
    <gat-form v-model="formIsValid" ref="form" :readonly="!canEditForm">
      <GatGroup title="Details" :collapsed="false">
        <v-layout wrap>
          <GatSubGroup title="Contact details" :size="size">
            <gat-edit
              v-model="contact.FIRST_NAME"
              label="First name"
              :autofocus="true"
              :required="true"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Contacts.FIRST_NAME')">
            </gat-edit>
            <gat-edit
              v-model="contact.LAST_NAME"
              label="Last name"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Contacts.LAST_NAME')">
            </gat-edit>
            <gat-edit
              v-model="contact.TITLE"
              label="Title"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Contacts.TITLE')">
            </gat-edit>
            <gat-edit
              v-model="contact.DEPARTMENT"
              label="Department"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Contacts.DEPARTMENT')">
            </gat-edit>
          </GatSubGroup>

          <GatSubGroup title="Communication" :size="size">
            <gat-edit
              v-model="contact.EMAIL"
              label="Email"
              size="xs12"
              :readonly="canEditEmail.disabled"
              :messages="['hallo']"
              :maxChars="applicationStore.getColumnLength('Contacts.EMAIL')">
              <template slot="append">
                <div v-if="canEditEmail.disabled" @click.stop class="d-flex mt-1">
                  <v-tooltip :bottom="true" open-on-click color="primary" class="tooltip-test">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-alert</v-icon>
                    </template>
                    <div class="text-center">
                      <div v-for="(line, index) in canEditEmail.hint" :key="`hintLine${index}`">
                        {{ line }}
                      </div>
                    </div>
                  </v-tooltip>
                </div>
              </template>
            </gat-edit>
            <gat-edit
              v-model="contact.PHONE_DIRECT"
              label="Phone"
              size="xs12"
              :messages="['hallo']"
              :maxChars="applicationStore.getColumnLength('Contacts.PHONE_DIRECT')">
            </gat-edit>
            <gat-edit
              v-model="contact.PHONE_MOBILE"
              label="Mobile"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Contacts.PHONE_MOBILE')">
            </gat-edit>
            <gat-edit
              v-model="contact.PHONE_PRIVATE"
              label="Private phone"
              size="xs12"
              :maxChars="applicationStore.getColumnLength('Contacts.PHONE_PRIVATE')">
            </gat-edit>
          </GatSubGroup>

          <GatSubGroup title="Other" :size="size">
            <gat-edit
              v-model="contact.BL_CONTACT"
              checkbox
              label="Bl contact"
              size="xs6"
              v-if="applicationStore.user.internal"
              :maxChars="applicationStore.getColumnLength('Contacts.BL_CONTACT')">
            </gat-edit>
            <gat-edit
              v-model="contact.INACTIVE"
              :readonly="!canSetInactive()"
              checkbox
              :checkedValue="1"
              :uncheckedValue="0"
              label="Inactive"
              size="xs6">
            </gat-edit>
            <gat-edit
              v-model="contact.PRIORITY"
              label="Sorting / priority"
              size="xs6"
              dataType="number"
              v-if="applicationStore.user.internal">
            </gat-edit>
          </GatSubGroup>

          <gat-edit
            v-model="contact.COMMENTS"
            label="Comments"
            size="xs12"
            :rows="3"
            v-if="applicationStore.user.internal"
            :maxChars="applicationStore.getColumnLength('Contacts.COMMENTS')">
          </gat-edit>

          <gat-dyna-fields
            v-if="applicationStore.user.internal"
            v-model="contact.dynaValues"
            componentName="pDynaFields.ContactDyna"
            container="pDynaFields"></gat-dyna-fields>
        </v-layout>
        <template v-slot:compact>
          <v-layout wrap class="d-flex flex-row">
            <gat-compact-field :value="contact.FIRST_NAME" label="First name" />
            <gat-compact-field :value="contact.LAST_NAME" label="Last name" />
            <gat-compact-field :value="contact.TITLE" label="Title" />
            <gat-compact-field :value="contact.DEPARTMENT" label="Department" />
            <gat-compact-field :value="contact.EMAIL" label="Email" />
            <gat-compact-field :value="contact.PHONE_DIRECT" label="Phone" />
            <gat-compact-field :value="contact.PHONE_MOBILE" label="Mobile" />
            <gat-compact-field :value="contact.PHONE_PRIVATE" label="Private phone" />
            <gat-compact-field
              v-if="applicationStore.user.internal"
              :value="contact.BL_CONTACT"
              label="Bl contact"
              checkbox />
            <gat-compact-field
              v-if="applicationStore.user.internal"
              :value="contact.PRIORITY"
              label="Sorting / priority" />
            <gat-compact-field :value="contact.INACTIVE" label="Inactive" checkbox />
            <gat-compact-field
              v-if="applicationStore.user.internal"
              :value="contact.COMMENTS"
              label="Comments"
              :rows="3"
              size="xs12" />
            <gat-dyna-fields
              v-if="applicationStore.user.internal"
              v-model="contact.dynaValues"
              componentName="pDynaFields.ContactDyna"
              container="pDynaFields"
              compact></gat-dyna-fields>
          </v-layout>
        </template>
      </GatGroup>
      <GatGroup title="Web access" :size="size">
        <gat-edit
          v-model="contact.WEB_USER"
          :readonly="true"
          checkbox
          :checkedValue="1"
          :uncheckedValue="0"
          label="Web user"
          size="sm"></gat-edit>
        <gat-edit
          v-model="contact.WM_USER_ADM"
          :readonly="true"
          checkbox
          :checkedValue="1"
          :uncheckedValue="0"
          label="Web adm"
          size="sm"></gat-edit>
        <gat-edit v-model="contact.WebGruppe" :readonly="true" label="Web group" size="sm" :maxChars="0"></gat-edit>
        <template v-slot:compact>
          <v-layout wrap class="d-flex flex-row">
            <gat-compact-field :value="contact.WEB_USER" label="Web user" checkbox :hide="false" />
            <gat-compact-field :value="contact.WM_USER_ADM" label="Web adm" checkbox :hide="false" />
            <gat-compact-field :value="contact.WebGruppe" label="Web group" size="xs12" />
          </v-layout>
        </template>
      </GatGroup>

      <GatGroup size="xs12" title="legal base / consent (GDPR)" v-if="applicationStore.user.internal">
        <template v-slot:compact>
          <div v-if="contact.ID" class="pa-2">
            <div v-for="consent in contact.consents" :key="consent.CCO_ID">
              <gat-compact-field
                style="margin-bottom: 0px !important"
                :label="`${globalFormatDateTime(consent.CCO_UPDATED)} - ${consent.CCO_UPDATED_BY}`"
                :value="gdprCompactItem(consent)"
                size="xs12" />
              <div class="caption mx-3 mb-1" v-if="consent.CCO_REMARKS">Remark: {{ consent.CCO_REMARKS }}</div>
            </div>
          </div>
        </template>
        <div v-if="contact.ID">
          <v-layout wrap>
            <gat-grid
              :items="contact.consents"
              :columns="columns"
              class="elevation-0 consent-grid"
              @row-clicked="consentRowClicked"
              rowHint="click to edit">
              <template
                slot="cell"
                slot-scope="props"
                v-if="props.column.field == 'CCO_CONSENT_PURPOSE' || props.column.field == 'CCO_LEGAL_BASE'">
                <div v-if="props.column.field == 'CCO_CONSENT_PURPOSE'">
                  {{ lookupCode(props.value, purposes, 'COT_ID', 'COT_NAME') }}
                </div>
                <div v-if="props.column.field == 'CCO_LEGAL_BASE'">
                  {{ lookupCode(props.value, legalBases, 'COT_ID', 'COT_NAME') }}
                </div>
              </template>
            </gat-grid>
          </v-layout>
        </div>
        <v-btn v-if="contact.ID" slot="btn" class="addConsent" outlined color="primary" small @click="addConsent">
          Add
        </v-btn>

        <div v-else class="pa-3">The new contact have to be saved before this section can be edited</div>
      </GatGroup>
    </gat-form>
    <gat-bottom-panel
      :orgValue="orgContact"
      :newValue="contact"
      itemDescription="this contact"
      :isLoading="isLoading"
      :saveBtn="applicationStore.userRight('C.EDIT_CONTACT') || applicationStore.userRight('C.NEW_CONTACT')"
      :deleteBtn="applicationStore.user.internal && applicationStore.userRight('C.DELETE_CONTACT')"
      :buttonsEnabled="!saving"
      :deleteDisabled="!contact.ID"
      @saveClicked="saveClicked"
      @deleteClicked="deleteClicked"></gat-bottom-panel>

    <consent-form :showForm="showConsentForm" v-model="currentConsent" @close="consentFormClosed"></consent-form>
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import { defineComponent } from 'vue';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { getConsentTypes } from '@/services/api/api-calls/getConsentTypes';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import ConsentForm from './consentForm.vue';

export default defineComponent({
  name: 'Contact',
  props: ['contactId', 'size', 'clientId'],
  components: { ConsentForm, GatDynaFields },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      toolbarStore,
    };
  },
  data() {
    return {
      contact: {},
      orgContact: {},
      saving: false,
      columns: [],
      showConsentForm: false,
      currentConsent: {},
      isLoading: false,
      formIsValid: false,
    };
  },

  computed: {
    purposes() {
      return this.helperStore.consentTypes.purposes ? this.helperStore.consentTypes.purposes : null;
    },
    legalBases() {
      return this.helperStore.consentTypes.legalBases;
    },
    canEditEmail() {
      const result = {
        hint: '',
        disabled: false,
      };
      if (this.contact.WEB_USER === 1) {
        result.disabled = true;
        result.hint = [
          'Updating the email for users with web access can not be done from the web module.',
          'Please edit this users email from GAT-Ship classic.',
        ];
      }
      return result;
    },

    canEditForm() {
      return this.contactId ? this.applicationStore.userRight('C.EDIT_CONTACT') : true;
    },
  },

  created() {
    if (this.purposes.length == 0) {
      getConsentTypes();
    }
    this.columns = [
      // {"header":"Purpose",calculated:function(rowData){
      //     return this.lookupCode(rowData.CCO_CONSENT_PURPOSE,self.purposes,"COT_ID","COT_NAME");
      //     }},
      { header: 'Purpose', field: 'CCO_CONSENT_PURPOSE', hideOnPhone: false },
      { header: 'Legal base', field: 'CCO_LEGAL_BASE', hideOnPhone: false },
      // {"header":"Legal base","field":"CCO_LEGAL_BASE",calculated: (rowData)=>{return this.lookupCode(rowData.CCO_LEGAL_BASE,self.legalBases,"COT_ID","COT_NAME")}},
      { header: 'Remarks', field: 'CCO_REMARKS', hideOnPhone: true },
      { header: 'Updated', field: 'CCO_UPDATED', dataType: 'date' },
      { header: 'Updated by', field: 'CCO_UPDATED_BY', hideOnPhone: true },
    ];
  },

  activated() {
    if (!this.contactId && this.deleteClicked) {
      this.toolbarStore.setToolbarTitle('New contact');
      this.toolbarStore.setBackNavigation([{ text: 'contact list', href: `/client/${this.clientId}/contacts` }]);
    } else {
      this.toolbarStore.setToolbarTitle(`${this.contact.FIRST_NAME} ${this.contact.LAST_NAME}`);
      this.toolbarStore.setBackNavigation([
        { text: this.contact.CompanyName, href: `/client/${this.contact.CLIENT_ID}/contacts` },
      ]);
    }

    if (this.contactId && (!this.contact || this.contact.ID !== this.contactId)) {
      this.retrievContact(this.contactId);
    }
  },

  deactivated() {
    this.contact = {};
    this.orgContact = {};
    this.toolbarStore.setToolbarTitle('');
    this.toolbarStore.setBackNavigation([]);
  },

  watch: {
    contactId: {
      handler(id) {
        if (id) {
          this.retrievContact(id);
        } else if (this.clientId) {
          this.contact = { CLIENT_ID: +this.clientId };
          this.orgContact = { CLIENT_ID: +this.clientId };
        }
      },
      immediate: true,
    },
    clientId: {
      handler(id) {
        if (id) {
          if (!this.contactId) {
            this.contact = { CLIENT_ID: this.clientId };
            this.orgContact = { CLIENT_ID: this.clientId };
          }
        }
      },
      immediate: true,
    },
  },

  methods: {
    globalFormatDateTime(date, format) {
      return GlobalHelperFunctions.globalFormatDateTime(date, format);
    },
    lookupCode(code, list, codeField, textField) {
      return GlobalHelperFunctions.lookupCode(code, list, codeField, textField);
    },
    gdprCompactItem(consentItem) {
      return `${this.lookupCode(
        consentItem.CCO_CONSENT_PURPOSE,
        this.purposes,
        'COT_ID',
        'COT_NAME'
      )} - ${this.lookupCode(consentItem.CCO_LEGAL_BASE, this.legalBases, 'COT_ID', 'COT_NAME')}`;
    },
    addConsent() {
      this.currentConsent = {
        CCO_CONTACT_ID: this.contact.ID,
        CCO_UPDATED: new Date(),
        CCO_UPDATED_BY: this.applicationStore.user.userName,
      };
      this.showConsentForm = true;
    },

    canSetInactive() {
      if (this.applicationStore.user.internal) {
        return true;
      }
      // console.log("contatID : "+this.contact.ID);
      // console.log("externalUserContactId : "+this.applicationStore.user.externalUserContactId)
      if (this.applicationStore.user.externalUserContactId == this.contact.ID) {
        return false;
      }
      return this.applicationStore.user.externalUserAdmin;
    },

    consentFormClosed(params) {
      this.showConsentForm = false;
      if (params.btn == 'save') {
        apiPut('/consent', { value: params.value, orgValue: this.currentConsent }).then(() => {
          const idx = this.contact.consents.findIndex((item) => item.CCO_ID == params.value.CCO_ID);
          this.currentConsent = params.value;
          if (idx >= 0) {
            this.contact.consents.splice(idx, 1, params.value);
            this.orgContact.consents = this.contact.consents; // to avoid save btn "changes"
          } else {
            apiGet(`/consent/${this.contact.ID}`).then((result) => {
              this.contact.consents = result;
              this.orgContact.consents = this.contact.consents; // to avoid save btn "changes"
            });
          }
        });
      } else if (params.btn == 'delete') {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiDelete(`/consent/${params.value.CCO_ID}`).then(() => {
          this.isLoading = false;
          const idx = this.contact.consents.findIndex((item) => item.CCO_ID == params.value.CCO_ID);
          this.currentConsent = params.value;
          this.contact.consents.splice(idx, 1);
          this.orgContact.consents = this.contact.consents; // to avoid save btn "changes"
        });
      }
      this.currentConsent = {};
    },

    consentRowClicked(rowData) {
      this.currentConsent = rowData;
      this.showConsentForm = true;
    },

    deleteClicked() {
      this.saving = true;
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/contacts/${this.contact.ID}`).then((result) => {
        this.saving = false;
        this.isLoading = false;
        if (result.resultCategory == 1) {
          const contactPerson = { ...this.contact };
          contactPerson.DELETE = '';
          this.$router.push({
            name: 'clientContacts',
            params: {
              clientId: +this.contact.CLIENT_ID,
              contactPerson,
            },
          });
        }
      });
    },

    retrievContact(id) {
      apiGet(`contacts/${id}`).then((data) => {
        this.contact = data;
        this.orgContact = GatUtils.makeCopyViaJson(this.contact);
        this.toolbarStore.setToolbarTitle(`${this.contact.FIRST_NAME} ${this.contact.LAST_NAME}`);
        this.toolbarStore.setBackNavigation([
          { text: this.contact.CompanyName, href: `/client/${this.contact.CLIENT_ID}/contacts` },
        ]);
      });
    },

    saveClicked() {
      this.$refs.form.validate();
      if (this.formIsValid) {
        this.saving = true;
        if (!this.contact.CLIENT_ID) {
          this.contact.CLIENT_ID = this.clientId;
        }
        if (this.contact.PRIORITY == '') {
          this.contact.PRIORITY = null;
        }

        apiPut('/contacts', {
          value: this.contact,
          orgValue: this.orgContact,
          dynavalues: this.contact.dynaValues,
          orgDynavalues: this.orgContact.dynaValues,
        }).then((result) => {
          this.saving = false;
          const savedObj = { ...this.contact };
          if (result.resultCategory == 1) {
            /* savedObj.ID = result.payload; */
            this.orgContact = GlobalHelperFunctions.duplicateViaJson(this.contact);
            this.$nextTick(() => {
              this.$router.push({
                name: 'clientContacts',
                params: {
                  clientId: +savedObj.CLIENT_ID,
                  contactPerson: savedObj,
                },
              });
            });
          }
        });
      }
    },
  },
});
</script>

<style scoped>
.addConsent {
  margin-left: 0px;
  margin-top: -12px;
}
.consent-grid {
  width: 100%;
}
</style>
