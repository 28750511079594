<template>
  <v-flex>
    <v-card outlined class="ml-5 mr-3">
      <v-layout wrap>
        <gat-select v-model="filter.columnName" size="xs7 sm4" label="Column" :items="getCols" :autofocus="true" />
        <gat-select
          :disabled="!filter.columnName"
          v-model="filter.comparer"
          size="xs5 sm3"
          label="Criteria"
          :items="comparers" />
        <v-flex xs7 sm3>
          <gat-edit
            v-show="useValueToMatch && !(selectedColType == 'string')"
            v-model="filter.matchValue"
            size="xs12"
            label="Value to match"
            :dateEdit="
              (selectedColType == 'date' || selectedColType == 'datetime') &&
              filter.comparer &&
              !filter.comparer.includes('x days')
            "
            :maxChars="-1" />
          <gat-select
            v-show="useValueToMatch && selectedColType == 'string'"
            v-model="filter.matchValue"
            label="Value to match"
            size="xs12"
            :items="getValueItems"
            :combobox="true" />
        </v-flex>
        <v-flex xs5 sm2>
          <v-btn text small left class="mt-5" @click="$emit('removeFilter', filter)"
            ><v-icon>mdi-delete</v-icon>delete</v-btn
          >
        </v-flex>
      </v-layout>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  name: 'GatGridViewFilterItem',
  props: ['columns', 'orgFilter', 'items'],
  components: {},
  data() {
    return {
      filter: null,
    };
  },

  computed: {
    selectedColumn() {
      let result = null;
      if (this.filter.columnName) {
        let column = this.columns.find((item) => item.field == this.filter.columnName);
        if (!column) {
          column = this.columns.find((item) => item.header == this.filter.header);
        }
        if (column) {
          result = column;
        }
      }

      return result;
    },

    selectedColType() {
      let colType = 'string';
      if (this.selectedColumn) {
        if (this.selectedColumn.dataType) {
          colType = this.selectedColumn.dataType;
        }
        if (this.selectedColumn.checkbox) {
          colType = 'boolean';
        }
      }
      return colType;
    },
    comparers() {
      const result = [];

      if (this.selectedColType == 'boolean') {
        result.push({ text: 'Checked', value: 'is true' });
        result.push({ text: 'Unchecked', value: 'is false' });
      } else {
        result.push({ text: 'Less than', value: '<' });
        result.push({ text: 'Greater than', value: '>' });

        result.push({ text: 'Equal to', value: '=' });
        result.push({ text: 'Not equal to', value: '<>' });

        result.push({ text: 'Empty', value: 'is empty' });
        result.push({ text: 'not empty', value: 'not empty' });
      }
      if (this.selectedColType == 'date' || this.selectedColType == 'datetime') {
        result.push({ text: 'is yesterday', value: 'is yesterday' });
        result.push({ text: 'is today', value: 'is today' });
        result.push({ text: 'is tomorrow', value: 'is tomorrow' });
        result.push({ text: 'is last 7 days', value: 'is last 7 days' });
        result.push({ text: 'is last x days', value: 'is last x days' });
        result.push({ text: 'is last month', value: 'is last month' });
        result.push({ text: 'is last year', value: 'is last year' });
        result.push({ text: 'is past', value: 'is past' });
        result.push({ text: 'is this week', value: 'is this week' });
        result.push({ text: 'is this month', value: 'is this month' });
        result.push({ text: 'is next 7 days', value: 'is next 7 days' });
        result.push({ text: 'is next x days', value: 'is next x days' });
        result.push({ text: 'is next month', value: 'is next month' });
        result.push({ text: 'is next year', value: 'is next year' });
        result.push({ text: 'is future', value: 'is future' });
      }
      return result;
    },
    getCols() {
      const result = [];
      // eslint-disable-next-line array-callback-return
      this.columns.map((col) => {
        if (col.field && col.field != 'GRID_LINE_NO') {
          result.push({ text: col.header, value: col.field });
        }
      });
      return result;
    },

    getValueItems() {
      const result = [];
      if (this.selectedColumn) {
        const fieldName = this.selectedColumn.field;
        // eslint-disable-next-line array-callback-return
        this.items.map((item) => {
          const text = item[fieldName];
          if (text && !result.includes(text)) {
            result.push(text);
          }
        });
      }
      return result;
    },

    useValueToMatch() {
      let result = true;
      if (this.selectedColType == 'boolean') {
        return false;
      }
      if (
        !this.filter.comparer ||
        this.filter.comparer == 'is empty' ||
        this.filter.comparer == 'not empty' ||
        this.filter.comparer == 'is true' ||
        this.filter.comparer == 'is false'
      ) {
        result = false;
      }
      if ((this.selectedColType == 'date' || this.selectedColType == 'datetime') && this.filter.comparer) {
        if (
          ['is yesterday', 'is today', 'is tomorrow', '7 days', 'month', 'year', 'past', 'week', 'future'].findIndex(
            (item) => this.filter.comparer.includes(item)
          ) >= 0
        ) {
          result = false;
        }
      }
      return result;
    },
  },

  created() {},

  watch: {
    orgFilter: {
      handler(val) {
        this.filter = val;
      },
      immediate: true,
    },

    selectedColType(val) {
      this.filter.colType = val;
    },

    useValueToMatch(val) {
      if (!val) {
        this.filter.matchValue = undefined;
      }
    },
  },

  methods: {},
};
</script>

<style scoped></style>
