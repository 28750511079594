import { apiGet } from '@/store/api';
import { createReportObject } from '@/modules/dashboard/helper/dashboardHelper';
import { useDashboardStore } from '@/store/dashboardStore';

export async function getDashboardGroups() {
  const data = await apiGet('dashboard/groups');
  if (data.length > 0) {
    // Create the first group which should contain all available reports.
    let dashboardGroups: any[] = [
      {
        GROUP_ID: -1,
        NAME: 'All',
        SORT_ORDER: 10000,
        reports: [] as any[],
      },
    ];

    // allReports stores all the reports (no duplicates).
    const allReports: any[] = [];

    // Add all the other groups from the get-result.
    data.forEach((item: any) => {
      dashboardGroups.push(item);
    });

    // Iterate through the groups to assign new values.
    dashboardGroups = dashboardGroups.map((group: any) => {
      const newGroup = group;
      newGroup.sorting = {
        initial: [],
        user: [],
        default: [],
      };
      const groupReports: any[] = [];
      newGroup.reports.forEach((report: any) => {
        const newReport = createReportObject(report);
        groupReports.push({ ...newReport });
        const reportFoundInAllReports = allReports.find((item) => item.reportName == newReport.reportName);
        if (!reportFoundInAllReports) {
          allReports.push({ ...newReport }); // make copy of report object.
        }
      });
      newGroup.reports = groupReports;
      return newGroup;
    });
    // assign the allReports array to the first dashboard group.
    dashboardGroups[0].reports = allReports;

    // Finally add initial sorting
    dashboardGroups = dashboardGroups.map((group) => {
      const newGroup = group;
      newGroup.reports.forEach((report: any) => {
        const reportSorting = {
          reportName: report.reportName,
          sorting: report.sorting,
        };
        newGroup.sorting.initial.push(reportSorting);
      });
      const filteredResult = newGroup.sorting.initial.filter((item: any) => item.sorting > -10000);
      const finalResult = filteredResult.map((res: any) => res.reportName);
      newGroup.sorting.initial = finalResult;
      return newGroup;
    });
    dashboardGroups.sort((a, b) => a.SORT_ORDER - b.SORT_ORDER);
    // groups with initial sorting has been set in the store
    useDashboardStore().setDashboardGroups(dashboardGroups);
  }
}
