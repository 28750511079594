<template>
  <div>
    <!-- <h3>Active calls</h3> -->
    <v-layout wrap>
      <gat-select v-if="whiteBoards && whiteBoards.length > 1" v-model="whiteboardId" hide-details :items="whiteBoards"
        label="Whiteboard" textFieldName="name" codeFieldName="id" size="xs12 sm8 md6">
        <template v-slot:append>
          <GatReloadBtn @click="loadData" hint="Refresh whiteboard" />
        </template>
      </gat-select>
      <gat-edit v-if="whiteBoards && whiteBoards.length > 1" v-model="maxRows" hide-details label="max rows"
        dataType="number" hide="phone" size="xs6 sm4 md1" />
      <gat-edit v-if="applicationStore.user.internal && applicationStore.userRight('G.ACCESS_ALL_AGENCIES')" checkbox
        :label="isPhone ? 'all agencies' : 'Show port calls from all agencies'" :value="getShowAllAgencies"
        @input="showAllCallsChanged" hide-details size="xs8 sm6 md3" />
      <wm-button-group class="d-flex ml-auto mr-1 align-center" v-if="this.shouldShowNewPortcallBtn && menuItems"
        :buttons="menuItems" @click="buttonGroupClicked" @menuItemClick="buttonGroupClicked"
        @subMenuItemClick="buttonGroupClicked" :showMenu="this.$vuetify.breakpoint.xsOnly" />
    </v-layout>
    <white-board v-if="whiteboard" ref="wb" :whiteBoard="whiteboard" :rowData="WhiteBoardData" :favCalls="favCalls"
      :maxRows="maxRows" :loading="loading" @reload="loadData" />
    <!-- <record-presenter :value="whiteboard" /> -->
  </div>
</template>

<script>
import WhiteBoard from '@/app-components/WhiteBoard.vue';
// eslint-disable-next-line import/no-cycle
import { apiPost, apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { getWhiteBoards } from '@/services/api/api-calls/getWhiteBoards';
import { getWhiteboardData } from '@/services/api/api-calls/getWhiteboardData';

export default {
  name: 'PortCalls',
  props: {
    favoritesOnly: {
      default: false,
      type: Boolean,
    },
  },
  components: { WhiteBoard },
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      whiteboardId: 0,
      maxRows: 100,
      saveLastWhiteboard: true,
      loading: null,
      menuItems: null,
    };
  },
  mounted() {
    this.loadMenuItems();
  },

  watch: {
    whiteboardId(val, oldVal) {
      if (val && val != oldVal) {
        if (this.saveLastWhiteboard) {
          const savedInfo = {
            TypeName: 'web_last_whiteboard',
            StrValue: val,
          };
          apiPost('savedInfo', savedInfo);
        } else {
          this.saveLastWhiteboard = true;
        }
      }
    },

    whiteboard: {
      handler(val, oldVal) {
        if (val && this.whiteboardId == 0) {
          this.saveLastWhiteboard = false;
          this.whiteboardId = val.id; // for å sette select ved default wb
        }
        if (val && val != oldVal) {
          this.loadData();
        }
      },
      immediate: true,
    },

    whiteBoards: {
      handler(val) {
        if (!this.whiteboardId && val?.length === 1) {
          this.whiteboardId = val[0].id
        }
      },
      immediate: true,
    },
  },

  computed: {
    shouldShowNewPortcallBtn() {
      if (!this.applicationStore.user.internal) return false;

      // Button only visible on Whiteboard directly, not components like 'Starred port calls'
      if (this.$route.name != 'PortCalls') return false;

      return true;
    },
    getShowAllAgencies() {
      if (
        this.applicationStore.user &&
        this.applicationStore.user.userSettings &&
        this.applicationStore.user.userSettings.whiteBoards
      ) {
        return this.applicationStore.user.userSettings.whiteBoards.showCallsFromAllAgencies;
      }
      return false;
    },
    isPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    whiteboard() {
      let wb = this.applicationStore.whiteboard.whiteBoards.find((item) => item.id == this.whiteboardId);

      if (!wb && this.whiteboardId && this.applicationStore.whiteboard.whiteBoards.length > 0) {
        // eslint-disable-next-line prefer-destructuring
        wb = this.applicationStore.whiteboard.whiteBoards[0];
      }

      return wb;
    },
    whiteBoards() {
      return this.applicationStore.whiteboard.whiteBoards;
    },
    WhiteBoardData() {
      if (this.favoritesOnly) {
        if (this.applicationStore.whiteboard.whiteBoardData.length > 0 && this.applicationStore.favCalls.length > 0) {
          const result = [];
          this.applicationStore.whiteboard.whiteBoardData.forEach((item) => {
            if (this.applicationStore.favCalls.indexOf(item.ID) >= 0) {
              result.push(item);
            }
          });

          return result;
        }
      } else {
        return this.applicationStore.whiteboard.whiteBoardData;
      }
      return [];
    },
    favCalls() {
      return this.applicationStore.favCalls;
    },
  },

  activated() {
    if (this.applicationStore.whiteboard.isDirty) {
      this.loadData();
    }
  },

  created() {
    apiGet('savedinfo/web_last_whiteboard').then((data) => {
      if (data.length > 0) {
        const wbId = parseInt(data[0].MEMOVALUE1, 10);
        if (wbId && this.whiteboardId != wbId) {
          this.saveLastWhiteboard = false;
          this.whiteboardId = wbId;
        }
      }
    });
    window.messageBus.$on('new-agency-selected', () => {
      this.loading = true;
      getWhiteBoards(this.applicationStore.agencyIdSelected).then(() => {
        this.loadData();
      });
    });
  },

  methods: {
    showDuplicateForm(callId, fromTemplate) {
      this.$refs.wb.showDuplicateForm(callId, fromTemplate);
    },
    addNewportCallClick() {
      this.$router.push({ name: 'NewPortCall' });
    },
    addPortcallFromTemplateClicked(menuItem) {
      if (menuItem?.template?.PTE_PORTCALL_ID) {
        this.showDuplicateForm(menuItem.template.PTE_PORTCALL_ID, true);
      }
    },
    buttonGroupClicked(btn) {
      const event = btn.button.eventName;
      if (event === 'newOrFromTemplateClicked') {
        if (btn.menuItem.eventName === 'addNewportCallClick') {
          this.addNewportCallClick(btn.menuItem);
        } else {
          this.addPortcallFromTemplateClicked(btn.menuItem);
        }
      } else if (event === 'addNewportCallClick') {
        this.addNewportCallClick();
      } else if (event === 'addPortcallFromTemplateClicked') {
        this.addPortcallFromTemplateClicked(btn.menuItem);
      }
    },
    canCreatePortCall() {
      return this.applicationStore.userRight('P.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    buildMenuItem(caption, template, menuItems, eventName, userRight, icon = '', btnType = 'CUSTOM', disabled = false) {
      return { caption, template, menuItems, eventName, userRight, btnType, icon, disabled };
    },
    buildMenuMenuItem(menu) {
      return this.buildMenuItem(menu.MENU_CAPTION, menu, menu.menuItems, '_', 'P.NEW_AND_EDIT', 'mdi-menu');
    },
    buildTemplateMenuItem(template) {
      // return this.buildMenuItem(`${template.PTE_NAME}...`, template, [], '_', 'P.NEW_AND_EDIT', 'mdi-file-powerpoint');
      return this.buildMenuItem(template.PTE_NAME, template, [], '_', 'P.NEW_AND_EDIT', 'mdi-file-powerpoint');
    },
    async loadMenuItems() {
      const allMenuItems = await apiGet('portcall/portcall-template-menu-items');
      const allTemplates = await apiGet('portcall/portcall-templates');
      const toplevelMenuItems = [];
      const toplevelTemplateItems = [];

      // add menuItems key to each menu object
      allMenuItems.forEach((m) => {
        // eslint-disable-next-line no-param-reassign
        m.menuItems = [];
      });

      // build template menu item and set it to the parent menu if it has the parent menu, else save it in the top level template item
      const relevantMenuItems = [];
      allTemplates.forEach((template) => {
        const templateMI = this.buildTemplateMenuItem(template);
        if (template.PTE_MENU_ID) {
          // does this template has a parent menu?
          const menu = allMenuItems.find(
            (menuItem) => template.PTE_MENU_ID && menuItem.MENU_ID === template.PTE_MENU_ID && menuItem.MENU_TYPE === 1
          );
          if (menu) {
            menu.menuItems.push(templateMI);

            // put menu item in a list to loop through later
            if (!relevantMenuItems.find((menuItem) => menuItem.MENU_ID === menu.MENU_ID)) {
              relevantMenuItems.push(menu);
            }
          } else {
            // Menu id reference maybe deleted from the menu table = top level template
            toplevelTemplateItems.push(templateMI);
          }
        } else {
          // Top level template
          toplevelTemplateItems.push(templateMI);
        }
      });

      // recursively build menu structure with submenu items with templates and save it in the top level menu items
      while (relevantMenuItems.length > 0) {
        const menu = relevantMenuItems.pop();
        if (menu.MENU_ID === menu.MENU_PARENT) {
          menu.MENU_PARENT = null;
        }
        const menuMI = this.buildMenuMenuItem(menu);
        const parentMenu = allMenuItems.find((menuItem) => menuItem.MENU_ID === menu.MENU_PARENT);
        if (parentMenu) {
          if (!parentMenu.menuItems.find((menuItem) => menuItem.template?.MENU_ID === menu.MENU_ID)) {
            parentMenu.menuItems.push(menuMI);
          }
          if (!relevantMenuItems.find((menuItem) => menuItem.template?.MENU_ID === parentMenu.MENU_ID)) {
            relevantMenuItems.push(parentMenu);
          }
        } else if (!toplevelMenuItems.find((menuItem) => menuItem.template?.MENU_ID === menu.MENU_ID)) {
          toplevelMenuItems.push(menuMI);
        }
      }

      const isDisabled = () =>
        !(this.applicationStore.userRight('P.NEW_AND_EDIT') || this.applicationStore.userRight('P.NEW_FROM_TEMPLATE'));

      // build duplicate portcall and new port call menu structure based on rights
      const duplicatePortcallMenuItem = this.buildMenuItem(
        'Port call from template',
        {},
        [...toplevelMenuItems, ...toplevelTemplateItems],
        'addPortcallFromTemplateClicked',
        undefined,
        'mdi-file',
        undefined,
        isDisabled()
      );
      const newPCMenuItem = this.buildMenuItem(
        'New port call...',
        {},
        [],
        'addNewportCallClick',
        undefined,
        'mdi-plus',
        undefined,
        isDisabled()
      );

      // Only show "..from template" or "new port call.." if the user has the rights.
      const newAndDuplicateSubMenuItems = () => {
        const result = [];
        if (this.applicationStore.userRight('P.NEW_AND_EDIT')) {
          result.push(newPCMenuItem);
        }
        if (this.applicationStore.userRight('P.NEW_FROM_TEMPLATE')) {
          result.push(duplicatePortcallMenuItem);
        }
        return result;
      };
      const newAndDuplicateMenuItem = this.buildMenuItem(
        'New port call',
        {},
        newAndDuplicateSubMenuItems(),
        'newOrFromTemplateClicked',
        undefined,
        'mdi-plus',
        undefined,
        isDisabled()
      );

      if (allMenuItems.length === 0) {
        this.menuItems = [{ ...newPCMenuItem, mainAction: true }];
      } else {
        this.menuItems = [{ ...newAndDuplicateMenuItem, mainAction: true }];
      }
    },
    loadData() {
      if (this.whiteboard) {
        this.loading = true;
        let includeAllAgencies = false;
        if (
          this.applicationStore.user &&
          this.applicationStore.user.userSettings &&
          this.applicationStore.user.userSettings.whiteBoards
        ) {
          includeAllAgencies = this.applicationStore.user.userSettings.whiteBoards.showCallsFromAllAgencies;
        }
        getWhiteboardData({
          whiteboardId: this.whiteboard.id,
          agencyId: this.applicationStore.agencyIdSelected,
          maxRows: this.maxRows,
          includeAllAgencies,
        }).then(() => {
          this.loading = false;
          this.applicationStore.setWhiteBoardDataIsDirty(false);
        });
      }
    },

    showAllCallsChanged(showAll) {
      const newSettings = { ...this.applicationStore.user.userSettings.whiteBoards };
      newSettings.showCallsFromAllAgencies = showAll;
      this.applicationStore.setWhiteBoardUserSettings(newSettings);
      this.loadData();
      const savedInfo = {
        TypeName: `web_whiteboard_includeAllAgencies`,
        StrValue: JSON.stringify(showAll),
      };
      apiPost('savedInfo', savedInfo);
    },
  },
};
</script>

<style scoped></style>
