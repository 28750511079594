<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    dark
    :color="getBarColor"
    dense
    :extension-height="!hideTab || $slots.default || hasBackNavigation ? '30px' : '5px'">
    <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer" />
    <div v-show="drawerVisible && $vuetify.breakpoint.lgAndUp" class="appTitle">
      {{ this.applicationStore.appTitle }}
    </div>
    <v-toolbar-title :class="{ gsSubTitle: showDrawer }">
      <v-btn
        v-if="!isPhone"
        v-show="toolbarStore.useToolbarNavBtns && toolbarStore.title"
        @click="prevClicked"
        icon
        text
        small
        ><v-icon small>mdi-arrow-up</v-icon></v-btn
      >
      <v-btn
        v-if="!isPhone"
        v-show="toolbarStore.useToolbarNavBtns && toolbarStore.title"
        @click="nextClicked"
        icon
        text
        small
        ><v-icon small>mdi-arrow-down</v-icon></v-btn
      >
      <span v-if="!isPhone && showCounter" class="mr-2 idx-counter">{{ this.indexCounter }}</span>
      <span :style="isPhone ? 'font-size: 16px;' : ''">
        {{ $route.meta.pageTitle }}
        <span v-show="$route.meta.pageTitle && toolbarStore.title">: </span>{{ toolbarStore.title }}
      </span>
    </v-toolbar-title>
    <div class="ml-auto d-flex align-center">
      <agency-selector :agency_id.sync="selectedAgency" :agencies="agencyItems" />
      <!-- <gat-select v-if="!this.$vuetify.breakpoint.xs && applicationStore.user.internal" v-model="selectedAgency" :items="agencyItems" label="Agency" style="max-width: 300px; z-index: 1;" class="mt-4" /> -->
      <user-menu :agencyItems="agencyItems" />
    </div>
    <!-- <div class="d-flex flex-grow-1 mx-n4" style="width: 100%"> -->
    <div v-if="($slots.default || tabs) && !hideTab" slot="extension" :style="getTabExtLeftMargin">
      <v-tabs
        v-show="!hideTab"
        v-model="activeTabIndex"
        :height="30"
        :background-color="getBarColor"
        class="tabs"
        :show-arrows="isTouchDevice"
        style="z-index: inherit">
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab
          v-for="(item, index) in tabs"
          :key="index"
          active-class="activeTab"
          @click="tabClicked(index)"
          :style="$vuetify.breakpoint.xsOnly ? 'min-width: 50px; padding: 0 15px;' : ''">
          <div style="position: relative; width: 100%">
            {{ item.caption }}
            <v-icon
              v-if="item.noTabData && !tabHasData(item.noTabData)"
              :style="activeTabIndex === index ? 'opacity: 1' : 'opacity: 0.75'"
              x-small
              style="position: relative; top: -2.5px; right: 0px"
              >mdi-eye-off-outline</v-icon
            >
          </div>
        </v-tab>
      </v-tabs>

      <slot></slot>
    </div>
    <div
      v-if="$slots.default || hasBackNavigation"
      slot="extension"
      :class="showDrawer ? 'tabSlot' : $vuetify.breakpoint.xsOnly ? '' : 'ml-7'">
      <div slot="extension">
        <back-navigator class="backNavigator" :model-value="toolbarStore.backNavigation" />
      </div>
    </div>
    <div
      v-else-if="$vuetify.breakpoint.lgAndUp && drawerVisible"
      slot="extension"
      :class="showDrawer ? 'tabSlot' : 'ml-7'"></div>
  </v-app-bar>
</template>

<script>
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { defineComponent } from 'vue';
import BackNavigator from './BackNavigator.vue';
import UserMenu from './UserMenu.vue';
import AgencySelector from './AgencySelector.vue';

export default defineComponent({
  name: 'AppBar',
  props: {
    drawerVisible: Boolean,
  },
  components: { BackNavigator, UserMenu, AgencySelector },

  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    return {
      portcallStore,
      toolbarStore,
      applicationStore,
    };
  },
  data() {
    return {
      showDrawer: null,
      hasExtention: false,
      activeTabIndex: 0,
      pagePrams: [],
      hideTab: true,
    };
  },

  watch: {
    // activeTabIndex(newIndex){
    //     let item = this.tabs[newIndex];
    //     if(item.routeName !== this.$route.name){
    //         let params = item.routeParams;
    //         if(!params){
    //             params = this.pagePrams[item.routeName];
    //         }

    //         this.$router.push({name:item.routeName,params:params}).catch((err)=>{
    //             alert(err)
    //         });
    //     }
    // },

    $route(to) {
      // when route changes, set the tab page that corespond to the new route name
      if (this.tabs && this.tabs.length > 0) {
        this.setTabPageFromRoute(to.name);
        // save url params, so when the users switch back to this page the params are the same as last time
        this.pagePrams[to.name] = to.params;
      }
    },

    showDrawer(newValue) {
      this.$emit('showDrawerChanged', newValue);
    },

    tabs: {
      immediate: true,
      handler() {
        this.pagePrams = [];
        this.setTabPageFromRoute(this.$route.name);
      },
    },

    drawerVisible(val) {
      if (this.showDrawer !== val) {
        this.showDrawer = val;
      }
    },
    pageTitle(val) {
      if (val) {
        document.title = val;
      } else if (this.applicationStore) {
        document.title = this.applicationStore.documentTitle.defaultDocumentTitle;
      } else {
        document.title = 'GAT-Ship';
      }
    },
  },

  created() {
    window.messageBus.$on('toolbar-update-TabIndex', () => {
      if (this.tabs && this.tabs.length > 0) {
        this.setTabPageFromRoute(this.$route.name);
      }
    });
  },

  updated() {
    if (this.hasExtention != this.hasTab) {
      this.hasExtention = this.hasTab;
      this.$emit('hasExtention', this.hasExtention);
    }
  },

  computed: {
    pageTitle() {
      const { title } = this.toolbarStore;
      if (typeof title === 'string' && title !== '') {
        return title;
      }
      return this.$route.meta.pageTitle;
    },
    hasBackNavigation() {
      return this.toolbarStore.backNavigation.length > 0;
    },
    isPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    agencyItems() {
      const agency = this.applicationStore.agencySettings;
      const result = agency.map((obj) => {
        const newObj = {};
        if (obj.ID) {
          newObj.text = obj.GROUP_NAME;
          newObj.value = obj.ID;
        }
        return newObj;
      });
      return result;
    },
    indexCounter() {
      const { currentIndex } = this.applicationStore.traversableList;
      const traversedListLenght = this.applicationStore.traversableList.items.length;
      const result = `(${currentIndex}/${traversedListLenght})`;
      return result;
    },
    selectedAgency: {
      get() {
        return this.applicationStore.agencyIdSelected;
      },
      set(newValue) {
        const agencyId = newValue;
        this.applicationStore.setNewAgency(agencyId);
        window.messageBus.$emit('new-agency-selected', agencyId);
      },
    },
    showCounter() {
      return this.applicationStore.traversableList.showCounter;
    },
    getTabExtLeftMargin() {
      let style = {
        width: '100%',
      };
      const marginLeft = '250px';
      if (this.showDrawer && !this.$vuetify.breakpoint.xsOnly) {
        style = {
          width: `calc(${style.width} - ${marginLeft})`,
          minWidth: `calc(${style.width} - ${marginLeft})`,
          marginLeft,
        };
      }

      if (this.$vuetify.breakpoint.xsOnly) {
        delete style.marginLeft;
      }
      return style;
    },

    getGatshipLogo() {
      if (this.$vuetify.theme.dark) {
        return '/img/gatship-logo-dark.png';
      }
      return '/img/gatship-logo-reversed.jpg';
    },
    getBarColor() {
      if (!this.$vuetify.theme.dark) {
        return 'primary';
      }
      return '#EF6C00';
    },
    tabs() {
      return this.toolbarStore.tabs;
    },
    subTitle() {
      return this.toolbarStore.title;
    },
    hasTab() {
      if (this.$slots.default || this.tabs) {
        return true;
      }
      return false;
    },
    tabsHasData() {
      return this.portcallStore.callDetails.tabsHasData;
    },
    isTouchDevice() {
      return matchMedia('(hover: none)').matches;
    },
  },

  methods: {
    tabHasData(tabName) {
      return this.tabsHasData[tabName] > 0;
    },
    prevClicked() {
      window.messageBus.$emit(`toolbar_prev_clicked:${this.toolbarStore.navBtnName}`);
    },
    nextClicked() {
      window.messageBus.$emit(`toolbar_next_clicked:${this.toolbarStore.navBtnName}`);
    },
    setTabPageFromRoute(routeName) {
      let { hideTab } = this;
      if (this.tabs) {
        const index = this.tabs.findIndex((item) => {
          if (item.fullPath) {
            return (
              item.fullPath.trim().replace(' ', '').toLowerCase() ===
              this.$route.fullPath.trim().replace(' ', '').toLowerCase()
            );
          }
          return item.routeName == routeName;
        });
        if (index >= 0) {
          hideTab = false;
          this.activeTabIndex = index;
        } else {
          hideTab = true;
        }
      }
      this.hideTab = hideTab;
    },
    tabClicked(tabIndex) {
      const item = this.tabs[tabIndex];
      /* if(item.routeName !== this.$route.name){ */
      let params = item.routeParams;
      if (!params) {
        params = this.pagePrams[item.routeName];
      }

      this.$router.push({ name: item.routeName, params }).catch((err) => {
        // eslint-disable-next-line no-alert
        alert(err);
      });
      /* } */
    },
  },
});
</script>

<style lang="scss" scoped>
::v-deep.v-app-bar.v-app-bar--fixed {
  padding-top: calc(0 + env(safe-area-inset-top)) !important;
  height: auto !important;
  top: 0px !important;
  width: 100%;
  background: var(--gatPrimaryColor) !important;
}
.idx-counter {
  font-size: 0.75rem;
}

.backNavigator {
  margin-top: -8px;
}

.tabSlot {
  margin-left: 250px !important;
}

.tabSlotWidth {
  width: calc(100% - 250px);
}

.gsSubTitle {
  /* margin-left: 230px!important; */
  position: absolute;
  left: 270px;
}

.appTitle {
  margin-right: -70px;
  width: 195px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.imgRight {
  margin-right: 130px;
}

.gatToolbar {
  background-color: var(--gatPrimaryColor);
}

.gatshiplogo {
  font-size: 18px;
}

::v-deep.v-slide-group__content {
  background: pink !important;
}

.activeTab {
  color: white !important;
}

.v-tabs-bar.theme--dark .v-tab:not(.v-tab--active):not(.v-tab--disabled) {
  opacity: 1;
}
::v-deep
  .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right)
  > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes)
  .v-slide-group__prev {
  display: none;
}
</style>
