<template>
  <gat-flex size="xs12">
    <documents
      :location="location"
      :parentId="parentId"
      embedded="true"
      :compact="compact"
      :disabled="disabled"
      @file-selected="selectedFiles" />
  </gat-flex>
</template>

<script>
import Documents from '@/app-components/Documents.vue';

export default {
  name: 'EmbeddedDocList',
  props: ['location', 'parentId', 'compact', 'disabled'],
  components: { Documents },
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {
    selectedFiles(files) {
      this.$emit('files-selected', files);
    },
  },
};
</script>

<style scoped></style>
