import { apiDelete, apiPost } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export function toggleFavorite(fav: any) {
  const applicationStore = useApplicationStore();
  const userModel = applicationStore.user;
  const idx = userModel.favorites.findIndex((item) => item.FAV_TYPE == fav.FAV_TYPE && item.FAV_KEY == fav.FAV_KEY);
  if (idx >= 0) {
    apiDelete('favorites/', {
      recType: fav.FAV_TYPE,
      keyInt: fav.FAV_KEY,
      keyStr: '',
    });
    userModel.favorites.splice(idx, 1);
  } else {
    apiPost('favorites/', {
      recType: fav.FAV_TYPE,
      keyInt: fav.FAV_KEY,
      keyStr: '',
    });
    userModel.favorites.push({
      FAV_TYPE: fav.FAV_TYPE,
      FAV_KEY: fav.FAV_KEY,
    });
  }
}
