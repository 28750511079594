/* eslint-disable no-console */

import { register } from 'register-service-worker';
import { useApplicationStore } from './store/applicationStore';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: { scope: '/' },
    ready(registration) {
      const applicationStore = useApplicationStore();
      if (applicationStore.debugMode) {
        console.log(
          'App is being served from cache by a service worker.\n For more details, visit https://goo.gl/AFskqB'
        );
      }
      try {
        registration.update();
      } catch (err) {
        console.log(err);
      }
      setInterval(() => {
        try {
          console.log('Checking for update...');
          registration.update();
        } catch (err) {
          console.log(err);
        }
      }, 60000 * 2); // check for updates every 2 minutes.
    },
    registered() {
      const applicationStore = useApplicationStore();
      if (applicationStore.debugMode) {
        console.log('Service worker has been registered.');
      }
    },
    cached(reg) {
      const applicationStore = useApplicationStore();
      if (applicationStore.debugMode) {
        console.log(reg);
        console.log('Content has been cached for offline use.');
      }
    },
    updatefound() {
      const applicationStore = useApplicationStore();
      if (applicationStore.debugMode) {
        console.log('New content is downloading.');
      }
    },
    updated(registration) {
      const applicationStore = useApplicationStore();
      if (applicationStore.debugMode) {
        console.log('New content is available; please refresh.');
      }
      if (!applicationStore.appIsInitialized) {
        // if app init is not finished, update and install the new service worker (will reload the page.)
        document.dispatchEvent(
          new CustomEvent('swUpdated', {
            detail: { registration, hardRefresh: true },
          })
        );
      } else {
        document.dispatchEvent(
          new CustomEvent('swUpdated', {
            detail: { registration, hardRefresh: false },
          })
        );
      }
    },
    offline() {
      const applicationStore = useApplicationStore();
      if (applicationStore.debugMode) {
        console.log('No internet connection found. App is running in offline mode.');
      }
    },
    error(error) {
      const applicationStore = useApplicationStore();
      if (applicationStore.debugMode) {
        console.error('Error during service worker registration:', error);
      }
    },
  });
}
