<template>
  <gat-lookup-edit
    label="Vessel"
    v-model="model"
    :searchresult="items"
    @getItems="getItems($event)"
    textFieldName="NAME"
    codeFieldName="ID"
    @input="updateValue()"
    @itemNotFound="itemNotFound"
    :cashSearches="true"
    :size="size"
    :readonly="readonly"
    :required="required"
    customItem
    customSelection>
    <template v-slot:item="{ data, on, attrs }">
      <v-list-item
        :key="data.ID"
        dense
        v-on="on"
        v-bind="attrs"
        :style="{
          fontSize: '13px',
          fontWeight: fadeEntry() ? 400 : 500,
          opacity: fadeEntry() ? 0.5 : 1,
          marginBottom: '8px',
        }">
        <v-list-item-icon v-if="!isMobile" :style="{ minWidth: '31px', maxWidth: '31px' }">
          <gat-country-flag :value="getVesselFlag(data.FLAG)" />
        </v-list-item-icon>
        <v-list-item-content :style="{ maxWidth: '250px' }">
          <v-list-item-title v-text="data.NAME"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action :style="{ fontSize: '13px' }">
          <v-container :style="{ textAlign: 'center' }">
            <v-row dense class="compactLabel grey--text">
              <v-col :style="{ minWidth: '65px' }">Callsign</v-col>
              <v-col v-if="!isMobile" :style="{ minWidth: '65px' }">IMO</v-col>
              <v-col v-if="!isMobile" :style="{ minWidth: '65px' }">DWT</v-col>
            </v-row>
            <v-row dense>
              <v-col :style="{ minWidth: '65px' }">{{ data.CALLSIGN }}</v-col>
              <v-col v-if="!isMobile" :style="{ minWidth: '65px' }">{{ data.LLOYDSNR }}</v-col>
              <v-col v-if="!isMobile" :style="{ minWidth: '65px' }">{{ formatNumber(data.DWT, '0,0') }}</v-col>
            </v-row>
          </v-container>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:selection="{ data }">
      <gat-country-flag v-if="!isMobile && data.FLAG" :value="getVesselFlag(data.FLAG)" />
      <span :class="isMobile ? '' : 'mt-2 ml-2'"
        >{{ data.NAME }} <span v-if="data.CALLSIGN" class="grey--text">({{ data.CALLSIGN }})</span></span
      >
    </template>
  </gat-lookup-edit>
</template>

<script>
import { apiGet, apiPost } from '@/store/api';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import GatCountryFlag from './GatCountryFlag.vue';

export default {
  name: 'GatVesselSelect',
  props: ['value', 'size', 'readonly', 'required'],
  components: { GatCountryFlag },
  data() {
    return {
      items: [],
      model: null,
    };
  },

  created() {},

  watch: {
    value: {
      handler(val) {
        this.model = val;
      },
      immediate: true,
    },

    items() {},
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },

  methods: {
    formatNumber(value, format) {
      return GlobalHelperFunctions.formatNumber(value, format);
    },
    getVesselFlag(flag) {
      if (flag) {
        return flag;
      }

      return '';
    },

    getItems(searchText) {
      apiPost('helper/search/', { table: 'vessel', text: searchText }).then((data) => {
        this.items = data;
      });
    },

    updateValue() {
      this.$emit('input', this.model);
    },

    itemNotFound(id) {
      apiGet(`helper/vessel/${id}`).then((data) => {
        this.items = data;
      });
    },

    fadeEntry() {
      return false;
    },
  },
};
</script>

<style scoped>
.v-list--dense .v-list-item .v-list-item__icon,
.v-list-item--dense .v-list-item__icon {
  margin-top: 0px !important;
}
th,
td {
  padding: 0 5px 0 5px;
}
.v-list-item__avatar,
.v-list-item__avatar.v-list-item__avatar--horizontal {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}
.v-list-item__action {
  margin: 0px 0px 0px 0px !important;
}
.v-list--dense .v-list-item .v-list-item__content,
.v-list-item--dense .v-list-item__content {
  padding: 0px 0 !important;
}
.v-list-item {
  padding: 0 8px;
}
.compactLabel {
  font-size: 75%;
}

.container {
  padding: 0px !important;
}
</style>
