<template>
  <div style="font-size: 0.875rem">
    <!-- :class="colClass(col, props.item, colsClass, index, stickyFirstColumn, noPadding)" -->
    {{ footerValue }}
  </div>
  <!--   <fragment v-if="isOpen">
      <td v-if="selectable" class="groupSummaryBackground"></td>
      <td v-if="favorites" class="groupSummaryBackground"></td>
      <td :colspan="firstFooterColumnIndex" class="groupSummaryBackground"></td>
      <template>
        <td
          v-for="(col, index) in columnsFromFirstFooter"
          :key="index"
          :style="colStyle(col, tight)"
          class="groupSummaryBackground">
          <b v-if="col && col.footerFunction" :class="noPadding ? '' : 'px-4'">{{ getFooterValue(col) }}</b>
        </td>
      </template>
    </fragment> -->
</template>

<script>
import { GatGridMixin } from './GatGridMixin';
import { GatGridFooterMixin } from './GatGridFooterMixin';

export default {
  name: 'GatShipGroopFooter',
  props: ['group', 'groupedBy', 'col', 'groupStatus', 'tight', 'noPadding', 'stickyFirstColumn'],
  components: {},
  mixins: [GatGridMixin, GatGridFooterMixin],
  data() {
    return {};
  },

  computed: {
    footerValue() {
      if (this.col && this.col.footerFunction) {
        return this.getFooterValue(this.col);
      }
      return undefined;
    },
    isOpen() {
      const idx = this.groupStatus.findIndex((item) => item.group == this.group);
      if (idx >= 0) {
        return this.groupStatus[idx].isOpen;
      }
      return false;
    },
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped>
.groupSummaryBackground {
  background: white !important;
}
</style>
