<template>
  <div :class="margins" class="fieldSpacer">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GatFieldSpacer',
  props: { fieldType: String },
  computed: {
    margins() {
      let result = 'mr-3 ml-3';
      // if phone
      if (this.$vuetify.breakpoint.xsOnly) {
        result = 'mr-2 ml-2';
      }
      if (this.fieldType == 'GatEdit-checkbox') {
        result += ' pt-0 ';
      } else {
        result += ' pt-2 ';
      }
      return result;
    },
  },
};
</script>

<style scoped>
.fieldSpacer {
  border: 0px;
  border-color: lightblue;
  border-style: solid;
}
</style>
