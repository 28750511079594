<template>
  <div>
    <wm-button-group :buttons="buttons" @click="menuItemClicked" />
  </div>
</template>

<script>
export default {
  name: 'TestPage',
  props: [],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {
    menuItemClicked(payload) {
      const { caption, menuItem, button } = payload;
      console.log('Payload:', payload);
      console.log(caption, menuItem, button);
    },
  },
  computed: {
    buttons() {
      const result = [];
      result.push({
        caption: 'Main button',
        menuItems: [
          {
            caption: 'menu-item',
            menuItems: [
              {
                caption: 'child of menu-item',
              },
            ],
          },
          {
            caption: 'menu-item 2',
            menuItems: [
              {
                caption: 'child of menu-item 2',
              },
              {
                caption: 'child of menu-item 2',
                menuItems: [
                  {
                    caption: 'child of child?',
                  },
                ],
              },
            ],
          },
        ],
      });
      return result;
    },
  },
};
</script>

<style scoped></style>
