<template>
  <gat-select
    v-if="showAll"
    :label="labelComputed"
    v-model="model"
    :items="items"
    textFieldName="ClientName"
    codeFieldName="ClientID"
    :autofocus="autofocus"
    :readonly="readonly"
    :required="required"
    :clearable="clearable"
    @input="updateValue()"
    :disabled="disabled">
  </gat-select>
  <gat-lookup-edit
    v-else
    :label="labelComputed"
    v-model="model"
    :searchresult="items"
    @getItems="getItems($event)"
    textFieldName="ClientName"
    codeFieldName="ClientID"
    :cashSearches="true"
    :size="size"
    :autofocus="autofocus"
    :readonly="readonly"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    @input="updateValue()"
    @itemNotFound="itemNotFound"
    customItem>
    <template v-slot:item="{ data, on, attrs }">
      <v-list-item
        :key="data.ClientID"
        dense
        v-on="on"
        v-bind="attrs"
        :style="{
          fontSize: '13px',
          fontWeight: fadeEntry() ? 400 : 500,
          opacity: fadeEntry() ? 0.5 : 1,
          marginBottom: '8px',
        }">
        <v-list-item-content :style="{ maxWidth: '200px', minWidth: '200px' }">
          <v-list-item-title v-text="data.ClientName"></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action :style="{ fontSize: '13px' }" class="d-flex flex-grow-1">
          <v-container :style="{ textAlign: 'center' }" class="d-flex flex-column">
            <v-row dense>
              <v-col :style="{ minWidth: '60px', maxWidth: '60px' }">
                <div class="compactLabel grey--text">Client No</div>
                <div>{{ data.ClientNumber }}</div>
              </v-col>
              <v-col v-if="!isMobile" :style="{ minWidth: '140px', maxWidth: '140px' }">
                <div class="compactLabel grey--text">City</div>
                <div>{{ data.City }}</div>
              </v-col>
              <v-col v-if="!isMobile" :style="{ minWidth: '65px', maxWidth: '60px' }">
                <div class="compactLabel grey--text">Country</div>
                <div>
                  {{ data.CountryCode }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-slot:selection="{ data }">
      <span :class="isMobile ? '' : 'mt-2 ml-2'"
        >{{ data.ClientName }} <span v-if="data.ClientNumber" class="grey--text">({{ data.ClientNumber }})</span></span
      >
    </template>
  </gat-lookup-edit>
</template>

<script>
import { apiPost } from '@/store/api';

export default {
  name: 'GatHeadOfficeSelect',
  props: {
    value: Number,
    category: Number,
    agencyId: Number,
    clientId: Number,
    label: String,
    size: String,
    showAll: Boolean,
    autofocus: Boolean,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      items: [],
      model: null,
      item: {},
    };
  },

  computed: {
    labelComputed() {
      let result = this.label;
      if (!result) {
        result = 'Head office';
      }
      return result;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },

  created() {},

  watch: {
    value: {
      handler(val) {
        this.model = val;
        this.setItem(val, this.items);
      },
      immediate: true,
    },

    items(val) {
      this.setItem(this.model, val);
    },

    showAll(all) {
      if (all) {
        this.getItems('');
      }
    },
  },

  methods: {
    getItems(searchText) {
      if (this.clientId && (this.agencyId || this.agencyId === 0)) {
        apiPost('clients/headoffices', {
          clientId: this.clientId,
          search: searchText,
          agencyId: this.agencyId,
          category: this.category,
        }).then((data) => {
          this.items = data;
        });
      }
    },

    setItem(id, items) {
      if (id && items && items.length > 0) {
        const item = items.find((x) => x.ID == id);
        if (item && item.ID != this.item.ID) {
          this.item = item;
          this.$emit('item-selected', item);
        }
      }
    },

    updateValue() {
      this.$emit('input', this.model);
      this.setItem(this.model, this.items);
    },

    itemNotFound(id) {
      apiPost('clients/headoffices', {
        clientId: this.clientId,
        headOfficeId: id,
        agencyId: this.agencyId,
        category: this.category,
      }).then((data) => {
        this.items = data;
      });
    },

    fadeEntry() {
      return false;
    },
  },
};
</script>

<style scoped>
.v-list--dense .v-list-item .v-list-item__icon,
.v-list-item--dense .v-list-item__icon {
  margin-top: 0px !important;
}

th,
td {
  padding: 0 5px 0 5px;
}

.v-list-item__avatar,
.v-list-item__avatar.v-list-item__avatar--horizontal {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

.v-list-item__action {
  margin: 0px 0px 0px 0px !important;
}

.v-list--dense .v-list-item .v-list-item__content,
.v-list-item--dense .v-list-item__content {
  padding: 0px 0 !important;
}

.v-list-item {
  padding: 0 8px;
}

.compactLabel {
  font-size: 75%;
}

.container {
  padding: 0px !important;
}
</style>
