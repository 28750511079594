<template>
  <tr if="footerCount" class="font-weight-bold">
    <td v-if="selectable"></td>
    <td v-if="favorites"></td>
    <td v-for="(col, index) in visibleColumns" :key="index" :style="colStyle(col, tight)">
      <span v-if="col && col.footerFunction" :class="noPadding ? '' : 'px-4'">{{ getFooterValue(col) }}</span>
    </td>
  </tr>
</template>

<script>
import { GatGridMixin } from './GatGridMixin';
import { GatGridFooterMixin } from './GatGridFooterMixin';

export default {
  name: 'GatGridFooter',
  props: ['tight', 'noPadding'],
  mixins: [GatGridMixin, GatGridFooterMixin],
  components: {},
  data() {
    return {};
  },

  computed: {},

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped></style>
