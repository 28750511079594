import { apiDelete } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function deleteServiceAndExpense({
  serviceId,
  expenseId,
}: {
  serviceId: string | number;
  expenseId: string | number;
}) {
  if (serviceId) {
    const portcallStore = usePortcallStore();
    const result = await apiDelete(`service/${serviceId}`);
    if (result.resultCategory == 1) {
      portcallStore.removeServiceItem(serviceId);
      if (expenseId) {
        const expenseResult = await apiDelete(`expense/${expenseId}`);
        if (expenseResult.resultCategory == 1) {
          portcallStore.removeExpenseItem(expenseId);
        }
      }
    }
  }
}
