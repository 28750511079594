import { render, staticRenderFns } from "./HotlistTempForm.vue?vue&type=template&id=2f4a132f&scoped=true&"
import script from "./HotlistTempForm.vue?vue&type=script&lang=js&"
export * from "./HotlistTempForm.vue?vue&type=script&lang=js&"
import style0 from "./HotlistTempForm.vue?vue&type=style&index=0&id=2f4a132f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4a132f",
  null
  
)

export default component.exports