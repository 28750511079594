<template>
  <div>
    <gat-grid
      dense
      :compact="true"
      title="Checklist"
      class="elevation-0"
      :items="items"
      :columns="columns"
      :fixedheader="true"
      height="calc(100vh - 135px)"
      :rowclass="this.getRowClass"
      :loading="isLoading"
      reloadBtn
      @reload-clicked="loadChecklist(callId)"
      @row-clicked="gotoChecklistItem"
      :selectable="true"
      keyName="ID"
      @selectionChanged="setselectedItemKeys"
      :selectedKeys="selectedItemKeys"
      :rowMenu="menuItems"
      @rowMenuClicked="rowMenuClicked"
      :orgSortBy="[{ field: 'SORT_ORDER' }]"
      viewGroupName="checklistView">
      <template v-if="props.column.field == `CHECKED`" slot="cell" slot-scope="props">
        <div v-if="props.value == `1`">
          <v-icon color="green">mdi-check-bold</v-icon>
        </div>
        <v-btn
          v-else-if="props.row.IGNORE_ITEM != 1"
          @click.stop="checkItem(props.row)"
          text
          color="blue"
          :disabled="!applicationStore.userRight('CH.NEW_AND_EDIT')">
          Check
        </v-btn>
        <span v-else />
      </template>
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="gridButtons"
          @addClick="$router.push('new-checklist-item')"
          @addFromTemplateClick="showDialog = true"
          @deleteClick="showDeleteDialog = true"
          @ignoreClick="ignoreItems" />
      </template>
    </gat-grid>
    <CheckListTemplateDialog
      :agencyId="portcallStore.callDetails.SETUP_ID"
      :showDialog="showDialog"
      :callId="callId"
      @close="showDialog = false" />
    <v-dialog v-model="showDeleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="title">Delete</v-card-title>
        <v-card-text class="text-center"
          >Are you sure you want to delete <span v-if="this.selectedItemKeys.length == 1">this</span
          >{{ setPluralItemText }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteItems(selectedItemKeys)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { apiPut, apiDelete } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';
import { getPendingActivities } from '@/services/api/api-calls/getPendingActivities';
import { getChecklist } from '@/services/api/api-calls/getChecklist';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import CheckListTemplateDialog from './CheckListTemplateDialog.vue';

export default {
  name: 'CheckList',
  props: {
    callId: {
      type: Number,
      required: true,
    },
  },
  components: { CheckListTemplateDialog },
  setup() {
    const portcallStore = usePortcallStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      portcallStore,
    };
  },
  data() {
    return {
      selectedItemKeys: [],
      showDialog: false,
      showIgnoredItems: true,
      isIgnoreTopBtnEnabled: false,
      showDeleteDialog: false,
      isLoading: false, // Loading animation for the gat-grid
    };
  },

  computed: {
    columns() {
      const result = [];
      result.push({ field: 'CHECKED' });
      result.push({ header: 'Status', calculated: this.showStatusIfChecked });
      result.push({ header: 'Name', field: 'ITEM' });
      result.push({ header: 'Grouping', field: 'GROUPING' });
      result.push({ header: 'Checked date', field: 'CHECKED_DATE', dataType: 'datetime' });
      result.push({ header: 'Checked by', field: 'CHECKED_BY' });
      result.push({ header: 'Sort order', field: 'SORT_ORDER' });
      result.push({ header: 'Reminder', calculated: this.reminderDescription });
      result.push({ header: 'Comments', field: 'COMMENTS' });

      return result;
    },

    gridButtons() {
      return [
        {
          btnType: 'ADD',
          openMenuOnHover: true,
          embeddMenuItems: true,
          mainAction: true,
          userRight: 'CH.NEW_AND_EDIT',
          menuItems: [
            { caption: 'Add checklist item', eventName: 'addClick', icon: 'mdi-plus' },
            { caption: 'Add items from template', eventName: 'addFromTemplateClick', icon: 'mdi-content-duplicate' },
          ],
        },
        { btnType: 'DELETE', disabled: this.selectedItemKeys.length == 0, userRight: 'CH.DELETE' },
        {
          btnType: 'CUSTOM',
          caption: 'ignore',
          icon: 'mdi-eye-off',
          eventName: 'ignoreClick',
          disabled: this.isIgnoreTopBtnEnabled || this.selectedItemKeys.length == 0,
          userRight: 'CH.NEW_AND_EDIT',
        },
        // {btnType:'CREATE_DOC',docType:18,applicationStatus:this.applicationStatus, disabled:!this.applicationStore.user.internal, userRight:'SER.GENERATE_DOCUMENT'},
      ];
    },

    items() {
      const result = [];
      const items = this.portcallStore.checklist;
      // eslint-disable-next-line array-callback-return
      items.map((item) => {
        if (this.showIgnoredItems || item.IGNORE_ITEM != 1) {
          result.push(item);
        }
      });
      return result;
    },

    menuItems() {
      const result = [
        {
          name: 'checkItem',
          caption: 'Check item',
          icon: 'mdi-check-bold',
          visible: (rowItem) => {
            if (rowItem.IGNORE_ITEM == 1 || rowItem.CHECKED == 1) {
              return false;
            }
            return true;
          },
        },
        {
          name: 'unCheckItem',
          caption: 'Uncheck item',
          icon: 'mdi-check-outline',
          visible: (rowItem) => rowItem.CHECKED == 1,
        },
        {
          name: 'ignoreItem',
          caption: 'Ignore item',
          icon: 'mdi-cancel',
          visible: (rowItem) => {
            if (rowItem.CHECKED == 1 || rowItem.IGNORE_ITEM == 1) {
              return false;
            }
            return true;
          },
        },
        {
          name: 'unIgnoreItem',
          caption: 'Unignore item',
          icon: 'mdi-cancel',
          visible: (rowItem) => rowItem.IGNORE_ITEM == 1,
        },
        {
          name: 'deleteItem',
          caption: 'Delete item',
          icon: 'mdi-delete',
          enabled: true,
        },
      ];
      return result;
    },

    setPluralItemText() {
      const { length } = this.selectedItemKeys;
      let text = ' item';
      if (length > 1) {
        text = ` ${length} items`;
      }
      return text;
    },
  },

  watch: {
    callId: {
      handler(val) {
        this.loadChecklist(val);
      },
      immediate: true,
    },
  },
  methods: {
    refreshPendingActivities() {
      getPendingActivities();
    },

    checkItem(rowItem) {
      // set the date variable to local datetime and formats it before its inserted to DB
      const date = GlobalHelperFunctions.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss');
      const username = this.applicationStore.user.fullName;
      const idx = this.portcallStore.checklist.findIndex((item) => item.ID == rowItem.ID);
      const status = this.portcallStore.checklist[idx].STATUS;
      // add/replace values for newItem to be set in store & DB.
      const newItem = GlobalHelperFunctions.duplicateViaJson(rowItem);
      newItem.CHECKED = 1;
      newItem.CHECKED_DATE = date;
      newItem.CHECKED_BY = username;
      if (!status) {
        newItem.STATUS = 'Done';
      }
      apiPut(`checklist/${rowItem.ID}`, { orgValue: rowItem, value: newItem })
        .then((result) => {
          if (result.resultCategory == 1) {
            this.portcallStore.setChecklistItemChecked(newItem);
          }
        })
        .finally(() => {
          this.refreshPendingActivities();
        });
    },

    deleteItem(id) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/checklist/${id}`)
        .then((result) => {
          // remove progressbar
          this.isLoading = false;
          if (result.resultCategory == 1) {
            this.portcallStore.removeChecklistItem(id);
          }
        })
        .finally(() => {
          this.refreshPendingActivities();
        });
    },
    deleteItems(items) {
      if (items) {
        for (let i = 0; i < items.length; i++) {
          this.deleteItem(items[i]);
        }
        this.selectedItemKeys = [];
        this.showDeleteDialog = false;
      }
    },

    getRowClass(rowItem) {
      let result = '';
      if (rowItem.IGNORE_ITEM) {
        result += 'ignored';
      }
      return result;
    },

    gotoChecklistItem(rowItem) {
      this.$router.push({ name: 'checkListItem', params: { checklistId: rowItem.ID } });
    },

    ignoreItem(orgItem, newItem) {
      apiPut(`checklist/${orgItem.ID}`, { orgValue: orgItem, value: newItem }).then((result) => {
        if (result.resultCategory == 1) {
          this.portcallStore.setChecklistItemIgnored(newItem);
        }
      });
    },

    ignoreItems() {
      if (this.selectedItemKeys) {
        let newItem = [];

        for (let i = 0; i < this.selectedItemKeys.length; i++) {
          const itemsId = this.selectedItemKeys[i];
          const orgItem = this.items.find((item) => item.ID == itemsId);
          newItem = GlobalHelperFunctions.duplicateViaJson(orgItem);
          newItem.IGNORE_ITEM = 1;
          this.ignoreItem(orgItem, newItem);
        }
      }
    },

    isIgnoreAllowed(rowItem) {
      for (let i = 0; i < this.selectedItemKeys.length; i++) {
        const idx = this.portcallStore.checklist.findIndex((item) => item.ID == rowItem[i]);
        const isIgnored = this.portcallStore.checklist[idx].IGNORE_ITEM;
        if (isIgnored) {
          this.isIgnoreTopBtnEnabled = true;
          break;
        }
        this.isIgnoreTopBtnEnabled = false;
      }
    },

    loadChecklist(portcall_id) {
      getChecklist(portcall_id);
    },

    reminderDescription(item) {
      let result = '';
      let reminderRelativeType = '';
      switch (item.REM_REL_TYPE) {
        case 1:
          reminderRelativeType = 'minutes';
          break;
        case 60:
          reminderRelativeType = 'hours';
          break;
        case 1440:
          reminderRelativeType = 'days';
          break;
        default:
          reminderRelativeType = '';
      }

      let reminderBeforeAfter = '';
      if (item.REM_RELATIVE < 0) {
        reminderBeforeAfter = 'before';
      } else {
        reminderBeforeAfter = 'after';
      }
      // sets the value to an absolute number (value: -12 = result: 12)
      result = `${Math.abs(item.REM_RELATIVE)} ${reminderRelativeType} ${reminderBeforeAfter}`;
      switch (item.REM_TYPE) {
        case null:
          return '';
        case -1:
          return '';
        case 10: // Absolute time
          return `at ${GlobalHelperFunctions.globalFormatDateTime(item.REM_DATETIME)}`;
        case 20: // Relative ETA
          return `${result} ETA`;
        case 21: // Relative ATA
          return `${result} ATA`;
        case 22: // Relative ETD
          return `${result} ETD`;
        case 23: // Relative ATD
          return `${result} ATD`;
        case 24: // Relative ETB
          return `${result} ETB`;
        default:
          return `To be implemented (${item.REM_TYPE})`;
      }
    },

    rowMenuClicked(obj) {
      const { rowItem } = obj;
      const newItem = { ...rowItem }; // copy rowItem to newItem (soft-copy shorthand "{...object}")
      // eslint-disable-next-line default-case
      switch (obj.menuItem.name) {
        case 'checkItem': {
          this.checkItem(rowItem);
          break;
        }
        case 'ignoreItem': {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          rowItem.IGNORE_ITEM != 1 ? (newItem.IGNORE_ITEM = 1) : '';
          this.ignoreItem(rowItem, newItem);
          break;
        }
        case 'unIgnoreItem': {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          rowItem.IGNORE_ITEM == 1 ? (newItem.IGNORE_ITEM = 0) : '';
          this.ignoreItem(rowItem, newItem);
          break;
        }
        case 'deleteItem': {
          if (this.selectedItemKeys.length > 1) {
            this.selectedItemKeys = [];
            this.selectedItemKeys.push(rowItem.ID);
          } else {
            this.selectedItemKeys.push(rowItem.ID);
          }
          this.showDeleteDialog = true;
          break;
        }
        case 'unCheckItem': {
          if (rowItem.CHECKED == 1) {
            newItem.CHECKED = 0;
            newItem.CHECKED_BY = '';
            newItem.CHECKED_DATE = null;
          }
          this.uncheckItem(rowItem, newItem);
          break;
        }
      }
    },

    setselectedItemKeys(rowItemId) {
      this.selectedItemKeys = rowItemId;
      if (this.selectedItemKeys.length > 0) {
        this.isIgnoreAllowed(rowItemId);
      } else {
        this.isIgnoreTopBtnEnabled = true;
      }
    },

    showStatusIfChecked(item) {
      if (item.CHECKED) {
        return item.STATUS;
      }
      return '';
    },

    uncheckItem(orgItem, newItem) {
      apiPut(`checklist/${orgItem.ID}`, { orgValue: orgItem, value: newItem })
        .then((result) => {
          if (result.resultCategory == 1) {
            this.portcallStore.setChecklistItemChecked(newItem);
          }
        })
        .finally(() => {
          this.refreshPendingActivities();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ignored {
  td:nth-of-type(1) {
    border-left: 5px solid rgba(0, 0, 0, 0.12);
  }
}

// Apply styles to the 2nd child of the tr-element (td#2).
::v-deep td:nth-of-type(2) {
  //max-width: 100px;
  text-decoration: none;
  button,
  div {
    width: 25px;
    display: block;
    margin: 0 auto;
  }
}

::v-deep td:nth-of-type(1) {
  border-left: 5px solid transparent;
}
::v-deep th:nth-of-type(1) {
  border-left: 3.25px solid transparent;
}
</style>
