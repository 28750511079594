<template>
  <v-dialog v-model="dialogVisible" persistent max-width="600">
    <v-card class="pa-3">
      <v-layout wrap> </v-layout>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="close('cancel')">Cancel</v-btn>
        <v-btn color="success" class="right" @click="close('save')">Save</v-btn>
      </v-card-actions>
      doc:{{ document }}
    </v-card>
  </v-dialog>
</template>

<script>
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'NewDocument',
  props: ['value', 'showForm'],
  components: {},
  data() {
    return {
      dialogVisible: false,
      document: {},
    };
  },

  mounted() {
    this.document = GlobalHelperFunctions.duplicateViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value(newValue) {
      this.document = GlobalHelperFunctions.duplicateViaJson(newValue);
    },

    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {},

  methods: {
    close(btn) {
      if (btn == 'cancel' || this.formIsValid()) {
        this.$emit('close', { btn, value: this.document });
      }
    },

    formIsValid() {
      const result = true;
      return result;
    },
  },
};
</script>

<style scoped></style>
