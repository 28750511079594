<template>
  <div>
    <gat-tab :items="tabs" :show-in-toolbar="true" />
    <keep-alive>
      <router-view :value="vessel" location="vessel" :parentId="vesselId"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
// eslint-disable-next-line import/no-cycle
import { apiGet } from '@/store/api';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';

export default {
  name: 'vessel',
  props: ['vesselId'],
  components: {},
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      toolbarStore,
    };
  },
  data() {
    return {
      vessel: null,
      activeWindowIndex: 0,
      tabItems: [{ caption: 'Details' }, { caption: 'Documents' }, { caption: 'History' }],
    };
  },

  computed: {
    tabHasSqlScripts() {
      return this.helperStore.tabHasSqlScripts;
    },
    tabs() {
      const result = [];
      result.push({ caption: 'Details', routeName: 'vesselDetails' });
      result.push({ caption: 'History', routeName: 'vesselHistory', routeParams: {} });
      result.push({ caption: 'Documents', routeName: 'vesselDocs', routeParams: {} });
      if (this.applicationStore.user.internal && this.tabHasSqlScripts.VESSEL > 0) {
        result.push({ caption: 'Dashboard', routeName: 'vesselDashboard', routeParams: {} });
      }
      if (this.applicationStore.user.internal || this.applicationStore.userRight('EM.ACCESS')) {
        result.push({ caption: 'E-Mail', routeName: 'vesselEmailList' });
      }
      return result;
    },
  },

  watch: {
    vesselId(id) {
      if (id && id !== this.vessel.id) {
        this.loadVessel(id);
      }
    },
  },

  activated() {
    if (this.vessel) {
      this.toolbarStore.setToolbarTitle(`${this.vessel.VESSEL_PREFIX} ${this.vessel.NAME}`);
    }
  },

  deactivated() {
    this.toolbarStore.setToolbarTitle('');
  },

  created() {
    window.messageBus.$on('vesselUpdate', (param) => {
      this.vessel = GatUtils.makeCopyViaJson(param);
    });

    if (this.vesselId) {
      this.loadVessel(this.vesselId);
    }
  },

  methods: {
    loadVessel() {
      apiGet(`/vessel/${this.vesselId}`).then((data) => {
        const newData = data;
        if (!newData.VESSEL_PREFIX) {
          newData.VESSEL_PREFIX = '';
        }
        this.vessel = newData;
        this.toolbarStore.setToolbarTitle(`${this.vessel.VESSEL_PREFIX} ${this.vessel.NAME}`);
      });
    },
  },
};
</script>

<style scoped></style>
