<template>
  <gat-dialog
    v-model="dialogVisible"
    :title="!value ? 'New anchorage' : 'Anchorage'"
    :okButtonCaption="!value ? 'Add' : 'Save'"
    :okButtonIsProminent="true"
    width="600px"
    :deleteButton="!value ? false : applicationStore.userRight('P.EDIT')"
    :itemDescription="'anchorage'"
    :okButtonDisabled="!applicationStore.userRight('P.EDIT')"
    @cancelClick="close('cancel')"
    @deleteClick="close('delete')"
    @okClick="close('save')">
    <gat-form v-model="isValid" ref="form" :readonly="!applicationStore.userRight('P.EDIT')">
      <GatSubGroup title="Anchorage" :compact="true" :elevation="0" size="xxl">
        <v-layout wrap>
          <gat-select
            v-model="anchorage.ANC_PLACE"
            :items="anchoragePlaces"
            label="Anchorage place"
            textFieldName="APL_NAME"
            codeFieldName="APL_NAME"
            size="xs12 sm7">
          </gat-select>
          <gat-edit label="Current" v-model="anchorage.ANC_ACTIVE" checkbox size="xs12 sm5" />
          <gat-edit
            label="Action / description"
            v-model="anchorage.ANC_ACTION"
            size="xs12 sm7"
            :maxChars="applicationStore.getColumnLength('anchorage.ANC_ACTION')" />
          <gat-edit
            label="Sort order"
            v-model="anchorage.ANC_SORT_ORDER"
            dataType="number"
            :decimals="0"
            size="xs6 sm5" />
        </v-layout>
      </GatSubGroup>
      <GatSubGroup title="Arrival / departure" :compact="true" :elevation="0" size="xxl">
        <v-layout wrap>
          <gat-edit
            v-show="anchorage.ANC_CALCULATE == 1"
            label="Shifting time"
            v-model="anchorage.ANC_SHIFTING_TIME"
            :timeEdit="true"
            size="xs12 sm6"
            hint="Estimated arrival" />
          <gat-edit
            v-show="anchorage.ANC_CALCULATE != 1"
            label="ETA"
            v-model="anchorage.ANC_ETA"
            dateTimeEdit
            futureDate
            size="xs12 sm6"
            hint="Estimated time of arrival" />
          <gat-edit
            label="ETD"
            v-model="anchorage.ANC_ETD"
            dateTimeEdit
            futureDate
            size="xs12 sm6"
            hint="Estimated time of departure" />
        </v-layout>
        <v-layout wrap>
          <gat-edit
            label="ATA"
            v-model="anchorage.ANC_ATA"
            dateTimeEdit
            size="xs12 sm6"
            hint="Actual time of departure" />
          <gat-edit
            label="ATD"
            v-model="anchorage.ANC_ATD"
            dateTimeEdit
            size="xs12 sm6"
            hint="Actual time of departure" />
        </v-layout>
      </GatSubGroup>
      <GatSubGroup title="Draft" :compact="true" size="xxl">
        <v-layout wrap>
          <gat-edit
            label="Arrival draft"
            v-model="anchorage.ANC_ARR_DRAFT"
            dataType="number"
            :decimals="2"
            size="xs6 sm4" />
          <gat-edit
            label="Departure draft"
            v-model="anchorage.ANC_DEP_DRAFT"
            dataType="number"
            :decimals="2"
            size="xs6 sm4" />
        </v-layout>
      </GatSubGroup>
    </gat-form>
  </gat-dialog>
</template>

<script>
import { apiGet, apiCashedGet } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'AnchorageDockEdit',
  props: ['value', 'showForm', 'readonly', 'isCalculated', 'agency_id', 'newItem'],
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      dialogVisible: false,
      anchorage: {},
      anchoragePlacesCashed: null,
      orgValue: {},
      isValid: false,
    };
  },

  mounted() {
    this.dialogVisible = this.showForm;
  },

  computed: {
    /* eslint-disable */
    anchoragePlaces() {
      if (this.anchoragePlacesCashed) {
        return this.anchoragePlacesCashed;
      } else {
        if (this.agency_id) {
          apiCashedGet('helper/anchoragePlaces/agencyId/' + this.agency_id).then((data) => {
            if (data && data.length > 0) {
              this.anchoragePlacesCashed = data;
            }
          });
        }
      }
    },
    /* eslint-anable */
  },

  created() {},

  watch: {
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
    newItem(item) {
      if (!this.value) {
        this.anchorage = item;
      }
    },

    value: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          apiGet('anchorage/' + newValue).then((data) => {
            this.anchorage = data[0];
            this.orgValue = GatUtils.makeCopyViaJson(this.anchorage);
          });
        } else {
          this.anchorage = this.newItem;
          this.orgValue = {};
        }
      },
    },
  },

  methods: {
    close(btn) {
      if (btn == 'cancel' || this.formIsValid()) {
        this.dialogVisible = false;
        // this.roleSelectErrors = [];
        this.$emit('close', { btn: btn, value: this.anchorage, orgValue: this.orgValue });
      }
    },

    formIsValid() {
      this.$refs.form.validate();
      return this.isValid;
    },
  },
};
</script>

<style scoped></style>
