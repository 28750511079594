<template>
  <div>
    <v-text-field
      ref="field"
      :autocomplete="autocomplete"
      :data-lpignore="dataLpignore"
      :data-form-type="dataFormType"
      v-model="textValue"
      :disabled="disabled"
      :label="getLabel"
      :messages="getMessages"
      @blur="onBlur"
      @focus="onFocus($event)"
      @keyup="onKeyUp($event)"
      @keydown="onKeyDown($event)"
      :error-messages="errorMessages"
      :readonly="readonly"
      :hint="hint"
      :outlined="isOutlined"
      :filled="isFilled"
      :rules="rules"
      :suffix="suffix"
      :dense="gatComponentsStore.input_dense"
      :hide-details="hideDetails"
      placeholder=" ">
      <template slot="append">
        <slot name="append"></slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import numeral from 'numeral';
import { useGatComponentsStore } from '@/store/gatComponentsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { GatInputMixin } from './GatInputMixin';

export default {
  name: 'GatNumEdit',
  props: {
    dense: Boolean,
    decimals: Number,
    disabled: Boolean,
    errorMessages: [String, Array],
    hint: String,
    label: String,
    markOptionalInputs: Boolean,
    inlineEdit: Boolean,
    readonly: Boolean,
    required: Boolean,
    suffix: String,
    validatorRules: [Function, Array],
    value: [String, Number],
    noThousandSeperator: Boolean,
    hideDetails: Boolean,
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  components: {},
  mixins: [GatInputMixin],
  setup() {
    const gatComponentsStore = useGatComponentsStore();
    const applicationStore = useApplicationStore();
    return {
      gatComponentsStore,
      applicationStore,
    };
  },
  data() {
    return {
      textValue: null,
      inFocus: false,
      lastTypedKey: '',
    };
  },

  created() {},

  computed: {
    format() {
      let result = '0,0.[00000]';

      if (this.decimals >= 0) {
        let decimals = '.000000000000';
        const decCount = parseInt(this.decimals, 10);
        decimals = decimals.substr(0, 1 + decCount);
        result = `0,0${decimals}`;
      }

      return result;
    },
    isOutlined() {
      return !this.inlineEdit;
    },

    isFilled() {
      return this.inlineEdit;
    },

    rules() {
      const rules = [];

      // valid number tule
      rules.push((value) => {
        const isInvalid = !this.isValidNumberOrEmpty(value);
        if (isInvalid) {
          return 'not a valid number';
        }
        return true;
      });

      // required rule
      if (this.required) {
        rules.push((value) => {
          if (value || value == 0) {
            return true;
          }
          return 'required';
        });
      }

      // custom rules
      if (this.validatorRules) {
        this.validatorRules.forEach((rule) => {
          const value = this.convertFromStringToNumber(this.textValue);
          rules.push(rule(value));
        });
        // rules = rules.concat(this.validatorRules);
      }

      return rules;
    },
    numberFormat() {
      return this.applicationStore.settings.numberFormat;
    },
  },

  watch: {
    format(val, oldVal) {
      if (val != oldVal && !this.inFocus) {
        this.textValue = this.formatedNumber(this.value);
      }
    },
    value: {
      handler(val) {
        if (this.inFocus) {
          // this.textValue = this.unFormatedNumber(val);
        } else {
          this.textValue = this.formatedNumber(val);
        }
      },
      immediate: true,
    },

    textValue: {
      handler(txtVal) {
        const val = this.convertFromStringToNumber(txtVal);
        if (val != this.value && val !== null) {
          this.$emit('input', val);
        }
        this.checkForErrors(txtVal); // mixin
      },
      immediate: true,
    },
  },

  methods: {
    convertFromStringToNumber(valueAsString) {
      const val = numeral(valueAsString).value();
      if (this.isValidNumberOrEmpty(valueAsString)) {
        return val;
      }
      return null;
    },
    isNull(val) {
      return val === null || val === undefined || val === '';
    },

    isValidNumberOrEmpty(text) {
      const val = numeral(text).value();
      // if this is a formated value it is ok
      if (text === this.formatedNumber(val)) {
        return true;
      }
      // if is a unformated number it is ok
      if (text === this.unFormatedNumber(this.formatedNumber(val))) {
        return true;
      }
      // check if valid js number (use . as decimalseparator)
      const jsNumber = text.replace(',', '.');
      // eslint-disable-next-line no-restricted-globals
      const isValid = !isNaN(jsNumber);
      return isValid;
    },

    onBlur(eventParams) {
      if (this.isValidNumberOrEmpty(this.textValue)) {
        const val = numeral(this.textValue).value();
        this.inFocus = false;
        this.$emit('input', val);
        this.textValue = this.formatedNumber(val);
      }

      this.$emit('blur', eventParams);
    },

    onFocus() {
      // event.target.setSelectionRange(0, end);
      this.inFocus = true;
      if (this.isValidNumberOrEmpty(this.textValue)) {
        this.textValue = this.unFormatedNumber(this.textValue);
      }
    },
    isNumberKeys(event) {
      if (
        event.key === '1' ||
        event.key === '2' ||
        event.key === '3' ||
        event.key === '4' ||
        event.key === '5' ||
        event.key === '6' ||
        event.key === '7' ||
        event.key === '8' ||
        event.key === '9' ||
        event.key === '0'
      ) {
        return true;
      }
      return false;
    },
    isDefaultKeys(event) {
      if (
        event.code === 'Tab' ||
        event.code === 'Backspace' ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'ArrowUp' ||
        event.key === 'ArrowDown' ||
        event.key === 'Home' ||
        event.key === 'End'
      ) {
        return true;
      }
      if (
        event.key === 'Control' ||
        event.key === 'v' ||
        event.key === 'c' ||
        event.key === 'x' ||
        event.key === 'a' ||
        event.key === 'z' ||
        event.key === 'shift'
      ) {
        if (
          (this.lastTypedKey === 'Control' && event.key !== 'Control') ||
          event.key === 'shift' ||
          event.key === 'Ar'
        ) {
          return true;
        }
      }
      return false;
    },
    preventNonNumbersInInput(event) {
      // Don't
      if (event.key.toUpperCase() === 'DELETE') {
        return;
      }
      const characters = String.fromCharCode(event.which);
      const currentValue = this.$el.querySelector('input').value;
      if (event.key === '-') {
        if (!currentValue.includes('-')) {
          if (this.textValue && this.textValue.length > 0) {
            this.textValue = `-${this.textValue}`;
          } else {
            return;
          }
        }
      }
      if (event.key === '+') {
        if (!currentValue.includes('+')) {
          if (this.textValue && this.textValue.length > 0) {
            this.textValue = Math.abs(this.textValue).toString();
          } else {
            return;
          }
        }
      }

      if (event.key === '.' || event.key === ',') {
        if (currentValue.includes('.') || currentValue.includes(',')) {
          // Prevent the user from typing '.' or ',' twice
          event.preventDefault();
        }
        if ((this.numberFormat === 'de' || this.numberFormat === 'nl-nl') && event.key === ',') {
          return;
        }
        if (this.numberFormat === 'en' && event.key === '.') {
          return;
        }
      }

      if (!(/[0-9]/.test(characters) || this.isNumberKeys(event)) && !this.isDefaultKeys(event)) {
        event.preventDefault();
      }
    },

    onKeyUp(keyboardEvent) {
      if (keyboardEvent.key === 'Tab') {
        const input = this.$refs.field.$el.querySelector('input');
        if (input && this.textValue && this.textValue.toString().length > 0) {
          // Handle a bug with the selection range, so that the text is selected when using tab-key.
          const end = this.textValue.length;
          setTimeout(() => {
            input.setSelectionRange(0, end);
          });
        }
      }
    },

    onKeyDown(keyboardEvent) {
      this.preventNonNumbersInInput(keyboardEvent);
      // eslint-disable-next-line no-restricted-globals
      this.lastTypedKey = event.key;
    },

    unFormatedNumber(text) {
      if (this.isNull(text)) {
        return null;
      }
      const n = numeral(text);
      return n.format('0.[00000]');
    },

    formatedNumber(val) {
      if (this.isNull(val)) {
        return null;
      }
      if (this.noThousandSeperator) {
        return this.unFormatedNumber(this.value);
      }
      const n = numeral(val);
      return n.format(this.format);
    },
  },
};
</script>

<style scoped></style>
