import { computed } from 'vue';

// Replaced by useGatInput.ts
export function useGatInput(
  props: {
    label?: string;
    textValue?: string | null;
    required?: boolean;
    markOptionalInputs?: boolean;
    validatorRules?: any[];
  },
  emit: {
    (e: 'OnErrorState', payload: any): void;
  }
) {
  const getRequiredOrOptionalHint = computed(() => {
    let result = '';
    // onlys how for empty fields
    if (!(props.textValue == '' || props.textValue == null)) {
      return '';
    }
    // normally hint is shown on required field only
    if (props.required) {
      result = '*required';
    }

    // if most fields are required, we can insted mark the optional fields
    if (props.markOptionalInputs) {
      if (props.required) {
        result = '';
      } else {
        result = '*optional';
      }
    }
    return result;
  });

  const getMessages = computed(() => {
    const result = getRequiredOrOptionalHint.value;
    if (result != '') {
      return result;
    }
    return undefined;
  });

  const getLabel = computed(() => {
    let result = props.label;
    if (getRequiredOrOptionalHint.value != '') {
      if (result && !result.endsWith('*')) {
        result += '*';
      }
    }
    return result;
  });

  // returns true if errors, false if no erros found.  Willby default emit "OnErrorState" if error is found
  function checkForErrors(inputValue: any, altRules: any[]) {
    let rules = props.validatorRules;
    if (altRules) {
      rules = altRules;
    }
    if (!rules) {
      return false;
    }
    for (let index = 0; index < rules.length; index++) {
      const rule = rules[index];
      const ruleDataType = typeof rule;
      let ruleValue;
      if (ruleDataType == 'function') {
        ruleValue = rules[index].call(props, inputValue);
      } else {
        ruleValue = rule;
      }
      if (ruleValue !== true) {
        emit('OnErrorState', inputValue);
        return true;
      }
    }
    return false;
  }

  function getParentGatGroup(component: any): any {
    const parent = component.$parent;
    if (!parent) {
      return null;
    }

    if (parent.compName != 'GatGroup') {
      return getParentGatGroup(parent);
    }
    return parent;
  }

  function expandParentGatGroup(component: any) {
    try {
      const grp = getParentGatGroup(component);
      if (grp) {
        grp.isExpanded = true;
      }
    } catch (error) {
      console.log(`expandParentGatGroup (${props.label}): `, error);
    }
  }

  return {
    getLabel,
    getMessages,
    checkForErrors,
    getParentGatGroup,
    expandParentGatGroup,
  };
}
