import { render, staticRenderFns } from "./PortcallVesselDetails.vue?vue&type=template&id=4533ed50&scoped=true&"
import script from "./PortcallVesselDetails.vue?vue&type=script&lang=js&"
export * from "./PortcallVesselDetails.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4533ed50",
  null
  
)

export default component.exports