<template>
  <div>
    <search-panel
      :fields="[{ name: 'filterField', caption: 'Filter', size: 'lg3 sm5 xs12', maxChars: 0 }]"
      @input="setFilterText"
      submitBtn="true">
    </search-panel>
    <gat-grid
      :items="filterItems"
      :columns="columns"
      :isLoading="isLoading"
      :reloadBtn="true"
      title="Agencies"
      @row-clicked="agencyClicked"
      @reload-clicked="retrieveAgencies">
      <template slot="btns">
        <wm-button-group class="right mr-1" :buttons="[{ btnType: 'ADD' }]" @addClick="addAgencyLocaion()" />
      </template>
    </gat-grid>
  </div>
</template>

<script>
import SearchPanel from '@/app-components/SearchPanel.vue';
// eslint-disable-next-line import/no-cycle
import { apiGet } from '../../../store/api';

export default {
  name: 'AgencyLocationList',
  props: [],
  components: { SearchPanel },
  data() {
    return {
      filterText: '',
      isLoading: false,
      items: [],
    };
  },

  activated() {
    this.retrieveAgencies();
  },

  computed: {
    columns() {
      const columns = [
        { header: 'Name', field: 'TSA_AGENCY' },
        { header: 'S/N', field: 'TSA_SERIAL' },
        { header: 'Group name', field: 'TSA_SHORTNAME' },
        { header: 'Country', field: 'AGENCY_COUNTRY' },
        { header: 'Lat', field: 'TSA_LAT' },
        { header: 'Long', field: 'TSA_LONG' },
        { header: 'Zoom', field: 'TSA_ZOOM' },
      ];

      return columns;
    },

    filterItems() {
      if (this.filterText === '') {
        return this.items;
      }

      const result = this.items.filter(
        (item) =>
          this.matchFilter(item.TSA_AGENCY) ||
          this.matchFilter(item.TSA_SERIAL) ||
          this.matchFilter(item.TSA_SHORTNAME) ||
          this.matchFilter(item.AGENCY_COUNTRY) ||
          this.matchFilter(item.TSA_LAT) ||
          this.matchFilter(item.TSA_LONG)
      );
      return result;
    },
  },

  methods: {
    addAgencyLocaion() {
      this.$router.push({ name: 'crmAgencyLocationForm', params: { isNew: true, agencyLocationId: -1 } });
    },
    agencyClicked(rowData) {
      this.$router.push(`/crm/agency-location/${rowData.TSA_ID}`);
    },
    matchFilter(valueToCheck) {
      const value = String(valueToCheck);
      return value.toLowerCase().includes(this.filterText);
    },
    setFilterText(filterObject) {
      const filterValue = filterObject.filterField;
      if (typeof filterValue === 'string' && filterValue != '') {
        this.filterText = filterValue.toLowerCase();
      } else {
        this.filterText = '';
      }
    },
    retrieveAgencies() {
      this.isLoading = true;
      apiGet('/agencylocation').then((result) => {
        this.items = result;
        this.isLoading = false;
      });
    },
  },
};
</script>

<style></style>
