<template>
  <div>
    <v-menu v-model="userMenuVisble" bottom right :close-on-content-click="false" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon large v-bind="attrs" v-on="on">
          <v-avatar size="26px">
            <img src="/img/user_image.png" alt="User" />
          </v-avatar>
        </v-btn>
      </template>
      <v-card max-width="400" outlined tile light>
        <v-list-item>
          <v-list-item-avatar class="ma-0 pa-0" color="grey">
            <img src="/img/user_image.png" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="d-flex justify-start align-baseline text-h5 ma-0 pa-0 ml-3 mb-2">
              <span style="font-size: 20px !important; font-weight: 500 !important">{{
                applicationStore.user.fullName
              }}</span>
            </v-list-item-title>
            <!-- <v-list-item-subtitle v-if="!this.$vuetify.breakpoint.smAndDown">{{selectedAgencyText.AGENT}} - {{selectedAgencyText.GROUP_NAME}}</v-list-item-subtitle> -->
            <span class="ml-3" style="font-size: 14px">{{ getUserName() }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="pa-0 ma-0">
          <gat-select
            v-if="applicationStore.user.internal"
            v-model="selectedAgency"
            :items="agencyItems"
            label="Agency"
            size="xxl"
            style="width: 100%"
            class="pa-0 ma-0"
            :disabled="!applicationStore.userRight('G.ACCESS_ALL_AGENCIES')"
            :noSearch="$vuetify.breakpoint.xsOnly" />
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item>
          <v-btn small text class="mb-2 mr-2" color="primary" @click="userMenuVisble = false">close</v-btn>
          <v-btn small text class="mb-2 mr-2" color="primary" @click="showPasswordDlg = true">change password</v-btn>
          <v-btn small class="mb-2 mr-2" color="primary" @click="logout">logout</v-btn>
        </v-list-item>

        <!-- <v-card-text>

              </v-card-text> -->
      </v-card>
    </v-menu>
    <ChangePassword :showDialog="showPasswordDlg" @close="showPasswordDlg = false" :logOutOnCancel="false" />
  </div>
</template>

<script>
import { apiLogOut } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'UserMenu',
  props: {
    agencyItems: {
      type: Array,
    },
  },
  components: {
    ChangePassword: () => import('../views/ChangePassword.vue'), // Uses lambda function to import componet to ommit problem with the component used in Auth and UserMenu
  },
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      userMenuVisble: false,
      showPasswordDlg: false,
    };
  },

  computed: {
    selectedAgency: {
      get() {
        return this.applicationStore.agencyIdSelected;
      },
      set(newValue) {
        const agencyId = newValue;
        this.applicationStore.setNewAgency(agencyId);
        window.messageBus.$emit('new-agency-selected', agencyId);
        // window.messageBus.$on('new-agency-selected',(agencyId)=>{
      },
    },
  },

  created() {},

  watch: {},

  methods: {
    getUserName() {
      let uname = this.applicationStore.user.userNameLong;
      if (!this.applicationStore.user.internal && uname && typeof uname === 'string') {
        uname = uname.toLowerCase();
      }
      return uname;
    },
    logout() {
      this.userMenuVisble = false;
      apiLogOut().then(() => {
        // Route to login form
        this.$router.push('/auth');
        this.$router.go();
      });
    },
  },
};
</script>

<style scoped>
.userMenuCard {
  position: absolute;
  top: 35px;
  min-width: 350px;
  right: 0px;
  z-index: 1000 !important;
}
</style>
