// Pinia Store
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAttendanceStore = defineStore('attendanceStore', () => {
  const attendanceCodes = ref<any[]>([]);
  const inAttendance = ref<any>(null);
  const berthedPortcalls = ref<any[]>([]);
  const previousAttendances = ref<any[]>([]);
  const portcallAttendances = ref<any[]>([]);

  function setAttendanceCodes(data: any[]) {
    attendanceCodes.value = data;
  }

  function setAttendanceItemCheckout(data: any) {
    const prevAttendance = previousAttendances.value;
    const portcallAttendance = portcallAttendances.value;
    for (let i = 0; prevAttendance.length > i; i++) {
      if (prevAttendance[i].ID === data.ID) {
        previousAttendances.value[i].CHECKOUT = data.CHECKOUT;
        previousAttendances.value[i].REMARK = data.REMARK;
        previousAttendances.value[i].STATUS = data.STATUS;
      }
    }
    if (portcallAttendance.length != 0) {
      for (let i = 0; portcallAttendance.length > i; i++) {
        if (portcallAttendance[i].ID === data.ID) {
          portcallAttendances.value[i].CHECKOUT = data.CHECKOUT;
          portcallAttendances.value[i].REMARK = data.REMARK;
          portcallAttendances.value[i].STATUS = data.STATUS;
        }
      }
    }
  }

  function setBerthedPortcalls(data: any[]) {
    berthedPortcalls.value = data;
  }

  function setPreviousAttendances(data: any[]) {
    previousAttendances.value = data;
  }

  function setPortcallAttendaces(data: any[]) {
    portcallAttendances.value = data;
  }

  function setPortcallAttendaceItem(data: any) {
    portcallAttendances.value.push(data);
  }

  function setPreviousAttendanceItem(data: any) {
    const berthedCalls = berthedPortcalls.value;
    const result = data;
    for (let i = 0; berthedCalls.length > i; i++) {
      if (!data.PORTCALL_NUMBER || !data.VESSEL_PREFIX || !data.VESSEL_NAME) {
        if (berthedCalls[i].PORTCALL_ID === data.PORTCALL_ID) {
          result.PORTCALL_NUMBER = berthedCalls[i].PORTCALL_NUMBER;
          result.VESSEL_PREFIX = berthedCalls[i].VESSEL_PREFIX;
          result.VESSEL_NAME = berthedCalls[i].VESSEL_NAME;
        }
      }
      // Add more properties to the object before being updated in the previousAttendance store.
    }

    const aCodes = attendanceCodes.value;
    for (let i = 0; aCodes.length > i; i++) {
      if (!result.ATTENDANCE_CODE_DESCRIPTION) {
        if (aCodes[i].value === data.ATTENDANCE_CODE) {
          result.ATTENDANCE_CODE_DESCRIPTION = aCodes[i].text;
        }
      }
    }

    // Add the object to the list.
    previousAttendances.value.push(result);
  }

  function setInAttendance(data: { [key: string]: any }) {
    if (Object.keys(data).length === 0 && data.constructor === Object) {
      // Check if data is empty and a object type.
      inAttendance.value = null;
    } else {
      inAttendance.value = data;
    }
  }

  function replaceAttendanceItem(attendanceItem: { [key: string]: any }) {
    // Find and replace items in previous attendace store.
    const previousAttendancesCopy = [...previousAttendances.value];
    if (previousAttendancesCopy.length > 0) {
      const idx = previousAttendancesCopy.findIndex((item) => +item.ID === +attendanceItem.ID);
      if (idx >= 0) {
        previousAttendancesCopy.splice(idx, 1, attendanceItem);
        previousAttendances.value = previousAttendancesCopy; // update the store.
      }
    }
    // Find and replace items in portcall attendace store.
    const portcallAttendacesCopy = [...portcallAttendances.value];
    if (portcallAttendacesCopy.length > 0) {
      const idx = portcallAttendacesCopy.findIndex((item) => +item.ID === +attendanceItem.ID);
      if (idx >= 0) {
        portcallAttendacesCopy.splice(idx, 1, attendanceItem);
        portcallAttendances.value = portcallAttendacesCopy; // update the store.
      }
    }
  }

  function removeAttendanceItem(id: string | number) {
    let idx = previousAttendances.value.findIndex((item) => item.ID == id);
    if (idx >= 0) {
      // replace item
      previousAttendances.value.splice(idx, 1);
    }
    if (portcallAttendances.value.length != 0) {
      idx = 0;
      idx = portcallAttendances.value.findIndex((item) => item.ID == id);
      if (idx >= 0) {
        portcallAttendances.value.splice(idx, 1);
      }
    }
  }

  return {
    attendanceCodes,
    inAttendance,
    berthedPortcalls,
    previousAttendances,
    portcallAttendances,
    setAttendanceCodes,
    setAttendanceItemCheckout,
    setBerthedPortcalls,
    setPreviousAttendances,
    setPortcallAttendaces,
    setPortcallAttendaceItem,
    setPreviousAttendanceItem,
    setInAttendance,
    replaceAttendanceItem,
    removeAttendanceItem,
  };
});
