<template>
  <div>
    <v-dialog v-model="dialogVisible" persistent max-width="600">
      <v-card class="pa-3">
        <v-layout wrap>
          <gat-edit
            v-model="document.TITLE"
            label="Document title"
            size="xs12"
            :maxChars="applicationStore.getColumnLength('Document.TITLE')" />
        </v-layout>
        <v-layout v-if="webAccessIsAllowed" wrap>
          <gat-multi-select
            v-model="document.WEB_ACCESS"
            :items="webAccessSelect"
            label="Web access"
            textFieldName="NAME"
            codeFieldName="ID"
            size="xs12">
          </gat-multi-select>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="close('cancel')">Cancel</v-btn>
          <v-btn color="success" class="right" @click="close('save')">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'DocumentProperties',
  props: ['value', 'showForm', 'webAccessGroups'],
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      dialogVisible: false,
      document: {},
      orgDocument: {},
    };
  },

  mounted() {
    this.document = GlobalHelperFunctions.duplicateViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value(newValue) {
      if (!newValue.WEB_ACCESS) {
        // eslint-disable-next-line no-param-reassign
        newValue.WEB_ACCESS = [];
      }
      this.orgDocument = GlobalHelperFunctions.duplicateViaJson(newValue);
      this.document = GlobalHelperFunctions.duplicateViaJson(newValue);
    },
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {
    webAccessIsAllowed() {
      if (this.value) {
        if (
          (this.value.DOCUMENT_ORIGIN === 'vessel' || this.value.DOCUMENT_ORIGIN === 'portcall') &&
          +this.value.StoredInDb === 1
        ) {
          return true;
        }
      }
      return false;
    },
    webAccessSelect() {
      let result = [];
      if (this.webAccessGroups) {
        const accessGroups = this.webAccessGroups;
        result = accessGroups.map((item) => ({
          NAME: item.NAVN,
          ID: item.ID,
        }));
      }
      return result;
    },
  },

  methods: {
    close(btn) {
      if (this.formIsValid()) {
        if (btn == 'cancel') {
          this.$emit('close', { btn, value: this.orgDocument });
        } else if (btn == 'save') {
          this.$emit('close', { btn, value: this.document });
        }
      }
    },

    formIsValid() {
      const result = true;
      return result;
    },
  },
};
</script>

<style scoped></style>
