<template>
  <gat-flex :size="compSize">
    <b>{{ caption }}</b>
    <v-treeview class="treeview" :items="items" dense>
      <template v-slot:prepend="{ item }">
        <v-icon>{{ getIcon(item.value) }}</v-icon>
      </template>
      <template v-slot:label="{ item }">
        <span class="blue--text">{{ item.name }} </span>:<span> {{ displayValue(item.value) }}</span>
      </template>
    </v-treeview>
  </gat-flex>
</template>

<script>
import GatFlex from './GatFlex.vue';

export default {
  name: 'RecordPresenter',
  props: {
    value: [Object, Array],
    caption: String,
    size: String,
  },
  components: { GatFlex },
  data() {
    return {
      lastId: 0,
    };
  },

  created() {},

  computed: {
    compSize() {
      if (this.size) {
        return this.size;
      }
      return 'xs6';
    },

    nextId() {
      let result = this.lastId;
      if (!result) {
        result = this.startId;
      }
      if (!result) {
        result = 1;
      }
      return result;
    },

    items() {
      return this.makeNodes(this.value, 0);
    },
  },

  watch: {},

  methods: {
    expandable(v) {
      const type = this.getDataType(v);
      if (type == 'array' && v.length > 0) return true;
      if (type == 'object' && v !== null) return true;
      return false;
    },
    displayValue(v) {
      let result = JSON.stringify(v);
      const type = this.getDataType(v);
      if (v === null) {
        result = 'null';
      }
      if (type == 'array') {
        if (v.length != 0) {
          result = `[...${v.length} items...]`;
        }
      }
      if (type == 'object') {
        const ol = Object.keys(v);
        if (ol.length != 0) {
          result = `{...${ol.length} properties...}`;
        }
      }
      return result;
    },
    getDataType(v) {
      let result = typeof v;
      if (result == 'object') {
        if (Array.isArray(v)) {
          result = 'array';
        }
        if (Object.prototype.toString.call(v) === '[object Date]') {
          result = 'date';
        }
        if (v === null) {
          result = 'null';
        }
      }
      return result;
    },
    getIcon(v) {
      const result = this.getDataType(v);
      // eslint-disable-next-line default-case
      switch (result) {
        case 'string':
          return 'mdi-format-size';
        case 'boolean':
          return 'mdi-toggle-switch';
        case 'number':
          return 'mdi-sigma';
        case 'date':
          return 'mdi-calendar-clock';
        case 'array':
          return 'mdi-format-list-numbered';
        case 'object':
          return 'mdi-ballot-outline';
        case 'null':
          return 'mdi-code-tags';
      }
      return '';
    },
    makeNodes(obj, level) {
      const result = [];
      if (this.getDataType(obj) == 'object') {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const prop in obj) {
          const v = obj[prop];
          this.lastId++;
          const item = {
            id: this.lastId,
            name: prop,
            value: v,
          };
          if (level < 10 && this.expandable(v)) {
            item.children = this.makeNodes(v, level + 1);
          }
          result.push(item);
        }
      }
      if (this.getDataType(obj) == 'array') {
        for (let i = 0; i < obj.length; i++) {
          const v = obj[i];
          this.lastId++;
          const item = {
            id: this.lastId,
            name: `[${i}]`,
            value: v,
          };
          if (level < 10 && this.expandable(v)) {
            item.children = this.makeNodes(v, level + 1);
          }
          result.push(item);
        }
      }
      return result;
    },
  },
};
</script>

<style scoped>
/* .treeview{
      background-color: antiquewhite
  } */
</style>
