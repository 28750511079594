<template>
  <div>
    <v-dialog :value="dialogVisible" persistent :width="width" scrollable :fullscreen="fullscreen">
      <v-card>
        <div :class="fullscreen ? 'safe-area' : ''">
          <!-- this div fixes a bug with the toolbar height -->
          <gat-dialog-toolbar
            v-if="fullscreen"
            :title="title"
            :okButtonCaption="okButtonCaption"
            :okButtonDisabled="okButtonDisabled"
            @cancelClick="cancelClick"
            :cancelButtonDisabled="cancelButtonDisabled"
            @okClick="okClick" />

          <v-card-title v-else class="title">{{ title }}</v-card-title>
        </div>
        <v-card-text v-if="value" :style="noPadding ? 'padding: 0' : ''">
          <slot> </slot>
        </v-card-text>

        <v-card-actions v-if="!fullscreen">
          <slot name="delete-btn">
            <v-btn text v-if="deleteButton" @click="showDeleteDialog = true">Delete</v-btn>
          </slot>
          <v-spacer></v-spacer>
          <v-btn v-if="cancelButtonCaption" text color="primary" @click="cancelClick">{{ cancelButtonCaption }}</v-btn>
          <v-btn
            v-if="okButtonCaption"
            :disabled="okButtonDisabled"
            :text="!okButtonIsProminent"
            color="primary"
            @click="okClick"
            >{{ okButtonCaption }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <gat-dialog
      v-if="deleteButton"
      v-model="showDeleteDialog"
      okButtonCaption="Delete"
      :title="'Delete ' + itemDescription"
      @okClick="deleteClick">
      <p>This operation can not be undone.</p>
      Arey you sure you would like to delete this {{ itemDescription }}?
    </gat-dialog>
  </div>
</template>

<script>
import GatDialogToolbar from './GatDialogToolbar.vue';

export default {
  name: 'GatDialog',
  props: {
    autoClose: {
      type: Boolean,
      default: true,
    },
    cancelButtonCaption: {
      type: String,
      default: 'cancel',
    },
    cancelButtonDisabled: Boolean,
    deleteButton: Boolean,
    fullscreen: Boolean,
    itemDescription: String,
    okButtonCaption: String,
    okButtonDisabled: Boolean,
    okButtonIsProminent: Boolean,
    title: String,
    value: Boolean,
    width: {
      type: String,
      default: '500px',
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  components: { GatDialogToolbar },
  data() {
    return {
      showDeleteDialog: false,
    };
  },

  created() {},

  watch: {},

  computed: {
    dialogVisible: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },

  methods: {
    cancelClick() {
      this.dialogVisible = false;
      this.$emit('cancelClick');
    },
    deleteClick() {
      if (this.autoClose) {
        this.dialogVisible = false;
      }
      this.$emit('deleteClick');
    },
    okClick() {
      if (this.autoClose) {
        this.dialogVisible = false;
      }
      this.$emit('okClick');
    },
  },
};
</script>

<style scoped>
.safe-area {
  padding-left: calc(0 + env(safe-area-inset-left)) !important;
  padding-top: calc(0 + env(safe-area-inset-top)) !important;
  background: var(--gatPrimaryColor);
}
</style>
