<template>
  <GatGroup class="pa-0" title="Berth and anchorage" :collapsed="false">
    <template slot="compact">
      <v-layout wrap>
        <gat-compact-field
          v-for="(berth, index) in berths"
          :value="getLocationText(berth)"
          :label="getCompactBerthCaption(berth)"
          size="m"
          :key="index" />
      </v-layout>
    </template>
    <div v-if="value.ID" style="width: 100%">
      <v-layout wrap style="width: inherit">
        <gat-grid
          :items="berths"
          :hideEmptyColumns="false"
          :columns="berthColumns"
          class="elevation-0"
          @row-clicked="berthRowClicked"
          rowHint="click to edit"
          :rowMenu="rowMenu"
          @rowMenuClicked="rowMenuClicked"
          style="width: inherit">
          <template slot="cell" slot-scope="props">
            <div v-if="props.column.field == `LINE_NO`" class="text-left d-flex align-center justify-start">
              <span class="mr-2">{{ props.value }}</span>
              <v-icon v-if="props.row.BA_TYPE == 'Anchorage'" class="berth-and-achorage-icon">mdi-anchor</v-icon>
              <div v-if="props.row.QUAY_RESTRICTIONS" style="position: relative" @click.stop>
                <v-tooltip
                  :bottom="$vuetify.breakpoint.smAndDown"
                  :right="!$vuetify.breakpoint.smAndDown"
                  open-on-click
                  color="blue"
                  class="tooltip-test">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="berth-and-achorage-icon" v-bind="attrs" v-on="on">mdi-information</v-icon>
                  </template>
                  <div>{{ props.row.QUAY_RESTRICTIONS }}</div>
                </v-tooltip>
              </div>
            </div>

            <div v-else-if="props.column.field == `ARRIVAL`" class="text-left d-flex align-center justify-start">
              <div v-if="props.row.warning" @click.stop>
                <v-tooltip
                  :bottom="$vuetify.breakpoint.smAndDown"
                  :right="!$vuetify.breakpoint.smAndDown"
                  open-on-click
                  color="primary"
                  class="tooltip-test">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on">mdi-alert</v-icon>
                  </template>
                  <div>
                    The arrival and departure conflicts with another registration on this berth.<br />
                    Used by "{{ props.row.warning.WARNING_VESSEL_NAME }}" on call no
                    {{ props.row.warning.WARNING_PORTCALL_NUMBER }}.<br />
                    Berth period on the other port call is
                    {{
                      props.row.warning.WARNING_ETB
                        ? globalFormatDateTime(props.row.warning.WARNING_ETB, 'DD.MMM HH:mm')
                        : globalFormatDateTime(props.row.warning.WARNING_ATA, 'DD.MMM HH:mm')
                    }}
                    -
                    {{
                      props.row.warning.WARNING_ETD
                        ? globalFormatDateTime(props.row.warning.WARNING_ETD, 'DD.MMM HH:mm')
                        : globalFormatDateTime(props.row.warning.WARNING_ATD, 'DD.MMM HH:mm')
                    }}
                  </div>
                </v-tooltip>
              </div>
              <span class="ml-2">{{ getArrivalTime(props.row) }}</span>
            </div>
            <div v-else-if="props.column.field == `TIME_AT_BERTH`" class="text-left d-flex align-center justify-start">
              <CountdownTimer
                :startDateTime="setTimeAtBerthArrival(props.row)"
                :endDateTime="setTimeAtBerthDeparture(props.row)" />
            </div>
            <div
              v-else-if="props.column.field == `ACCUMULATED_TIME`"
              class="text-left d-flex align-center justify-start">
              <CountdownTimer
                v-if="props.row.ATA"
                :startDateTime="getFirstBerth"
                :endDateTime="setAccumulatedTimeDeparture(props.row)" />
            </div>
          </template>
        </gat-grid>
      </v-layout>
    </div>

    <v-menu offset-y open-on-hover slot="btn">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on" outlined small :disabled="!applicationStore.userRight('P.EDIT')">
          <v-icon small>mdi-plus</v-icon>Add
        </v-btn>
      </template>
      <v-list>
        <v-list-item @click="addBerth"> <v-list-item-title>Add quay</v-list-item-title> </v-list-item>
        <v-list-item @click="addAnchorage"> <v-list-item-title>Add anchorage</v-list-item-title> </v-list-item>
      </v-list>
    </v-menu>
    <div v-if="!value.ID" class="pa-3">The new port call have to be saved before this section can be edited</div>
    <!-- <quay-dock-edit :showForm="showBerthForm" v-model="berthToEdit" @close="berthFormClosed" :readonly="readonly"
         :isCalculated="value.BERTH_CALCULATION"></quay-dock-edit> -->
    <anchorage-dock-edit
      :showForm="showAnchorageForm"
      v-model="berthToEdit.ANC_ID"
      @close="anchoredFormClosed"
      :readonly="readonly"
      :isCalculated="value.BERTH_CALCULATION"
      :agency_id="value.SETUP_ID"
      :new-item="berthToEdit"></anchorage-dock-edit>
  </GatGroup>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import { apiGet, apiPut, apiDelete } from '@/store/api';
import CountdownTimer from '@/app-components/CountdownTimer.vue';
import moment from 'moment';
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { getPortcallSyncChanges } from '@/services/api/api-calls/getPortcallSyncChanges';
import { getBerths } from '@/services/api/api-calls/getBerths';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import AnchorageDockEdit from './AnchorageDockEdit.vue';

export default {
  name: 'PortCallBerthAnchorage',
  props: ['value', 'readonly'],
  components: { AnchorageDockEdit, CountdownTimer },
  setup() {
    const constantsStore = useConstantsStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      constantsStore,
      applicationStore,
      portcallStore,
    };
  },
  data() {
    return {
      showBerthForm: false,
      showAnchorageForm: false,
      berthToEdit: {},
      orgBerth: {},
      berths: null,
    };
  },

  created() {},

  watch: {
    berthsStore: {
      immediate: true,
      handler(newValue) {
        this.berths = newValue;
      },
    },
  },

  computed: {
    berthsStore() {
      return this.portcallStore.berths;
    },
    berthColumns() {
      const result = [];
      result.push({ header: '#1', field: 'LINE_NO' });
      result.push({ header: 'Cur.', field: 'ACTIVE_QUAY', checkbox: true, hideOnPhone: true });
      result.push({
        header: 'Location',
        field: 'HARBOUR',
        nowrap: true,
        calculated: (rowData) => this.getLocationText(rowData),
      });
      result.push({ header: 'Action', field: 'ACTION', hideOnPhone: true });
      result.push({
        header: 'Shifting',
        field: 'SHIFTING_TIME',
        hideOnPhone: true,
        hide: this.value.BERTH_CALCULATION == 0,
      });
      result.push({
        header: 'Arrival',
        field: 'ARRIVAL',
        calculatedClass: (rowData) => `nowrap ${this.setEstArrivalExpired(rowData)}`,
        hideOnPhone: false,
      });
      result.push({
        header: 'Hrs',
        field: 'HOURS_TO_STAY',
        hideOnPhone: true,
        hide: this.value.BERTH_CALCULATION == 0,
      });
      if (this.applicationStore.globalSettings.SGL_SHOW_ITC_ETC == 1) {
        result.push({
          header: 'ITC',
          field: 'ITC',
          class: 'nowrap',
          calculated: (rowData) => this.globalFormatDateTime(rowData.ITC, 'DD.MMM HH:mm'),
          hide: this.hideColumnIfNoValuesExists(this.berths, 'ITC'),
          hideOnPhone: true,
        });
        result.push({
          header: 'ETC',
          field: 'ETC',
          class: 'nowrap',
          calculated: (rowData) => this.globalFormatDateTime(rowData.ETC, 'DD.MMM HH:mm'),
          hide: this.hideColumnIfNoValuesExists(this.berths, 'ETC'),
          hideOnPhone: true,
        });
      }
      result.push({
        header: 'Departure',
        calculated: (rowData) => this.getDepartureTime(rowData),
        calculatedClass: (rowData) => `nowrap ${this.setEstDepartureExpired(rowData)}`,
        hideOnPhone: false,
      });

      if (this.applicationStore.globalSettings.SGL_SHOW_ACTUAL_TIME_AT_BERTH == 1) {
        result.push({ header: 'Time at berth', field: 'TIME_AT_BERTH' });
        result.push({ header: 'Accumulated time', field: 'ACCUMULATED_TIME' });
      }

      result.push({
        header: 'Bollard from',
        field: 'BOLLARD_FROM',
        hide: this.hideColumnIfNoValuesExists(this.berths, 'BOLLARD_FROM'),
        hideOnPhone: true,
      });
      result.push({
        header: 'Bollard to',
        field: 'BOLLARD_TO',
        hide: this.hideColumnIfNoValuesExists(this.berths, 'BOLLARD_TO'),
        hideOnPhone: true,
      });

      if (this.applicationStore.globalSettings.SGL_SHOW_PILOT_DETAILS_ON_BERTH == 1) {
        result.push({
          header: 'Pilot arrival',
          field: 'PILOT_IN_PBT',
          class: 'nowrap',
          calculated: (rowData) => this.globalFormatDateTime(rowData.PILOT_IN_PBT, 'DD.MMM HH:mm'),
          hide: this.hideColumnIfNoValuesExists(this.berths, 'PILOT_IN_PBT'),
          hideOnPhone: true,
        });
        result.push({
          header: 'Pilot departure',
          field: 'PILOT_OUT_PBT',
          class: 'nowrap',
          calculated: (rowData) => this.globalFormatDateTime(rowData.PILOT_OUT_PBT, 'DD.MMM HH:mm'),
          hide: this.hideColumnIfNoValuesExists(this.berths, 'PILOT_OUT_PBT'),
          hideOnPhone: true,
        });
      }
      if (this.applicationStore.globalSettings.SGL_SHOW_TUG_ON_BERTH == 1) {
        result.push({
          header: 'Tug arr company',
          field: 'TUG_COMPANY',
          calculated: (rowData) => this.getTugCompanyName(rowData.TUG_COMPANY),
          hide: this.hideColumnIfNoValuesExists(this.berths, 'TUG_COMPANY'),
          hideOnPhone: true,
        });
        result.push({
          header: 'Tugs arr',
          field: 'TUG_QTY',
          hide: this.hideColumnIfNoValuesExists(this.berths, 'TUG_QTY'),
          hideOnPhone: true,
        });
        result.push({
          header: 'Tug dep company',
          field: 'TUG_DEP_COMPANY',
          calculated: (rowData) => this.getTugCompanyName(rowData.TUG_DEP_COMPANY),
          hide: this.hideColumnIfNoValuesExists(this.berths, 'TUG_DEP_COMPANY'),
          hideOnPhone: true,
        });
        result.push({
          header: 'Tugs dep',
          field: 'TUG_DEP_QTY',
          hide: this.hideColumnIfNoValuesExists(this.berths, 'TUG_DEP_QTY'),
          hideOnPhone: true,
        });
      }

      result.push({
        header: 'Quay no',
        field: 'QUAY_NO',
        hide: this.hideColumnIfNoValuesExists(this.berths, 'QUAY_NO'),
        hideOnPhone: true,
      });
      result.push({
        header: 'Pos remark',
        field: 'POSITION_REMARK',
        hide: this.hideColumnIfNoValuesExists(this.berths, 'POSITION_REMARK'),
        hideOnPhone: true,
      });
      result.push({
        header: 'Connections',
        field: 'HOSE_CONNECTIONS',
        hide: this.hideColumnIfNoValuesExists(this.berths, 'HOSE_CONNECTIONS'),
        hideOnPhone: true,
      });

      return result;
    },
    getFirstBerth() {
      let result = null;
      if (this.berths) {
        for (let i = 0; this.berths.length > i; i++) {
          if (result == null) {
            result = this.berths[i].ATA;
          }

          const isFirstBerth = moment(this.berths[i].ATA) <= moment(result);

          if (isFirstBerth) {
            result = this.berths[i].ATA;
          }
        }
      }
      return result;
    },
    rowMenu() {
      const result = [
        {
          name: 'move-up',
          caption: 'Move up!',
          icon: 'mdi-arrow-up',
          enabled: (rowItem) => this.canMoveUp(rowItem),
        },
        {
          name: 'move-down',
          caption: 'Move down!',
          icon: 'mdi-arrow-down',
          enabled: (rowItem) => this.canMoveDown(rowItem),
        },
        {
          name: 'calculate-berth',
          caption: 'Calculate arrival and departure times',
          icon: this.value.BERTH_CALCULATION === 1 ? 'mdi-check' : undefined, //  'mdi-calendar-refresh-outline',
          enabled: (rowItem) => this.canCalculateBerth(rowItem),
        },
      ];
      return result;
    },
  },

  methods: {
    globalFormatDateTime(date, format) {
      return GlobalHelperFunctions.globalFormatDateTime(date, format);
    },
    setAccumulatedTimeDeparture(row) {
      if (row.ATD) {
        return row.ATD;
      }

      if (row.BERTH_CALCULATION && row.BERTH_CALCULATION == 1) {
        return row.CALC_ETD;
      }
      return row.ETD;
    },
    setTimeAtBerthArrival(row) {
      if (row.ATA) return row.ATA;
      return null;
    },
    setTimeAtBerthDeparture(row) {
      if (row.ATD != null) {
        return row.ATD;
      }
      if (row.BERTH_CALCULATION && row.BERTH_CALCULATION == 1) {
        return row.CALC_ETD;
      }
      if (row.ETD != null) {
        return row.ETD;
      }

      return null;
    },

    getTugCompanyName(id) {
      const companies = this.helperStore.towageCompanies; // text(name) / value(id)
      const idx = companies.findIndex((item) => item.value == id);
      if (idx >= 0) {
        return companies[idx].text;
      }
      return null;
    },
    hideColumnIfNoValuesExists(obj, key) {
      for (let i = 0; obj.length > i; i++) {
        if (obj[i][key] != null) {
          return false;
        }
      }
      return true;
    },
    setEstArrivalExpired(rowData) {
      let result = null;
      if (rowData.ATA) {
        return '';
      }
      if (rowData.ETB) {
        result = rowData.ETB;
      } else if (rowData.CALC_ETA) {
        result = rowData.CALC_ETA;
      }
      const dateExpired = new Date(result) < new Date();

      return dateExpired ? 'warning-text' : '';
    },

    setEstDepartureExpired(rowData) {
      let result = null;

      if (rowData.ATD) {
        return '';
      }
      if (rowData.ETD) {
        result = rowData.ETD;
      } else if (rowData.CALC_ETD) {
        result = rowData.CALC_ETD;
      }
      const dateExpired = new Date(result) < new Date();

      return dateExpired ? 'warning-text' : '';
    },

    addAnchorage() {
      let sortOrder = 10;
      if (this.berths.length > 0) {
        const lastBerthInList = this.berths[this.berths.length - 1];
        sortOrder = lastBerthInList.SORT_ORDER + 10;
      }
      this.orgBerth = {};
      this.berthToEdit = { ANC_PORTCALL_ID: this.value.ID, ANC_SORT_ORDER: sortOrder };
      this.berthToEdit = { ANC_PORTCALL_ID: this.value.ID, ANC_SORT_ORDER: sortOrder };
      this.showAnchorageForm = true;
    },

    addBerth() {
      this.$router.push({ name: 'newDock' });
    },

    anchoredFormClosed(params) {
      this.showAnchorageForm = false;
      if (params.btn == 'save') {
        apiPut('anchorage', { value: params.value, orgValue: params.orgValue }).then((result) => {
          if (result.resultCategory == 1) {
            if (result.syncChanges) {
              if (result.syncChanges.portcall) {
                getPortcallSyncChanges(this.value.ID);
              }
              if (result.syncChanges.sof) {
                this.portcallStore.clearSof();
              }
              if (result.syncChanges.portcall || result.syncChanges.sof) {
                this.applicationStore.setWhiteBoardDataIsDirty(true);
              }
            }
            this.berths = result.payload;
          }
        });
      } else if (params.btn == 'delete') {
        apiDelete(`anchorage/${params.value.ANC_ID}`).then((result) => {
          if (result.resultCategory == 1) {
            if (this.value.BERTH_CALCULATION) {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              apiGet(`dock/berths/${this.value.ID}`).then((result) => {
                if (result.resultCategory == 1) {
                  this.berths = result.payload;
                  if (result.syncChanges) {
                    if (result.syncChanges.portcall) {
                      getPortcallSyncChanges(this.value.ID);
                    }
                    if (result.syncChanges.sof) {
                      this.portcallStore.clearSof();
                    }
                    if (result.syncChanges.portcall || result.syncChanges.sof) {
                      this.applicationStore.setWhiteBoardDataIsDirty(true);
                    }
                  }
                } else {
                  this.removeBerthsFromList(params.value.ID);
                }
              });
            } else {
              this.removeBerthsFromList(params.value.ID);
              /* let idx = this.berths.findIndex(item=>{return item.ANC_ID == params.value.ID;});
               this.berths.splice(idx,1); */
            }
          }
        });
      }
    },

    berthRowClicked(rowItem) {
      if (rowItem.ANC_ID) {
        this.orgBerth = rowItem;
        this.berthToEdit = GatUtils.makeCopyViaJson(rowItem);
        this.showAnchorageForm = true;
      } else {
        this.$router.push({ name: 'quayDetails', params: { quayId: rowItem.ID } });
      }
    },

    getArrivalTime(rowData) {
      let result = null;
      let estimated = true;
      if (rowData.ATA) {
        result = rowData.ATA;
        estimated = false;
      } else if (rowData.ETB) {
        result = rowData.ETB;
      } else if (rowData.BERTH_CALCULATION && rowData.CALC_ETA) {
        result = rowData.CALC_ETA;
      }
      result = this.globalFormatDateTime(result, 'DD.MMM HH:mm');
      if (estimated && result) {
        result += ' (eta)';
      }
      return result;
    },

    getCompactBerthCaption(berth) {
      const arrivalTime = this.getArrivalTime(berth) || 'N/A';
      const departureTime = this.getDepartureTime(berth) || 'N/A';
      return `${berth.LINE_NO}. ${berth.BA_TYPE}: ${arrivalTime} - ${departureTime}`;
    },

    getDepartureTime(rowData) {
      let result = null;
      let estimated = true;
      if (rowData.ATD) {
        result = rowData.ATD;
        estimated = false;
      } else if (rowData.ETD) {
        result = rowData.ETD;
      } else if (rowData.BERTH_CALCULATION && rowData.CALC_ETD) {
        result = rowData.CALC_ETD;
      }

      result = this.globalFormatDateTime(result, 'DD.MMM HH:mm');
      if (estimated && result) {
        result += ' (etd)';
      }
      return result;
    },

    getLocationText(rowdata) {
      let result = rowdata.NAME;
      if (rowdata.QUAY_NO) {
        result = `${result} (${rowdata.QUAY_NO})`;
      }
      if (rowdata.TERMINAL_NAME) {
        result = `${rowdata.TERMINAL_NAME} - ${result}`;
      }
      if (this.applicationStore.globalSettings.SGL_SHOW_HARBOUR_AT_BERTH && rowdata.HARBOUR) {
        result = `${rowdata.HARBOUR} / ${result}`;
      }
      if (this.applicationStore.globalSettings.SGL_SHOW_VESSEL_POSITION && rowdata.VESSEL_ORIENTATION) {
        result = `${result} - ${GlobalHelperFunctions.lookupCode(
          rowdata.VESSEL_ORIENTATION,
          this.constantsStore.vesselOrientations
        )}`;
      }

      return result;
    },

    SetActionTypeValue(berth) {
      const cargoAction = this.helperStore.cargoActions.find((item) => item.value == berth.ACTION);
      if (cargoAction) {
        // eslint-disable-next-line no-param-reassign
        berth.TYPE = cargoAction.type;
        if (cargoAction.type == 2) {
          // eslint-disable-next-line no-param-reassign
          berth.TYPE_OTHER = cargoAction.value;
        } else {
          // eslint-disable-next-line no-param-reassign
          berth.TYPE_OTHER = null;
        }
      }
    },

    removeBerthsFromList(id) {
      const idx = this.berths.findIndex((item) => item.ANC_ID == id);
      this.berths.splice(idx, 1);
    },

    canMoveUp(rowItem) {
      const idx = this.berths.indexOf(rowItem);
      const prevBerth = this.berths[idx - 1];
      return idx > 0 && idx < this.berths.length && !rowItem.ATA && !rowItem.ETB && !prevBerth.ATA && !prevBerth.ETB;
    },
    canMoveDown(rowItem) {
      const idx = this.berths.indexOf(rowItem);
      const nextBerth = this.berths[idx + 1];
      return (
        idx >= 0 && idx < this.berths.length - 1 && !rowItem.ATA && !rowItem.ETB && !nextBerth.ATA && !nextBerth.ETB
      );
    },
    canCalculateBerth(/* rowItem */) {
      return true;
    },

    onMoveCompleted(portCallId, response) {
      if (response.resultCategory == 1 && response.resultType == 2) {
        getBerths(portCallId);
        if (response.syncChanges) {
          if (response.syncChanges.portcall) {
            getPortcallSyncChanges(portCallId);
          }
          if (response.syncChanges.sof) {
            this.portcallStore.clearSof();
          }
          if (response.syncChanges.portcall || response.syncChanges.sof) {
            this.applicationStore.setWhiteBoardDataIsDirty(true);
          }
        }
      }
    },

    rowMenuClicked(event) {
      let id = -1;
      let berthQueryId = '';
      if (event.rowItem.BA_TYPE == 'Anchorage') {
        id = event.rowItem.ANC_ID;
        berthQueryId = `&anchorageId=${id}`;
      } else if (event.rowItem.BA_TYPE == 'Quay') {
        id = event.rowItem.ID;
        berthQueryId = `&quayId=${id}`;
      }

      if (event.menuItem.name == 'move-up') {
        apiPut(`dock/moveup?portCallId=${event.rowItem.PORTCALL_ID || -1}${berthQueryId}`).then((response) => {
          this.onMoveCompleted(event.rowItem.PORTCALL_ID, response);
        });
      } else if (event.menuItem.name == 'move-down') {
        apiPut(`dock/movedown?portCallId=${event.rowItem.PORTCALL_ID || -1}${berthQueryId}`).then((response) => {
          this.onMoveCompleted(event.rowItem.PORTCALL_ID, response);
        });
      }

      if (event.menuItem.name === 'calculate-berth') {
        const invertBerthCalculation = () => {
          const berthCalc = this.value.BERTH_CALCULATION ?? 0;
          if (typeof berthCalc === 'number') {
            if (berthCalc === 0) {
              return 1;
            }
            return 0;
          }
          return berthCalc;
        };
        this.$emit('calculate-berth-clicked', invertBerthCalculation());
        // console.log('CALCULATE BERTH PLZ!', invertBerthCalculation());
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .warning-text {
  color: red;
}

::v-deep table {
  tr {
    td {
      .berth-and-achorage-icon {
        color: $colorStatusAnchoredDark;
      }
    }
  }
}
.v-tooltip__content {
  background: $colorStatusAnchoredDark !important;
  opacity: 1 !important;
}
</style>
