<template>
  <GatGroup
    title="Visitor/Client search results"
    class="pb-2"
    :class="{ uthev: emp }"
    :collapsed="visitors.length > 12">
    <template slot="compact">
      <span v-if="visitors.length > 0">{{ visitors.length }} visitor/client matches</span>
    </template>
    <span v-show="applicationStore.userRight('P.NEW_AND_EDIT')"
      >Click on a visitor in below list to import the data</span
    >
    <gat-grid
      class="searchGrid elevation-0"
      :items="visitors"
      :columns="columns"
      @row-clicked="rowClicked"
      :loading="isLoading"></gat-grid>
  </GatGroup>
</template>

<script>
import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';

export default {
  name: 'VisitorSearch',
  props: {
    visitorName: [String],
    visitorCompany: [String],
    emp: [String, Boolean],
  },
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    return {
      portcallStore,
      applicationStore,
    };
  },
  data() {
    return {
      visitors: [],
      isLoading: false,
    };
  },

  created() {},

  watch: {
    visitorName(newValue) {
      if (newValue && newValue.length >= 3) {
        this.getVisitorsByName(newValue, this.visitorCompany ? this.visitorCompany : '');
      } else {
        this.visitors = [];
      }
    },
    visitorCompany(newValue) {
      if (newValue && newValue.length >= 3) {
        this.getVisitorsByName(this.visitorName ? this.visitorName : '', newValue);
      } else {
        this.visitors = [];
      }
    },
  },

  computed: {
    columns() {
      const result = [];
      const settings = this.applicationStore.getAgencySettings(this.portcallStore.callDetails.SETUP_ID);
      result.push({ header: 'Company', field: 'VIS_COMPANY' });
      result.push({ header: 'Name', field: 'VIS_NAME' });
      result.push({ header: 'Phone', field: 'VIS_PHONE' });
      result.push({ header: 'Other', field: 'VIS_OTHER' });

      for (let i = 1; i <= 6; i++) {
        if (settings[`VIS_USER${i}`]) {
          result.push({ header: settings[`VIS_USER${i}`], field: `VIS_USER${i}` });
        }
      }
      return result;
    },
    agencyId() {
      return this.applicationStore.agencyIdSelected;
    },
  },

  methods: {
    rowClicked(rowItem) {
      this.$emit('visitor-import', rowItem);
    },
    getVisitorsByName(person, company) {
      this.visitors = [];
      this.isLoading = true;
      apiGet(`/visitor/search?agencyId=${this.agencyId}&personName=${person}&companyName=${company}`)
        .then((result) => {
          this.visitors = result;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.uthev {
  background-color: rgba(31, 204, 235, 0.56);
}
.vert-center {
  position: absolute;
  top: 45%;
  left: 45%;
}
</style>
