<template>
  <div v-if="field.BREAK" class="break-flex-new-line" />
  <gat-select
    v-else-if="field.FIELD_TYPE == 9 || field.FIELD_TYPE == 7 || field.FIELD_TYPE == 8"
    v-model="editValue"
    :label="field.CAPTION"
    :items="getComboItems()"
    :disabled="isDisabled"
    :readonly="getReadOnly()"
    :size="getSize()" />
  <div v-else-if="field.FIELD_TYPE == 6"></div>
  <!-- Not supported fields  -->
  <gat-edit
    v-else
    :textValue="size"
    v-model="editValue"
    :label="field.CAPTION"
    :checkbox="field.FIELD_TYPE == 5"
    :dateEdit="field.FIELD_TYPE == 3"
    :timeEdit="field.FIELD_TYPE == 4"
    :dateTimeEdit="field.FIELD_TYPE == 12"
    :dataType="getDataType()"
    :size="getSize()"
    :rows="getTextRows()"
    :disabled="isDisabled"
    :readonly="getReadOnly()"
    :maxChars="getMaxChars()"
    :hint="getHint()"
    :uppercase="getCharCaseUpper()"
    :lowercase="getCharCaseLower()"
    :decimals="getDecimals()"
    :required="getMandatory()" />
</template>

<script>
import { apiGet } from '@/store/api';
import { useHelperStore } from '@/store/helperStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';

export default {
  name: 'GatDynaField',
  props: {
    disabled: Boolean,
    field: Object,
    readonly: Boolean,
    size: String,
    value: [String, Number, Boolean],
    portcallStatus: Number,
  },
  components: {},
  setup() {
    const helperStore = useHelperStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    return {
      portcallStore,
      applicationStore,
      helperStore,
    };
  },
  data() {
    return {
      editValue: null,
    };
  },

  created() {
    this.editValue = this.value;
    if (this.field.FIELD_TYPE == 8) this.getSQLComboItems();
  },

  watch: {
    value(newValue) {
      this.editValue = newValue;
    },

    editValue(newValue) {
      if (this.value != newValue) {
        this.$emit('input', newValue);
      }
    },
  },

  computed: {
    isDisabled() {
      return this.disabled || this.field.FIELD_TYPE === 10;
    },

    isExternalUser() {
      return !this.applicationStore.user.internal;
    },
  },

  methods: {
    // Dynafield sizes: xs, s, m, l, xl
    getSize() {
      const size = this.field.WEB_FIELD_SIZE;
      let result = 'm';
      if (this.compactSize) {
        return this.compactSize;
      }
      if ((size && size == 'xs') || size == 's' || size == 'm' || size == 'l' || size == 'xl') {
        if (size == 'xs') {
          return 'xs6 sm3 md2 lg2 xl1';
        }
        if (size == 's') {
          return 'xs6 sm4 md3 lg3 xl2';
        }
        if (size == 'm') {
          return 'xs12 sm5 md4 lg4 xl3';
        }
        if (size == 'l') {
          return 'xs12 sm6 md6 lg6 xl4';
        }
        if (size == 'xl') {
          return 'xs12 sm12 md12 lg12 xl12';
        }
        return size;
      }
      if (this.field.FIELD_WIDTH >= 400) {
        result = 'l';
      }
      if (this.field.FIELD_WIDTH < 66) {
        result = 's';
      }

      return result;
    },

    // eslint-disable-next-line consistent-return
    getDecimals() {
      if (this.field.FIELD_TYPE == 2) {
        return this.field.DECIMALS || 0;
      }
    },

    getCharCaseUpper() {
      if (this.field.FIELD_TYPE != 2 && this.field.FIELD_TYPE != 3 && this.field.CHAR_CASE == 2) {
        return true;
      }
      return false;
    },

    getCharCaseLower() {
      if (this.field.FIELD_TYPE != 2 && this.field.FIELD_TYPE != 3 && this.field.CHAR_CASE == 0) {
        return true;
      }
      return false;
    },

    getDataType() {
      let result = typeof 'text';
      if (this.field.FIELD_TYPE == 2) {
        result = typeof 0;
      }
      if (this.field.FIELD_TYPE == 3) {
        result = typeof new Date();
      }
      return result;
    },

    getComboItems() {
      const result = [];
      let items = [];

      if (this.field.COMBO_ITEMS) {
        items = this.field.COMBO_ITEMS.split(/\r?\n/);

        // eslint-disable-next-line array-callback-return
        items.map((item) => {
          result.push({ value: item, text: item });
        });
      }
      return result;
    },

    getSQLComboItems() {
      apiGet(`helper/dynafields/dynafieldID/${this.field.ID}`).then((data) => {
        let comboitems = '';
        // eslint-disable-next-line array-callback-return
        data.map((item) => {
          comboitems = `${comboitems}\n${item[Object.keys(item)[0]]}`;
        });
        this.helperStore.setDynaFieldSQLCombo({ ID: this.field.ID, COMBOITEMS: comboitems });
      });
    },
    getHint() {
      return this.field.CAPTION_HINT;
    },

    // eslint-disable-next-line consistent-return
    getTextRows() {
      if (this.field.FIELD_TYPE == 11) {
        const rows = Math.round(this.field.FIELD_HEIGHT / 25);
        return rows;
      }
    },

    getMaxChars() {
      if (this.field.MAX_CHARS != undefined) return this.field.MAX_CHARS;
      if (
        this.field.FIELD_TYPE == 1 ||
        this.field.FIELD_TYPE == 7 ||
        this.field.FIELD_TYPE == 10 ||
        this.field.FIELD_TYPE == 12
      )
        return 4000; // Highet value found in this.applicationStore.varcharColumns.
      return undefined;
    },
    getMandatory() {
      let result = false;
      const portcallStatus = this.portcallStatus || this.portcallStore.callDetails.STATUS;
      if (this.field.MANDATORY == 1) {
        // Always
        result = true;
      }
      if (this.field.MANDATORY == 2) {
        // When anchored, berthed or sailed
        result = portcallStatus == 1 || portcallStatus == 2 || portcallStatus == 3;
      }
      if (this.field.MANDATORY == 3) {
        // When berthed or sailed
        result = portcallStatus == 2 || portcallStatus == 3;
      }
      if (this.field.MANDATORY == 4) {
        // When sailed
        result = portcallStatus == 3;
      }
      return result;
    },

    // getMaxChars(){
    //   let maxChars = this.field.MAX_CHARS;
    //   if(maxChars){
    //     return maxChars
    //   }
    // },

    getReadOnly() {
      let result = false;
      if (this.isExternalUser && this.field.WEB_EXTERNAL_USER_ACCESS == 1) {
        result = true;
      }
      if (this.readonly) {
        result = true;
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.break-flex-new-line {
  flex-basis: 100%;
  width: 0px;
  height: 0px;
  overflow: hidden;
}
</style>
