const Dashboard = () => import("@/views/dashboard/Dashboard.vue");
const DashboardGrid = () =>
  import("@/modules/dashboard/components/DashboardGrid.vue");

const dashboardRoutes = [
  {
    path: "/dashboard",
    component: Dashboard,
    props: (route) => ({
      groupName: route.params.groupName,
      location: "main",
    }),
    children: [
      /* {path:'', name:'allDashboards', meta: {pageTitle: 'Dashboard'}, component:DashboardGrid, props:(route) => ({groupName:route.params.groupName || 'all', location: 'main'})}, */
      {
        path: ":groupName",
        name: "DashboardGroup",
        meta: { pageTitle: "Dashboard" },
        component: DashboardGrid,
        props: (route) => ({
          groupName: route.params.groupName,
          location: "main",
        }),
      },
    ],
  },
];
export default dashboardRoutes;
