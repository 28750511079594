import { apiDelete } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function deleteExpense(id: string | number) {
  const portcallStore = usePortcallStore();
  const result = await apiDelete(`expense/${id}`);
  if (result.resultCategory == 1) {
    portcallStore.removeExpenseItem(id);
  }
}
