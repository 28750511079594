<template>
  <div class="ma-0">
    <gat-grid
      title="Cargo"
      class="elevation-0"
      :items="items"
      :columns="columns"
      reloadBtn
      :fixedheader="true"
      height="calc(100vh - 135px)"
      :hideEmptyColumns="true"
      viewGroupName="callCargoGrid"
      @reload-clicked="getCargoLines(callId)"
      @row-clicked="gotoCargoItem"
      selectable
      keyName="ID"
      @selectionChanged="cargoSelected($event)">
      <template
        slot="cell"
        slot-scope="props"
        v-if="
          props.column.field == 'AMOUNT_WEIGHT' ||
          props.column.field == 'AMOUNT_GROSS_WEIGHT' ||
          props.column.field == 'OUTTURN_WEIGHT' ||
          props.column.field == 'AMOUNT_MEASUREMENT'
        ">
        {{ formatCargoWeight(props.value, props.row.CODE, props.row.DECIMALS) }}
      </template>

      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="[{ btnType: 'ADD', userRight: 'CA.NEW_AND_EDIT' }]"
          @addClick="$router.push('new-cargo-item')" />
      </template>
    </gat-grid>
  </div>
</template>

<script>
import numeral from 'numeral';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
// eslint-disable-next-line import/no-cycle
import { getCargoItems } from '@/services/api/api-calls/getCargoItems';
import { DateTime } from 'luxon';

export default {
  name: 'CargoList',
  props: {
    callId: [String, Number],
    refresh: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup() {
    const appStatusStore = useAppStatusStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      portcallStore,
      applicationStore,
      appStatusStore,
    };
  },
  data() {
    return {
      selectedCargoIds: [],
    };
  },

  computed: {
    agencyId() {
      return this.portcallStore.callDetails.SETUP_ID;
    },
    items() {
      return this.portcallStore.cargo;
    },
    columns() {
      const result = [];
      result.push({ header: 'No', field: 'REF_NO', hideOnPhone: true, footerFunction: { fn: 'count' } });
      result.push({ header: 'Cargo type', field: 'NAME', hideOnPhone: true });
      result.push({ header: 'Description', field: 'BL_TEXT' });
      result.push({ header: 'Action', field: 'ACTION' });
      result.push({ header: 'Net weight', field: 'AMOUNT_WEIGHT', footerFunction: { fn: 'sum' }, dataType: 'number' });
      result.push({
        header: 'Gross weight',
        field: 'AMOUNT_GROSS_WEIGHT',
        footerFunction: { fn: 'sum' },
        hideOnPhone: true,
        dataType: 'number',
      });
      result.push({
        header: 'Outturned weight',
        field: 'OUTTURN_WEIGHT',
        footerFunction: { fn: 'sum' },
        hideOnPhone: true,
        dataType: 'number',
      });
      result.push({
        header: 'Handled',
        field: 'SOF_QUANT',
        footerFunction: { fn: 'sum' },
        hideOnPhone: true,
        dataType: 'number',
      });

      result.push({
        header: 'Measurement',
        field: 'AMOUNT_MEASUREMENT',
        footerFunction: { fn: 'sum' },
        hideOnPhone: true,
        dataType: 'number',
      });
      result.push({ header: 'Marks No', field: 'MARKS_NUMBER', hideOnPhone: true });
      result.push({
        header: 'No. of packs',
        field: 'NO_OF_PACKS',
        footerFunction: { fn: 'sum' },
        hideOnPhone: true,
        dataType: 'number',
      });
      result.push({ header: 'Packages type', field: 'PTNAME', hideOnPhone: true });
      result.push({ header: 'Size of packs', field: 'SIZE_OF_PACKS', hideOnPhone: true });
      result.push({ header: 'Quay', field: 'QUAYNAME', hideOnPhone: true });

      const consignee = { header: 'Consginee', hideOnPhone: true };
      if (!this.applicationStore.user.internal) {
        consignee.field = 'ClientName';
      } else {
        consignee.linkUrl = '/client/field[ConsigneeId]';
        consignee.linkText = 'field[ClientName]';
      }

      result.push(consignee);
      result.push({ header: 'L/D port', field: 'LDPORT', hideOnPhone: true });
      result.push({ header: 'IMO H. No', field: 'IMO_NUMBER', hideOnPhone: true });
      result.push({ header: 'UN No', field: 'UN_NUMBER', hideOnPhone: true });

      result.push({
        header: this.applicationStore.getAgencySettings(this.agencyId).CARGO_USER_TEXT_1,
        field: 'USER_1',
        hideOnPhone: true,
      });
      result.push({
        header: this.applicationStore.getAgencySettings(this.agencyId).CARGO_USER_TEXT_2,
        field: 'USER_2',
        hideOnPhone: true,
      });
      result.push({
        header: this.applicationStore.getAgencySettings(this.agencyId).CARGO_USER_TEXT_3,
        field: 'USER_3',
        hideOnPhone: true,
      });
      result.push({
        header: this.applicationStore.getAgencySettings(this.agencyId).CARGO_USER_TEXT_4,
        field: 'USER_4',
        hideOnPhone: true,
      });
      if (this.applicationStore.userRight('SOF.ACCESS')) {
        result.push({
          header: 'SOF',
          linkUrl: `/call/${this.callId}/sof/field[SOF_ID]`,
          linkText: 'field[SOFTITLE]',
          hideOnPhone: true,
        });
      }

      result.push({ header: 'Sort', field: 'SORT_ORDER', hideOnPhone: true });

      // dynafields columns
      for (let index = 0; index < this.helperStore.dynafields.length; index++) {
        const dynafield = this.helperStore.dynafields[index];
        if (dynafield.USE_IN_GRID && dynafield.COMPONENT_NAME == 'pDynaFields.CargoDyna') {
          const field = `dyn_${dynafield.ID}`;
          const col = { header: dynafield.CAPTION, field, hideOnPhone: true };
          if (dynafield.FIELD_TYPE == 2) {
            col.dataType = 'number';
            col.maxDecimals = dynafield.DECIMALS;
          }
          if (dynafield.FIELD_TYPE === 3) {
            col.dataType = 'date';
            col.calculated = (row) => {
              const value = row[field];
              if (value && typeof value === 'string') {
                const dt = DateTime.fromFormat(value, 'dd.MM.yyyy HH:mm:ss');
                if (dt.isValid) {
                  return dt.toISODate();
                }
              }
              return value;
            };
          }
          result.push(col);
        }
      }
      return result;
    },
  },

  created() {
    this.getCargoLines(this.callId);
  },

  watch: {
    callId: {
      handler(val) {
        this.getCargoLines(val);
      },
      immediate: true,
    },
    refresh: {
      handler(val) {
        if (val) {
          this.getCargoLines(this.callId);
        }
      },
      immediate: true,
    },
  },

  methods: {
    addCargoItem() {
      this.$router.push({ name: 'cargoItem', params: { isNew: true } });
    },
    formatCargoWeight(weight, unitCode, decimals) {
      if (numeral(weight).value() === 0 || weight == '') {
        return '';
      }
      let result = numeral(weight).format(`0,0.${'00000000000000000'.substr(1, decimals)}`);
      if (unitCode) {
        result = `${result} ${unitCode}`;
      }

      return result;
    },

    getCargoLines(portcall_id) {
      getCargoItems(portcall_id);
    },

    gotoCargoItem(rowItem) {
      //  this.$router.push('cargo-item/'+rowItem.ID);
      this.$router.push({ name: 'cargoItem', params: { cargoId: rowItem.ID } });
    },
    cargoSelected(cargoIds) {
      this.selectedCargoIds = cargoIds;
      this.appStatusStore.setAppStatusSelectedCargoIds(cargoIds);
    },
  },
};
</script>

<style scoped></style>
