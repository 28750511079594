// Pinia Store
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useToolbarStore = defineStore('toolbarStore', () => {
  const title = ref('');
  const tabs = ref<any>(undefined);
  const backNavigation = ref<any[]>([]);
  const useToolbarNavBtns = ref(false);
  const navBtnName = ref('');
  const peviousNavBtnState = ref({ name: '', show: false });

  function setBackNavigation(data: any[] | undefined) {
    if (Array.isArray(data)) {
      backNavigation.value = data;
    } else if (data === undefined) {
      backNavigation.value = [];
    }
  }
  function setToolbarNavBtns(data: any) {
    if (data.previousState) {
      useToolbarNavBtns.value = peviousNavBtnState.value.show;
      navBtnName.value = peviousNavBtnState.value.name;
    } else {
      useToolbarNavBtns.value = data.show;
      navBtnName.value = data.name;
      if (!data.doNotRegisterState) {
        peviousNavBtnState.value = data;
      }
    }
  }
  function setToolbarTitle(newValue: string) {
    title.value = newValue;
  }
  function setToolbarTitleWithSecondary(data: any) {
    let result = data.title;
    if (data.secondary) {
      result = `${data.title} (${data.secondary})`;
    }
    title.value = result;
  }

  function setToolbarTabs(value: any) {
    tabs.value = value;
  }

  return {
    title,
    tabs,
    backNavigation,
    useToolbarNavBtns,
    navBtnName,
    peviousNavBtnState,
    setBackNavigation,
    setToolbarNavBtns,
    setToolbarTitle,
    setToolbarTitleWithSecondary,
    setToolbarTabs,
  };
});
