<template>
  <div>
    <v-layout wrap>
      <gat-edit dateTimeEdit label="From" v-model="fromDateTime"
        :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'"></gat-edit>
      <gat-edit dateTimeEdit label="To" v-model="toDateTime" :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'"></gat-edit>
      <gat-multi-select v-model="selectedAgencies" :items="agencies" label="Agencies" textFieldName="GROUP_NAME"
        codeFieldName="ID" :disabled="agencies.length === 1" :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'">
      </gat-multi-select>
      <gat-multi-select v-model="selectedPortcallStatuses" :items="portcallStatuses" label="Port call statuses"
        textFieldName="STATUS" codeFieldName="ID" :disabled="portcallStatuses.length === 1"
        :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'">
      </gat-multi-select>
      <gat-multi-select v-model="selectedActivityTypes" :items="activityTypes" label="Activity types"
        textFieldName="GROUP" codeFieldName="GROUP" :disabled="activityTypes.length === 1"
        :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'">
      </gat-multi-select>
      <v-radio-group label="Activities:" v-model="showAllActivities" row dense
        :size="$vuetify.breakpoint.xs ? 'xs12' : 'xs6'">
        <v-radio label="All" v-bind:value="true" @click="doSearch()"></v-radio>
        <v-radio label="My" v-bind:value="false" @click="doSearch()"></v-radio>
      </v-radio-group>
      <v-btn class="searchBtn primary mr-3 mt-3 adjusright" @click="doSearch()"
        :disabled="!selectedAgencies.length">Search</v-btn>
    </v-layout>
    <gat-grid :columns="columns" :items="allActivities" dense selectable :loading="isLoading" :rowBar="true"
      :rowBarClass="getRowBarClass" :options="{
        checkboxIsHidden,
      }" viewGroupName="activityView" title="Activities" keyName="ID" @selectionChanged="selectionChanged($event)"
      @row-clicked="goToActivity" noSelectAll>
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'iconIndx'">
          <v-icon>{{ getIconName(props.value) }}</v-icon>
          <span> {{ props.value }}</span>
        </div>
        <div v-else-if="props.column.field == 'TaskDescripion'">
          <span :class="getActivityUrl(props) ? 'gridMainLink' : ''">{{ props.value }}</span>
        </div>
        <div v-else-if="props.column.header == 'Port call'">
          <a v-if="props.row.PortcallNumber" :href="'call/' + props.row.CallID" class="gridLink">{{ props.row.VesselName
          }} ({{ props.row.PortcallNumber }})</a>
        </div>
      </template>
      <template slot="btns">
        <wm-button-group class="right mr-2" :buttons="getButtons" @markDoneClick="markActivityAsDone()" />
      </template>
    </gat-grid>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiPut } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { getPendingActivities } from '@/services/api/api-calls/getPendingActivities';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import portcallStatusesEnum from './EPortcallStatuses';

export default {
  name: 'ActivityList',
  props: [],
  components: {},
  setup() {
    const constantsStore = useConstantsStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      constantsStore,
    };
  },
  data() {
    return {
      showAllActivities: false,
      fromDateTime: null,
      toDateTime: null,
      selectedAgencies: [],
      isLoading: false,
      allActivities: [],
      selectedActivities: [],
      portcallStatuses: portcallStatusesEnum,
      selectedPortcallStatuses: [], // TODO: save these values, load these values
      activityTypesSet: new Set(),
      activityTypes: [].map((g) => ({ GROUP: g })),
      selectedActivityTypes: [], // TODO: save these values, load these values
    };
  },

  watch: {
    agencies() {
      if (this.selectedAgencies.length === 0) {
        this.setDefaultFilterValues().then(() => {
          this.doSearch();
        });
      }
    },
    // fromDateTime: {
    //   handler(from) {
    //     this.saveUserChoice('fromDateTime', from)
    //   },
    //   immediate: false,
    // },
    // toDateTime: {
    //   handler(to) {
    //     this.saveUserChoice('toDateTime', to)
    //   },
    //   immediate: false,
    // },
    showAllActivities: {
      handler(state) {
        this.saveUserChoice('showAllActivities', state)
      },
      immediate: false,
    },
    selectedAgencies: {
      handler(agencyIds) {
        this.saveUserChoice('selectedAgencies', agencyIds?.join(','))
      },
      immediate: false,
    },
    selectedPortcallStatuses: {
      handler(pcStatuses) {
        this.saveUserChoice('selectedPortcallStatuses', pcStatuses?.join(','))
      },
      immediate: false,
    },
    selectedActivityTypes: {
      handler(activityTypes) {
        this.saveUserChoice('selectedActivityTypes', activityTypes?.join(','))
      },
      immediate: false,
    },
  },

  created() {
    this.setDefaultFilterValues().then(() => {
      this.doSearch();
    })
  },

  computed: {
    columns() {
      const result = [];
      result.push({ field: 'iconIndx', nowrap: true });
      result.push({ header: 'Time', field: 'TaskTime', dataType: 'datetime' });
      // result.push({ header: 'Type', field: 'TaskGroup' });
      // result.push({"header":"Port call status","field":"STATUS"});
      result.push({ header: 'Port call', nowrap: true });
      result.push({ header: 'Task / Activity', field: 'TaskDescripion', nowrap: true });
      // result.push({"header":"Harbour","field":"Harbour","nowrap":true});
      result.push({ header: 'Responsible', field: 'Responsible', nowrap: true });
      result.push({ header: 'Agency', field: 'AgencyGroup', nowrap: true });
      return result;
    },
    agencies() {
      return this.applicationStore.agencySettings;
    },
    getButtons() {
      return [
        {
          btnType: 'CUSTOM',
          icon: 'mdi-check',
          caption: 'Mark as done',
          mainAction: true,
          eventName: 'markDoneClick',
          disabled: !this.canMarkAsDone,
        },
      ];
    },
    canMarkAsDone() {
      if (!this.selectedActivities.length) {
        return false;
      }

      return this.selectedActivities.every(act => this.userHasRightsToEdit(act.iconIndx))
    },
  },

  methods: {
    async saveUserChoice(activityParamName, savedInfoValue) {
      const savedInfo = {
        StrValue: savedInfoValue,
        TypeName: `wm_activity_list.${activityParamName}`,
      };
      await apiPost('savedinfo', savedInfo)
    },
    checkboxIsHidden(item) {
      if (item.ID) {
        return false;
      }
      return true;
    },
    async setDefaultFilterValues() {
      const userSettings = await apiGet('savedinfo/wm_activity_list.')

      this.showAllActivities = userSettings.find(us => us.TYPE_NAME === 'wm_activity_list.showAllActivities')?.MEMOVALUE1 === 'true'

      const selectedActivityTypeSetting = userSettings.find(us => us.TYPE_NAME === 'wm_activity_list.selectedActivityTypes')?.MEMOVALUE1
      this.selectedActivityTypes = selectedActivityTypeSetting ? selectedActivityTypeSetting.split(',') : []

      const portcallStatusSetting = userSettings.find(us => us.TYPE_NAME === 'wm_activity_list.selectedPortcallStatuses')?.MEMOVALUE1
      this.selectedPortcallStatuses = portcallStatusSetting ? portcallStatusSetting.split(',').map(v => +v) : []

      const selectedAgencySetting = userSettings.find(us => us.TYPE_NAME === 'wm_activity_list.selectedAgencies')?.MEMOVALUE1
      this.selectedAgencies = selectedAgencySetting ? selectedAgencySetting.split(',').map(v => +v) : this.agencies.map(a => a.ID)

      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      tomorrow.setHours(23, 59, 59, 59);
      this.fromDateTime = GlobalHelperFunctions.globalFormatDate(today, 'YYYY-MM-DDTHH:mm:ss');
      this.toDateTime = GlobalHelperFunctions.globalFormatDate(tomorrow, 'YYYY-MM-DDTHH:mm:ss');

      // const fromDateSetting = userSettings.find(us => us.TYPE_NAME === 'wm_activity_list.fromDateTime')?.MEMOVALUE1
      // if (fromDateSetting) {
      //   this.fromDateTime = fromDateSetting
      // } else {
      //   this.fromDateTime = GlobalHelperFunctions.globalFormatDate(today, 'YYYY-MM-DDTHH:mm:ss');
      // }

      // const tomorrow = new Date(this.fromDateTime);
      // tomorrow.setDate(tomorrow.getDate() + 1);
      // tomorrow.setHours(23, 59, 59, 59);
      // const toDateSetting = userSettings.find(us => us.TYPE_NAME === 'wm_activity_list.toDateTime')?.MEMOVALUE1
      // if (toDateSetting) {
      //   this.toDateTime = toDateSetting
      // } else {
      //   this.toDateTime = GlobalHelperFunctions.globalFormatDate(tomorrow, 'YYYY-MM-DDTHH:mm:ss');
      // }
    },
    // eslint-disable-next-line consistent-return
    async doSearch() {
      if (this.selectedAgencies.length) {
        this.isLoading = true;

        let queryString = `?FromDate=${this.fromDateTime}&ToDate=${this.toDateTime}&ShowAllActivities=${this.showAllActivities}`;
        for (let i = 0; i < this.selectedAgencies.length; i++) {
          queryString += `&Agency=${this.selectedAgencies[i]}`;
        }

        const response = await apiGet(`activity/search${queryString}`)
        const allActivities = GatUtils.makeCopyViaJson(response)
        allActivities.map(g => this.activityTypesSet.add(g.iconIndx)) // only store the unique task type
        this.activityTypes = Array.from(this.activityTypesSet.values()).map((g) => ({ GROUP: g }))
        this.allActivities = allActivities
          .filter(a => this.selectedPortcallStatuses.length === 0 || this.selectedPortcallStatuses.includes(a.STATUS)) // filter by portcall status if selected
          .filter(a => this.selectedActivityTypes.length === 0 || this.selectedActivityTypes.includes(a.iconIndx))
        await getPendingActivities();
        this.isLoading = false;
      }
    },
    getRowBarClass(rowData) {
      let result = '';
      if (rowData) {
        const statusName = GlobalHelperFunctions.lookupCode(rowData.STATUS, this.constantsStore.portcallStatus);
        if (statusName) {
          result += ` pc-status-${statusName.toLowerCase()}-bg`;
        }
      }
      return result;
    },
    getStatusName(statusCode) {
      return GlobalHelperFunctions.lookupCode(statusCode, this.constantsStore.portcallStatus);
    },
    getIconName(icnValue) {
      switch (icnValue) {
        case 'Transportation':
          return 'mdi-bus';
        case 'Transport':
          return 'mdi-bus';
        case 'Service':
          return 'mdi-handshake';
        case 'Vessel':
          return 'mdi-ferry';
        case 'Checklist':
          return 'mdi-beaker-check';
        case 'Accommodation':
          return 'mdi-bed-empty';
        case 'Visitor':
          return 'mdi-account-tie';
        case 'Client':
          return 'mdi-account-multiple';
        case 'Enquiry':
          return 'mdi-human-greeting';
        default:
          return icnValue;
      }
    },
    getLastWhiteboardId() {
      let whiteboardId = 0;
      apiGet('savedinfo/web_last_whiteboard').then((data) => {
        if (data.length > 0) {
          whiteboardId = data[0].MEMOVALUE1;
        }
      });
      return whiteboardId;
    },
    async markActivityAsDone() {
      const selectedActivitiesGroup = this.selectedActivities.reduce((prev, next) => {
        // eslint-disable-next-line no-param-reassign
        prev[next.iconIndx] = prev[next.iconIndx] || [];
        prev[next.iconIndx].push(next);
        return prev
      }, [])

      const promises = [];
      Object.entries(selectedActivitiesGroup).forEach(([taskType, activities]) => {
        const ids = [];
        activities.forEach((activity) => {
          ids.push(activity.ID.replace(/[^0-9]/g, ''));
        });

        let queryString = `?id=${ids[0]}`;
        for (let i = 1; i < ids.length; i++) {
          queryString += `&id=${ids[i]}`;
        }

        promises.push(apiPut(`/activity/${taskType}${queryString}`))
      })

      await Promise.allSettled(promises);
      await this.doSearch();
    },
    selectionChanged(event) {
      this.selectedActivities = GatUtils.makeCopyViaJson(this.allActivities);
      this.selectedActivities = this.selectedActivities.filter((act) => event.includes(act.ID));
    },
    userHasRightsToEdit(taskType) {
      switch (taskType) {
        case 'Transportation':
          return false;
        case 'Transport':
          return false;
        case 'Service':
          return this.applicationStore.userRight('SER.EDIT');
        case 'Vessel':
          return false;
        case 'Checklist':
          return this.applicationStore.userRight('CH.NEW_AND_EDIT');
        case 'Accommodation':
          return false;
        case 'Client':
          return this.applicationStore.userRight('C.EDIT');
        case 'Enquiry':
          return this.applicationStore.userRight('C.EDIT');
        default:
          return false;
      }
    },
    goToActivity(activity) {
      let activityUrl = ''
      switch (activity.iconIndx) {
        case 'Transportation':
          activityUrl = this.applicationStore.userRight('CRCH.ACCESS') ? `call/${activity.CallID}/crewchange` : '';
          break
        case 'Transport':
          activityUrl = '';
          break
        case 'Service':
          activityUrl = `/call/${activity.CallID}/service/${activity.ItemId}`;
          break
        case 'Vessel':
          activityUrl = '';
          break
        case 'Checklist':
          activityUrl = `/call/${activity.CallID}/checklist/${activity.ItemId}`;
          break
        case 'Accommodation':
          activityUrl = this.applicationStore.userRight('CRCH.ACCESS') ? `call/${activity.CallID}/crewchange` : '';
          break
        case 'Client':
          activityUrl = `/client/${activity.ItemId}/followup`;
          break
        case 'Enquiry':
          activityUrl = '';
          break
        case 'Visitor':
          activityUrl = `/call/${activity.CallID}/visitor-item/${activity.ItemId}`;
          break
        default:
          activityUrl = '';
          break
      }
      if (activityUrl) {
        this.$router.push(activityUrl);
      }
    },
    getActivityUrl(props) {
      switch (props.row.iconIndx) {
        case 'Transportation':
          return this.applicationStore.userRight('CRCH.ACCESS') ? `call/${props.row.CallID}/crewchange` : false;
        case 'Transport':
          return false;
        case 'Service':
          return `/call/${props.row.CallID}/service/${props.row.ItemId}`;
        case 'Vessel':
          return false;
        case 'Checklist':
          return `/call/${props.row.CallID}/checklist/${props.row.ItemId}`;
        case 'Accommodation':
          return this.applicationStore.userRight('CRCH.ACCESS') ? `call/${props.row.CallID}/crewchange` : false;
        case 'Client':
          return `/client/${props.row.ItemId}`;
        case 'Enquiry':
          return false;
        default:
          return false;
      }
    },
  },
};
</script>

<style scoped></style>
