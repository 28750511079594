<template>
  <v-dialog v-model="dialogVisible" persistent max-width="600">
    <v-card class="pa-3" v-show="!deleteDialog">
      <v-layout wrap>
        <GatSubGroup title="Client connected to vessel" size="xs12">
          <gat-select
            v-model="role.VHO_ROLE_ID"
            :items="roles"
            label="Role"
            textFieldName="NAME"
            codeFieldName="ID"
            :autofocus="true"
            :errorMsg="roleSelectErrors"
            size="xs8">
          </gat-select>
          <gat-edit
            v-model="role.VHO_RESTRICT_CURRENT_ROLE"
            label="Restrict to role"
            checkbox
            size="xs4"
            :checkedValue="1" />
          <gat-client-select
            v-model="role.VHO_CLIENT_ID"
            size="xs8"
            customItem
            showClientWarning
            showClientCreditLimit />
          <gat-edit
            v-model="role.VHO_DEFAULT_PORTCALL_HOTLIST"
            label="Copy to call"
            checkbox
            size="xs4"
            :checkedValue="1" />
        </GatSubGroup>
      </v-layout>
      <v-card-actions>
        <v-btn text @click="deleteDialog = true" :disabled="readonly">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="close('cancel')">Cancel</v-btn>
        <v-btn color="success" @click="close('save')" :disabled="readonly">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="deleteDialog">
      <v-card-title class="title">Remove this client from current vessel?</v-card-title>
      <!-- <v-card-text>If the consent has been withdrawn you should use the legal base value "Whithdawn"</v-card-text> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            deleteDialog = false;
            close('delete');
          "
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import GatClientSelect from '@/app-components/GatClientSelect.vue';
import { useConstantsStore } from '@/store/constantsStore';
import { useHelperStore } from '@/store/helperStore';

export default {
  name: 'VesselClientRole',
  props: ['value', 'showForm', 'readonly'],
  components: { GatClientSelect },
  setup() {
    const constantsStore = useConstantsStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      constantsStore,
    };
  },
  data() {
    return {
      dialogVisible: false,
      deleteDialog: false,
      role: {},
      roleSelectErrors: [],
    };
  },

  mounted() {
    this.role = GatUtils.makeCopyViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value(newValue) {
      this.role = GatUtils.makeCopyViaJson(newValue);
    },
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {
    roles() {
      return this.helperStore.clientTypes;
    },

    rankings() {
      return this.constantsStore.clientTypeRanking;
    },
  },

  methods: {
    close(btn) {
      if (btn == 'cancel' || this.formIsValid()) {
        this.dialogVisible = false;
        this.roleSelectErrors = [];
        this.$emit('close', { btn, value: this.role });
      }
    },

    formIsValid() {
      let result = true;
      if (!this.role.VHO_ROLE_ID) {
        result = false;
        this.roleSelectErrors = ['Required'];
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
