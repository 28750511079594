import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },

  theme: {
    themes: {
      light: {
        primary: '#FB8C00',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
      },
      // dark: {
      //   // primary: '#424242',
      //   primary:"orange darken-2",
      //   secondary: '#b0bec5',
      //   accent: '#8c9eff',
      //   error: '#b71c1c',
      // },
    },
  },
});
