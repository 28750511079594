import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { useGatComponentsStore } from '@/store/gatComponentsStore';

export async function getAppSettings() {
  const applicationStore = useApplicationStore();
  const gatComponentsStore = useGatComponentsStore();
  const data = await apiGet('savedinfo/web-app-settings');

  if (data.length > 0) {
    const result = JSON.parse(data[0].MEMOVALUE1);
    if (result.numberFormat) {
      applicationStore.setNumberFormat(result.numberFormat);
    }
    if (result.weekStartDay) {
      // GAT COMPONENTS STORE! (REMOVE THIS COMMENT AFTER IMPLEMENTATION)
      gatComponentsStore.setWeekStartDay(result.weekStartDay);
    }
    if (result.weekNumberInCalendar) {
      // GAT COMPONENTS STORE! (REMOVE THIS COMMENT AFTER IMPLEMENTATION)
      gatComponentsStore.setWeekNumberInCalendar(result.weekNumberInCalendar);
    }
    if (result.dateFormat) {
      applicationStore.setDateFormat(result.dateFormat);
    }
  }
}
