import { useApplicationStore } from '@/store/applicationStore';

export function waitForUserData() {
  const applicationStore = useApplicationStore();
  return new Promise<void>((resolve) => {
    if (applicationStore.user.loaded) {
      resolve();
    } else {
      if (applicationStore.debugMode) {
        console.log('waiting for userdata...');
      }
      window.setTimeout(() => {
        waitForUserData().then(() => {
          resolve();
        });
      }, 300);
    }
  });
}
