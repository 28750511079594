import { render, staticRenderFns } from "./GatCountryFlag.vue?vue&type=template&id=072c08b7&scoped=true&"
import script from "./GatCountryFlag.vue?vue&type=script&lang=js&"
export * from "./GatCountryFlag.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "072c08b7",
  null
  
)

export default component.exports