<template>
  <div class="mr-1" v-if="showAgencySelect">
    <div v-if="applicationStore.userRight('G.ACCESS_ALL_AGENCIES')">
      <v-menu open-on-hover offset-y :max-height="getMaxHeight" :min-height="minHeight" v-resize="onResize">
        <template v-slot:activator="{ on, attrs }">
          <div v-on="on" v-bind="attrs">
            <v-icon class="mr-1 agencyIcon">mdi-home</v-icon>
            <span class>{{ agencyName }}</span>
            <v-icon class="ml-1">mdi-menu-down</v-icon>
          </div>
        </template>

        <v-list>
          <v-list-item v-for="item in agencies" :key="item.value" link @click="$emit('update:agency_id', item.value)">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <gat-select
        v-if="false && !this.$vuetify.breakpoint.xs && applicationStore.user.internal"
        :value="agency_id"
        :items="agencies"
        label="Agency"
        style="max-width: 300px; z-index: 1"
        class="mt-4"
        @input="$emit('update:agency_id', $event)" />
    </div>
    <div v-else>
      <v-icon class="mr-1 agencyIcon">mdi-home</v-icon>
      <span class>{{ agencyName }}</span>
    </div>
  </div>
</template>

<script>
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'AgencySelector',
  props: {
    agencies: Array,
    agency_id: Number,
  },
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      minHeight: 60,
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },
  mounted() {
    this.onResize();
  },

  created() {},

  watch: {},

  computed: {
    getMaxHeight() {
      let result = 0;
      result = this.windowSize.y - 60;
      if (result < this.minHeight) {
        return this.minHeight;
      }
      return result;
    },
    showAgencySelect() {
      if (this.applicationStore.user.internal && this.agencies && this.agencies.length > 1) {
        if (this.$vuetify.breakpoint.xs) {
          return this.$route.name === 'PortCalls';
        }
        return true;
      }
      return false;
    },
    agencyName() {
      let result = '';
      if (this.agency_id && this.agencies && this.agencies.length > 0) {
        const agency = this.agencies.find((item) => item.value === this.agency_id);
        if (agency) {
          result = agency.text;
        }
      }
      return result;
    },
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>

<style scoped>
.agencyIcon {
  position: relative;
  top: -2px;
}
</style>
