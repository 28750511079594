// Pinia Store
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { usePortcallStore } from './portcallStore';

export const useAppStatusStore = defineStore('appStatusStore', () => {
  const PortCall = ref<any>(undefined);
  const VesselId = ref<any>(undefined);
  const SetupID = ref<any>(undefined);
  const selectedSofId = ref<any>(undefined);
  const ServiceId = ref<any>(undefined);
  const selectedProspectId = ref<any>(undefined);
  const expense = ref<any>({
    selectedIds: [],
    selectedClientId: undefined,
  });
  const ServiceSelectedIds = ref<any[]>([]);
  const VisitorIds = ref<any[]>([]);
  const AttendanceSelectedIds = ref<any[]>([]);
  const CcmPersonIds = ref<any[]>([]);
  const CcmTaskIds = ref<any[]>([]);
  const SelectedCargoIds = ref<any[]>([]);

  function setPortcallApplicationStatus(payload: any) {
    const portcall: any = {};
    if (payload.ID) {
      portcall.Id = payload.ID;
    }
    PortCall.value = portcall;
  }

  function setAppStatusSetupId(payload: any) {
    if (payload.SETUP_ID) {
      SetupID.value = payload.SETUP_ID;
    }
  }

  function setAppStatusVesselId(payload: any) {
    if (payload.VESSEL_ID) {
      VesselId.value = payload.VESSEL_ID;
    }
  }

  function setAppStatusSelectedSofId(payload: any) {
    selectedSofId.value = payload;
  }

  function setAppStatusSelectedProspectId(payload: any) {
    selectedProspectId.value = payload;
  }

  function setAppStatusExpenseClientId(payload: any) {
    expense.value.selectedClientId = payload;
  }

  function setAppStatusExpenseSelectedIds(payload: any) {
    expense.value.selectedIds = payload;
  }

  function setAppStatusServiceSelectedIds(payload: any) {
    ServiceSelectedIds.value = payload;
  }

  function setAppStatusServiceId(payload: any) {
    ServiceId.value = payload;
  }

  function setAppStatusVisitorSelectedIds(payload: any[]) {
    VisitorIds.value = payload;
  }

  function setAppStatusAttendanceSelectedIds(payload: any[]) {
    AttendanceSelectedIds.value = payload;
  }
  function setAppStatusCcmPersonIds(payload: any[]) {
    CcmPersonIds.value = payload;
  }
  function setAppStatusCcmTaskIds(payload: any[]) {
    CcmTaskIds.value = payload;
  }
  function setAppStatusSelectedCargoIds(payload: any[]) {
    SelectedCargoIds.value = payload;
  }

  function resetAppStatusSelection() {
    setAppStatusSelectedSofId(undefined);
    setAppStatusSelectedProspectId(undefined);
    setAppStatusExpenseClientId(undefined);
    setAppStatusExpenseSelectedIds([]);
    setAppStatusServiceSelectedIds([]);
    setAppStatusVisitorSelectedIds([]);
    setAppStatusAttendanceSelectedIds([]);
    setAppStatusCcmPersonIds([]);
    setAppStatusSelectedCargoIds([]);
  }

  function createApplicationStatusWhenPortcallIsLoaded(payload: any) {
    // console.log("createApplicationStatusWhenPortcallIsLoaded:", payload);
    setPortcallApplicationStatus(payload);
    setAppStatusSetupId(payload);
    setAppStatusVesselId(payload);
    resetAppStatusSelection();
  }

  const getAppStatus = computed(() => {
    const appStatusResult: any = {};
    const portcallStore = usePortcallStore();
    if (PortCall.value) {
      appStatusResult.PortCall = { ...PortCall.value };
      // SofId
      if (selectedSofId.value) {
        appStatusResult.PortCall.SofId = selectedSofId.value;
      } else {
        const sofId = portcallStore.getFirstSofTitleId;
        if (sofId) {
          appStatusResult.PortCall.SofId = sofId;
        }
      }
      if (selectedProspectId.value) {
        appStatusResult.PortCall.ProspectsId = selectedProspectId.value;
      }
      if (ServiceId.value) {
        appStatusResult.PortCall.ServiceId = ServiceId.value;
      }
      // expense selected Id's
      if (Array.isArray(expense.value.selectedIds) && expense.value.selectedIds.length > 0) {
        appStatusResult.PortCall.Expense = {};
        appStatusResult.PortCall.Expense.SelectedIds = expense.value.selectedIds;
      }
      // expense selected client id
      if (expense.value.selectedClientId) {
        if (!appStatusResult.PortCall.Expense) {
          appStatusResult.PortCall.Expense = {};
        }
        appStatusResult.PortCall.Expense.selectedClientId = expense.value.selectedClientId;
      }

      // service selected Id's
      if (Array.isArray(ServiceSelectedIds.value) && ServiceSelectedIds.value.length > 0) {
        appStatusResult.PortCall.ServiceSelectedIds = ServiceSelectedIds.value;
      }

      // visitors selected Id's
      if (Array.isArray(VisitorIds.value) && VisitorIds.value.length > 0) {
        appStatusResult.PortCall.VisitorIds = VisitorIds.value;
      }

      // attendance selected Id's
      if (Array.isArray(AttendanceSelectedIds.value) && AttendanceSelectedIds.value.length > 0) {
        appStatusResult.PortCall.AttendanceSelectedIds = AttendanceSelectedIds.value;
      }

      // ccm person selected Id's
      if (Array.isArray(CcmPersonIds.value) && CcmPersonIds.value.length > 0) {
        appStatusResult.PortCall.CcmPersonIds = CcmPersonIds.value;
      }

      // ccm person selected Id's
      if (Array.isArray(CcmTaskIds.value) && CcmTaskIds.value.length > 0) {
        appStatusResult.PortCall.CcmTaskIds = CcmTaskIds.value;
      }

      // cargo selected Id's
      if (Array.isArray(SelectedCargoIds.value) && SelectedCargoIds.value.length > 0) {
        appStatusResult.PortCall.SelectedCargoIds = SelectedCargoIds.value;
      }
    }
    if (VesselId.value) {
      appStatusResult.VesselId = VesselId.value;
    }
    if (SetupID.value) {
      appStatusResult.SetupID = SetupID.value;
    }
    return appStatusResult;
  });

  return {
    PortCall,
    VesselId,
    SetupID,
    selectedSofId,
    ServiceId,
    selectedProspectId,
    expense,
    ServiceSelectedIds,
    VisitorIds,
    AttendanceSelectedIds,
    CcmPersonIds,
    CcmTaskIds,
    SelectedCargoIds,
    setAppStatusSetupId,
    setPortcallApplicationStatus,
    setAppStatusVesselId,
    setAppStatusSelectedSofId,
    setAppStatusSelectedProspectId,
    setAppStatusExpenseClientId,
    setAppStatusExpenseSelectedIds,
    setAppStatusServiceSelectedIds,
    setAppStatusServiceId,
    setAppStatusVisitorSelectedIds,
    setAppStatusAttendanceSelectedIds,
    setAppStatusCcmPersonIds,
    setAppStatusCcmTaskIds,
    setAppStatusSelectedCargoIds,
    resetAppStatusSelection,
    createApplicationStatusWhenPortcallIsLoaded,
    // Getters.
    getAppStatus,
  };
});
