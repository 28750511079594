import { DateTime } from 'luxon';

interface ParseResult {
  isValid: boolean;
  parsedDate: DateTime | null | undefined;
  invalidReason?: string | null;
}

function createYearFromDatepart(datePart: string | undefined) {
  const { year } = DateTime.local();
  if (!datePart) {
    return year;
  }
  if (datePart.length === 1) {
    return +`200${datePart}`;
  }
  if (datePart.length === 2) {
    return +`${year.toString().substring(0, 2)}${datePart}`;
  }
  if (datePart.length === 3) {
    return +`${year.toString().substring(0, 1)}${datePart}`;
  }
  if (datePart.length === 4) {
    return +`${datePart}`;
  }
  return year;
}

function createMonthFromDatepart(datePart: string | undefined) {
  const { month } = DateTime.local();
  if (datePart && !Number.isNaN(+datePart)) {
    return +datePart;
  }
  return month;
}
function createDayFromDatepart(datePart: string | undefined) {
  const { day } = DateTime.local();
  if (datePart && !Number.isNaN(+datePart)) {
    return +datePart;
  }
  return day;
}

function getDatePartsByDateformat(text: string, dateFormat: string | 'YYYY-MM-DD') {
  const dateparts = text.split(/[^0-9]/g).filter((i) => i !== '');
  const result = {
    day: createDayFromDatepart(undefined),
    month: createMonthFromDatepart(undefined),
    year: createYearFromDatepart(undefined),
  };
  // DD.MM.YYYY
  if (dateFormat.startsWith('DD')) {
    result.day = createDayFromDatepart(dateparts[0]);
    result.month = createMonthFromDatepart(dateparts[1]);
    result.year = createYearFromDatepart(dateparts[2]);
  }
  // MM/DD/YYYY
  if (dateFormat.startsWith('MM')) {
    result.month = createMonthFromDatepart(dateparts[0]);
    result.day = createDayFromDatepart(dateparts[1]);
    result.year = createYearFromDatepart(dateparts[2]);
  }
  // YYYY-MM-DD
  if (dateFormat.startsWith('YY')) {
    if (dateparts.length === 2) {
      result.year = createYearFromDatepart(undefined);
      result.month = createMonthFromDatepart(dateparts[0]);
      result.day = createDayFromDatepart(dateparts[1]);
    } else {
      result.year = createYearFromDatepart(dateparts[0]);
      result.month = createMonthFromDatepart(dateparts[1]);
      result.day = createDayFromDatepart(dateparts[2]);
    }
  }
  return result;
}

function parseStringToIsoFormat(text: string, dateFormat: string) {
  // DD.MM.YYYY

  let day: string | number = text.substring(0, 2);
  let month: string | number = createMonthFromDatepart(undefined);
  let year: string | number = createYearFromDatepart(undefined);
  if (dateFormat.startsWith('DD')) {
    if (text.length > 2) {
      day = text.substring(0, 2) ?? createDayFromDatepart(undefined);
      month = (text.substring(2, 4) || null) ?? createMonthFromDatepart(undefined);
      year = (text.substring(4, 6) || null) ?? createYearFromDatepart(undefined);
    }
    return `${day}.${month}.${year}`;
  }
  // MM/DD/YYYY
  if (dateFormat.startsWith('MM')) {
    if (text.length > 2) {
      month = (text.substring(0, 2) || null) ?? createMonthFromDatepart(undefined);
      day = text.substring(2, 4) ?? createDayFromDatepart(undefined);
      year = (text.substring(4, 6) || null) ?? createYearFromDatepart(undefined);
    }
    return `${month}/${day}/${year}`;
  }
  // YYYY-MM-DD
  if (dateFormat.startsWith('YY')) {
    if (text.length > 2) {
      // if(text.length === 3){
      //   month = +((text.substring(0, 2) || null) ?? createMonthFromDatepart(undefined))
      //   if(month > 12){

      //   }
      // }
      year = (text.substring(0, 4) || null) ?? createYearFromDatepart(undefined);
      month = (text.substring(4, 6) || null) ?? createMonthFromDatepart(undefined);
      day = text.substring(6, 8) ?? createDayFromDatepart(undefined);
    }
    return `${year}-${month}-${day}`;
  }
  return text;
}

function isValidDate({ day, month, year }: { day: number; month: number; year: number }) {
  let isValid = true;
  let invalidReason = '';

  if (day === 0 || month === 0 || year === 0) {
    return {
      isValid: false,
      invalidReason: 'Day/month/year cannot be 0.',
    };
  }
  // check if the date does not exceed maximum days in a month.
  const maxDays = DateTime.local(year, month).daysInMonth;
  if (maxDays) {
    isValid = day <= maxDays;
    if (!isValid) {
      invalidReason = `Only ${maxDays} days in ${DateTime.local().setLocale('en-GB').monthLong.toLowerCase()}`;
    }
  }
  const maxMonths = 12;
  if (month > maxMonths) {
    return {
      isValid: false,
      invalidReason: 'Month-part must be between 1-12.',
    };
  }
  if (isValid) {
    return {
      isValid,
    };
  }

  return {
    isValid,
    invalidReason,
  };
}

function parseTextToDateTime(
  text: string,
  dateFormat: string | 'YYYY-MM-DD'
): { isValid: boolean; parsedDate: DateTime | null; invalidReason?: string } {
  // Regex for sepparators: "." | "/" | "-"
  const seperators = /[./-\s]/g;
  const hasSepparator = seperators.test(text);
  let textResult = text;
  if (!hasSepparator) {
    textResult = parseStringToIsoFormat(text, dateFormat);
  }
  const { day, month, year } = getDatePartsByDateformat(textResult, dateFormat);

  const { isValid, invalidReason } = isValidDate({ day, month, year });

  const dateTime = isValid
    ? DateTime.local().set({ day, month, year, hour: 0, millisecond: 0, minute: 0, second: 0 })
    : null;

  const result: { isValid: boolean; parsedDate: DateTime | null; invalidReason?: string } = {
    isValid,
    parsedDate: dateTime,
  };
  if (invalidReason) {
    result.invalidReason = invalidReason;
  }
  return result;
}

function validateUserInput(
  text: string,
  dateFormat: string | 'YYYY-MM-DD'
): {
  isValid: boolean;
  invalidReason?: string;
} {
  /** A regex to test if the input-value is a string with only number and/or sepparators: "." | "/" | "-" */
  const userInputIsValidFormatRegex = /^[0-9./-\s]+$/;
  let isValid = userInputIsValidFormatRegex.test(text);

  const hasSepparator = /[./-\s]/g.test(text);
  const maxInputLength = hasSepparator ? dateFormat.length : dateFormat.length - 2;
  if (text.length > maxInputLength) {
    isValid = false;
  }

  if (!isValid) {
    return {
      isValid,
      invalidReason: 'Not a valid date',
    };
  }
  return {
    isValid: true,
  };
}

function checkUserinputDateparts(text: string) {
  const dateparts = text.split(/[^0-9]/g).filter((i) => i !== '');
  const result = {
    /** If the user-input has day-part */
    hasDayPart: dateparts.length >= 1,
    /** If the user-input has month-part */
    hasMonthPart: dateparts.length >= 2,
    /** If the user-input has year-part */
    hasYearPart: dateparts.length >= 3,
  };
  return result;
}

function parseTextToDate(dateText: string, dateFormat: string | 'YYYY-MM-DD', futureDate: boolean = false): ParseResult {
  let text = dateText;
  if (text?.includes('T')) {
    text = text.split('T')[0]
  }
  if (text === '') {
    return {
      isValid: true,
      parsedDate: null,
    };
  }

  const validUserInput = validateUserInput(text, dateFormat);
  if (!validUserInput.isValid) {
    return {
      ...validUserInput,
      parsedDate: null,
    };
  }

  const result = parseTextToDateTime(text, dateFormat);

  const { hasMonthPart } = checkUserinputDateparts(text);

  if (!hasMonthPart && futureDate && result.parsedDate && result.parsedDate.day < DateTime.local().day) {
    if (result.parsedDate.month === DateTime.now().month) {
      result.parsedDate = result.parsedDate.plus({ month: 1 });
    }
  }

  return result;
}

const GatDateEditFunctions = {
  parseTextToDate,
};

export default GatDateEditFunctions;
