import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { apiDelete, apiPost } from '@/store/api';
import { useDashboardStore } from '@/store/dashboardStore';
import { DashboardLocation } from '@/types/DashboardLocation';

export async function updateChartSettings({
  location,
  dashboardGroupName,
  reportName,
  settings,
  settingName,
}: {
  location: DashboardLocation;
  dashboardGroupName?: string;
  reportName: string | number;
  settings: any;
  settingName: 'settings' | 'userSettings' | 'defaultSettings';
}) {
  if ((!location && !reportName) || (!location && !dashboardGroupName && !reportName)) {
    console.error('location, dashboard group or report name was not provided.');
    return;
  }
  const dashboardStore = useDashboardStore();
  const urlExtension = dashboardGroupName
    ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}.${reportName}`
    : `${location}.${reportName}`;
  if (!settingName) {
    // eslint-disable-next-line no-param-reassign
    settingName = 'userSettings';
  }
  const savedInfo: any = {
    TypeName: `web_dashboard_${urlExtension}`,
    StrValue: JSON.stringify(settings),
    StrValue2: reportName,
  };
  if (settingName === 'defaultSettings') {
    savedInfo.Default = true;
  }
  const payload = {
    location,
    dashboardGroupName,
    reportName,
    settings,
    settingName,
  };
  // Check if the settings are equal to initial settings.
  const { initialSettings } = dashboardStore.getChartSettings({
    location,
    dashboardGroupName,
    reportName,
    getSettingsName: true,
  });
  const changes = GlobalHelperFunctions.getObjectChanges(initialSettings, settings);
  // if settings are empty or they are equal to initial settings, delete them from the DB.
  if (JSON.stringify(settings) == JSON.stringify({}) || changes.length === 0) {
    // delete
    await apiDelete('savedInfo', savedInfo);
    dashboardStore.setReportSettings(payload);
  } else {
    // save
    await apiPost('savedInfo', savedInfo);
    dashboardStore.setReportSettings(payload);
  }
}
