<template>
  <gat-flex :size="size">
    <gat-field-spacer :size="size">
      <v-autocomplete
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :clearable="clearable"
        :label="getLabel"
        :messages="getMessages"
        :items="items"
        placeholder="Start typing to Search"
        :return-object="!codeFieldName"
        :item-value="codeFieldName"
        :loading="isLoading"
        v-model="model"
        @input="updateValue()"
        :outlined="true"
        :dense="gatComponentsStore.input_dense"
        :search-input.sync="search"
        hide-no-data
        :item-text="textFieldName"
        :readonly="readonly"
        :autofocus="autofocus"
        :rules="getRules"
        :disabled="disabled">
        <template v-if="customSelection" v-slot:selection="data">
          <slot name="selection" v-bind:data="data.item">{{ data.item[textFieldName] }}</slot>
        </template>
        <template v-if="customItem" v-slot:item="data">
          <slot name="item" v-bind:data="data.item" v-bind="data">{{ data.item[textFieldName] }}</slot>
        </template>
      </v-autocomplete>
    </gat-field-spacer>
  </gat-flex>
</template>

<script>
import { useGatComponentsStore } from '@/store/gatComponentsStore';
import GatFlex from '../GatFlex.vue';
import GatFieldSpacer from '../GatFieldSpacer.vue';
import { GatInputMixin } from './GatInputMixin';

export default {
  name: 'GatLookupEdit',
  props: {
    autofocus: Boolean,
    value: [String, Number],
    label: String,
    size: String,
    searchresult: [String, Object, Array],
    textFieldName: String,
    codeFieldName: String,
    cashSearches: Boolean,
    // markOptionalInputs,
    readonly: Boolean,
    required: Boolean,
    rules: [String, Object, Array],
    disabled: Boolean,
    clearable: Boolean,
    customSelection: Boolean,
    customItem: Boolean,
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  components: { GatFlex, GatFieldSpacer },
  mixins: [GatInputMixin],
  setup() {
    const gatComponentsStore = useGatComponentsStore();
    return {
      gatComponentsStore,
    };
  },
  data() {
    return {
      model: null,
      items: [],
      isLoading: false,
      search: null,
      prevSearch: null,
      prevSearchResults: {},
    };
  },

  created() {},

  watch: {
    value: {
      handler(val) {
        this.model = val;
        if (!this.valueExistInItems(val)) {
          if (this.cashSearches && Object.prototype.hasOwnProperty.call(`ID:${val}`, this.prevSearchResults)) {
            this.items = this.prevSearchResults[`ID:${val}`];
          } else {
            this.prevSearch = `ID:${val}`;
            this.$emit('itemNotFound', val);
          }
        }

        if (this.checkForErrors(val, this.getRules)) {
          this.expandParentGatGroup(); // mixin
        }
      },
      immediate: true,
    },

    search(val) {
      // Items have already been requested
      if (this.isLoading) return;
      if (val && val.length > 1 && !this.prevSearchIsWider(val.toUpperCase()) && !this.textMatchesCurrentItem(val)) {
        this.doSearch(val);
      }
    },

    searchresult(val) {
      this.items = val;
      if (this.cashSearches) {
        let key = this.prevSearch;
        this.prevSearchResults[key] = val;
        if (
          key.startsWith('ID:') &&
          val.length == 1 &&
          this.textFieldName &&
          Object.prototype.hasOwnProperty.call(this.textFieldName, val[0])
        ) {
          key = val[0][this.textFieldName].toUpperCase();
          this.prevSearchResults[key] = val;
        }
      }
      this.isLoading = false;
    },
  },

  computed: {
    getRules() {
      const result = this.rules || [];
      // required rule
      if (this.required) {
        result.push((value) => {
          if (value) {
            return true;
          }
          return 'required';
        });
      }

      return result;
    },

    textValue() {
      return this.value; // brukes av getMessage (mixin)
    },
  },

  methods: {
    doSearch(searchText) {
      this.prevSearch = searchText.toUpperCase();

      if (this.cashSearches && Object.prototype.hasOwnProperty.call(searchText.toUpperCase(), this.prevSearchResults)) {
        this.items = this.prevSearchResults[searchText.toUpperCase()];
      } else {
        this.isLoading = true;
        this.$emit('getItems', searchText);
      }
    },

    updateValue() {
      this.$emit('input', this.model);
    },

    prevSearchIsWider(searchText) {
      return this.prevSearch && searchText.startsWith(this.prevSearch);
    },

    textMatchesCurrentItem(text) {
      const self = this;
      if (this.textFieldName) {
        const idx = this.items.findIndex((item) => item[self.textFieldName] == text);
        if (idx >= 0) {
          return this.items[idx][self.codeFieldName] === self.value;
        }
      }
      return false;
    },

    // eslint-disable-next-line consistent-return
    valueExistInItems(value) {
      if (!value) {
        return true;
      }
      const self = this;
      if (this.codeFieldName) {
        const idx = this.items.findIndex((item) => item[self.codeFieldName] == value);
        return idx >= 0;
      }
    },
  },
};
</script>

<style scoped></style>
