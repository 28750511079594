<template>
  <v-navigation-drawer :clipped="$vuetify.breakpoint.lgAndUp" v-model="isVisible" app>
    <drawer-menu-items />
    <!-- <record-presenter :value="applicationStore.unsavedPages" />    -->
  </v-navigation-drawer>
</template>

<script>
import DrawerMenuItems from './DrawerMenuItems.vue';

export default {
  name: 'NavigationDrawer',
  props: ['value'],
  components: { DrawerMenuItems },
  data() {
    return {
      isVisible: null,
    };
  },

  created() {},

  watch: {
    value(val) {
      this.isVisible = val;
    },
    isVisible(val) {
      this.$emit('showDrawerChanged', val);
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
nav {
  padding-left: calc(0 + env(safe-area-inset-left));
  padding-top: calc(0 + env(safe-area-inset-top));
  padding-bottom: calc(0 + env(safe-area-inset-bottom));
}
</style>
