<template>
  <div>
    <gat-grid
      title="Services"
      :items="services"
      :columns="columns"
      class="elevation-0"
      :fixedheader="true"
      height="calc(100vh - 135px)"
      :hide-empty-columns="true"
      :selectable="true"
      :selectedKeys="selectedServiceIds"
      keyName="SER_ID"
      viewGroupName="portcallServicesView"
      reloadBtn
      @reload-clicked="getServices()"
      @row-clicked="serviceClicked"
      @selectionChanged="serviceSelected($event)"
      :orgSortBy="getSort">
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == 'SER_STATUS'">
          <span>
            <v-icon :class="getStatusItem(props.value).iconClass" class="pr-2" v-if="props.value">{{
              getStatusItem(props.value).icon
            }}</v-icon>
            <span v-show="$vuetify.breakpoint.smAndUp">{{ getStatusItem(props.value).NAME }}</span>
          </span>
        </div>
        <div v-else-if="props.column.field == `EMAIL`">
          <a class="gridLink" :href="`mailto:${props.value}`" @click.stop>{{ props.value }}</a>
        </div>
        <!-- <div v-else-if="props.column.field === 'EXP_BUDGET'">
                  <v-icon v-if="+props.value === 0">mdi-checkbox-blank-outline</v-icon>
                  <v-icon color="success lighten-1" v-else-if="+props.value === 1">mdi-checkbox-marked</v-icon>
               </div> -->
        <v-chip
          v-else-if="props.column.field === 'EXP_INVOICE_NO' && props.value"
          small
          label
          text-color="black"
          class="invoice-label"
          >{{ props.value }}</v-chip
        >
      </template>
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="wmButtonGroup"
          @addClick="$router.push('/call/' + callId + '/new-service')"
          @addFromTemplateClick="showTemplateDialog = true"
          @deleteClick="showDeleteServiceDialog = true"
          @markClosedClick="closeSelectedServices(selectedServiceIds)"
          @createDocClick="createDocument"
          @createEmailClick="(val) => createEmailClick(val)" />
      </template>
    </gat-grid>
    <ServiceTemplateDialog :showDialog="showTemplateDialog" :callId="+callId" @close="showTemplateDialog = false" />
    <v-dialog v-model="showDeleteServiceDialog" persistent max-width="290">
      <v-card v-if="!showDeleteExpenseDialog">
        <v-card-title class="title">Delete service{{ this.selectedServiceIds.length > 1 ? 's' : '' }}</v-card-title>

        <v-card-text class="text-center"
          >Are you sure you want to delete
          {{
            this.selectedServiceIds.length > 1
              ? this.selectedServiceIds.length + ' services?'
              : this.selectedServiceIds.length + ' service?'
          }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteServiceDialog = false">Cancel</v-btn>
          <v-btn v-if="SelectedExpenseIds.length > 0" text color="primary" @click="showDeleteExpenseDialog = true"
            >Delete</v-btn
          >
          <v-btn v-else text color="primary" @click="deleteSelectedServices(selectedServiceIds)">Delete</v-btn>
        </v-card-actions>
      </v-card>
      <v-card v-if="showDeleteExpenseDialog">
        <v-card-title class="title">Delete expense{{ this.SelectedExpenseIds.length > 1 ? 's' : '' }}</v-card-title>

        <v-card-text class="text-center"
          >Do you want to delete the expense{{ this.SelectedExpenseIds.length > 1 ? 's' : '' }} connected to the
          service{{ this.selectedServiceIds.length > 1 ? 's' : '' }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showDeleteExpenseDialog = showDeleteServiceDialog = false">Cancel</v-btn>
          <v-btn text color="primary" @click="deleteSelectedServices(selectedServiceIds)">No</v-btn>
          <v-btn text color="primary" @click="deleteSelectedServicesAndExpenses(SelectedExpenseAndServiceIds)"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <email-generator :portcallId="callId" location="service" :guid="emailTemplateGuid" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiPut, apiGet } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import EmailGenerator from '@/app-components/EmailGenerator.vue';
import { usePortcallStore } from '@/store/portcallStore';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { deleteExpense } from '@/services/api/api-calls/deleteExpense';
import { deleteService } from '@/services/api/api-calls/deleteService';
import { deleteServiceAndExpense } from '@/services/api/api-calls/deleteServiceAndExpense';
import { getServices } from '@/services/api/api-calls/getServices';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import ServiceTemplateDialog from './ServiceTemplateDialog.vue';

export default {
  name: 'Services',
  props: ['callId', 'accessGroup'],
  components: { ServiceTemplateDialog, EmailGenerator },
  setup() {
    const portcallStore = usePortcallStore();
    const appStatusStore = useAppStatusStore();
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      portcallStore,
      appStatusStore,
    };
  },
  data() {
    return {
      selectedServiceIds: [],
      showTemplateDialog: false,
      showDeleteServiceDialog: false,
      showDeleteExpenseDialog: false,
      upcomingServices: [],
      emailTemplates: [],
      emailTemplateGuid: undefined,
    };
  },

  created() {},

  activated() {},

  watch: {
    callId: {
      immediate: true,
      handler() {
        this.getServices();
      },
    },
    externalClientId: {
      handler() {
        this.getServices();
      },
    },
  },

  computed: {
    webmodulUserType() {
      return this.applicationStore.globalSettings.SGL_USE_WEBMODULE_2021;
    },
    externalClientId() {
      return this.applicationStore.user.externalClientId;
    },
    agencyId() {
      return this.portcallStore.callDetails.SETUP_ID;
    },
    wmButtonGroup() {
      const result = [];
      if (this.applicationStore.user.internal) {
        result.push({
          btnType: 'ADD',
          openMenuOnHover: true,
          embeddMenuItems: true,
          mainAction: true,
          userRight: 'SER.NEW_AND_EDIT',
          menuItems: [
            { caption: 'Add service', eventName: 'addClick', icon: 'mdi-plus' },
            { caption: 'Add from template', eventName: 'addFromTemplateClick', icon: 'mdi-content-duplicate' },
          ],
        });
      }
      if (!this.applicationStore.user.internal && !this.isUpcomingServicesPage) {
        result.push({
          btnType: 'CUSTOM',
          caption: 'Request service',
          eventName: 'addClick',
          icon: 'mdi-plus',
          userRight: 'SER.NEW_AND_EDIT',
        });
      }
      if (this.applicationStore.userRight('SER.DELETE')) {
        // should always be visible for internal users, and only for external users with DELETE user rights.
        result.push({ btnType: 'DELETE', disabled: this.selectedServiceIds.length == 0, userRight: 'SER.DELETE' });
      }
      result.push({
        btnType: 'CUSTOM',
        caption: 'mark as closed',
        icon: 'mdi-check-all',
        eventName: 'markClosedClick',
        disabled: this.isClosable,
        userRight: 'SER.NEW_AND_EDIT',
      });
      if ((!this.applicationStore.user.internal && this.userCanGenerateDoc) || this.applicationStore.user.internal) {
        result.push({
          btnType: 'CREATE_DOC',
          docType: 18,
          agencyId: this.agencyId,
          applicationStatus: this.applicationStatus,
          location: 'portcall',
          foreignKey: this.callId,
          disabled: !this.userCanGenerateDoc,
        });
        if (
          this.applicationStore.globalSettings.SGL_WM_EMAIL_PROVIDER !== 0 &&
          this.applicationStore.userRight('EM.EDIT')
        ) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Create email',
            icon: 'mdi-email',
            menuItems: this.emailTemplateItems,
            disabled: this.emailTemplateItems.length === 0,
          });
        }
      }
      return result;
    },

    userCanGenerateDoc() {
      const isInternal = this.applicationStore.user.internal;
      if (isInternal) {
        return true;
      }
      const externalRights = this.applicationStore.userRight('SER.GENERATE_DOCUMENT');
      return externalRights;
    },

    SelectedExpenseIds() {
      const result = [];
      if (this.selectedServiceIds.length != 0) {
        for (let i = 0; this.selectedServiceIds.length > i; i++) {
          const idx = this.services.findIndex((item) => item.SER_ID == this.selectedServiceIds[i]);
          if (idx != 0 && this.services[idx] && this.services[idx].EXP_ID) {
            result.push(this.services[idx].EXP_ID);
          }
        }
      }
      return result;
    },
    SelectedExpenseAndServiceIds() {
      const result = [];
      if (this.selectedServiceIds && this.selectedServiceIds.length != 0) {
        for (let i = 0; this.selectedServiceIds.length > i; i++) {
          const idx = this.services.findIndex((item) => item.SER_ID == this.selectedServiceIds[i]);
          if (idx != 0 && this.services[idx] && this.services[idx].EXP_ID && this.services[idx].SER_ID) {
            result.push({ serviceId: this.services[idx].SER_ID, expenseId: this.services[idx].EXP_ID });
          } else if (this.services[idx] && this.services[idx].SER_ID) {
            result.push({ serviceId: this.services[idx].SER_ID });
          }
        }
      }
      return result;
    },
    applicationStatus() {
      return this.appStatusStore.getAppStatus;
      /* let result = {};
              result.VesselId = this.portcallStore.callDetails.VESSEL_ID;
              result.PortCall = {};
              result.PortCall.Id = this.callId;
              result.PortCall.ServiceSelectedIds = this.selectedServiceIds;
              return result; */
    },
    services() {
      if (this.callId && this.callId != 0) {
        return this.portcallStore.services;
      }
      return this.upcomingServices;
    },
    columns() {
      const result = [];
      let statusWidth = '140px';
      if (this.$vuetify.breakpoint.xsOnly) {
        statusWidth = undefined;
      }
      result.push({ header: 'Status', field: 'SER_STATUS', hideOnPhone: false, width: statusWidth, nowrap: true });
      result.push({
        header: 'Time of service',
        field: 'SER_DATE',
        dataType: 'datetime',
        nowrap: true,
        hideOnPhone: false,
        calculated: (rowData) => GlobalHelperFunctions.globalFormatDateTime(rowData.SER_DATE, 'DD.MMM HH:mm'),
      });
      result.push({ header: 'Service type', field: 'STY_NAME', hideOnPhone: false });
      result.push({ header: 'Description', field: 'SER_DESCRIPTION', hideOnPhone: false });
      result.push({ header: 'Docs', field: 'hasAttachment', checkbox: true, hideOnPhone: true });
      result.push({ header: 'Requested by', field: 'SER_ORDER_BY', hideOnPhone: true });
      if (this.applicationStore.user.internal) {
        result.push({ header: 'Internal comment', field: 'SER_OUR_COMMENT', hideOnPhone: true });
        result.push({
          header: 'External comment',
          field: 'SER_WEB_COMMENT',
          hideOnPhone: true,
          hide: this.webmodulUserType === 3,
        });
      } else {
        result.push({ header: 'Comment', field: 'SER_WEB_COMMENT', hideOnPhone: true });
      }

      if (this.isUpcomingServicesPage) {
        result.push({ header: 'Port call', field: 'PORTCALL_NUMBER' });
        result.push({ header: 'Vessel', field: 'Vessel' });
      }

      result.push({ header: 'Supplier', field: 'SupplierName', hideOnPhone: true });
      if (this.applicationStore.user.internal) {
        result.push({
          header: 'Supplier phone',
          field: 'PHONE',
          linkUrl: 'tel:field[PHONE]',
          linkText: 'field[PHONE]',
          nowrap: true,
          hideOnPhone: true,
        });
        result.push({ header: 'Supplier email', field: 'EMAIL', nowrap: true, hideOnPhone: true });
      }
      if (this.applicationStore.userRight('SER.SHOW_EXPENSES')) {
        const localCurCode = this.applicationStore.getAgencySettings(this.agencyId).LocalCurrency;
        result.push({
          header: `Expense ${localCurCode}`,
          field: 'EXP',
          dataType: 'currency',
          footerFunction: { fn: 'sum' },
          hideOnPhone: true,
        });
        result.push({ header: 'Budget ', field: 'EXP_BUDGET', checkbox: true, hideOnPhone: true });
        result.push({ header: 'Invoice no. ', field: 'EXP_INVOICE_NO', hideOnPhone: true });
        result.push({ header: 'Debtor ', field: 'EXP_DEBTOR', hideOnPhone: true, nowrap: true });
      }

      // dynafields columns
      for (let index = 0; index < this.helperStore.dynafields.length; index++) {
        const dynafield = this.helperStore.dynafields[index];

        if (dynafield.USE_IN_GRID && dynafield.COMPONENT_NAME == 'pDynaFields.ServicesDyna') {
          /* console.log(dynafield); */
          const col = { header: dynafield.CAPTION, field: `dyn_${dynafield.ID}`, hideOnPhone: true };
          if (dynafield.FIELD_TYPE == 2) {
            col.dataType = 'number';
            col.maxDecimals = dynafield.DECIMALS;
            // eslint-disable-next-line consistent-return
            col.calculated = (rowData) => {
              // eslint-disable-next-line @typescript-eslint/no-shadow
              const result = +rowData[`dyn_${dynafield.ID}`];
              if (!Number.isNaN(result)) {
                // do not return if number is 0/NaN.
                return result + this.addDecimals(dynafield.DECIMALS);
              }
            };
          }
          result.push(col);
        }
      }

      result.push({ header: 'Sorting', field: 'SER_SORT_ORDER', dataType: 'number', hideOnPhone: true });

      return result;
    },

    isClosable() {
      // Mark as closed btn (disable check)
      if (this.selectedServiceIds) {
        for (let i = 0; this.selectedServiceIds.length > i; i++) {
          const idx = this.services.findIndex((item) => item.SER_ID == this.selectedServiceIds[i]);
          if (this.services[idx] && this.services[idx].SER_STATUS && this.services[idx].SER_STATUS != 40) {
            return false;
          }
        }
      }

      return true;
    },
    userCanAddService() {
      if (this.applicationStore.user.internal) {
        return !this.applicationStore.userRight('CH.NEW_AND_EDIT');
      }
      return !this.applicationStore.userRight('SER.NEW_AND_EDIT');
    },
    isUpcomingServicesPage() {
      return this.$route.path === '/services';
    },
    getSort() {
      return this.isUpcomingServicesPage ? [{ field: 'SER_DATE' }] : [];
    },
    emailTemplateItems() {
      const templates = [...this.emailTemplates];
      let result = [];
      const ignoreGroupMinCount = this.applicationStore.hasFeatureCode('IGNORE_DOCUMENT_GROUPING_MIN_COUNT');

      if (Array.isArray(templates) && templates.length > 0) {
        if (ignoreGroupMinCount ? true : templates.length > 10) {
          result = this.helperStore.emailTemplatesGroupedByLabels(templates, false);
        } else {
          result = templates.map((item) => ({
            caption: item.TITLE,
            eventName: 'createEmailClick',
            icon: 'mdi-file-outline',
            ID: item.ID,
          }));
        }
      }
      return result;
    },
  },

  methods: {
    addDecimals(number) {
      let result = '';
      if (number) {
        for (let i = 0; number > i; i++) {
          if (result.length <= 0) result = ',';
          result += '0';
        }
      }
      return result;
    },
    closeSelectedServices(ids) {
      const services = [...this.portcallStore.services];
      for (let i = 0; ids.length > i; i++) {
        const idx = services.findIndex((item) => item.SER_ID == ids[i]);
        if (services[idx].SER_STATUS != 40) {
          // only update the ones not closed (SER_STATUS 40)
          const orgItem = { ...services[idx] };
          const newItem = { ...services[idx] };
          newItem.SER_STATUS = 40;
          newItem.SER_PORTCALL_ID = this.callId;
          apiPut('service', { value: newItem, orgValue: orgItem }).then((result) => {
            if (result.resultCategory == 1) {
              this.portcallStore.addServiceItem(result.payload);
              this.selectedServiceIds = [];
            }
          });
        }
      }
    },
    createDocument(menuItem) {
      console.log(menuItem);
    },
    createEmailClick(item) {
      if (this.callId) {
        const guid = GlobalHelperFunctions.GUID();
        const templateId = item.ID;
        const location = 'portcall';
        const foreignKey = this.callId;
        const appStatus = JSON.stringify(this.applicationStatus);
        const param = { guid, templateId, location, foreignKey, appStatus, isEmail: 1 };
        apiPut('document/generate/', param).then((result) => {
          if (result.resultCategory === 0 && result.resultType === 0) {
            this.emailTemplateGuid = guid;
          }
        });
      }
    },
    deleteSelectedExpenses(idArr) {
      for (let i = 0; idArr.length > i; i++) {
        deleteExpense(idArr[i]);
      }
      this.showDeleteServiceDialog = false;
      this.showDeleteExpenseDialog = false;
    },
    deleteSelectedServices(idArr) {
      for (let i = 0; idArr.length > i; i++) {
        deleteService(idArr[i]);
      }
      this.showDeleteServiceDialog = false;
      this.showDeleteExpenseDialog = false;
    },
    deleteSelectedServicesAndExpenses(SelectedExpenseAndServiceIds) {
      for (let i = 0; SelectedExpenseAndServiceIds.length > i; i++) {
        deleteServiceAndExpense(SelectedExpenseAndServiceIds[i]);
      }
      this.showDeleteServiceDialog = false;
      this.showDeleteExpenseDialog = false;
      this.selectedServiceIds = [];
    },
    getServices() {
      if (this.callId && this.callId != 0) {
        getServices(this.callId).then(() => {
          apiGet(`mail/templates/service/${this.agencyId}`).then((result) => {
            if (result) {
              this.emailTemplates = result;
            }
          });
        });
      } else if (this.applicationStore.user.externalClientId != undefined) {
        apiGet(`service/client/${this.applicationStore.user.externalClientId}`).then((result) => {
          this.upcomingServices = GatUtils.makeCopyViaJson(result);
        });
      }
    },
    getStatusItem(code) {
      const status = this.helperStore.serviceStatuses.find((item) => item.ID == code);
      if (status) {
        return status;
      }
      return {};
    },
    getStatusCaption(code, small) {
      let result = 'Not handled';
      if (code == '20') {
        result = 'Pending';
      } else if (code == '40') {
        result = 'Closed';
      }
      if (small) {
        result = result.charAt(0).toUpperCase();
      }
      return result;
    },
    getStatusColor(code) {
      let result = 'white';
      if (code == '20') {
        result = 'grey lighten-2';
      } else if (code == '40') {
        result = 'green lighten-1';
      }
      return result;
    },
    getStatusTextColor(code) {
      let result = 'black';
      if (code == '40') {
        result = 'white';
      }
      return result;
    },
    serviceClicked(rowItem) {
      this.$router.push({ name: 'callService', params: { serviceId: rowItem.SER_ID, accessGroup: this.accessGroup } });
    },
    serviceSelected(serviceIds) {
      if (serviceIds.length === 1) {
        this.appStatusStore.setAppStatusServiceId(serviceIds[0]);
      } else if (serviceIds.length === 0) {
        this.appStatusStore.setAppStatusServiceId(undefined);
      }
      this.selectedServiceIds = serviceIds;
      this.appStatusStore.setAppStatusServiceSelectedIds(serviceIds);
    },
  },
};
</script>

<style scoped>
.rightBtn {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-top: 0px;
}

.invoice-label {
  background: #c8e6c9 !important;
}
</style>
