export function isValidEmail(emailAddress) {
  
  const emails = emailAddress.split(/,|;/);
  let result = null;
  for (let e = 0; e < emails.length; e++){
    const res = emails[e].trim()
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    if (!res){
      return false;
    }
    
      result = res;
    
  }

  return result;
}
