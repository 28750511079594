import { apiGet } from '@/store/api';
import { useAttendanceStore } from '@/store/attendanceStore';
import { getInAttendance } from './getInAttendance';

export async function getPreviousAttendances() {
  const data = await apiGet('/attendances');
  useAttendanceStore().setPreviousAttendances(data);
  getInAttendance();
  return data;
}
