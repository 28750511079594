<template>
  <div>
    <gat-form
      v-model="isValid"
      ref="gatform"
      :readonly="!userRightsPeople() || this.person.LOCK_FLAG == 1"
      :isLoading="isLoading">
      <gat-group title="Crew change details" :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <gat-select
            v-model="person.TYPE_ID"
            label="Type"
            :items="crewChangeTypes"
            textFieldName="NAME"
            codeFieldName="ID"
            @item-selected="TypeChanged"
            :required="true"
            :disabled="Object.keys(this.orgPerson).length > 0" />

          <gat-client-select
            v-if="!applicationStore.globalSettings.SGL_CREWCHANGE_DEBTOR_FROM_HOTLIST"
            label="Debtor"
            v-model="person.PRINCIPLE_ID"
            category="0"
            :required="true"
            :clearable="this.person.LOCK_FLAG != 1 && userRightsPeople()"
            showClientWarning
            showClientCreditLimit />
          <gat-select
            v-else
            :items="hotlistClients(0)"
            textFieldName="displayName"
            codeFieldName="value"
            label="Debtor"
            v-model="person.PRINCIPLE_ID"
            category="0"
            customItem>
            <template v-slot:item="{ data }">
              <div>{{ data['displayName'] }}</div>
              <div v-if="+data.SHOW_WARNING === 1 || data.CLIENT_CREDIT_FLAG > 0" class="ml-auto">
                <v-icon
                  v-if="+data.SHOW_WARNING === 1"
                  class="orange--text"
                  :class="data.CLIENT_CREDIT_FLAG > 0 ? 'mr-1' : ''"
                  >mdi-alert</v-icon
                >
                <v-icon v-if="data.CLIENT_CREDIT_FLAG > 0" :class="getClienCreditFlagColor(+data.CLIENT_CREDIT_FLAG)"
                  >mdi-flag</v-icon
                >
              </div>
            </template>
          </gat-select>

          <gat-edit v-model="person.SEQUENCE" label="Sequence" size="s" dataType="number" />
        </v-layout>

        <v-layout wrap>
          <v-radio-group
            label="Operation"
            v-model="person.OPERATION"
            class="ml-4"
            :error-messages="person.OPERATION === 3 ? '*required' : ''">
            <v-radio label="Embarking" :value="0" />
            <v-radio label="Disembarking" :value="1" />
            <v-radio label="Other" :value="2" />
          </v-radio-group>
          <v-radio-group label="Person type" v-model="person.PERSON_TYPE" class="ml-4">
            <v-radio label="Crew" :value="0" />
            <v-radio label="Passenger" :value="1" />
            <v-radio label="Other" :value="2" />
          </v-radio-group>

          <v-radio-group label="Status" v-model="person.STATUS" class="ml-4">
            <v-radio label="In progress" :value="0" />
            <v-radio label="Completed" :value="1" />
            <v-radio label="Cancelled" :value="2" />
          </v-radio-group>
        </v-layout>
      </gat-group>

      <gat-group title="Person details" :collapsed="isNew ? !expandedIfNew : false">
        <v-layout wrap>
          <gat-edit
            v-model="person.FIRSTNAME"
            label="Firstname"
            :maxChars="50"
            size="xs12 sm6 xl3"
            :onchange="updateFullName()" />
          <gat-edit
            v-model="person.LASTNAME"
            uppercase
            label="Lastname"
            :maxChars="50"
            size="xs12 sm6 xl3"
            :onchange="updateFullName()" />
          <gat-edit v-model="person.FULLNAME" label="Fullname" :maxChars="100" size="xs12 sm6 xl3" required />
          <gat-select
            v-model="person.RANK"
            label="Rank"
            :items="crewChangeRanks"
            textFieldName="NAME"
            codeFieldName="NAME"
            size="xs12 sm6 xl3" />
          <gat-select
            v-model="person.NATIONALITY"
            label="Nationality"
            :items="countries"
            textFieldName="text"
            codeFieldName="text"
            size="xs12 sm6 xl3" />
          <gat-select
            v-model="person.GENDER"
            label="Gender"
            :items="Genders"
            textFieldName="NAME"
            codeFieldName="NAME"
            size="xs12 sm6 xl3" />
          <gat-edit v-model="person.MOBILEPHONE" label="Mobilephone" :maxChars="20" size="xs12 sm6 xl3" />
          <GatSubGroup title="" size="xs12 sm12 xl12">
            <gat-edit
              v-model="person.PASSPORT_NUMBER"
              :required="passportRequired"
              label="Passport number"
              :maxChars="25"
              size="xs12 sm4 xl3" />
            <gat-edit v-model="person.PASSPORT_ISSUED_DATE" dateEdit label="Issued date" size="xs12 sm4 xl3" />
            <gat-edit v-model="person.PASSPORT_VALID_TO" dateEdit label="Valid to" size="xs12 sm4 xl3" />
          </GatSubGroup>
          <GatSubGroup title="" size="xs12 sm12 xl12">
            <gat-edit
              v-model="person.SEAMANBOOK_NUMBER"
              :required="seamanbookRequired"
              label="Seamanbook number"
              :maxChars="25"
              size="xs12 sm4 xl3" />
            <gat-edit v-model="person.SEAMANBOOK_ISSUED_DATE" dateEdit label="Issued date" size="xs12 sm4 xl3" />
            <gat-edit v-model="person.SEAMANBOOK_VALID_TO" dateEdit label="Valid to" size="xs12 sm4 xl3" />
          </GatSubGroup>
          <gat-edit v-model="person.REMARKS" label="Remarks" :maxChars="10000" :rows="3" size="xs12 sm12 xl12" />
        </v-layout>
      </gat-group>

      <gat-group
        title="Dynamic fields"
        :collapsed="false"
        v-show="dynaFieldIds !== undefined && dynaFieldIds.length > 0">
        <gat-dyna-fields
          v-model="person.DynaFields"
          componentName="pDynaFields.CrewChangeDynaPerson"
          container="pDynaFields"
          :filterByIds="dynaFieldIds"></gat-dyna-fields>
      </gat-group>

      <gat-group title="Documents" :collapsed="false">
        <v-layout wrap>
          <embedded-doc-list
            v-if="!isNew && person && person.ID"
            :compact="false"
            location="crewchangeperson"
            :parentId="personIDString"
            :disabled="isDocListDisabled()" />
        </v-layout>
        <span v-if="isNew">The person must be created in order to add documents.</span>
      </gat-group>
      <gat-grid
        v-if="this.applicationStore.userRight('CRCH.SHOW_EXPENSES')"
        :items="person.EXPENSES"
        :columns="expense_columns"
        title="Expenses"
        :loading="isLoading"
        :fixedheader="true"
        :selectable="true"
        keyName="ID"
        @selectionChanged="expensesInUseChanged"
        :selectedKeys="selectedExpensesIDs">
        <template slot="cell" slot-scope="props">
          <div v-if="props.column.field == `Price`">
            <v-text-field
              hide-spin-buttons
              type="number"
              reverse
              dense
              v-model="props.row.Price"
              @input="priceChanged(props.row.ID)" />
          </div>
        </template>
      </gat-grid>
      <gat-bottom-panel
        :orgValue="orgPerson"
        :newValue="person"
        :itemDescription="'person'"
        :saveBtn="true"
        :deleteBtn="true"
        :buttonsEnabled="!saving"
        :deleteDisabled="!person.ID || !userRightsPeople() || this.person.LOCK_FLAG == 1"
        :saveDisabled="!userRightsPeople() || this.person.LOCK_FLAG == 1 || !this.isValid"
        @saveClicked="savePerson"
        @deleteClicked="deletePerson"></gat-bottom-panel>
    </gat-form>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut, apiDelete } from '@/store/api';
import GatClientSelect from '@/app-components/GatClientSelect.vue';
import GatDynaFields from '@/app-components/dynafields/GatDynaFields.vue';
import EmbeddedDocList from '@/app-components/EmbeddedDocList.vue';
import { useToolbarStore } from '@/store/toolbarStore';
import { useApplicationStore } from '@/store/applicationStore';
import { useHelperStore } from '@/store/helperStore';
import { getCrewChangePeople } from '@/services/api/api-calls/getCrewChangePeople';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { usePortcallStore } from '@/store/portcallStore';

export default {
  name: 'CrewChangePerson',
  props: ['personID', 'callId', 'isNew', 'sequence'],
  components: { GatClientSelect, GatDynaFields, EmbeddedDocList },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    const helperStore = useHelperStore();
    const portcallStore = usePortcallStore();
    return {
      helperStore,
      applicationStore,
      toolbarStore,
      portcallStore,
    };
  },
  data() {
    return {
      person: {},
      orgPerson: {},
      isValid: null,
      saving: false,
      isLoading: true,
      expandedIfNew: false, // is set in activated/deactivated based on isNew prop.
      selectedExpensesIDs: [],
      Genders: ['Male', 'Female'],
      expense_columns: [
        { header: 'Name', field: 'EXPENSE_GROUP_NAME' },
        { header: 'Template', field: 'EXPENSE_TEMPLATE_NAME' },
        { header: 'Price tariff', field: 'PRICE_GROUP_NAME' },
        { header: 'Price', field: 'Price', dataType: 'number' },
        { header: 'Merge', field: 'MERGE_EXPENSES', dataType: 'boolean' },
      ],
      dynaFieldIds: [],
      loaded: false,
    };
  },
  created() {},
  async activated() {
    this.dynaFieldIds = [];
    if (!this.loaded) {
      this.loading = true;
      await this.loadPerson(this.personID);
    }

    this.toolbarStore.setBackNavigation([
      { text: 'port call', href: `/call/${this.callId}` },
      { text: 'crew change', href: `/call/${this.callId}/crewchange` },
    ]);
    this.expandedIfNew = this.isNew;
  },
  deactivated() {
    this.toolbarStore.setBackNavigation(undefined);
    this.loaded = false;
    this.toolbarStore.setToolbarNavBtns({ previousState: true });
    this.expandedIfNew = !this.isNew;
  },

  computed: {
    crewChangeTypes: {
      get() {
        return this.helperStore.crewChangeTypes.filter((typen) => typen.CC_TYPE == 0);
      },
    },
    crewChangeRanks: {
      get() {
        return this.helperStore.crewChangeRanks;
      },
    },
    countries: {
      get() {
        return this.helperStore.countries;
      },
    },
    personIDString: {
      get() {
        if (this.person.ID) return this.person.ID.toString();
        return '';
      },
    },
    passportRequired: {
      get() {
        let result = false;
        if (this.person.PERSON_TYPE !== 0) return result;

        switch (this.applicationStore.globalSettings.SGL_CCM_MANDATORY_PAPERS) {
          case 0:
            result =
              (this.person.SEAMANBOOK_NUMBER === '' || this.person.SEAMANBOOK_NUMBER === undefined) &&
              (this.person.PASSPORT_NUMBER === '' || this.person.PASSPORT_NUMBER === undefined);
            break;
          case 1:
            result = true;
            break;
          case 2:
            result = false;
            break;
          case 3:
            result = true;
            break;
          case 4:
            result = false;
            break;
          default:
            result =
              (this.person.SEAMANBOOK_NUMBER === '' || this.person.SEAMANBOOK_NUMBER === undefined) &&
              (this.person.PASSPORT_NUMBER === '' || this.person.PASSPORT_NUMBER === undefined);
            break;
        }
        return result;
      },
    },
    seamanbookRequired: {
      get() {
        if (this.person.PERSON_TYPE !== 0) return false;

        switch (this.applicationStore.globalSettings.SGL_CCM_MANDATORY_PAPERS) {
          case 0:
            return (
              (this.person.SEAMANBOOK_NUMBER === '' || this.person.SEAMANBOOK_NUMBER === undefined) &&
              (this.person.PASSPORT_NUMBER === '' || this.person.PASSPORT_NUMBER === undefined)
            );
          case 1:
            return false;
          case 2:
            return true;
          case 3:
            return true;
          case 4:
            return false;
          default:
            return (
              (this.person.SEAMANBOOK_NUMBER === '' || this.person.SEAMANBOOK_NUMBER === undefined) &&
              (this.person.PASSPORT_NUMBER === '' || this.person.PASSPORT_NUMBER === undefined)
            );
        }
      },
    },
  },
  methods: {
    hotlistClients(category) {
      const clients = [];
      // eslint-disable-next-line array-callback-return
      this.portcallStore.hotlist.map((client) => {
        if (client.CATEGORY == category) {
          const menuItem = {
            value: client.CLIENT_ID,
            displayName: client.C_NAME,
          };
          if (client.C_ACCOUNT_NO) {
            menuItem.displayName = `${menuItem.displayName} (${client.C_ACCOUNT_NO})`;
          }
          if (client.CLIENT_CREDIT_FLAG > 0) {
            menuItem.CLIENT_CREDIT_FLAG = client.CLIENT_CREDIT_FLAG;
            menuItem.CLIENT_CREDIT_BALANCE = client.CLIENT_CREDIT_BALANCE;
          }
          if (client.SHOW_WARNING) {
            menuItem.SHOW_WARNING = client.SHOW_WARNING;
            menuItem.WARNING = client.WARNING;
          }
          clients.push(menuItem);
        }
      });
      return clients;
    },
    getClienCreditFlagColor(value, warningElement = false) {
      let result = '';
      switch (+value) {
        case 0:
          result = 'd-none';
          break;
        case 1:
          result = warningElement ? 'red darken-1' : 'red--text text--darken-1';
          break;
        case 2:
          result = warningElement ? 'orange darken-1' : 'orange--text text--darken-1';
          break;
        case 3:
          result = warningElement ? 'green darken-1' : 'green--text text--darken-1';
          break;
        default:
          result = 'd-none';
          break;
      }
      return result;
    },
    updateFullName() {
      if (this.$refs.gatform != undefined && this.person.LASTNAME != undefined && this.person.FIRSTNAME != undefined)
        this.person.FULLNAME = `${this.person.LASTNAME.toUpperCase()}, ${
          this.person.FIRSTNAME.charAt(0).toUpperCase() + this.person.FIRSTNAME.slice(1)
        }`;
    },
    setInUsedExpenses() {
      const tmpArr = [];
      for (let index = 0; index < this.person.EXPENSES.length; index++) {
        if (this.person.EXPENSES[index].USE_EXPENSE === 1) {
          tmpArr.push(this.person.EXPENSES[index].ID);
        }
      }
      this.selectedExpensesIDs = tmpArr;
    },
    async loadPerson(personID) {
      this.loaded = true;
      this.loading = true;
      this.orgPerson = {};
      if (this.isNew) {
        this.$refs.gatform.resetValidation();
        this.person = {
          OPERATION: 0,
          PERSON_TYPE: 0,
          STATUS: 0,
          PORTCALL_ID: this.callId,
          SEQUENCE: await apiGet(`/crewchange/people/new-sequence/${this.callId}`).then((res) => res + 10),
          PRINCIPLE_ID: await apiGet(
            `/crewchange/people/default-principle/${this.applicationStore.globalSettings.SGL_DEFAULT_PAYING_PARTY}/${this.callId}`
          ).then((clientID) => clientID),
          DynaFields: this.dynaFieldIds,
        };
        this.isLoading = false;
      } else {
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiGet(`/crewchange/people/${personID}`).then(
          (result) => {
            // remove progressbar
            this.person = result;
            this.orgPerson = GlobalHelperFunctions.duplicateViaJson(this.person);
            this.filterDynaFields();
            this.isLoading = false;
            this.setInUsedExpenses();
          },
          () => {
            this.isLoading = false;
          }
        );
      }
    },
    deletePerson() {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/CrewChange/${this.person.ID}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        if (result.resultCategory == 1) {
          this.person = {};
          this.orgPerson = {};
          // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
          this.$nextTick(() => {
            getCrewChangePeople({ callId: this.callId, clientId: this.clientId() });
            this.$router.push({ name: 'crewChange', params: { callId: this.callId } });
          });
        }
      });
    },
    savePerson() {
      this.$refs.gatform.validate();
      if (this.isValid) {
        this.saving = true;
        const person = { ...this.person };
        this.isLoading = true;
        apiPut('/CrewChange/Person', {
          value: person,
          orgValue: this.orgPerson,
          dynaValues: this.person.DynaFields,
          orgDynaValues: this.orgPerson.DynaFields,
          expenses: this.person.EXPENSES,
          orgExpenses: this.orgPerson.EXPENSES,
        }).then((result) => {
          // remove progressbar
          this.isLoading = false;
          //   person = result.payload;
          if (result.resultCategory == 1) {
            if (this.isNew) {
              this.person = {};
              this.orgPerson = {};
            } else {
              this.person = person;
              this.orgPerson = GlobalHelperFunctions.duplicateViaJson(this.person);
            }

            // delayed for å ungå at dialog om data endret vises (changed property må rekke å settes)
            this.$nextTick(() => {
              getCrewChangePeople({ callId: this.callId, clientId: this.clientId() });
              this.$router.push({ name: 'crewChange', params: { callId: this.callId } });
              this.saving = false;
            });
          } else {
            this.saving = false;
          }
        });
      }
    },
    userRightsPeople() {
      return this.applicationStore.user.internal || this.applicationStore.userRight('CRCH.ACCESS_ALL_PERSONS');
    },

    TypeChanged(event) {
      if (!this.isNew) return;
      this.person.OPERATION = event.DEFAULT_OPERATION;
      this.person.PERSON_TYPE = event.DEFAULT_CATEGORY;
      // Get dynamic fields
      this.isLoading = true;
      apiGet(`/CrewChange/Person/type/${event.ID}/dynafields`)
        .then((ids) => {
          this.dynaFieldIds = ids;
        })
        .finally(() => {
          this.isLoading = false;
        });

      // Get expense lines
      this.isLoading = true;
      apiGet(`/CrewChange/Person/type/${event.ID}/expenses`)
        .then((expenses) => {
          this.person.EXPENSES = expenses;
          this.setInUsedExpenses();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    expensesInUseChanged(expenseID) {
      if (this.saving) return;
      if (!this.person.EXPENSES) return;

      this.selectedExpensesIDs = expenseID;
      for (let index = 0; index < this.person.EXPENSES.length; index++) {
        const expIndex = this.selectedExpensesIDs.indexOf(this.person.EXPENSES[index].ID);
        this.person.EXPENSES[index].USE_EXPENSE = expIndex != -1 ? 1 : 0;
      }
    },
    filterDynaFields() {
      this.dynaFieldIds = [];
      for (let index = 0; index < this.person.DynaFields.length; index++) {
        this.dynaFieldIds.push(this.person.DynaFields[index].FIELD_ID);
      }
    },
    clientId() {
      if (
        this.applicationStore.user.internal ||
        this.applicationStore.userRight('CRCH.ACCESS_ALL_PERSONS') ||
        this.applicationStore.userRight('CRCH.ACCESS')
      ) {
        return 0;
      }
      return this.applicationStore.user.externalClientId ? this.applicationStore.user.externalClientId : null;
    },
    isDocListDisabled() {
      const result = !this.userRightsPeople() || this.person.LOCK_FLAG == 1;
      return result;
    },
    priceChanged(ID) {
      const idx = this.person.EXPENSES.findIndex((x) => x.ID === ID);
      this.person.EXPENSES[idx].AMOUNT = this.person.EXPENSES[idx].QTY * this.person.EXPENSES[idx].Price;
    },
  },
  watch: {
    personID: {
      handler(newPersonID) {
        if (newPersonID) {
          this.loading = true;
          this.loadPerson(newPersonID);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss"></style>
