<template>
  <v-chip
    v-if="labelId"
    label
    :color="getLabelColor(labelId)"
    small
    class="font-weight-medium pa-0 d-flex justify-center"
    :style="getLabelStyle"
    :text-color="getLabelColor(labelId) == '#ffffff' ? 'black' : 'white'">
    <div style="text-align: center" class="d-flex pa-2 mx-auto">{{ getLabelTitle(labelId) }}</div>
    <!--  -->
  </v-chip>
</template>

<script>
import { useHelperStore } from '@/store/helperStore';
/* GridLabel documentation:
The GridLabel component should be used in grids which has labels attached to them, for example the documet-grid.
For this component to work properly you must send in the required props:
labelId (LABEL_ID of the record)
*/
export default {
  name: 'GridLabel',
  props: {
    /* labelId should be the same as DLA_LABEL in helperStore.documentLabels */
    labelId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const helperStore = useHelperStore();
    return {
      helperStore,
    };
  },
  components: {},
  computed: {
    getLabelStyle() {
      const style = {
        width: '100%',
      };
      if (this.getLabelColor(this.labelId) == '#ffffff') {
        style.border = '1px solid lightgrey';
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        style.width = '40px !important';
      }
      return style;
    },
  },
  methods: {
    // eslint-disable-next-line consistent-return
    getLabelColor(labelId) {
      const label = this.getLabelFromId(labelId);
      if (label) {
        return `#${this.delphiColorToHex(label.DLA_COLOR_BACKGROUND)}`;
      }
    },
    getLabelFromId(labelId) {
      const result = this.helperStore.documentLabels.find((label) => label.DLA_ID == labelId);
      return result;
    },
    getLabelTextColor(labelId) {
      const label = this.getLabelFromId(labelId);
      if (label && label.DLA_DARK_FONT) {
        return 'black';
      }
      return 'white';
    },
    getLabelTitle(labelId) {
      const label = this.getLabelFromId(labelId);
      if (label) {
        if (this.$vuetify.breakpoint.xsOnly && label.DLA_LABEL.length > 3) {
          return label.DLA_LABEL.substring(0, 3);
        }
        return label.DLA_LABEL;
      }
      return '';
    },

    delphiColorToHex(number) {
      let str = number.toString(16);
      str = str.padStart(6, '0');
      const R = str.substring(4, 6);
      const G = str.substring(2, 4);
      const B = str.substring(0, 2);
      // return str+':'+R+' '+G+' '+B;
      return R + G + B;
    },
  },
};
</script>

<style scoped></style>
