/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import moment from 'moment';

class GatUtils {
  static makeUrlParams(baseUrl, params) {
    let result = baseUrl;

    let sep = '?';
    for (const propName in params) {
      result = `${result + sep + propName}=${params[propName]}`;
      sep = '&';
    }

    return result;
  }

  static makeCopyViaJson(obj) {
    let result = {};
    if (obj) {
      const jsonStr = JSON.stringify(obj);
      result = JSON.parse(jsonStr);
    }
    return result;
  }

  static lookupCode(code, list, codeField = 'value', textField = 'text') {
    console.log('GatUtils.lookupCode is deprecated.  Use mixin version of the method');
    let result = code;
    if (list && list.length > 0) {
      const record = list.find((item) => {
        if (item[codeField] == code) {
          return item;
        }
      });
      if (record) {
        result = record[textField];
      }
    }

    return result;
  }

  static formatDate(date, format) {
    if (!format) {
      format = 'DD.MM.YYYY';
    }
    const m = moment(date);
    if (m.isValid()) {
      return m.format(format);
    }
  }

  static formatDateTime(date) {
    if (date === 'nullTnull') {
      return '';
    }
    const m = moment(date);
    if (m.isValid()) {
      return m.format('DD.MM.YYYY HH:mm');
    }
  }

  static combineDateAndTime(date, time) {
    let datetime = null;
    if (typeof date == 'string') {
      return `${date}T${time}`;
    }
    date = moment.utc(date).toDate();
    time = moment.utc(time).toDate();
    if (date != null && date != null) {
      datetime = new Date(
        Date.UTC(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          time.getUTCHours(),
          time.getUTCMinutes(),
          time.getUTCSeconds()
        )
      );
    }
    return datetime;
  }

  static setTimeOnDate(date, time) {
    let datetime = null;
    if (date != null && time != null) {
      datetime = `${date}T${time}`;
    }
    return datetime;
  }
}

export default GatUtils;
