<template>
  <gat-flex :size="size">
    <v-card text class="mr-0 mb-1 mt-3 subGrpCard elevation-0">
      <v-card-title :class="margins" class="mb-1 pa-0 cardTitle" v-if="title">{{ title }}</v-card-title>
      <v-layout wrap>
        <slot></slot>
      </v-layout>
    </v-card>
  </gat-flex>
</template>

<script>
import GatFlex from './GatFlex.vue';

export default {
  name: 'GatsubGroup',
  components: { GatFlex },
  props: {
    title: String,
    size: String,
  },

  computed: {
    margins() {
      let result = 'ml-3';
      // if phone
      if (this.$vuetify.breakpoint.xsOnly) {
        result = 'ml-2';
      }
      return result;
    },
  },
};
</script>

<style scoped>
.cardTitle {
  color: var(--gatGroupCaption);
  font-size: 120%;
}
.subGrpCard {
  background-color: transparent !important;
}
</style>
