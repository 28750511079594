<template>
  <div>
    <div @drop="drop" @dragover="dragover" @dragleave="dragleave">
      <div v-if="isDragging && !disabled" id="overlay" :class="computedClass">
        <div style="position: absolute; z-index: 1000; top: 45%; bottom: 0; right: 0; left: 0">
          <input type="file" multiple name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file" />
          <div class="text-center mx-auto text-white" style="width: fit-content">
            <div>Drop files here to upload</div>
          </div>
        </div>
        <div
          class="bg-primary"
          style="width: 100%; height: 100%"
          :style="isDragging ? 'opacity: .85' : 'opacity: 0.75'"></div>
      </div>
      <slot v-else name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileDragDrop',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup() {},
  data() {
    return {
      isDragging: false,
    };
  },

  created() {},

  mounted() {},

  watch: {},

  computed: {
    computedClass() {
      return this.embedded ? ['bg-white', 'dropzone-overlay-embedded'] : ['bg-none', 'dropzone-overlay'];
    },
  },

  methods: {
    selectUniqueFiles(draggedFiles) {
      const uniqueFiles = [];
      const uniqueFilenames = []; // Names of files to upload
      draggedFiles.forEach((file) => {
        const fileName = file.name;
        if (!uniqueFilenames.includes(fileName)) {
          uniqueFilenames.push(fileName);
          uniqueFiles.push(file);
        }
      });
      return uniqueFiles;
    },
    onChange(files) {
      try {
        if (this.disabled) return;
        const draggedFiles = [...files];
        const selectedUniqueFiles = this.selectUniqueFiles(draggedFiles);
        this.$emit('unique-files-selected', selectedUniqueFiles);
      } catch {
        this.isDragging = false;
      }
    },
    dragover(e) {
      if (this.disabled) return;
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      if (this.disabled) return;
      this.isDragging = false;
    },
    drop(e) {
      if (this.disabled) return;
      try {
        e.preventDefault();
        this.onChange(e.dataTransfer.files);
        this.isDragging = false;
      } catch {
        this.isDragging = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  min-width: 20vw;
  min-height: 20vh;
  border: 2px solid #FB8C00; // var(--v-primary-base);
  border-radius: 0.5rem;
}

.dropzone-overlay-embedded {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 20vh;
  z-index: 1000;
  min-width: 20vw;
  min-height: 20vh;
  border: 2px solid #FB8C00; // var(--v-primary-base);
  border-radius: 0.5rem;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
</style>
