<template>
  <gat-dialog
    v-if="followUpItem"
    v-model="showDialog"
    :title="!followUp.CFU_ID ? 'New follow up' : 'Follow up'"
    :okButtonCaption="!followUp.CFU_ID ? 'Add' : 'Save'"
    :okButtonIsProminent="true"
    width="800px"
    :autoClose="false"
    :deleteButton="!followUp.CFU_ID ? false : true"
    :itemDescription="'follow up'"
    @cancelClick="close('cancel')"
    @deleteClick="close('delete')"
    @okClick="close('save')">
    <gat-form v-model="isValid" ref="form">
      <GatSubGroup size="xs12">
        <gat-edit v-model="followUp.CFU_DATETIME" label="Date" size="xs12 sm4" :required="true" dateEdit />
        <gat-edit
          v-model="followUp.CFU_USER"
          label="User"
          size="xs12 sm4"
          :required="true"
          :maxChars="applicationStore.getColumnLength('Client_FollowUp.CFU_USER')" />
        <gat-edit
          v-model="followUp.CFU_NEXT_FOLLOWUP"
          label="Next follow up"
          size="xs12 sm4"
          :required="false"
          dateEdit />
        <gat-edit v-model="followUp.CFU_NOTE" :autofocus="true" label="Comments" size="xs12" :rows="4" :maxChars="0" />
        <gat-edit
          v-model="followUp.CFU_CLOSED"
          class="ma-0 pa-0"
          label="Closed"
          checkbox
          size="xs12"
          :checkedValue="1"
          :uncheckedValue="0" />
        <!-- <gat-edit v-model="followUp.CFU_ALERT" label="Alert" checkbox size="xs4 sm3" :checkedValue="1" :uncheckedValue="null" > </gat-edit> -->
      </GatSubGroup>
    </gat-form>
  </gat-dialog>
</template>

<script>
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'FollowUpForm',
  props: {
    followUpItem: {
      required: true,
      default: null,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      followUp: {
        /* CFU_ALERT: null */
      },
      isValid: true,
      showDialog: false,
    };
  },
  mounted() {
    this.followUp = this.value;
  },
  watch: {
    followUpItem: {
      immediate: true,
      handler(newValue) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        newValue ? (this.followUp = newValue) : null;
      },
    },
    dialog: {
      immediate: true,
      handler(newValue) {
        this.showDialog = newValue;
      },
    },
  },
  methods: {
    close(action) {
      // eslint-disable-next-line default-case
      switch (action) {
        case 'cancel':
          // eslint-disable-next-line no-lone-blocks
          {
            this.$emit('cancel');
          }
          break;
        case 'save':
          // eslint-disable-next-line no-lone-blocks
          {
            if (this.formIsValid()) {
              this.$emit('save', action);
            }
          }
          break;
        case 'delete':
          // eslint-disable-next-line no-lone-blocks
          {
            this.$emit('delete', action);
            this.showDialog = false;
            this.deleteDialog = false;
          }
          break;
      }
    },
    formIsValid() {
      this.$refs.form.validate();
      return this.isValid;
    },
  },
};
</script>

<style scoped></style>
