import { apiGet } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function getSofTitles(callId: string | number) {
  let result = await apiGet(`sof/all/${callId}`);
  result = result.map((sof: any) => {
    const res = sof;
    if (!res.TITLE) {
      res.TITLE = `SOF no ${sof.LINE_NO}`;
    }
    return res;
  });
  usePortcallStore().setSofTitles(result);
}
