var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.debug)?_c('record-presenter',{attrs:{"value":_vm.columns,"title":"columns"}}):_vm._e(),_c('gat-grid',{staticClass:"table-spacing elevation-0",attrs:{"items":_vm.rowData,"columns":_vm.columns,"favorites":_vm.favourites,"rowMenu":_vm.rowMenu,"gridName":"Whiteboard","height":_vm.getHeight,"fixedheader":true,"reloadBtn":"","hideEmptyColumns":true,"loading":_vm.loading || _vm.duplicating,"rowBar":true,"rowBarClass":_vm.getRowBarClass,"rowclass":_vm.getRowClass,"noCellWrap":_vm.noCellWrap,"stickyFirstColumn":false},on:{"favClick":_vm.favClicked,"row-clicked":_vm.gotoCall,"reload-clicked":function($event){return _vm.$emit('reload')},"rowMenuClicked":_vm.rowMenuClicked},scopedSlots:_vm._u([{key:"cell",fn:function(props){return (_vm.spesialColumnRendering(props))?[(props.column.field == 'Status')?_c('span',{class:_vm.whiteBoard.name == 'Search Board' ? 'pl-4' : ''},[_vm._v(_vm._s(props.value))]):_vm._e(),(props.column.field == 'Comments')?_c('div',{staticClass:"nowrap"},[_vm._v(_vm._s(props.value))]):_vm._e(),(_vm.applicationStore.user.internal && props.column.GTI_NAME == 'dbo.gsf_VesselName(P')?_c('a',{staticClass:"gridLink",attrs:{"href":'/vessel/' + props.row['VESSEL_ID']}},[_vm._v(_vm._s(props.value))]):_vm._e(),(_vm.applicationStore.user.internal && props.row.PAYING_PARTY_COLUMN == props.column.header)?_c('a',{staticClass:"gridLink",attrs:{"href":'/client/' + props.row.PAYING_PARTY_CLIENT_ID}},[_vm._v(_vm._s(props.row.PAYING_PARTY_CLIENT_NAME))]):_vm._e(),(
          (_vm.applicationStore.user.internal || _vm.applicationStore.userRight('CA.ACCESS')) &&
          props.column.GTI_NAME == 'dbo.gsf_GetCargoInfo'
        )?_c('a',{staticClass:"gridLink",attrs:{"href":'/call/' + props.row.ID + '/cargo-list'}},[_vm._v(_vm._s(props.value))]):_vm._e(),(props.column.GTI_NAME == 'PortCall.PORTCALL_NU' || props.column.field == 'Call no')?_c('span',{staticClass:"gridMainLink"},[_vm._v(_vm._s(props.value))]):_vm._e(),(props.column.GTI_NAME == 'case when Vessel.SHO' || props.column.GTI_NAME == '(select TOP 1 177 as')?_c('div',{staticClass:"d-flex flex-column nowrap justify-center"},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v(_vm._s(props.value == 177 ? 'mdi-alert-outline' : ''))])],1):_vm._e(),(props.column.checklistIcon)?_c('v-icon',{attrs:{"color":_vm.setChecklistIconColor(props.value)}},[_vm._v(_vm._s(_vm.setChecklistIcons(props.value)))]):_vm._e(),(props.column.GTI_NAME == 'Setup.ICON_INDEX')?_c('v-icon',{attrs:{"size":"28","color":_vm.getAgencyIconColor(props.value)}},[_vm._v(_vm._s(_vm.getAgencyIconName(props.value)))]):_vm._e(),(
          props.value &&
          (props.column.header.toLowerCase().includes('email') ||
            props.column.header.toLowerCase().includes('e-mail'))
        )?_c('div',{staticClass:"d-flex flex-column nowrap justify-center",staticStyle:{"height":"100%"},on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.createEmailLinksArrayFromString(props.value)),function(emailItem,index){return _c('div',{key:emailItem + index},[_c('span',[_vm._v(_vm._s(emailItem.caption))]),_c('a',{staticClass:"gridLink align-center",staticStyle:{"padding":"0.5rem","padding-left":"4px"},attrs:{"href":`mailto:${emailItem.email}`}},[_vm._v(_vm._s(emailItem.email))])])}),0):_vm._e(),(
          props.value &&
          props.column.header.toLowerCase().includes('phone') &&
          !props.column.header.toLowerCase().includes('e-mail') &&
          !props.column.header.toLowerCase().includes('email')
        )?_c('div',{staticClass:"d-flex flex-column nowrap justify-center",staticStyle:{"height":"100%"},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.createPhoneLinksFromString(props.value).length > 0)?_vm._l((_vm.createPhoneLinksFromString(props.value)),function(phoneLinks,index){return _c('div',{key:phoneLinks + index},[_c('span',[_vm._v(_vm._s(phoneLinks.caption))]),_c('a',{staticClass:"gridLink align-center",staticStyle:{"padding":"0.3rem","padding-left":"4px","width":"100%","height":"100%"},attrs:{"href":`tel:${phoneLinks.phoneNo}`}},[_vm._v(_vm._s(phoneLinks.phoneNo))])])}):_c('div',[_vm._v(_vm._s(props.value))])],2):_vm._e(),(props.column.field.toUpperCase() === 'ETA')?_c('span',{class:_vm.getExpiredDateClass(props.row)},[_vm._v(_vm._s(props.value))]):_vm._e(),(props.column.field.toUpperCase() === 'ETB')?_c('span',{class:_vm.getExpiredDateClass(props.row, 'ETB')},[_vm._v(_vm._s(props.value))]):_vm._e(),(props.column.field.toUpperCase() === 'ETD')?_c('span',{class:_vm.getExpiredDateClass(props.row, 'ETD')},[_vm._v(_vm._s(props.value))]):_vm._e(),(props.column.GTI_NAME === 'Departure')?_c('span',{class:_vm.getExpiredDateClass(props.row, 'Departure')},[_vm._v(_vm._s(_vm.formatArrivalDeparture(props)))]):_vm._e(),(props.column.GTI_NAME === 'Arrival')?_c('span',{class:_vm.getExpiredDateClass(props.row, 'Arrival')},[_vm._v(_vm._s(_vm.formatArrivalDeparture(props)))]):_vm._e()]:undefined}}],null,true)}),(_vm.dialogVisible && _vm.duplicateParams)?_c('duplicate-port-call',{attrs:{"showForm":_vm.dialogVisible,"fromTemplate":_vm.fromTemplate,"duplicateParams":_vm.duplicateParams,"portcall":_vm.duplicatePortcall},on:{"close":_vm.closeDuplicateDialog}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }