<template>
    <div>
        <services></services>
    </div>
</template>

<script>
import Services from '../services/Services.vue'

    export default {
        name: 'UpcomingServices',
        props: [],
        components: {
            Services
        },
        data() {
            return	{
            }
        },


        created(){
        },

        watch:{
        },

        computed:{
        },

        methods:{
        },
    }
</script>

<style scoped>

</style>
