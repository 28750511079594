<template>
  <gat-select
    v-if="showAll"
    :label="labelComputed"
    v-model="model"
    :items="items"
    textFieldName="NAME"
    codeFieldName="ID"
    :autofocus="autofocus"
    :readonly="readonly"
    :required="required"
    :clearable="clearable"
    @input="updateValue()"
    :disabled="disabled"
    :useIcons="useIcons">
  </gat-select>
  <gat-lookup-edit
    v-else
    :label="labelComputed"
    v-model="model"
    :searchresult="items"
    @getItems="getItems($event)"
    textFieldName="displayName"
    codeFieldName="ID"
    :cashSearches="true"
    :size="size"
    :autofocus="autofocus"
    :readonly="readonly"
    :required="required"
    :disabled="disabled"
    :clearable="clearable"
    @input="updateValue()"
    @itemNotFound="itemNotFound"
    customItem>
    <template v-slot:item="{ data }">
      <div>{{ data['displayName'] }}</div>
      <div v-if="showClientWarning || showClientCreditLimit" class="ml-auto">
        <v-icon
          v-if="showClientWarning && +data.SHOW_WARNING === 1"
          class="orange--text"
          :class="showClientCreditLimit && data.CLIENT_CREDIT_FLAG > 0 ? 'mr-1' : ''"
          >mdi-alert</v-icon
        >
        <v-icon
          v-if="showClientCreditLimit && data.CLIENT_CREDIT_FLAG > 0"
          :class="getClienCreditFlagColor(+data.CLIENT_CREDIT_FLAG)"
          >mdi-flag</v-icon
        >
      </div>
    </template>
  </gat-lookup-edit>
</template>

<script>
import { apiGet, apiPost } from '@/store/api';

export default {
  name: 'GatClientSelect',
  props: {
    value: Number,
    size: String,
    category: String,
    label: String,
    role: {
      type: [String, Number],
    },
    showAll: Boolean,
    autofocus: Boolean,
    readonly: Boolean,
    required: Boolean,
    disabled: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
    useIcons: Boolean,
    showClientCreditLimit: Boolean,
    showClientWarning: Boolean,
  },
  components: {},
  data() {
    return {
      items: [],
      model: null,
      item: {},
    };
  },

  computed: {
    labelComputed() {
      let result = this.label;
      if (!result) {
        result = 'Client';
      }
      return result;
    },
  },

  created() {},

  watch: {
    value: {
      handler(val) {
        this.model = val;
        this.setItem(val, this.items);
      },
      immediate: true,
    },

    items(val) {
      this.setItem(this.model, val);
    },

    showAll(all) {
      if (all) {
        this.getItems('');
      }
    },
  },

  methods: {
    getItems(searchText) {
      apiPost('helper/search/', { table: 'client', text: searchText, text2: this.role }).then((data) => {
        let clients = data;
        if (typeof this.category !== 'undefined') {
          clients = clients.filter((item) => item.CATEGORY == this.category);
        }
        this.setDisplayName(clients);
        this.items = clients;
      });
    },

    setDisplayName(clients) {
      // eslint-disable-next-line array-callback-return
      clients.map((client) => {
        let displayName = client.NAME;
        if (client.ACCOUNT_NO) {
          displayName = `${displayName} (${client.ACCOUNT_NO})`;
        }
        // eslint-disable-next-line no-param-reassign
        client.displayName = displayName;
      });
    },

    setItem(id, items) {
      if (id && items && items.length > 0) {
        const item = items.find((x) => x.ID == id);
        if (item && item.ID != this.item.ID) {
          this.item = item;
          this.$emit('item-selected', item);
        }
      }
    },

    updateValue() {
      this.$emit('input', this.model);
      this.setItem(this.model, this.items);
    },

    itemNotFound(id) {
      apiGet(`helper/client/id/${id}`).then((data) => {
        this.setDisplayName(data);
        this.items = data;
      });
    },

    getClienCreditFlagColor(value) {
      let result = '';
      switch (+value) {
        case 0:
          result = 'd-none';
          break;
        case 1:
          result = 'red--text text--darken-1';
          break;
        case 2:
          result = 'orange--text text--darken-1';
          break;
        case 3:
          result = 'green--text text--darken-1';
          break;
        default:
          result = 'd-none';
          break;
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
