import { apiGetFile } from '@/store/api';

function download(docId, fileName) {
  if (docId) {
    apiGetFile(`document/download/${docId}`, fileName).then(() => {});
  }
}

function getMdiFileIcon(fileName) {
  let result = {
    icon: 'mdi-file',
    color: 'grey',
  };
  if (!fileName) {
    return result;
  }

  if (fileName.endsWith('docx') || fileName.endsWith('rtf') || fileName.endsWith('doc')) {
    result = { icon: 'mdi-file-word-box', color: 'blue darken-2' };
  } else if (fileName.endsWith('xlsx') || fileName.endsWith('xls') || fileName.endsWith('csv')) {
    result = { icon: 'mdi-file-excel-box', color: 'green darken-2' };
  } /* else if (fileName.endsWith("txt") || fileName.endsWith("csv")) {
    return iconLocation + "txt.png";
  }  */ else if (fileName.endsWith('pdf')) {
    result = { icon: 'mdi-file-pdf-box', color: 'red darken-2' };
  } else if (fileName.endsWith('jpg') || fileName.endsWith('jpeg')) {
    result = { icon: 'mdi-file-jpg-box', color: 'yellow darken-3' };
  } else if (fileName.endsWith('png')) {
    result = { icon: 'mdi-file-png-box', color: 'yellow darken-3' };
  }
  return result;
}

const DocumentHelper = {
  download,
  getMdiFileIcon,
};

export default DocumentHelper;
