import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';

export async function isExpenseOnPortcallLocked(callId: string | number) {
  const applicationStore = useApplicationStore();
  const portcallStore = usePortcallStore();
  if (applicationStore.globalSettings.SGL_EXP_LOCK_MONTHS_AFTER_ATD <= 0) {
    portcallStore.setExpenseLocked(false);
    return false;
  }
  if (applicationStore.userRight('EX.IGNORE_LOCKED_EXPENSES')) {
    portcallStore.setExpenseLocked(false);
    return false;
  }
  let isLockedByAtd = 0;
  let isLockedByStatus = 0;
  // select CASE WHEN DATEDIFF(MONTH,ISNULL(P.ATD_DATE,''19000101''),GETDATE()) >= @monthsAfterAtd then 1 else 0 end as IsLockedByAtd
  // ,case when P.STATUS = 5 then 1 else 0 end as IsLockedByStatus
  // from PortCall P
  // where P.ID = @callId
  // SQL PARAM: @callId = portcallId
  // SQL PARAM: @monthsAfterAtd = SGL_EXP_LOCK_MONTHS_AFTER_ATD
  const result = await apiGet(`portcall/${callId}/is-locked-by-atd`);
  if (result) {
    isLockedByAtd = result.IsLockedByAtd;
    isLockedByStatus = result.IsLockedByStatus;
  }

  portcallStore.setExpenseLocked(isLockedByAtd === 1 && isLockedByStatus === 1);
  return isLockedByAtd === 1 && isLockedByStatus === 1;
}
