<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="400px" @keydown.enter="login">
      <v-card class="pa-2">
        <v-card-title primary-title>
          <v-container fill-height>
            <v-layout row wrap align-center>
              <v-flex class="text-xs-center">
                <LoginLogo />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-title>
        <gat-form
          v-model="isValid"
          ref="form"
          class="ml-3 mr-4"
          v-if="!isLoading"
          autocomplete="on"
          data-lpignore="false"
          data-form-type="username, password">
          <v-alert class="ml-2 mr-2" :text="isErrorMsg" :type="isErrorMsg ? 'error' : undefined">
            <div>
              {{ errorMsg }}
            </div>
            <div v-if="errorMsgLine2">
              {{ errorMsgLine2 }}
            </div>
          </v-alert>
          <div>
            <gat-select
              v-show="clientDbs && clientDbs.length > 1"
              :items="clientDbs"
              prepend-icon="home"
              name="db"
              label="client"
              v-model="db"
              size="xs12"
              :noflex="true" />
            <v-text-field
              autocomplete="on"
              data-lpignore="false"
              data-form-type="username"
              ref="username"
              prepend-icon="person"
              name="Username"
              label="Username"
              v-model="userName"
              :autofocus="true"
              :outlined="true"
              :dense="gatComponentsStore.input_dense"></v-text-field>
            <v-text-field
              autocomplete="on"
              data-lpignore="false"
              data-form-type="password"
              ref="password"
              prepend-icon="lock"
              name="Password"
              label="Password"
              type="password"
              v-model="pwd"
              :outlined="true"
              :dense="gatComponentsStore.input_dense"></v-text-field>
          </div>
          <v-card-actions class="d-flex" :class="$vuetify.breakpoint.xs ? 'flex-column-reverse' : ''">
            <v-spacer></v-spacer>
            <v-btn class="mb-2 ma-2" text right @click="forgotPassword" :disabled="!(throttleRemainingSeconds == 0)"
              >Forgot password</v-btn
            >
            <v-btn class="mb-2 ma-2 signin-button right" color="primary" @click="login" :disabled="disableSignInBtn"
              >Sign in</v-btn
            >
          </v-card-actions>
        </gat-form>
        <div v-else class="d-flex flex-column align-center justify-center" style="height: 300px">
          <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular>
          <div class="d-flex flex-column text-center">
            <span> Authorizing </span>
            <span> please wait... </span>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <ChangePassword :showDialog="showPasswordDlg" @close="showPasswordDlg = false" :logOutOnCancel="true" />
    <OneTimeCode :showDialog="showOneTimeCodeDlg" @close="oneTimeCodeDlgClose()" />
    <ForgotPassword :showDialog="showForgotPasswordDlg" @close="showForgotPasswordDlg = false" :db="db" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { getApiToken, apiGet } from '@/store/api';
import { saveLoginNameToWebStorage, getLoginNameFromWebStorage } from '@/store/webStorage';
import { getApiTokenFromCookie, saveApiTokenToCookie } from '@/store/cookieStorage';
import { isValidBrowser, browserName, browserVersion, browserOs, browserPlatform } from '@/store/browserDetect';
import { useApplicationStore } from '@/store/applicationStore';
import { useGatComponentsStore } from '@/store/gatComponentsStore';

export default {
  name: 'Auth',
  components: {
    ChangePassword: () => import('./ChangePassword.vue'), // Uses lambda function to import componet to ommit problem with the component used in Auth and UserMenu
    OneTimeCode: () => import('./OneTimeCode.vue'),
    ForgotPassword: () => import('./ForgotPassword.vue'),
    LoginLogo: () => import('./LoginLogo.vue'),
  },
  setup() {
    const applicationStore = useApplicationStore();
    const gatComponentsStore = useGatComponentsStore();
    return {
      applicationStore,
      gatComponentsStore,
    };
  },
  data() {
    return {
      isValid: true,
      isLoading: false,
      dialog: false,
      showPasswordDlg: false,
      showOneTimeCodeDlg: false,
      showForgotPasswordDlg: false,
      throttleRemainingSeconds: 0,
      throttleTimer: null,
      messageType: 0, // 0 = info, 1 = error
      userName: '',
      pwd: '',
      errorMsg: ' ',
      errorMsgLine2: '',
      db: 'nodbconfigured',
      clientDbs: ['nodbconfigured'],
    };
  },

  mounted() {
    this.dialog = true;
  },

  created() {
    apiGet('auth').then((data) => {
      this.clientDbs = data;
      if (this.clientDbs.length == 1) {
        // eslint-disable-next-line prefer-destructuring
        this.db = this.clientDbs[0];
      }
    });
    const cred = getLoginNameFromWebStorage();
    if (cred.db && this.clientDbs && this.clientDbs.length > 1) {
      this.db = cred.db;
    }
    this.userName = cred.username;
  },

  deactivated() {
    clearInterval(this.throttleTimer);
  },

  computed: {
    disableSignInBtn() {
      if (!this.userName) {
        return true;
      }
      if (!this.pwd) {
        return true;
      }
      if (this.throttleRemainingSeconds !== 0) {
        return true;
      }
      return false;
    },
    isErrorMsg() {
      return this.messageType >= 1;
    },
  },

  methods: {
    getBrowserInfo() {
      return `BrowserValid: ${
        isValidBrowser() || false
      } - BrowserName: ${browserName()} - BrowserVersion: ${browserVersion()} - Platform: ${browserPlatform()} - OS: ${browserOs()} -`; // + navigator.userAgent;
    },
    getErrorMessage(licenseType) {
      if (licenseType == 1 && this.userName.indexOf('@') == -1) {
        return 'Wrong username, email or password, please retry! The given username is not a valid email address, please retry login with a valid email address as your username.';
      }
      if (licenseType == 3) {
        return 'Wrong username or password, please retry!';
      }
      return 'Wrong username, email or password, please retry!';
    },
    forgotPassword() {
      console.log('forgotPassword');
      console.log(this.clientDbs);
      console.log(this.clientDbs.length);
      console.log(this.db.indexOf('nodbconfigured'));
      console.log(this.clientDbs && this.clientDbs.length > 1 && this.db.indexOf('nodbconfigured') == -1);

      if (this.clientDbs && this.clientDbs.length > 1 && this.db.indexOf('nodbconfigured') != -1) {
        // We do have multiple connectionstrings in the backend. Make sure we have selected one of them
        this.messageType = 0;
        this.errorMsg = 'Please select a client first!';
      } else if (this.db.indexOf('nodbconfigured') >= 0) {
        this.messageType = 1;
        this.errorMsg = 'Could not connect to the database!';
      } else {
        this.errorMsg = '';
        this.messageType = 0;
        this.showForgotPasswordDlg = true;
      }
    },
    login() {
      this.$refs.form.validate();
      if (this.isValid && !this.isLoading && this.throttleRemainingSeconds == 0) {
        if (!(this.userName && this.pwd && this.throttleRemainingSeconds == 0)) {
          return;
        }

        if (this.clientDbs && this.clientDbs.length > 1 && this.db.indexOf('nodbconfigured') != -1) {
          // We do have multiple connectionstrings in the backend. Make sure we have selected one of them
          this.messageType = 0;
          this.errorMsg = 'Please select a client first!';
          return;
        }

        if (this.db.indexOf('nodbconfigured') >= 0) {
          this.messageType = 1;
          this.errorMsg = 'Could not connect to the database!';
          return;
        }
        this.isLoading = true;
        getApiToken(this.userName, this.pwd, this.db, this.getBrowserInfo())
          .then((data) => {
            // save token
            saveApiTokenToCookie(data.token);
            // save db/username
            saveLoginNameToWebStorage(this.userName, this.db);

            // // update store

            // Check 2FA
            if (data.require2fa) {
              this.showOneTimeCodeDlg = true;
            } else if (data.forcePasswordChange) {
              // change password
              this.showPasswordDlg = true;
            } else {
              // navigate to home page

              // update store
              this.applicationStore.setApiToken(data.token);

              this.dialog = false;
              this.$router.push('/');
            }
          })
          .catch((error) => {
            console.log('Auth - getApitoken', error);
            if (error.response && error.response.status == 429) {
              // Throttle message
              this.throttleRemainingSeconds = error.response.headers['gs-throttle-remaining-sec'];
              this.messageType = 1;
              this.errorMsg = this.getErrorMessage(error.response.data.webModuleLicense);
              this.errorMsgLine2 = `Please wait ${this.throttleRemainingSeconds}sec!`;

              // Clear timer before we start it so we do not have multiple timers running in different processes
              clearInterval(this.throttleTimer);
              // Start timer for throttle countdown
              this.throttleTimer = setInterval(this.updateThrottleTime, 1000); // runs method every second.
              return;
            }

            if (error.message) {
              this.messageType = error.messageType;
              this.errorMsg = error.message;
              this.errorMsgLine2 = error.messageLine2;
            } else {
              this.messageType = 1;
              this.errorMsg = this.getErrorMessage(error.webModuleLicense);
              this.errorMsgLine2 = '';
            }
          })
          .finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          });
      }
    },
    oneTimeCodeDlgClose() {
      // update store
      this.applicationStore.setApiToken(getApiTokenFromCookie());
      this.showOneTimeCodeDlg = false;
    },
    updateThrottleTime() {
      if (this.throttleRemainingSeconds <= 0) {
        // Stop timer if no time left on throttling
        clearInterval(this.throttleTimer);
        this.errorMsgLine2 = '';
      } else {
        this.throttleRemainingSeconds -= 1;
        const minutes = Math.floor(this.throttleRemainingSeconds / 60);
        const seconds = Math.floor(this.throttleRemainingSeconds % 60);

        let msg = 'Please wait ';
        if (minutes > 0) {
          msg = `${msg + minutes}m `;
        }
        if (seconds > 0) {
          msg = `${msg + seconds}s`;
        }
        msg = `${msg.trim()}!`;

        this.errorMsgLine2 = msg;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.signin-button {
  min-width: 150px !important;
}

/* .theme--light.first-of-type.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    color: white !important;
    background-color: orange !important;
  }

  .theme--light:first-of-type.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    color: grey !important;
  }

  .theme--dark.first-of-type.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    color: white !important;
  }

  .theme--dark.first-of-type.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
    color: grey !important;
  } */
</style>
