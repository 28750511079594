const Attendance = () => import("@/views/attendance/Attendance.vue");
const AttendanceList = () => import("../components/AttendanceList.vue");
const AttendanceItem = () => import("../components/AttendanceItem.vue");
const CheckOut = () => import("../components/CheckOut.vue");

const attendanceRoutes = [
  {
    path: "/attendance",
    component: Attendance,
    props: true,
    children: [
      {
        path: "",
        name: "AttendanceList",
        meta: { pageTitle: "Attendance" },
        component: AttendanceList,
        props: true,
      },
      {
        name: "attendanceItem",
        path: "/attendance/:attendanceId",
        meta: { pageTitle: "Attendance" },
        component: AttendanceItem,
        props: (route) => ({
          attendanceId:
            +route.params.attendanceId > 0 ? +route.params.attendanceId : null,
          isNew: route.params.isNew,
          callId: +route.params.callId,
        }),
      },
      {
        path: "checkin",
        name: "CheckIn",
        meta: { pageTitle: "Attendance - Check in" },
        component: AttendanceItem,
        props: (route) => ({
          attendanceId: null,
          isNew: true,
          callId: +route.params.callId > 0 ? +route.params.callId : null,
          isCheckin: true,
        }),
      },
      {
        path: "checkout",
        name: "Checkout",
        meta: { pageTitle: "Attendance - Check out" },
        component: CheckOut,
        props: (route) => ({
          callId: +route.params.callId > 0 ? +route.params.callId : 0,
          isCheckin: false,
        }),
      },
    ],
  },
];

export default attendanceRoutes;
