<template>
  <v-tooltip v-if="showTooltip" bottom style="z-index: 1050">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <country-flag :country="countryCode" :size="size" class="flagIdcon" />
      </div>
    </template>
    <span>{{ countryFullName }}</span>
  </v-tooltip>
  <country-flag v-else :country="countryCode" :size="size" class="flagIdcon" />
</template>

<script>
import iso from 'iso-3166-1';
import CountryFlag from 'vue-country-flag';

export default {
    name: 'GatCountryFlag',
    props: {
      value: {
        type: String,
        required: true,
      },
      size: {
        type: String,
        default: 'normal',
      },
      countryIsFullName: {
        type: Boolean,
        default: false,
      },
      showTooltip: {
        type: Boolean,
        default: true,
      },
    },
    components: {CountryFlag},

    data() {
        return  {
             
        };
    },

    computed: {
    countryCode() {
      if (this.value) {
        if (this.countryIsFullName) {
          return this.getCountryCodeISOByCountryName(this.value);
        }
        return this.value;
      }
      return ' ';
    },
    countryFullName() {
      if (this.countryIsFullName) return this.value;
      const nameByAlpha2 = iso.whereAlpha2(this.value);
      if (nameByAlpha2 && nameByAlpha2.country) return nameByAlpha2.country;
      const nameByAlpha3 = iso.whereAlpha3(this.value);
      if (nameByAlpha3 && nameByAlpha3.country) return nameByAlpha3.country;
      return '';
    },
  },

    created(){
    },

    watch:{
    },

    methods:{
      getCountryCodeISOByCountryName(countryName) {
        const country = iso.whereCountry(countryName);
        if (!country) return '';
        return country.alpha3;
      },
    },
};
</script>

<style scoped>

</style>
