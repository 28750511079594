// eslint-disable-next-line import/no-cycle
import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getNumUpcomingTasks(clientId: string | number) {
  const applicationStore = useApplicationStore();
  const data = await apiGet(`crewchange/numUpcomingTasks/${clientId}`);

  if (data[0].ACCOM_AMOUNT > 0) {
    applicationStore.setUpcomingAccommodations(true);
  }
  if (data[0].TRANSP_AMOUNT > 0) {
    applicationStore.setUpcomingTransports(true);
  }
}
