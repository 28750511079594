// eslint-disable-next-line import/no-cycle
import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getNumUpcomingServices(clientId: string | number) {
  const applicationStore = useApplicationStore();
  const data = await apiGet(`service/numUpcomingServices/${clientId}`);
  if (data[0].SERVICE_AMOUNT > 0) {
    applicationStore.setUpcomingServices(true);
  }
}
