import numeral from 'numeral';

export const GatGridFooterMixin = {
  props: ['columns', 'items', 'selectable', 'favorites'],
  data: () => ({}),

  computed: {
    columnsFromFirstFooter() {
      const result = [];
      for (let index = this.firstFooterColumnIndex; index < this.visibleColumns.length; index++) {
        const col = this.visibleColumns[index];
        result.push(col);
      }
      return result;
    },

    footerCount() {
      let result = 0;
      // eslint-disable-next-line array-callback-return
      this.columns.map((col) => {
        if (col && col.footerFunction) {
          result++;
        }
      });
      return result;
    },

    firstFooterColumnIndex() {
      for (let index = 0; index < this.visibleColumns.length; index++) {
        const col = this.visibleColumns[index];
        if (col && col.footerFunction) {
          return index;
        }
      }
      return -1;
    },

    visibleColumns() {
      let result = [];
      result = this.columns.filter((item) => {
        if (item.groupedBy) {
          return false;
        }
        return true;
      });
      return result;
    },
  },

  methods: {
    getFooterValue(col) {
      let result;
      if (col.footerFunction) {
        switch (col.footerFunction.fn) {
          case 'count': {
            result = 0;
            if (this.items) {
              // eslint-disable-next-line array-callback-return
              this.items.map((item) => {
                if (Object.prototype.hasOwnProperty.call(item, col.field)) {
                  const value = item[col.field];
                  if (value) {
                    result += 1;
                  }
                }
              });
            }
            break;
          }
          case 'count unique': {
            result = 0;
            const values = [];
            if (this.items) {
              // eslint-disable-next-line array-callback-return
              this.items.map((item) => {
                if (Object.prototype.hasOwnProperty.call(item, col.field)) {
                  const value = item[col.field];
                  if (value) {
                    if (values.indexOf(value) == -1) {
                      result += 1;
                      values.push(value);
                    }
                  }
                }
              });
            }
            break;
          }
          case 'sum':
            result = 0;
            if (this.items) {
              // eslint-disable-next-line array-callback-return
              this.items.map((item) => {
                if (Object.prototype.hasOwnProperty.call(item, col.field)) {
                  const value = numeral(item[col.field]).value();
                  // eslint-disable-next-line no-restricted-globals
                  if (!isNaN(value)) {
                    result += value;
                  }
                }
              });
            }
            break;
          case 'average':
            result = 0;
            if (this.items) {
              // eslint-disable-next-line array-callback-return
              this.items.map((item) => {
                if (Object.prototype.hasOwnProperty.call(item, col.field)) {
                  const value = numeral(item[col.field]).value();
                  // eslint-disable-next-line no-restricted-globals
                  if (!isNaN(value)) {
                    result += value;
                  }
                }
              });
            }
            if (this.items.length > 0) {
              result /= this.items.length;
            }
            break;
          case 'min':
            if (this.items) {
              // eslint-disable-next-line array-callback-return
              this.items.map((item) => {
                if (Object.prototype.hasOwnProperty.call(item, col.field)) {
                  const value = numeral(item[col.field]).value();
                  // eslint-disable-next-line no-restricted-globals
                  if (!isNaN(value)) {
                    if (value < result || typeof result == 'undefined') {
                      result = value;
                    }
                  }
                }
              });
            }
            break;
          case 'max':
            if (this.items) {
              // eslint-disable-next-line array-callback-return
              this.items.map((item) => {
                if (Object.prototype.hasOwnProperty.call(item, col.field)) {
                  const value = numeral(item[col.field]).value();
                  // eslint-disable-next-line no-restricted-globals
                  if (!isNaN(value)) {
                    if (value > result || typeof result == 'undefined') {
                      result = value;
                    }
                  }
                }
              });
            }
            break;
          default:
            break;
        }
      }

      result = this.formatValue(result, col.dataType, false, col);

      // add text after the calculated value
      if (col.footerFunction.postText) {
        result += col.footerFunction.postText;
      }

      // add text before the calculated value;
      if (col.footerFunction.preText) {
        result = col.footerFunction.preText + result;
      }

      return result;
    },
  },
};
