import { apiGet } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function getExpenses(callId: string | number) {
  let result = await apiGet(`expense/call/${callId}`);
  if (result) {
    // map expense credit flag & balance
    result = result.map((data: any) => {
      const expense = data;
      expense.CLIENT_CREDIT_BALANCE = 0;
      if (expense.CLIENT_CREDIT_FLAG === '0') {
        expense.CLIENT_CREDIT_FLAG = +expense.CLIENT_CREDIT_FLAG;
      } else {
        const flagLimit = expense.CLIENT_CREDIT_FLAG.split('#');
        expense.CLIENT_CREDIT_FLAG = +flagLimit[0];
        expense.CLIENT_CREDIT_BALANCE = +flagLimit[1];
      }
      return expense;
    });
  }
  usePortcallStore().setExpenses(result);
}
