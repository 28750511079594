export function createReportObject(report) {
  const newReport = {};
  newReport.reportName = report.DAS_ID;
  newReport.title = report.DAS_TITLE;
  newReport.freezeColumn = report.DAS_FREEZE_COLUMNS;
  newReport.sorting = report.DAS_SORTING;

  // create the settings object.
  const settings = { chartType: 'Table' }; // chartType should be table by default if no value is given.
  if (report.DAS_CHART_TYPE) {
    settings.chartType = report.DAS_CHART_TYPE;
  }
  settings.hidden = +report.DAS_HIDDEN === 1;
  settings.hiddenOnPhone = +report.DAS_HIDDEN_ON_PHONE === 1;
  // Assign the chart sizes to settings.
  settings.size = {
    width: report.DAS_WEB_DASH_WIDTH || 0,
    height: report.DAS_WEB_DASH_HEIGHT || 0,
  };
  // Create the options object and assign values.
  let options = {};
  if (report.DAS_SETTINGS) {
    options = JSON.parse(report.DAS_SETTINGS);
    if (!report.DAS_CHART_TYPE && options.chartType) {
      // To account for old setup in classic.
      settings.chartType = options.chartType;
    }
    if (options.chartType) {
      // remove chartType from options.
      delete options.chartType;
    }
    if (options.options) {
      options = options.options;
    }
  }
  options.isStacked = +report.DAS_IS_STACKED === 1;

  // Add the options to the settings.
  settings.options = options;

  newReport.settings = settings;
  newReport.userSettings = {};
  newReport.defaultSettings = {};
  return newReport;
}
