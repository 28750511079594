import { computed, ref, watch } from 'vue';
import moment from 'moment';
import { useApplicationStore } from '@/store/applicationStore';
import GatDateEditFunctions from '../common/GatDateEditFunctions';

interface Props {
  value: string | null;
  futureDate?: boolean;
}

export function useGatDateEditModelValue(props: Props) {
  const applicationStore = useApplicationStore();

  const dateEditValue = ref<string | null>(null);

  const displayFormat = computed((): string => applicationStore.settings.dateFormat ?? 'DD.MM.YYYY');

  const dataFormat = 'YYYY-MM-DD';

  function textToValidDateTextOrNull(text: string | null, inFormat: string): string | null {
    if (text) {
      return GatDateEditFunctions.parseTextToDate(text, inFormat, props.futureDate).parsedDate?.toISODate() || null;
    }
    return null;
  }

  function setDate(newValue: string | null) {
    if (moment(newValue).format(dataFormat) !== dateEditValue.value) {
      const date = textToValidDateTextOrNull(newValue, dataFormat);
      if (date === null) {
        dateEditValue.value = null;
      } else {
        dateEditValue.value = moment(date).format(displayFormat.value);
      }
    }
  }

  watch(
    () => props.value,
    (newValue) => {
      setDate(newValue);
    },
    {
      immediate: true,
    }
  );

  // Is replica of gs-cloud's globalHelperFunctions.formatDate
  function formatDate(date: Date | string, format?: string): string {
    const dt = moment(date);
    if (dt.isValid()) {
      return dt.format(format);
    }
    return '';
  }

  watch(
    () => displayFormat.value,
    (newValue) => {
      // Watch in case the user changes date format.
      if (props.value) {
        dateEditValue.value = formatDate(props.value, newValue);
      }
    }
  );

  return {
    dateEditValue,
    setDate,
  };
}
