import { apiPost } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export function removeFromLastClientUsed(clientId: string | number) {
  const applicationStore = useApplicationStore();
  const lastUsedClients = [...applicationStore.clients.lastClientsUsed];
  const idx = lastUsedClients.findIndex((item) => item.ID == clientId);
  if (idx >= 0) {
    lastUsedClients.splice(idx, 1);
  }
  applicationStore.clients.lastClientsUsed = lastUsedClients;
  const savedInfo = {
    TypeName: 'web_last_clients',
    StrValue: JSON.stringify(lastUsedClients),
  };
  apiPost('savedInfo', savedInfo);
}
