<template>
  <div>
    <div v-if="expanded">
      <div v-for="(item, index) in logItem.log_details" :key="index">
        <span class="blue--text text-overline">{{ item.FIELD }} </span>
        :
        <span v-show="logItem.LO_LOGTYPE == 'Update' || logItem.LO_LOGTYPE == 'EDIT'" class="text-overline">
          {{ item.OLD || 'null' }}
          <v-icon small>mdi-arrow-right-bold</v-icon>
        </span>

        <span class="text-overline font-weight-medium"
          ><b>{{ item.NEW }}</b></span
        >
      </div>
    </div>
    <div v-else>
      <div class="blue--text text-overline">
        <span>Primary key </span> :
        <span class="text-overline font-weight-medium"
          ><b>{{ logItem.LO_TABLE_ID }}</b></span
        >
      </div>
      <div v-if="logItem.log_details && logItem.LO_LOGTYPE != 'Delete'" class="grey--text text-overline">
        {{ logItem.log_details.length }} fields <span v-show="logItem.LO_LOGTYPE == 'Insert'">Inserted</span
        ><span v-show="logItem.LO_LOGTYPE != 'Insert'">changed</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventLogDetails',
  props: ['logItem', 'expanded'],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  methods: {},
};
</script>

<style scoped></style>
