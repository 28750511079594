<template>
  <v-dialog v-if="$route.name !== 'NoLicense'" :value="value" width="400px" persistent overlay-opacity="0.8">
    <v-card>
      <v-card-title class="title"> {{ initializingText }} </v-card-title>
      <v-card-text> Please wait... </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InitializingDialog',
  props: {
    value: Boolean,
  },
  components: {},
  data() {
    return {
      configLoaded: false,
    };
  },

  created() {
    this.getGsConfig();
  },

  computed: {
    initializingText() {
      if (!this.configLoaded) {
        return 'Initializing GAT-Ship';
      }
      return 'Loading GAT-Ship';
    },
  },

  methods: {
    getGsConfig() {
      if (this.$gsconfig) {
        this.configLoaded = true;
      }
      setTimeout(() => {
        this.getGsConfig();
      }, 500);
    },
  },
});
</script>

<style scoped></style>
