<template>
  <v-simple-table>
    <tr>
      <td v-for="(col, index) in columns" :key="index" :style="styles(col)">
        {{ item[col.field] }}
      </td>
    </tr>
  </v-simple-table>
</template>

<script>
export default {
  name: 'GatSelectItem',
  props: ['item', 'columns'],
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {},

  methods: {
    styles(column) {
      let result = '';
      if (column.width) {
        result = `${result}width:${column.width}px; max-width:${column.width}px; min-width:${column.width}px;`;
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
