<template>
  <!-- FULLSCREEN chart item -->
  <div
    v-if="settings && showChart"
    :class="maximized ? 'pa-1 ' : 'pa-2 ' + chartSize"
    v-show="showChart"
    style="overflow: hidden">
    <v-dialog v-model="maximized" fullscreen v-if="maximized" overflow="hidden">
      <v-card
        @mouseover="hover = true"
        @mouseleave="hover = false"
        :class="maximized ? 'elevation-0' : ''"
        style="overflow: hidden; border-radius: 0">
        <div class="chartHeader d-flex d-flex-inline align-center" :style="'height:' + headerHeight + 'px;'">
          <span
            class="ml-3"
            :class="{ hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown) }"
            >{{ title }}
          </span>
          <div class="chart-hover-menu ml-auto d-flex flex-row-reverse">
            <v-btn v-show="maximized" small text class="pa-0 mr-2 ml-1" color="primary" @click="maximized = false"
              ><v-icon>close</v-icon>close</v-btn
            >
            <chart-settings-menu
              :cardActive="hover || maximized"
              :settings="settings"
              :reportName="reportName"
              :maximized="maximized"
              @refresh="loadData"
              :location="location"
              :dashboardGroupName="dashboardGroupName" />
            <v-btn small text icon v-show="hover && !maximized" class="right" @click="maximized = true">
              <v-icon>fullscreen</v-icon>
            </v-btn>
            <v-btn small text icon v-show="hover && !maximized" class="right" @click="loadData">
              <v-icon>refresh</v-icon>
            </v-btn>
          </div>
        </div>
        <div
          v-if="chartIsLoading && !loadError"
          class="d-flex flex-grow-1 justify-center align-center"
          :style="'height:' + (getTableHeight - headerHeight - 9) + 'px'">
          <v-container
            style="width: inherit; height: inherit"
            class="d-flex flex-column flex-grow-1 justify-center align-center">
            <v-progress-circular indeterminate></v-progress-circular>
            <span class="ml-4 mt-2">Loading...</span>
          </v-container>
        </div>

        <div v-show="!chartIsLoading && !loadError">
          <GChart
            v-if="chartData && chartData.length > 0 && settings.chartType != 'Table'"
            :key="chartKey"
            class="chartBox"
            :class="{
              hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
              maximized: maximized,
            }"
            :create-chart="drawGEOChart"
            :data="chartData"
            :options="chartOptions"
            :settings="chartSettings"
            :tableHeight="getTableHeight" />
          <!-- :resizeDebounce="500" -->
          <chart-table
            v-else
            :data="chartData"
            :maximized="maximized"
            :columns="columns"
            :class="{
              hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
              maximized: maximized,
            }"
            :formating="chartOptions.formating"
            :stickyFirstColumn="stickyFirstColumn"
            :headerHeight="headerHeight"
            :tableHeight="getTableHeight" />
        </div>
        <v-alert v-if="loadError" outlined type="error">
          <v-row align="center">
            <v-col class="grow">Network error. Could not load data.</v-col>
            <v-col class="shrink">
              <v-btn small @click="loadData">Retry</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card>
    </v-dialog>
    <!-- normal chart item v-else -->
    <v-card
      v-if="!maximized"
      @mouseover="hover = true"
      @mouseleave="hover = false"
      :class="maximized ? 'elevation-0' : ''"
      style="overflow: hidden; width: 100%">
      <!-- v-resize="onResize" -->
      <div class="chartHeader d-flex d-flex-inline align-center" :style="'height:' + headerHeight + 'px;'">
        <span
          class="ml-3"
          style="white-space: nowrap; overflow: hidden !important; text-overflow: ellipsis"
          :class="{ hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown) }"
          >{{ title }}
        </span>
        <div class="chart-hover-menu ml-auto d-flex flex-row-reverse">
          <v-btn v-show="maximized" small text class="pa-0 mr-2 ml-1" color="primary" @click="maximized = false"
            ><v-icon>close</v-icon>close</v-btn
          >
          <chart-settings-menu
            :cardActive="hover || maximized"
            :settings="settings"
            :reportName="reportName"
            :maximized="maximized"
            @refresh="loadData"
            :location="location"
            :dashboardGroupName="dashboardGroupName" />
          <v-btn small text icon v-show="hover && !maximized" class="right" @click="maximized = true">
            <v-icon>fullscreen</v-icon>
          </v-btn>
          <v-btn small text icon v-show="hover && !maximized" class="right" @click="loadData">
            <v-icon>refresh</v-icon>
          </v-btn>
        </div>
      </div>
      <div
        v-if="chartIsLoading && !loadError"
        class="d-flex flex-grow-1 justify-center align-center"
        :style="'height:' + (getTableHeight - headerHeight - 9) + 'px'">
        <v-container
          style="width: inherit; height: inherit"
          class="d-flex flex-column flex-grow-1 justify-center align-center">
          <v-progress-circular indeterminate></v-progress-circular>
          <span class="ml-4 mt-2">Loading...</span>
        </v-container>
      </div>
      <div v-show="!chartIsLoading && !loadError">
        <GChart
          v-if="chartData && chartData.length > 0 && settings.chartType != 'Table'"
          :key="chartKey"
          class="chartBox"
          :class="{
            hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
            maximized: maximized,
          }"
          :create-chart="drawGEOChart"
          :data="chartData"
          :options="chartOptions"
          :settings="chartSettings" />
        <!-- :resizeDebounce="500" -->
        <chart-table
          v-else
          :data="chartData"
          :maximized="maximized"
          :columns="columns"
          :class="{
            hidden: settings.hidden || (settings.hiddenOnPhone && $vuetify.breakpoint.smAndDown),
            maximized: maximized,
          }"
          :formating="chartOptions.formating"
          :stickyFirstColumn="stickyFirstColumn"
          :headerHeight="headerHeight"
          :tableHeight="getTableHeight"
          :settings="settings" />
      </div>
      <div v-if="loadError" class="d-flex flex-grow-1 justify-center align-center">
        <v-alert outlined type="error">
          <v-row align="center">
            <v-col class="grow">Network error. Could not load data.</v-col>
            <v-col class="shrink">
              <v-btn small @click="loadData">Retry</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </div>
    </v-card>
  </div>
</template>

<script setup>
import { GChart } from 'vue-google-charts';
import { useApplicationStore } from '@/store/applicationStore';
import { useDashboardStore } from '@/store/dashboardStore';
import { fetchChartData } from '@/services/api/api-calls/fetchChartData';
import { ref, computed, watch } from 'vue';
import { useVuetify } from '@/common/useVuetify';
import ChartSettingsMenu from './ChartSettingsMenu.vue';
import ChartTable from './ChartTable.vue';
/* interface Props {
  title: string;
  reportName: number| string;
  columns: any[];
  showHidden: boolean;
  dashboardGroupName: string;
  reload: boolean;
  stickyFirstColumn: number | boolean;
  height: string | number;
  width: string | number;
  showExternalDashboards: boolean;
  location: string;
  chartUpdateKey: string |  number;
  reportsLoadingErrors: any[]
} */

const props = defineProps({
  title: String,
  reportName: [Number, String],
  columns: [Array, Object],
  showHidden: Boolean,
  dashboardGroupName: String,
  reload: Boolean,
  stickyFirstColumn: [Number, Boolean],
  height: [String, Number],
  width: [String, Number],
  showExternalDashboards: Boolean,
  location: String,
  chartUpdateKey: [String, Number],
  reportsLoadingErrors: Array,
});
const emit = defineEmits(['onMaximized', 'updateChartKey', 'setreportsLoadingErrors']);

const applicationStore = useApplicationStore();
const dashboardStore = useDashboardStore();

const data = ref([]);
const hover = ref(false);
const chartKey = ref(1);
const chartSettings = ref({
  packages: ['geochart', 'corechart', 'table'],
  mapsApiKey: 'AIzaSyCwlZpSdQlHiGnqFhzgfuAeLhaecO_6BoY',
});
const headerHeight = ref(30); // Fixed height of the header
const rowHeight = ref(250); // height of each row in pixels (correlates with CSS "grid-auto-rows" in Dashboard.vue)
const maximized = ref(false);
const isLoading = ref(false);

const selectedAgencyId = computed(() => applicationStore.agencyIdSelected);
const getChartData = computed(() => dashboardStore.getChartData({ reportName: props.reportName }));

function structurData(orgData) {
  const result = [];
  // add row data
  if (orgData) {
    for (let index = 0; index < orgData.length; index++) {
      const row = [];
      const element = orgData[index];
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const propname in element) {
        row.push(element[propname]);
      }
      result.push(row);
    }
  }

  return result;
}

const chartData = computed(() => {
  if (Array.isArray(getChartData.value) && getChartData.value.length > 0) {
    return structurData(getChartData.value);
  }
  return [];
});

const loadingData = computed(() => !chartData.value);
const chartIsLoading = computed(() => !(!isLoading.value && !loadingData.value));
const loadError = computed(() => {
  if (props.reportsLoadingErrors.length > 0) {
    const found = props.reportsLoadingErrors.find((item) => item === props.reportName);
    if (found) {
      return true;
    }
  }
  return false;
});
const settings = computed(() => {
  let result = {};
  if (props.location && props.reportName) {
    result = dashboardStore.getChartSettings({
      location: props.location,
      dashboardGroupName: props.dashboardGroupName,
      reportName: props.reportName,
    });
  }
  return result;
});
const getCurrentHeightUsed = computed(() => settings.value.size.height);
const getCurrentWidthUsed = computed(() => settings.value.size.width);
const getTableHeight = computed(() => {
  const heightInInteger = getCurrentHeightUsed.value;
  return rowHeight.value * (heightInInteger + 1); // +1 to account for sizes: 0 = small, 1 = medium, 2 = large, 3 = x-large
});
const vuetify = useVuetify();

const darkTheme = computed(() => vuetify.theme.dark);

const chartOptions = computed(() => {
  let result = {};
  if (settings.value.options) {
    result = { ...settings.value.options };
  }
  if (darkTheme.value) {
    result.backgroundColor = { fill: 'transparent' };
    const chartTextStyle = { color: '#FFF' };

    if (!result.vAxis) {
      result.vAxis = {};
    }
    result.vAxis.textStyle = chartTextStyle;
    result.vAxis.titleTextStyle = chartTextStyle;
    result.vAxis.gridlines = { color: '#787878' };

    if (!result.hAxis) {
      result.hAxis = {};
    }
    result.hAxis.textStyle = chartTextStyle;
    result.hAxis.titleTextStyle = chartTextStyle;

    if (!result.legend) {
      result.legend = {};
    }
    result.legend.textStyle = chartTextStyle;
  }
  result.width = 'auto';
  if (!maximized.value) {
    result.height = getTableHeight.value - headerHeight.value - 9; // 9 is to adjust for margins etc. (can be adjusted later & must have the same value in G-Chart)
  } else {
    result.height = 'auto';
  }
  return result;
});

const chartType = computed(() => settings.value.chartType);

const showChart = computed(() => {
  let result = true;
  if (props.showHidden) {
    return true;
  }
  if (settings.value.hidden) {
    result = false;
  }
  if (settings.value.hiddenOnPhone && vuetify.breakpoint.smAndDown) {
    result = false;
  }

  return result;
});

watch(
  () => maximized.value,
  (val) => {
    emit('onMaximized', { maximized: val, reportName: props.reportName });
  }
);

function setLoading(val) {
  isLoading.value = val;
}

function increaseChartKey() {
  chartKey.value += 1;
}
/* function updateChartKey() {
      emit('updateChartKey');
    } */

async function loadData() {
  setLoading(true);
  const { reportName } = props;
  try {
    emit('setreportsLoadingErrors', { remove: true, reportName });
    await fetchChartData({ reportName });
  } catch (error) {
    emit('setreportsLoadingErrors', { add: true, reportName });
  } finally {
    increaseChartKey();
    setLoading(false);
  }
}

watch(
  () => props.reload,
  () => {
    loadData();
  }
);
watch(
  () => showChart.value,
  (val) => {
    if (val && data.value.length == 0) {
      loadData();
    }
  }
);

watch(
  () => data.value,
  () => {
    increaseChartKey();
  }
);

watch(
  () => settings.value,
  () => {
    increaseChartKey();
  }
);
watch(
  () => props.chartUpdateKey,
  () => {
    increaseChartKey();
  }
);
watch(
  () => selectedAgencyId.value,
  () => {
    const found = dashboardStore.agencyReports.find((item) => item.DAS_ID === props.reportName);
    if (found?.RefreshOnAgencyChanged) {
      loadData();
    }
  }
);

function drawGEOChart(el, google) {
  if (el === undefined) {
    return;
  }
  // eslint-disable-next-line consistent-return
  return new google.visualization[chartType.value](el);
}

function getWidth(width) {
  switch (width) {
    case 0:
      return 's-width';
    case 1:
      return 'm-width';
    case 2:
      return 'l-width';
    case 3:
      return 'xl-width';
    default:
      return 's-width';
  }
}
function getHeight(height) {
  switch (height) {
    case 0:
      return 's-height';
    case 1:
      return 'm-height';
    case 2:
      return 'l-height';
    case 3:
      return 'xl-height';
    default:
      return 's-height';
  }
}

const chartSize = computed(() => `${getWidth(getCurrentWidthUsed.value)} ${getHeight(getCurrentHeightUsed.value)}`);
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  overflow: hidden;
}
</style>

<style scoped lang="scss">
.hidden {
  opacity: 0.3;
}
.maximized {
  //min-height: 100%;
  min-width: 100%;
  min-height: 93vh;
}

.loadingSection {
  min-height: 200px;
}

::v-deep .chartHeader {
  background: inherit;
  .chart-hover-menu {
    position: relative;
    right: 0;
    top: 0;
    background: inherit;
  }
}
</style>
