import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getWhiteboardData(param: {
  whiteboardId: string;
  agencyId: number;
  maxRows: number;
  includeAllAgencies: boolean;
}) {
  const applicationStore = useApplicationStore();
  const data = await apiGet(
    `whiteBoardData/${param.whiteboardId}/${param.agencyId}/${param.maxRows}?includeAllAgencies=${param.includeAllAgencies}`
  );
  applicationStore.setWhiteBoardData(data);
}
