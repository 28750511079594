import { apiPost } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export function addToLastClientUsed(client: any) {
  const applicationStore = useApplicationStore();
  const lastUsedClients = [...applicationStore.clients.lastClientsUsed];
  const idx = lastUsedClients.findIndex((item) => item.ID == client.ID);
  if (idx >= 0) {
    lastUsedClients.splice(idx, 1);
  }
  lastUsedClients.unshift(client);
  if (lastUsedClients.length > 3) {
    lastUsedClients.pop();
  }
  applicationStore.clients.lastClientsUsed = lastUsedClients;
  // save to SavedInfo
  const clientsResult: { ID: string | number; NAME: String }[] = [];
  // eslint-disable-next-line array-callback-return
  lastUsedClients.forEach((item) => {
    const obj = { ID: item.ID, NAME: item.NAME };
    clientsResult.push(obj);
  });
  const savedInfo = {
    TypeName: 'web_last_clients',
    StrValue: JSON.stringify(clientsResult),
  };
  apiPost('savedInfo', savedInfo);
}
