import moment from 'moment';

const datetimeDisplaySettings = {
  dateFormat: 'DD.MMM YYYY',
  timeFormat: 'HH:mm',
  datetimeFormat: 'DD.MMM YYYY HH:mm',
};

const datetimeEditSettings = {
  dateFormat: 'DD.MM.YYYY',
  timeFormat: 'HH:mm',
  datetimeFormat: 'DD.MM.YYYY HH:mm',
};

function formatDisplayDate(date: any) {
  return moment(date).format(datetimeDisplaySettings.dateFormat);
}

const GatCommon = {
  datetimeDisplaySettings,
  datetimeEditSettings,
  formatDisplayDate,
};

export default GatCommon;
