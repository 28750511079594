enum EPortcallStatuses {
    Expected,
    Anchored,
    Berthed,
    Sailed,
    Canceled,
    Archieved
}

const portcallStatusesEnum = Object.entries(EPortcallStatuses).filter(e => !(+e[0] + 1)).map((s, id) => ({ STATUS: s[0], ID: id }))

export { EPortcallStatuses }
export default portcallStatusesEnum
