<template>
    <div class="pb-6 mb-6">
        <gat-dialog v-model="dialogVisible" :cancel-button-disabled="isSaving" cancelButtonCaption="CANCEL"
            okButtonCaption="SAVE" width="1000" :auto-close="false" @okClick="addOrEditProspect()"
            @cancelClick="close(true)" :ok-button-disabled="!isValid || !canSave">
            <span class="text-h6 grey--text text--darken-1">{{ this.selectedProspect?.CHO_ID ? 'Edit' : 'Add' }}
                prospect</span>
            <gat-form title="Prospect detail" :is-loading="isSaving || loading" ref="form" v-model="isValid">
                <gat-sub-group size="xs12 sm12">
                    <GatEdit label="Name" v-model="prospectName" size="xs6" :maxChars="100" required />
                    <GatEdit label="Email subject" v-model="prospectEmailSubject" size="xs6" :maxChars="500" required />
                </gat-sub-group>
                <gat-grid title="Cargo lines" class="elevation-0" :items="cargoItems" :columns="cargoColumns"
                    :hideEmptyColumns="true" selectable keyName="ID" max-height="40vh" no-footer
                    :selectedKeys="prospectCargoLines" @selectionChanged="cargoGridSelectionCanged($event)">
                </gat-grid>
                <gat-grid title="Clients" class="elevation-0" :items="clientsContactsItems" :columns="clientsColumns"
                    max-height="40vh" :hideEmptyColumns="true" selectable keyName="ID" no-footer
                    :selectedKeys="prospectConnectedClients" @selectionChanged="clientsGridSelectionCanged($event)">
                    <template slot="btns">
                        <GatEdit label="Hide contacts" v-model="hideContacts" toggle size="xs12" />
                    </template>
                </gat-grid>
            </gat-form>
        </gat-dialog>
    </div>
</template>
  
<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPut } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';

export default {
    name: 'ProspectItem',
    props: {
        showForm: Boolean,
        clientsContacts: {
            type: Array,
            required: true,
        },
        callId: {
            type: [String, Number],
            required: true
        },
        selectedProspect: {
            type: [Object, null, undefined],
            required: false
        },
    },
    components: {},
    setup() {
        const portcallStore = usePortcallStore();
        const applicationStore = useApplicationStore();
        return {
            portcallStore,
            applicationStore,
        };
    },
    data() {
        return {
            loading: false,
            isValid: false,
            isSaving: false,
            hideContacts: false,
            prospectCargoLines: [],
            prospectConnectedClients: [],
            dialogVisible: this.showForm,
            prospectName: this.selectedProspect?.CHO_NAME ?? '',
            prospectEmailSubject: this.selectedProspect?.CHO_SUBJECT ?? '',
        };
    },

    created() { },

    mounted() {
        this.loading = false;
        this.isSaving = false;
        if (this.selectedProspect?.CHO_ID) {
            try {
                this.loading = true;
                apiGet(`prospects/${this.selectedProspect.CHO_ID}/items`).then((hotlistItems) => {
                    this.prospectCargoLines = hotlistItems.filter(hi => hi.CHI_CARGO_ID).map(ci => ci.CHI_CARGO_ID)
                    const clientsContacts = hotlistItems.filter(hi => !hi.CHI_CARGO_ID)
                    this.prospectConnectedClients = clientsContacts.map(c => {
                        if (c.CHI_HOTLIST_PROV_ID) { // type 3 items
                            return this.clientsContacts.find(acc => c.CHI_HOTLIST_PROV_ID === acc.ID)?.ID
                        }
                        if (c.CHI_CONTACT_ID) { // type 2 items
                            return this.clientsContacts.find(acc => c.CHI_CONTACT_ID === acc.ID)?.ID
                        }
                        if (c.CHI_CLIENT_ID) { // type 1 items
                            return this.clientsContacts.find(acc => c.CHI_CLIENT_ID === acc.ID)?.ID
                        }

                        return -1
                    })
                    this.loading = false;
                })
                this.prospectName = this.selectedProspect.CHO_NAME
                this.prospectEmailSubject = this.selectedProspect.CHO_SUBJECT
            } catch {
                this.loading = false
            }
        } else if (this.callId) {
            this.prospectName = `Prospects ${this.portcallStore.callDetails.vessel.NAME}`
            apiGet(`portcall/${this.callId}/harbour`).then(result => {
                this.prospectEmailSubject = `Prospects ${this.portcallStore.callDetails.vessel.NAME} - ${result?.NAME ?? ''}`
            })
        }
    },

    watch: {
        showForm(newValue) {
            this.dialogVisible = newValue;
        },
    },

    computed: {
        cargoItems() {
            return this.portcallStore.cargo;
        },
        clientsContactsItems() {
            return this.hideContacts ? this.clientsContacts.filter(c => c.TYPE !== 2) : this.clientsContacts;
        },
        canCreateNewProspect() {
            return !this.isSaving && this.applicationStore.userRight('PRO.NEW') && this.applicationStore.user.internal;
        },
        canDeleteProspects() {
            return !this.isSaving && this.applicationStore.userRight('PRO.DELETE') && this.applicationStore.user.internal;
        },
        canSave() {
            return this.prospectCargoLines.length > 0 && this.prospectConnectedClients.length > 0 && this.canCreateNewProspect
        },
        cargoColumns() {
            const result = [];
            result.push({ header: 'Cargo', field: 'BL_TEXT', hideOnPhone: false });
            result.push({ header: 'Gross weight', field: 'AMOUNT_GROSS_WEIGHT', hideOnPhone: false });
            result.push({ header: 'Net weight', field: 'AMOUNT_WEIGHT', hideOnPhone: false });
            result.push({ header: 'Code', field: 'CODE', hideOnPhone: false });
            result.push({ header: 'Quay', field: 'QUAYNAME', hideOnPhone: false });
            return result;
        },
        clientsColumns() {
            const result = [];
            // result.push({ header: 'Id', field: 'ID', hideOnPhone: true });
            result.push({ header: 'Role', field: 'ROLE', hideOnPhone: false });
            result.push({ header: 'Client name', field: 'NAME', hideOnPhone: false });
            result.push({ header: 'Email', field: 'EMAIL', hideOnPhone: false });
            // result.push({ header: 'Type', field: 'TYPE', hideOnPhone: false });
            // result.push({ header: 'Role id', field: 'CLIENT_ROLE_ID', hideOnPhone: true });
            return result;
        },
    },

    methods: {
        clientsGridSelectionCanged(items) {
            this.prospectConnectedClients = items;
        },
        cargoGridSelectionCanged(items) {
            this.prospectCargoLines = items;
        },
        async addOrEditProspect() {
            try {
                this.$refs.form.validate();
                if (this.isValid && this.canSave) {
                    this.isSaving = true;
                    const hotlistItems = []
                    this.prospectCargoLines.forEach(cId => {
                        const cargo = this.cargoItems.find(cg => cg.ID === cId)
                        hotlistItems.push({ CHI_CLIENT_ID: null, CHI_CARGO_ID: cargo.ID, CHI_CLIENT_ROLE: null, CHI_HOTLIST_PROV_ID: null, CHI_CONTACT_ID: null })
                    })
                    this.prospectConnectedClients.forEach(cId => {
                        const clientContact = this.clientsContacts.find(cc => cc.ID === cId)
                        if (clientContact) {
                            const roleId = clientContact.CLIENT_ROLE_ID > 0 ? clientContact.CLIENT_ROLE_ID : null
                            if (clientContact.TYPE === 3) {
                                hotlistItems.push({ CHI_CLIENT_ID: null, CHI_CARGO_ID: null, CHI_CLIENT_ROLE: null, CHI_HOTLIST_PROV_ID: clientContact.ID, CHI_CONTACT_ID: null })
                            }
                            else if (clientContact.TYPE === 2) {
                                hotlistItems.push({ CHI_CLIENT_ID: null, CHI_CARGO_ID: null, CHI_CLIENT_ROLE: roleId, CHI_HOTLIST_PROV_ID: null, CHI_CONTACT_ID: clientContact.ID })
                            }
                            else if (clientContact.TYPE === 1) {
                                hotlistItems.push({ CHI_CLIENT_ID: clientContact.ID, CHI_CARGO_ID: null, CHI_CLIENT_ROLE: roleId, CHI_HOTLIST_PROV_ID: null, CHI_CONTACT_ID: null })
                            }
                        }
                    })
                    const newProspect = {
                        CHO_ID: this.selectedProspect?.CHO_ID, // existing prospect to edit
                        HOTLIST: {
                            CHO_PORTCALL_ID: this.callId,
                            CHO_NAME: this.prospectName,
                            CHO_SUBJECT: this.prospectEmailSubject
                        },
                        HOTLIST_ITEMS: hotlistItems,
                    }
                    await apiPut('prospects', { value: newProspect });
                    this.isSaving = false;
                    this.close();
                }
            } catch {
                this.isSaving = false;
                this.close(true);
            } finally {
                this.isSaving = false;
                this.close();
            }
        },
        close(cancel) {
            this.isSaving = false;
            this.$emit('close', cancel);
        },
    },
};
</script>
  
<style lang="scss" scoped></style>
