<template>
  <div>
    <h2>An error occured.</h2>
    <br />
    <b>{{ lastError.message }}</b
    ><br />
    {{ lastError.stack }}
  </div>
</template>

<script>
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'ErrorPage',
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },

  computed: {
    lastError() {
      return this.applicationStore.lastError;
    },
  },
};
</script>

<style scoped></style>
