<template>
  <tr
    :class="rowClass"
    :active="checkboxValues[props.item[keyName]]"
    @click="rowClicked(props.item)"
    @contextmenu="onRowRightClick($event, props.item)"
    :draggable="draggable">
    <td v-if="rowBar" :class="rowBarClass" class="row-bar sticky-column"></td>
    <!-- the td element which contains the left status bar -->
    <td
      v-if="selectable"
      class="pl-0 pr-0"
      :style="getStickyColumnStartPosition('selectable')"
      :class="stickyFirstColumn ? 'sticky-column' : ''"
      @click.stop>
      <gat-edit
        v-if="!checkboxIsHidden"
        checkbox
        :value="checkboxValues[props.item[keyName]]"
        :disabled="!props.item[keyName]"
        :class="dense ? 'selectCheckboxDense' : 'selectCheckbox'"
        :dense="dense"
        class="primary--text"
        @change="$emit('on-checkbox-change', { selected: !checkboxValues[props.item[keyName]], item: props.item })" />
    </td>
    <td v-if="props.item.editMode" colspan="100" style="background-color: inherit">
      <slot name="rowEdit" :value="props.item"></slot>
    </td>
    <td
      v-if="favorites && !props.item.editMode"
      class="favoriteColumn pr-0 pl-0"
      :class="stickyFirstColumn ? 'sticky-column' : ''"
      :style="getStickyColumnStartPosition('favorites')">
      <v-btn text icon v-on:click.stop="$emit('fav-clicked', props.item)">
        <v-icon v-if="isFavorite" color="yellow darken-2">grade</v-icon>
        <v-icon class="outlined" v-else color="grey lighten-3">grade</v-icon>
      </v-btn>
    </td>
    <!-- eslint-disable -->
    <td
      v-if="!(props.item && props.item.editMode) && (!col.field || col.field != groupedBy)"
      v-for="(col, index) in visibleColumns"
      :key="col.field"
      :col="col"
      :style="colStyle(col, tight) + getStickyColumnStartPosition()"
      :class="colClass(col, props.item, colsClass, index, stickyFirstColumn, noPadding)">
      <slot
        name="cell"
        :column="col"
        :row="props.item"
        :value="formatValue(props.item[col.field], col.dataType, false, col, props.item)">
        <v-icon v-if="col.checkbox">{{ cellData(col, props.item) }}</v-icon>
        <v-icon v-else-if="col.icon">{{ cellData(col, props.item) }}</v-icon>
        <a v-else-if="col.linkUrl" class="gridLink" :href="cellData(col, props.item).href">{{
          cellData(col, props.item).caption
        }}</a>
        <template v-else>
          <div v-if="allowHtml" v-html="textToSafeHTML(cellData(col, props.item))"></div>
          <span v-else>{{ cellData(col, props.item) }}</span>
        </template>
      </slot>
    </td>
    <!-- eslint-enable -->
    <v-menu v-model="showMenu" absolute offset-y :position-x="menu_x" :position-y="menu_y">
      <v-list dense>
        <div v-for="(item, index) in rowMenu" :key="index">
          <v-subheader v-if="item.subHeader">{{ item.subHeader }}</v-subheader>
          <v-list-item
            @click="$emit('row-menu-clicked', { menuItem: item, rowItem: props.item })"
            v-show="!menuIsHidden(item, props.item)"
            :disabled="menuIsDisabled(item, props.item)">
            <v-list-item-icon>
              <v-icon :color="item.iconColor">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.caption }}</v-list-item-title>
          </v-list-item>

          <v-divider v-if="item.separatorBelow"></v-divider>
        </div>
        <v-divider v-if="rowMenu"></v-divider>
        <v-list-item @click="$emit('copy-grid-clicked')">
          <v-list-item-icon>
            <v-icon>mdi-content-copy</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Copy all to clipboard</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </tr>
</template>

<script>
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { GatGridMixin } from './GatGridMixin';

export default {
  name: 'GatGridItem',
  mixins: [GatGridMixin],
  props: [
    'props',
    'visibleColumns',
    'checkboxValues',
    'colsClass',
    'dense',
    'groupedBy',
    'isFavorite',
    'rowClass',
    'keyName',
    'selectable',
    'favorites',
    'visibleItems',
    'rowMenu',
    'tight',
    'gridName',
    'noPadding',
    'stickyFirstColumn',
    'rowBar',
    'rowBarClass',
    'draggable',
    'allowHtml',
    'options',
  ],
  components: {},
  data() {
    return {
      showMenu: false,
      menu_x: 0,
      menu_y: 0,
    };
  },

  created() {
    this.gatComponentsMessageBus.$on('gatgrid-rowmenu-activated', () => {
      if (this.showMenu) {
        this.showMenu = false;
      }
    });
    this.gatComponentsMessageBus.$on(
      'gatgrid-rowmenu-show-context-menu',
      ({ event, rowItemId, gridName, targetRowId }) => {
        // targetRowId is optional if the ID "column" is something else than ID. example: EXP_ID.
        // Check if this is the correct grid and if the ID sent in corresponds to the ID of the row item. Else it would trigger on all the items.
        let id = this.props.item.ID;
        if (targetRowId) {
          id = this.props.item[targetRowId];
        }
        if (this.gridName === gridName && id == rowItemId) {
          this.onRowRightClick(event);
        }
      }
    );
  },

  watch: {},

  methods: {
    textToSafeHTML(htmlAsString) {
      return GlobalHelperFunctions.textToSafeHTML(htmlAsString);
    },
    getStickyColumnStartPosition(tableRowName) {
      if (!this.stickyFirstColumn) {
        return '';
      }
      const style = 'left:';
      let pixels = 0;
      if (this.rowBar) {
        pixels = 8;
      }
      if (tableRowName == 'selectable') {
        // comes after rowBar
        return `${style + pixels}px`;
      }
      if (this.selectable) {
        pixels += 46;
      }
      if (tableRowName == 'favorites') {
        // comes after selectable
        return `${style + pixels}px`;
      }
      if (this.favorites) {
        pixels += 36;
      }

      return `${style + pixels}px`; // returns the correct value for td element after rowBar/selectable/favorites.
    },

    isFunction(functionToCheck) {
      return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
    },

    onRowRightClick(event) {
      this.gatComponentsMessageBus.$emit('gatgrid-rowmenu-activated', { menuItem: this.rowMenu });
      this.menu_x = event.clientX;
      this.menu_y = event.clientY;
      event.preventDefault();
      // hack to make sure all other row menu items have been hidden (via the above emit)
      this.$nextTick(() => {
        this.showMenu = true;
      });
      return false;
    },

    menuIsDisabled(menuItem, rowItem) {
      if (this.isFunction(menuItem.enabled)) {
        return !menuItem.enabled(rowItem);
      }
      // if enabled is not set, we by default do not hide the item
      if (typeof menuItem.enabled == 'undefined') {
        return false;
      }
      return !menuItem.enabled;
    },

    menuIsHidden(menuItem, rowItem) {
      if (this.isFunction(menuItem.visible)) {
        return !menuItem.visible(rowItem);
      }
      // if visible is not set, we by default do not hide the item
      if (typeof menuItem.visible == 'undefined') {
        return false;
      }
      return !menuItem.visible;
    },

    rowClicked(rowItem) {
      this.$emit('rowclicked', rowItem);
    },
  },
  computed: {
    checkboxIsHidden() {
      if (this.options?.checkboxIsHidden && typeof this.options.checkboxIsHidden === 'function') {
        return this.options.checkboxIsHidden(this.props.item);
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.row-bar {
  border-radius: 4px 0 0 4px;
}

.selectCheckbox {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 2px;
}

.selectCheckboxDense {
  margin-top: 0px;
  margin-bottom: -14px;
  margin-left: 0px;
}

@media only screen and (max-width: 600px) {
  tr:nth-child(even) {
    background-color: rgba(240, 238, 238, 0.473);
  }
}
</style>
