import { DateTime } from 'luxon';
import { ref, watch, Ref, computed } from 'vue';

export function useDocumentUploaderReplaceFilesDialog(props: { documents: any[] }, filesToUpload: Ref<any[]>) {
  const showReplaceDocumentDialog = ref(false);

  const replaceDialogAnswer = ref<undefined | 'yes' | 'no' | 'cancel'>(undefined);

  function waitForReplaceDialogAnswer() {
    showReplaceDocumentDialog.value = true;
    return new Promise<'yes' | 'no' | 'cancel'>((resolve) => {
      watch(
        () => replaceDialogAnswer.value,
        (val) => {
          const answer = val;
          if (answer) {
            resolve(answer);
          }
        }
      );
    });
  }

  function convertToJsDate(lastSaved: string | undefined) {
    if (!lastSaved) {
      return undefined;
    }

    const dt = DateTime.fromISO(lastSaved);
    if (dt.isValid) {
      return dt.toJSDate();
    }
    return undefined;
  }

  /** Holds the values for which files should be replaced. */
  const unsortedFilesToReplace = ref<any[]>([]);

  /** Is sorted by LAST_SAVED which makes it easier to pick the file which is most recently updated by any user. */
  const filesToReplace = computed(() => {
    const sorted = unsortedFilesToReplace.value.sort((a, b) => {
      const lastSavedA = convertToJsDate(a.LAST_SAVED);
      const lastSavedB = convertToJsDate(b.LAST_SAVED);
      if (!lastSavedA || !lastSavedB) {
        return 0;
      }
      if (lastSavedA < lastSavedB) {
        return 1;
      }
      if (lastSavedA > lastSavedB) {
        return -1;
      }
      return 0;
    });
    return sorted;
  });

  interface DocumentUploaderDialogContent {
    lastUpdated: Date | undefined;
    fileName: string;
    duplicates: number;
  }

  const replaceDialogContent = computed((): DocumentUploaderDialogContent[] => {
    const result: DocumentUploaderDialogContent[] = [];
    const duplicateFilenames: string[] = [];
    filesToReplace.value.forEach((item) => {
      const fileName = item.FILENAME;
      if (!duplicateFilenames.includes(fileName)) {
        duplicateFilenames.push(fileName);
      }
    });
    duplicateFilenames.forEach((fileName) => {
      let duplicates = filesToReplace.value.filter((item) => item.FILENAME === fileName).length;
      if (duplicates === 1) {
        duplicates = 0;
      }
      result.push({
        duplicates,
        fileName,
        lastUpdated: convertToJsDate(filesToReplace.value.find((item) => item.FILENAME === fileName).LAST_SAVED),
      });
    });
    return result;
  });

  async function checkForReplacableFiles() {
    unsortedFilesToReplace.value = [];
    /** The filenames of the files to upload. */
    const fileNames: string[] = [];
    filesToUpload.value.forEach((file) => {
      const fileName: string = file.name;
      if (!fileNames.includes(fileName)) {
        fileNames.push(fileName);
      }
    });
    if (fileNames.length > 0) {
      const { documents } = props;
      documents.forEach((document) => {
        if (fileNames.includes(document.FILENAME)) {
          unsortedFilesToReplace.value.push(document);
        }
      });
    }
    if (unsortedFilesToReplace.value.length > 0) {
      const answer = await waitForReplaceDialogAnswer();
      if (answer === 'no') {
        unsortedFilesToReplace.value = [];
      }
      showReplaceDocumentDialog.value = false;
      replaceDialogAnswer.value = undefined;
      return answer;
    }
    return undefined;
  }

  function getFirstReplaceFileId(fileName: string) {
    if (filesToReplace.value.length > 0) {
      const replaceIds = filesToReplace.value.filter((item) => item.FILENAME === fileName);
      if (replaceIds.length > 0) {
        if (replaceIds[0].ID) {
          return replaceIds[0].ID.toString();
        }
      }
    }
    return '';
  }

  return {
    showReplaceDocumentDialog,
    replaceDialogAnswer,
    checkForReplacableFiles,
    replaceDialogContent,
    getFirstReplaceFileId,
  };
}
