<template>
  <gat-flex :size="size">
    <gat-field-spacer :size="size">
      <v-select
        :autocomplete="autocomplete"
        :data-lpignore="dataLpignore"
        :data-form-type="dataFormType"
        :autofocus="autofocus"
        :clearable="clearable"
        deletable-chips
        :dense="gatComponentsStore.input_dense"
        :disabled="disabled"
        :filter="filter"
        :items="items"
        :item-text="textFieldName"
        :item-value="codeFieldName"
        :label="getLabel"
        :messages="getMessages"
        multiple
        :outlined="isOutlined"
        placeholder=" "
        :prepend-icon="prependIcon"
        :readonly="isReadonly"
        :rules="rules"
        :small-chips="chips"
        v-model="itemValue"
        @update:search-input="searchValue = $event"
        @input="updateValue()"
        @blur="$emit('blur')">
        <!-- selection slot (rendering of edit when not in drop down mode) -->
        <template v-if="maxVisibleItemCount" v-slot:selection="{ item, index }">
          <v-chip v-if="chips && index < maxVisibleItemCount" small close @click:close="unselectItem(index)">
            <span>{{ item[textFieldName] }}</span>
          </v-chip>
          <span v-if="!chips && index < maxVisibleItemCount">
            <span v-if="index > 0">, </span> {{ item[textFieldName] }}
          </span>
          <span
            v-if="value.length > maxVisibleItemCount && index === maxVisibleItemCount"
            class="grey--text caption pl-1">
            (+{{ value.length - maxVisibleItemCount }} more)
          </span>
        </template>

        <!-- Prepend item slot (item on top of list) -->
        <template v-if="selectAllOption" v-slot:prepend-item>
          <v-list-item ripple @click="toggleSelectAll" dense>
            <v-list-item-action>
              <v-icon :color="value.length > 0 ? 'indigo darken-4' : ''">
                {{ toggleIcon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title> Select All </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
      </v-select>
    </gat-field-spacer>
  </gat-flex>
</template>

<script>
import { useGatComponentsStore } from '@/store/gatComponentsStore';
import GatFlex from '../GatFlex.vue';
import GatFieldSpacer from '../GatFieldSpacer.vue';
import { GatInputMixin } from './GatInputMixin';

export default {
  name: 'GatMultiSelect',
  components: { GatFlex, GatFieldSpacer },
  mixins: [GatInputMixin],
  props: {
    autofocus: Boolean,
    chips: {
      type: Boolean,
      default: true,
    },
    clearable: Boolean,
    codeFieldName: String,
    disabled: Boolean,
    items: Array,
    label: String,
    maxVisibleItemCount: Number,
    markOptionalInputs: Boolean,
    prependIcon: String,
    readonly: Boolean,
    required: Boolean,
    selectAllOption: {
      type: Boolean,
      default: true,
    },
    size: String,
    textFieldName: String,
    value: Array,
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  setup() {
    const gatComponentsStore = useGatComponentsStore();
    return {
      gatComponentsStore,
    };
  },
  data() {
    return {
      item: {},
      itemValue: undefined,
      returnObject: false, // not supported
      searchValue: null,
    };
  },

  watch: {
    value: {
      handler(newValue) {
        this.itemValue = newValue;
        this.setItem(newValue, this.items);
        if (!this.itemValue) {
          this.item = {};
        }
        if (this.checkForErrors(newValue)) {
          this.expandParentGatGroup(); // mixin
        }
      },
      immediate: true,
    },

    items(val) {
      this.setItem(this.itemValue, val);
    },
  },

  mounted() {
    if (this.value) {
      this.itemValue = this.value;
      this.setItem(this.itemValue, this.items);
    } else {
      this.item = {};
    }
  },

  computed: {
    columnsWithWith() {
      if (this.columns) {
        // eslint-disable-next-line array-callback-return
        this.columns.map((col) => {
          let maxWidth = 10;
          // eslint-disable-next-line array-callback-return
          this.items.map((item) => {
            const value = item[col.field];
            if (value) {
              const w = this.getTextWidth(value, '14px Roboto sans-serif') + 32;
              if (w > maxWidth) {
                maxWidth = w;
              }
            }
          });
          // eslint-disable-next-line no-param-reassign
          col.width = maxWidth;
        });
      }
      return this.columns;
    },

    getLabel() {
      let result = this.label;
      if (this.getRequiredOrOptionalHint != '') {
        result += '*';
      }
      return result;
    },
    getMessages() {
      const result = this.getRequiredOrOptionalHint;
      if (result != '') {
        return result;
      }
      return undefined;
    },
    getRequiredOrOptionalHint() {
      let result = '';
      // onlys how for empty fields
      if (this.itemValue != null) {
        return '';
      }
      // normally hint is shown on required field only
      if (this.required) {
        result = '*required';
      }

      // if most fields are required, we can insted mark the optional fields
      if (this.markOptionalInputs) {
        if (this.required) {
          result = '';
        } else {
          result = '*optional';
        }
      }
      return result;
    },

    isOutlined() {
      return !this.inlineEdit;
    },

    isFilled() {
      return this.inlineEdit;
    },

    isReadonly() {
      if (typeof this.readonly != 'undefined') {
        return this.readonly;
      }
      return false;
      // return this.getGroupReadonly();
    },

    rules() {
      const rules = [];
      // required rule
      if (this.required) {
        rules.push((value) => {
          if (value && value.length == 0) {
            return 'required';
          }
          return true;
        });
      }

      return rules;
    },

    toggleIcon() {
      if (this.value.length === this.items.length) return 'mdi-close-box';
      if (this.value.length > 0) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },

  methods: {
    filter(item, queryText, itemText) {
      if (this.columns) {
        let result = false;
        for (let index = 0; index < this.columns.length; index++) {
          const element = item[this.columns[index].field];
          if (element) {
            result = element.toLowerCase().includes(queryText.toLowerCase());
          }
          if (result) {
            return true;
          }
        }
        return result;
      }
      return itemText.toLowerCase().includes(queryText.toLowerCase());
    },

    getAllValues() {
      const result = [];
      if (this.returnObject) {
        return this.items.slice();
      }
      let codeName = this.codeFieldName;
      if (!this.codeFieldName) {
        codeName = 'value';
      }
      // eslint-disable-next-line array-callback-return
      this.items.map((item) => {
        result.push(item[codeName]);
      });
      return result;
    },

    getTextWidth(text, font) {
      // re-use canvas object for better performance
      const canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'));
      const context = canvas.getContext('2d');
      context.font = font;
      const metrics = context.measureText(text);
      return Math.round(metrics.width) + 1;
    },

    setItem(codeValue, items) {
      let codeName = this.codeFieldName;
      if (!this.codeFieldName) {
        codeName = 'value';
      }
      if (codeValue && items && items.length > 0) {
        const item = items.find((x) => x[codeName] == codeValue);
        if (item && item[codeName] != this.item[codeName]) {
          this.item = item;
        }
      }
    },

    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.value.length === this.items.length) {
          this.itemValue = [];
          this.updateValue();
        } else {
          const allValues = this.getAllValues();

          this.itemValue = allValues;
          this.updateValue();
        }
      });
    },

    unselectItem(index) {
      // let keyValue = item[this.codeFieldName];
      this.itemValue.splice(index, 1);
      this.updateValue();
    },

    updateValue() {
      if (typeof this.itemValue === 'undefined') {
        this.itemValue = null;
      }
      this.setItem(this.itemValue, this.items);
      this.$emit('input', this.itemValue);
    },
  },
};
</script>

<style scoped></style>
