<template>
  <v-layout wrap>
    <gat-select
      label="Harbour filter"
      v-model="harbourId"
      :items="harbours"
      textFieldName="NAME"
      codeFieldName="ID"
      :size="harbourSize || 'xs6 sm4'"
      :clearable="true" />
    <gat-select
      label="Terminal filter"
      v-model="terminalName"
      :items="terminals"
      textFieldName="NAME"
      codeFieldName="ID"
      :size="terminalSize || 'xs6 sm4'"
      :clearable="true" />
    <gat-lookup-edit
      v-show="useLookup"
      :label="label || 'Quay'"
      v-model="quayId"
      :searchresult="items"
      @getItems="getItems($event)"
      textFieldName="NAME"
      codeFieldName="ID"
      @input="updateValue()"
      @itemNotFound="itemNotFound"
      :cashSearches="true"
      :size="size"
      :required="quayRequired">
    </gat-lookup-edit>
    <gat-select
      v-show="!useLookup"
      :label="label || 'Quay'"
      v-model="quayId"
      :items="quays"
      textFieldName="NAME"
      codeFieldName="ID"
      :size="size"
      @input="updateValue()"
      :required="quayRequired"
      customSelection>
      <template v-slot:selection="{ data }">
        <gat-icon v-if="warningMessage" :tooltip="warningMessage" />
        <span>
          {{ data['NAME'] }}
        </span>
      </template>
    </gat-select>
    <!-- <record-presenter caption="Items" :value="items"></record-presenter>
    <record-presenter caption="quays" :value="quays"></record-presenter>
    <record-presenter caption="terminals" :value="this.helperStore.ownTerminals"></record-presenter>
    <div>Selected HARBOUR ID: {{ harbourId }}</div>
    <div>Selected TERMINAL NAME: {{ terminalName }}</div>
    <div>Selected QUAY ID: {{ quayId }}</div> -->
  </v-layout>
</template>

<script>
import { apiCashedGet, apiPost } from '@/store/api';
import { useHelperStore } from '@/store/helperStore';
import GatIcon from './GatIcon.vue';

export default {
  name: 'QuaySelect',
  props: ['harbourSize', 'terminalSize', 'size', 'value', 'label', 'quayRequired', 'showWarningIcon', 'warningMessage'],
  components: { GatIcon },
  setup() {
    const helperStore = useHelperStore();
    return {
      helperStore,
    };
  },
  data() {
    return {
      harbourId: null,
      terminalName: null,
      quayId: null,
      items: [],
      quays: [],
      showfilter: false,
      pauseValueWatchers: false,
    };
  },

  created() {
    if (this.value) {
      apiCashedGet(`helper/quay/id/${this.value}`).then((data) => {
        this.pauseValueWatchers = true;
        this.harbourId = data[0].HARBOUR_ID;
        this.terminalName = data[0].TERMINAL_NAME;
        this.getQuays(data[0].HARBOUR_ID, data[0].TERMINAL_NAME).then(() => {
          this.quayId = data[0].ID;
          this.pauseValueWatchers = false;
        });
      });
    }
  },

  computed: {
    harbours() {
      return this.helperStore.ownHarbours;
    },

    terminals() {
      let result = this.helperStore.ownTerminals;
      if (this.harbourId) {
        result = this.helperStore.ownTerminals.filter((item) => item.HARBOUR_ID == this.harbourId);
      }
      const isProbablyInactive = this.terminalName && !result.find((item) => item.ID === this.terminalName);
      if (isProbablyInactive) {
        result.push({
          NAME: `${this.terminalName} (inactive)`,
          ID: this.terminalName,
          HARBOUR_ID: this.harbourId || 0,
        });
      }
      return result;
    },

    useLookup() {
      let result = true;
      if (this.harbourId || this.terminalName) {
        result = false;
      }
      return result;
    },
  },

  watch: {
    value(val) {
      if (!val) {
        this.harbourId = null;
        this.terminalName = null;
        this.quayId = null;
      }
      if (!this.pauseValueWatchers && val) {
        apiCashedGet(`helper/quay/id/${val}`).then((data) => {
          this.pauseValueWatchers = true;
          this.harbourId = data[0].HARBOUR_ID;
          this.terminalName = data[0].TERMINAL_NAME;
          this.getQuays(data[0].HARBOUR_ID, data[0].TERMINAL_NAME).then(() => {
            this.quayId = data[0].ID;
            this.pauseValueWatchers = false;
          });
        });
      }
    },

    harbourId(val, oldValue) {
      if (!this.pauseValueWatchers) {
        if (oldValue && !val) {
          this.terminalName = undefined;
        }
        this.getQuays(val, this.terminalName);
      }
    },

    terminalName(val) {
      if (!this.pauseValueWatchers) {
        if (!this.harbourId) {
          this.harbourId = this.getHarbourIdFromTerminalName(val);
        } else {
          this.getQuays(this.harbourId, val);
        }
      }
    },
  },

  methods: {
    filterInactiveItems(apiResult) {
      const result = apiResult.filter((item) => item.INACTIVE === 0 || item.ID === this.value);
      return result.map((n) => ({ ...n, NAME: n.INACTIVE === 1 ? `${n.NAME} (inacitve)` : n.NAME }));
    },
    getItems(searchText) {
      apiPost('helper/search/', { table: 'quay', text: searchText }).then((data) => {
        const result = data.filter((item) => {
          let include = true;
          if (this.harbourId) {
            include = this.harbourId;
            // eslint-disable-next-line no-param-reassign
            item.HARBOUR_ID = this.harbourId;
          }
          if (this.terminalName) {
            include = include && item.TERMINAL_NAME == this.terminalName;
          }
          return include;
        });
        this.items = this.filterInactiveItems(result);
      });
    },

    // eslint-disable-next-line consistent-return
    getHarbourIdFromTerminalName(terminalName) {
      const terminal = this.helperStore.ownTerminals.find((item) => item.NAME == terminalName);
      if (terminal) {
        return terminal.HARBOUR_ID;
      }
    },

    getQuays(harbourId, terminalName) {
      return new Promise((resolve) => {
        if (harbourId) {
          apiCashedGet(`helper/quay/harbourId/${harbourId}`).then((data) => {
            let result = [];
            if (terminalName) {
              result = data.filter((item) => item.TERMINAL_NAME == terminalName);
            } else {
              result = data;
            }
            this.quays = this.filterInactiveItems(result);
            resolve();
          });
        } else {
          this.quays = [];
          resolve();
        }
      });
    },

    updateValue() {
      this.$emit('input', this.quayId);
    },

    itemNotFound(id) {
      apiCashedGet(`helper/quay/id/${id}`).then((data) => {
        this.items = this.filterInactiveItems(data);
      });
      // }
    },
  },
};
</script>

<style scoped></style>
