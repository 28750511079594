import Router from 'vue-router';
import EmailEditor from '@/views/EmailEditor.vue';
import EmailView from '@/views/EmailView.vue';
import { useApplicationStore } from '@/store/applicationStore';
import { waitForUserData } from '@/services/api/api-calls/waitForUserData';
// eslint-disable-next-line import/no-cycle
import { getNumUpcomingServices } from '@/services/api/api-calls/getNumUpcomingServices';
import { getNumUpcomingTasks } from '@/services/api/api-calls/getNumUpcomingTasks';
import PortCalls from '../views/PortCalls.vue';
import PortcallSearch from '../views/portcall/PortcallSearch.vue';
import TabDashboard from '../views/dashboard/TabDashboard.vue';
import QuayDetails from '../views/portcall/QuayDockEdit.vue';
import Documents from '../app-components/Documents.vue';
import Vessel from '../views/vessels/Vessel.vue';
import VesselList from '../views/vessels/VesselList.vue';
import PortCall from '../views/portcall/PortCall.vue';
import ErrorPage from '../views/ErrorPage.vue';
import Auth from '../views/Auth.vue';
import Settings from '../views/Settings.vue';
import SofPage from '../views/portcall/sof/SofPage.vue';
import PortCallDetails from '../views/portcall/PortcallDetails.vue';
import NewPortCall from '../views/portcall/NewPortCall.vue';
import VesselDetails from '../views/vessels/VesselDetails.vue';
import VesselHistory from '../views/vessels/VesselHistory.vue';
import Services from '../views/portcall/services/Services.vue';
import Service from '../views/portcall/services/Service.vue';
import Expense from '../views/portcall/expenses/Expense.vue';
import Expenses from '../views/portcall/expenses/Expenses.vue';
import Hotlist from '../views/portcall/hotlist/Hotlist.vue';
import TestPage from '../views/Test/TestPage.vue';
import TestPage3 from '../views/Test/test3.vue';
import ApplicationState from '../views/other/ApplicationState.vue';
import EventLog from '../views/other/EventLog.vue';
import CargoList from '../views/portcall/cargo/CargoList.vue';
import CargoItem from '../views/portcall/cargo/CargoItem.vue';
import CheckList from '../views/portcall/checklist/CheckList.vue';
import CheckListItem from '../views/portcall/checklist/CheckListItem.vue';
import AttendanceList from '../modules/attendance/components/AttendanceList.vue';
import AttendanceItem from '../modules/attendance/components/AttendanceItem.vue';
import NoLicense from '../views/NoLicense.vue';
import VoyageList from '../views/portcall/voyage/VoyageList.vue';
import VoyageItem from '../views/portcall/voyage/VoyageForm.vue';
import VisitorList from '../views/portcall/visitors/VisitorList.vue';
import VisitorItem from '../views/portcall/visitors/VisitorItem.vue';
import CrewChange from '../views/portcall/crewchange/CrewChange.vue';
import Transports from '../views/portcall/crewchangeexternal/Transports.vue';
import Accommodations from '../views/portcall/crewchangeexternal/Accommodations.vue';
import UpcomingServices from '../views/portcall/servicesexternal/UpcomingServices.vue';
import ActivityList from '../views/activities/ActivityList.vue';
import attendanceRoutes from '../modules/attendance/routes/attendanceRoutes';
import dashboardRoutes from '../modules/dashboard/routes/dashboardRoutes';
import clientRoutes from '../modules/clients/routes/clientRoutes';
import EmailList from '../views/EmailList.vue';
import EmailItem from '../views/EmailItem.vue';
import crmRoutes from '../modules/crm/routes/crmRoutes';
import CrewChangePerson from '../views/portcall/crewchange/CrewChangePerson.vue';
import ProspectsList from '../views/portcall/prospects/ProspectsList.vue';
import ProspectDetails from '../views/portcall/prospects/ProspectDetails.vue';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    // console.log('reject:'+onResolve);
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      beforeEnter: (to, from, next) => {
        if (from.path == '/' || from.path == '/auth') {
          waitForUserData().then(() => {
            const applicationStore = useApplicationStore();
            if (
              !applicationStore.user.internal &&
              applicationStore.user.externalClientId &&
              (applicationStore.userRight('CRCH.ACCESS') || applicationStore.userRight('CRCH.ACCESS_OWN_TASK'))
            ) {
              getNumUpcomingTasks(applicationStore.user.externalClientId).then(() => {
                if (applicationStore.user.upcomingTasks.transport) {
                  next({ name: 'Transports' });
                } else if (applicationStore.user.upcomingTasks.accommodation) {
                  next({ name: 'Accommodations' });
                } else {
                  next({ name: 'PortCalls' });
                }
              });
            } else if (
              !applicationStore.user.internal &&
              applicationStore.user.externalClientId &&
              applicationStore.userRight('SER.ACCESS')
            ) {
              getNumUpcomingServices(applicationStore.user.externalClientId).then(() => {
                if (applicationStore.user.upcomingTasks.service) {
                  next({ name: 'UpcomingServices' });
                } else {
                  next({ name: 'PortCalls' });
                }
              });
            } else {
              next({ name: 'PortCalls' });
            }
          });
          // next({ name: 'PortCalls' });
        } else {
          next({ name: 'PortCalls' });
        }
      },
    },
    ...dashboardRoutes,
    ...attendanceRoutes,
    ...clientRoutes,
    ...crmRoutes,
    {
      path: '/AppState',
      name: 'AppState',
      component: ApplicationState,
    },
    {
      path: '/EventLog',
      name: 'EventLog',
      component: EventLog,
    },
    {
      path: '/test',
      name: 'TestPage',
      component: TestPage,
    },
    {
      path: '/test3',
      name: 'Test3',
      component: TestPage3,
    },
    {
      path: '/help',
      name: 'Help',
      component: TestPage,
    },

    {
      path: '/call/:callId',
      component: PortCall,
      meta: { pageTitle: 'Port call' },
      props: true,
      children: [
        {
          name: 'callDetails',
          path: '',
          component: PortCallDetails,
          alias: 'details',
        },
        {
          name: 'quayDetails',
          path: 'quay/:quayId',
          component: QuayDetails,
          props: (route) => ({ quayId: +route.params.quayId }),
        },
        {
          name: 'newDock',
          path: 'new-quay',
          component: QuayDetails,
          props: { quayId: 0, isNew: true },
        },
        {
          name: 'newExpense',
          path: 'new-expense',
          component: Expense,
          props: (route) => ({
            callId: route.params.callId,
            gridView: route.params.gridView,
            isNew: true,
          }),
        },
        {
          name: 'newServiceExpense',
          path: 'new-service-expense',
          component: Expense,
          props: (route) => ({
            callId: route.params.callId,
            serviceId: route.params.serviceId,
            templateId: route.params.templateId,
            isNew: true,
          }),
        },
        {
          name: 'callExpense',
          path: 'expense/:expenseId/:duplicate?',
          component: Expense,
          props: (route) => ({
            callId: route.params.callId,
            expenseId: +route.params.expenseId,
            duplicate: route.params.duplicate,
            gridView: route.params.gridView,
          }),
        },
        {
          name: 'callExpenses',
          path: 'expenses',
          component: Expenses,
          props: (route) => ({
            callId: route.params.callId,
            gridView: route.query.gridView,
          }),
        },
        {
          name: 'callServices',
          path: 'services',
          component: Services,
          props: true,
        },
        {
          name: 'cargoList',
          path: 'cargo-list',
          component: CargoList,
          props: true,
        },
        {
          name: 'prospectsList',
          path: 'prospects-list',
          component: ProspectsList,
          props: true,
        },
        {
          name: 'prospectDetails',
          path: 'prospect-details/:prospectId',
          component: ProspectDetails,
          props: true,
        },
        {
          name: 'checkList',
          path: 'checklist',
          component: CheckList,
          props: (route) => ({ callId: +route.params.callId }),
        },
        {
          name: 'checkListItem',
          path: 'checklist/:checklistId',
          component: CheckListItem,
          props: (route) => ({
            checklistId: +route.params.checklistId,
            callId: +route.params.callId,
            isNew: false,
          }),
        },
        {
          name: 'newCheckListItem',
          path: 'new-checklist-item',
          component: CheckListItem,
          props: (route) => ({ callId: +route.params.callId, isNew: true }),
        },
        {
          name: 'cargoItem',
          path: 'cargo-item/:cargoId',
          component: CargoItem,
          props: (route) => ({ isNew: false, cargoId: +route.params.cargoId }),
        },
        {
          name: 'newCargoItem',
          path: 'new-cargo-item',
          component: CargoItem,
          props: () => ({
            isNew: true,
            cargoId: -Math.floor(Math.random() * 1000),
          }),
        },
        {
          name: 'newService',
          path: 'new-service',
          component: Service,
          props: () => ({
            isNew: true,
            serviceId: -Math.floor(Math.random() * 1000),
          }),
        },
        {
          name: 'callService',
          path: 'service/:serviceId',
          component: Service,
          props: (route) => ({
            serviceId: route.params.serviceId,
            expenseId: route.params.expenseId,
          }),
        },
        {
          name: 'callSof',
          path: 'sof/:urlSofId?',
          component: SofPage,
          props: true,
        },
        {
          name: 'callDocs',
          path: 'docs',
          component: Documents,
          props: (route) => ({
            docTypeFilter: 0,
            applicationStatus: { PortCall: { Id: route.params.callId } },
          }),
        },

        { name: 'hotlist', path: 'hotlist', component: Hotlist, props: true },
        {
          name: 'voyageList',
          path: 'voyage',
          component: VoyageList,
          props: true,
        },
        {
          name: 'voyageItem',
          path: 'voyage-item/:voyageID',
          component: VoyageItem,
          props: true,
        },
        {
          name: 'newVoyageItem',
          path: 'new-voyage-item',
          component: VoyageItem,
          props: { isNew: true },
        },
        {
          name: 'visitorList',
          path: 'visitors',
          component: VisitorList,
          props: true,
        },
        {
          name: 'visitorItem',
          path: 'visitor-item/:visitorId',
          component: VisitorItem,
          props: true,
        },
        {
          name: 'newVisitorItem',
          path: 'new-visitor-item',
          component: VisitorItem,
          props: { isNew: true },
        },
        {
          name: 'crewChange',
          path: 'crewchange',
          component: CrewChange,
          props: true,
        },
        {
          name: 'crewChangePerson',
          path: 'crew-change-person/:personID',
          component: CrewChangePerson,
          props: (route) => ({ isNew: false, personID: route.params.personID }),
        },
        {
          name: 'newCrewChangePerson',
          path: 'new-crew-change-person',
          component: CrewChangePerson,
          props: (route) => ({ isNew: true, callId: route.params.callId, sequence: route.params.sequence }),
        },

        {
          name: 'portcallAttendance',
          path: 'attendance',
          component: AttendanceList,
          props: true,
        },
        {
          name: 'portcallAttendanceItem',
          path: 'attendance/:attendanceId',
          component: AttendanceItem,
          props: (route) => ({
            attendanceId: +route.params.attendanceId > 0 ? +route.params.attendanceId : null,
            isNew: route.params.isNew,
            callId: +route.params.callId,
          }),
        },
        {
          name: 'newPortcallAttendanceItem',
          path: 'attendance/new',
          component: AttendanceItem,
          props: (route) => ({
            attendanceId: +route.params.attendanceId || null,
            isNew: route.params.isNew,
            callId: +route.params.callId,
          }),
        },
        {
          name: 'portcallDashboard',
          path: 'dashboard',
          component: TabDashboard,
          props: (route) => ({ portcallId: +route.params.callId, tabType: 2 }),
        },
        {
          name: 'portcallEmailList',
          path: 'e-mail',
          component: EmailList,
          props: (route) => ({
            portcallId: +route.params.callId,
            location: 'portcall',
            dirty: route.params.dirty || 0,
          }),
        },
        // **
        {
          name: '',
          path: 'e-mail/:emailId',
          component: EmailView,
          props: (route) => ({
            emailId: +route.params.emailId,
            portcallId: +route.params.callId,
            location: 'portcall',
          }),
          children: [
            {
              name: 'portcallEmailItem',
              path: '',
              component: EmailItem,
              props: (route) => ({
                emailId: +route.params.emailId,
                portcallId: +route.params.callId,
                location: 'portcall',
              }),
            },

            {
              name: 'prortcallEmailReply',
              path: 'reply',
              component: EmailEditor,
              props: (route) => ({
                emailId: +route.params.emailId,
                portcallId: +route.params.callId,
                location: 'portcall',
                content: route.params.content,
                mailStyle: route.params.mailStyle || '',
                subject: route.params.subject,
                to: route.params.to,
                cc: route.params.cc,
                bcc: route.params.bcc,
                attachments: route.params.attachments,
                onLoadError: route.params.onLoadError,
              }),
            },
            {
              name: 'prortcallEmailNew',
              path: 'new',
              component: EmailEditor,
              props: (route) => ({
                emailId: +route.params.emailId,
                portcallId: +route.params.callId,
                location: 'portcall',
                content: route.params.content || '',
                mailStyle: route.params.mailStyle || '',
                mailNo: route.params.mailNo,
                subject: route.params.subject,
                mailFrom: route.params.mailFrom,
                to: route.params.to,
                cc: route.params.cc,
                bcc: route.params.bcc,
                attachments: route.params.attachments,
                attachmentsFromDocuments: route.params.attachmentsFromDocuments,
                onLoadError: route.params.onLoadError,
                isDraft: route.params.isDraft || false,
                createdBy: route.params.createdBy || undefined,
                saveDraftOnLoad: route.params.saveDraftOnLoad || false,
              }),
            },
          ],
        },
        //* */
        /* {
          name: "portcallEmailItem",
          path: "e-mail/:emailId",
          component: EmailItem,
          props: (route) => ({
            emailId: +route.params.emailId,
            portcallId: +route.params.callId,
            location: "portcall",
          }),
        }, */
      ],
    },
    // {
    //   path: '/tool',
    //   name: 'Tool',
    //   component: Toolbar
    // },
    {
      name: 'Activities',
      path: '/activities',
      meta: { pageTitle: 'Activities' },
      component: ActivityList,
    },

    {
      path: '/calls',
      name: 'PortCalls',
      meta: { pageTitle: 'Whiteboard' },
      component: PortCalls,
      props: true,
    },
    {
      path: '/newcall',
      name: 'NewPortCall',
      meta: { pageTitle: 'New Port Call' },
      component: NewPortCall,
      props: true,
    },
    {
      path: '/starred',
      name: 'FavoritesCalls',
      component: PortCalls,
      meta: { pageTitle: 'Starred port calls' },
      props: { favoritesOnly: true },
    },
    {
      path: '/search',
      name: 'PortcallSearch',
      component: PortcallSearch,
      meta: { pageTitle: 'Port call search' },
      props: (route) => ({
        query: route.query,
      }),
    },
    {
      path: '/error',
      name: 'ErrorPage',
      component: ErrorPage,
    },
    {
      path: '/vessels',
      name: 'VesselList',
      meta: { pageTitle: 'Vessels' },
      props: (route) => ({
        query: route.query,
      }),
      component: VesselList,
    },
    {
      path: '/newvessel',
      name: 'VesselNew',
      component: VesselDetails,
      props: (route) => ({
        value: route.params.value,
        fromimport: route.params.fromimport,
        isNew: true,
      }),
      // props:{value:{ID:0, VESSEL_PREFIX:'M/V'}}
    },
    {
      path: '/settings',
      name: 'Settings',
      meta: { pageTitle: 'Settings' },
      component: Settings,
    },

    {
      path: '/vessel/:vesselId',
      component: Vessel,
      props: true,
      children: [
        {
          name: 'vesselDetails',
          path: '',
          component: VesselDetails,
          alias: 'details',
        },
        {
          name: 'vesselHistory',
          path: 'history',
          component: VesselHistory,
          props: true,
        },
        {
          name: 'vesselDocs',
          path: 'docs',
          component: Documents,
          props: (route) => ({
            docTypeFilter: 21,
            applicationStatus: { vesselId: route.params.vesselId },
          }),
        },
        {
          name: 'vesselDashboard',
          path: 'dashboard',
          component: TabDashboard,
          props: (route) => ({ vesselId: +route.params.vesselId, tabType: 4 }),
        },
        {
          name: 'vesselEmailList',
          path: 'e-mail',
          component: EmailList,
          props: (route) => ({
            vesselId: +route.params.vesselId,
            location: 'vessel',
            dirty: route.params.dirty || 0,
          }),
        },
        // ** /
        {
          name: '',
          path: 'e-mail/:emailId',
          component: EmailView,
          props: (route) => ({
            emailId: +route.params.emailId,
            vesselId: +route.params.vesselId,
            location: 'vessel',
          }),
          children: [
            {
              name: 'vesselEmailItem',
              path: '',
              component: EmailItem,
              props: (route) => ({
                emailId: +route.params.emailId,
                vesselId: +route.params.vesselId,
                location: 'vessel',
              }),
            },
            {
              name: 'vesselEmailReply',
              path: 'reply',
              component: EmailEditor,
              props: (route) => ({
                emailId: +route.params.emailId,
                vesselId: +route.params.vesselId,
                location: 'vessel',
                content: route.params.content,
                subject: route.params.subject,
                to: route.params.to,
                cc: route.params.cc,
                bcc: route.params.bcc,
                attachments: route.params.attachments,
                onLoadError: route.params.onLoadError,
              }),
            },
            {
              name: 'vesselEmailNew',
              path: 'new',
              component: EmailEditor,
              props: (route) => ({
                emailId: +route.params.emailId,
                vesselId: +route.params.vesselId,
                location: 'vessel',
                content: route.params.content || '',
                mailNo: route.params.mailNo,
                subject: route.params.subject,
                mailFrom: route.params.mailFrom,
                to: route.params.to,
                cc: route.params.cc,
                bcc: route.params.bcc,
                attachments: route.params.attachments,
                onLoadError: route.params.onLoadError,
                isDraft: route.params.isDraft || false,
                createdBy: route.params.createdBy || undefined,
              }),
            },
          ],
        },

        /** * */
        /* {
          name: "vesselEmailItem",
          path: "e-mail/:emailId",
          component: EmailItem,
          props: (route) => ({
            emailId: +route.params.emailId,
            vesselId: +route.params.vesselId,
            location: "vessel",
          }),
        }, */
      ],
    },
    {
      path: '/auth',
      name: 'Auth',
      component: Auth,
    },
    {
      name: 'Visitors',
      path: '/visitors',
      component: VisitorList,
      props: () => ({ showAll: true }),
    },
    {
      path: '/nolicense',
      name: 'NoLicense',
      component: NoLicense,
    },
    {
      path: '/transports',
      name: 'Transports',
      component: Transports,
    },
    {
      path: '/accommodations',
      name: 'Accommodations',
      component: Accommodations,
    },
    {
      path: '/services',
      name: 'UpcomingServices',
      component: UpcomingServices,
    },
  ],
});

router.afterEach((to, from) => {
  const applicationStore = useApplicationStore();
  const unsaved = applicationStore.unsavedPages.find((item) => item.routeName == from.name);
  if (unsaved && unsaved.allowLeave) {
    useApplicationStore().removePageSavedState(unsaved);
  }
});

router.beforeEach((to, from, next) => {
  if (to.path == '/nolicense') {
    next();
    return;
  }
  const applicationStore = useApplicationStore();
  // leaving a page that is not saved?
  const unsaved = applicationStore.unsavedPages.find((item) => item.routeName == from.name);
  if (unsaved && unsaved.unSaved && !unsaved.allowLeave) {
    // console.log('nav stopped fro '+from.name)
    next(false);
    const navigation = {
      from,
      to,
    };
    window.messageBus.$emit('router-navigation-save-stopped', navigation);
  } else if (to.path !== '/auth') {
    // sjekker om dato er ok i webToken
    if (applicationStore.isProbablyAuthenticated) {
      next();
    } else {
      next({ path: '/auth' });
    }
  } else {
    next();
  }
});

export default router;
