<template>
  <div class="gatBottomPanel">
    <v-card v-if="showChanges" class="safe-area-inset">
      <gat-grid class="elevation-0 changepanel" :columns="changeGridColumns" :items="changes" />
    </v-card>

    <v-card class="elevation-3">
      <div class="safe-area-inset">
        <slot name="append-left-buttons" />
        <v-btn
          v-if="saveBtn"
          :disabled="!buttonsEnabled || !valueChanged || saveDisabled"
          color="success"
          class="ml-3"
          @click="$emit('saveClicked')"
          >{{ getSaveBtnText }}</v-btn
        >
        <v-btn
          v-if="duplicateBtn"
          :disabled="!buttonsEnabled"
          class="ml-3 hidden-xs-only"
          @click="$emit('duplicateClicked')"
          ><span v-show="valueChanged" class="mr-1">Save & </span>Duplicate</v-btn
        >
        <v-btn v-if="refreshBtn" :disabled="!buttonsEnabled" @click="$emit('refreshClicked')" text class="ml-3 right"
          >Refresh</v-btn
        >
        <v-btn
          v-if="deleteBtn"
          text
          :disabled="!buttonsEnabled || deleteDisabled"
          class="ml-3 right"
          @click="deleteBtnClicked"
          >Delete</v-btn
        >
        <v-btn
          text
          v-show="valueChanged && !showChanges"
          class="right showChanges hidden-xs-only"
          @click="showChanges = true"
          >show changes</v-btn
        >
        <v-btn text v-show="showChanges" class="right" @click="showChanges = false">hide changes</v-btn>
      </div>
    </v-card>

    <gat-dialog
      v-model="deleteDialog"
      okButtonCaption="Delete"
      :title="'Delete ' + itemDescription"
      @okClick="deleteConfirmed">
      <p>This operation can not be undone.</p>
      Arey you sure you would like to delete this {{ itemDescription }}?
    </gat-dialog>

    <v-dialog v-model="confirmLeaveDialog" persistent max-width="400" :retain-focus="false">
      <v-card>
        <v-card-title class="text-h6">Unsaved changes</v-card-title>
        <v-card-text>
          There are unsaved changes on the current page. <br />
          Are you sure you want to leave, without saving?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="stayOnPageClicked">Stay on page</v-btn>
          <v-btn text color="primary" @click="leavePage">Do not save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { useApplicationStore } from '@/store/applicationStore';
import GatGrid from './gatgrid/GatGrid.vue';

export default {
  name: 'GatBottomPanel',
  props: {
    constraintCheckFunction: Function,
    deleteBtn: Boolean,
    refreshBtn: Boolean,
    duplicateBtn: Boolean,
    buttonsEnabled: Boolean,
    deleteDisabled: Boolean,
    orgValue: Object,
    primaryKeyValue: [String, Number],
    newValue: Object,
    itemDescription: String,
    saveBtn: Boolean,
    saveDisabled: Boolean,
    saveBtnText: String,
    tableName: String,
  },
  components: { GatGrid },
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {
      deleteDialog: false,
      routeName: undefined,
      stoppedNavigation: undefined,

      confirmLeaveDialog: false,
      showChanges: false,
      changeGridColumns: [
        { header: 'Property', field: 'propertyName', hideOnPhone: false },
        { header: 'Old value', field: 'oldValue', hideOnPhone: false },
        { header: 'New value', field: 'newValue', hideOnPhone: false },
      ],
    };
  },

  created() {
    this.routeName = this.$route.name;

    window.messageBus.$on('router-navigation-save-stopped', (navigation) => {
      if (navigation.from.name == this.routeName) {
        this.stoppedNavigation = navigation;
        this.confirmLeaveDialog = true;
      } else {
        // console.log('nav from='+navigation.from.name+'  routeName='+this.routeName );
      }
    });
  },

  activated() {
    this.routeName = this.$route.name;
  },

  computed: {
    changes() {
      const orgObject = this.orgValue;
      const newObject = this.newValue;

      const objectChanges = GlobalHelperFunctions.getObjectChanges(orgObject, newObject);

      const filteredChanges = objectChanges.filter((change) => {
        // empty string = null = undefined
        if (
          (change.newValue === '""' || change.newValue === 'null' || change.newValue === 'undefined') &&
          (change.oldValue === '""' || change.oldValue === 'null' || change.oldValue === 'undefined')
        ) {
          return false;
        }
        return true;
      });
      return filteredChanges;
    },

    valueChanged() {
      return this.changes.length > 0;
    },
    getSaveBtnText() {
      if (this.saveBtnText) return this.saveBtnText;
      return 'Save';
    },
  },

  watch: {
    // confirmLeaveDialog(newValue){
    //   console.log('confirmLeaveDialog:'+newValue);
    // },
    valueChanged: {
      handler(changed) {
        const pageState = {
          routeName: this.$route.name,
          unSaved: changed,
          allowLeave: false,
        };
        // TODO: Should be moved outside of gat-component.
        this.applicationStore.setPageSavedState(pageState);
      },
      deep: true,
    },
  },

  methods: {
    deleteBtnClicked() {
      if (this.constraintCheckFunction && this.tableName && this.primaryKeyValue) {
        this.constraintCheckFunction(this.tableName, this.primaryKeyValue).then((result) => {
          if (result.canDelete) {
            this.deleteDialog = true;
          }
        });
      } else {
        this.deleteDialog = true;
      }
    },

    deleteConfirmed() {
      this.$emit('deleteClicked');
      this.deleteDialog = false;
    },

    leavePage() {
      this.confirmLeaveDialog = false;
      const pageState = {
        routeName: this.$route.name,
        unSaved: this.valueChanged,
        allowLeave: true,
      };
      // TODO: Should be moved outside of gat-component.
      this.applicationStore.setPageSavedState(pageState);
      this.$router.push({ name: this.stoppedNavigation.to.name, params: this.stoppedNavigation.to.params });
      this.stoppedNavigation = undefined;
      this.$emit('leftWithoutSaving');
    },
    stayOnPageClicked() {
      this.confirmLeaveDialog = false;
      this.stoppedNavigation = undefined;
      window.messageBus.$emit('toolbar-update-TabIndex');
    },
  },
};
</script>

<style lang="scss" scoped>
.safe-area-inset {
  padding-left: calc(var(--gatDrawerWitdth) + env(safe-area-inset-left));
  padding-right: calc(4px + env(safe-area-inset-right));
  padding-bottom: calc(4px + env(safe-area-inset-bottom));
  padding-top: calc(4px);
}
.gatBottomPanel {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: 4;
}

.backPanel {
  height: 48px;
}

.changepanel {
  max-height: 500px;
}

.push-right {
  margin-left: auto;
}
</style>
