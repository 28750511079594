<template>
  <gat-dialog
    v-model="dialogVisible"
    :title="!value.ID ? 'New client type/role' : 'Client type/role'"
    :okButtonCaption="!value.ID ? 'Add' : 'Save'"
    :okButtonIsProminent="true"
    width="800px"
    :deleteButton="!value.ID ? false : true"
    :itemDescription="'anchorage'"
    @cancelClick="close('cancel')"
    @deleteClick="close('delete')"
    @okClick="close('save')">
    <GatSubGroup :title="showRankingsInConnectedClients ? 'Client type / role' : undefined" size="xs12">
      <gat-select
        v-model="role.CLIENT_TYPE_ID"
        :items="roles"
        label="Role"
        textFieldName="NAME"
        codeFieldName="ID"
        :autofocus="true"
        :errorMsg="roleSelectErrors"
        size="xs12" />
    </GatSubGroup>
    <GatSubGroup title="Ranking" size="xs12" v-if="showRankingsInConnectedClients">
      <gat-select
        v-model="role.CT_RANKING"
        :items="rankings"
        label="Score"
        size="xs12 sm6"
        textFieldName="text"
        codeFieldName="value" />
      <gat-harbour-select v-model="role.HARBOUR_ID" size="xs12 sm6" />
      <gat-edit
        v-model="role.RANKING_BY"
        label="Ranked by"
        size="xs12 sm6"
        :maxChars="applicationStore.getColumnLength('Client_Type_Link.RANKING_BY')" />
      <gat-edit v-model="role.CT_RANKING_DATE" label="Date" size="xs12 sm6" dateEdit />
      <gat-edit
        v-model="role.CT_COMMENT"
        label="Remarks"
        size="xs12"
        :rows="3"
        :maxChars="applicationStore.getColumnLength('Client_Type_Link.CT_COMMENT')"
        :counter="applicationStore.getColumnLength('Client_Type_Link.CT_COMMENT')" />
    </GatSubGroup>
  </gat-dialog>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import GatHarbourSelect from '@/app-components/GatHarbourSelect.vue';
import { useConstantsStore } from '@/store/constantsStore';
import { useHelperStore } from '@/store/helperStore';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'ClientRoleForm',
  props: ['value', 'showForm', 'readonly'],
  components: { GatHarbourSelect },
  setup() {
    const constantsStore = useConstantsStore();
    const helperStore = useHelperStore();
    const applicationStore = useApplicationStore();
    return {
      helperStore,
      constantsStore,
      applicationStore,
    };
  },
  data() {
    return {
      dialogVisible: false,
      role: {},
      roleSelectErrors: [],
    };
  },

  mounted() {
    this.consent = GatUtils.makeCopyViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        const role = GatUtils.makeCopyViaJson(newValue);
        if (!role.ID) {
          role.CT_RANKING = 0;
        }
        this.role = role;
      },
    },

    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {
    showRankingsInConnectedClients() {
      return this.applicationStore.globalSettings?.SGL_SHOW_FINANCEINFO_HOTLIST;
    },
    roles() {
      return this.helperStore.clientTypes;
    },

    rankings() {
      return this.constantsStore.clientTypeRanking;
    },
  },

  methods: {
    close(btn) {
      if (btn == 'cancel' || this.formIsValid()) {
        this.dialogVisible = false;
        this.roleSelectErrors = [];
        this.$emit('close', { btn, value: this.role });
      }
    },

    formIsValid() {
      let result = true;
      if (!this.role.CLIENT_TYPE_ID) {
        result = false;
        this.roleSelectErrors = ['Required'];
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
