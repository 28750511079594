import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getSavedInfoForWeb() {
  const applicationStore = useApplicationStore();
  const data = await apiGet('savedInfo/web_');

  const userWhiteboardSettingIncludeAgencies = data.find(
    (item: any) => item.TYPE_NAME == 'web_whiteboard_includeAllAgencies'
  );
  if (userWhiteboardSettingIncludeAgencies) {
    const newSettings = {
      ...applicationStore.user.userSettings.whiteBoards,
    };
    newSettings.showCallsFromAllAgencies = JSON.parse(userWhiteboardSettingIncludeAgencies.MEMOVALUE1);
    applicationStore.setWhiteBoardUserSettings(newSettings);
  }
}
