<template>
  <div>
    <!-- {{applicationStatus}} -->
    <gat-grid
      :title="callId ? 'Attendances' : 'Previous attendances (last 30 days)'"
      class="table-spacing elevation-0"
      :items="previousAttendances"
      :columns="columns"
      :fixedheader="true"
      :height="callId ? 'calc(100vh - 135px)' : 'calc(100vh - 110px)'"
      reloadBtn
      :dense="callId >= 0"
      @reload-clicked="callId ? getPortcallAttendaces() : getPreviousAttendances()"
      @row-clicked="gotoAttendanceItem"
      keyName="ID"
      :orgSortBy="[{ field: 'CHECKIN', desc: true }]"
      :selectable="callId ? true : false"
      :selectedKeys="selectedAttendanceIds"
      @selectionChanged="attendanceSelected($event)"
      viewGroupName="attendanceList">
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == `TIME`">
          <div
            v-if="
              !props.row.CHECKOUT && (props.row.USER_ID || props.row.EXTERNAL_USER_ID) === applicationStore.user.userId
            ">
            <v-chip
              :color="
                (props.row.USER_ID || props.row.EXTERNAL_USER_ID) === applicationStore.user.userId ? 'green' : 'none'
              "
              label>
              <CountdownTimer :startDateTime="props.row.CHECKIN" :endDateTime="props.row.CHECKOUT" />
            </v-chip>
          </div>
          <div v-else>
            <CountdownTimer :startDateTime="props.row.CHECKIN" :endDateTime="props.row.CHECKOUT" />
          </div>
        </div>
        <div v-else-if="props.column.field == `USERNAME`">
          {{ props.row.USERNAME || props.row.EXTERNAL_USERNAME }}
        </div>
        <div v-else-if="props.column.field == `PORTCALL`" class="text-left d-flex align-center justify-start">
          <v-icon :class="'mr-2 ' + getPortCallStatusColorClass(props.row.STATUS)">mdi-checkbox-blank</v-icon>
          <a class="gridLink" @click.stop="goToPortcall(props.row.PORTCALL_ID)">{{
            columnHeaderPortcall(props.row)
          }}</a>
        </div>
        <div v-else-if="props.column.field == `ATTENDANCE_CODE`" class="text-left d-flex align-center justify-start">
          <a class="gridLink gridMainLink">{{ setCombinedAttendanceCode(props.row) }}</a>
        </div>
        <div v-else-if="props.column.field == `CHECKOUT`" class="text-left d-flex align-center justify-start">
          <span v-if="props.value">{{ globalFormatDateTime(props.value, 'DD.MMM HH:mm') }}</span>
          <span v-else>In attendance</span>
        </div>
      </template>
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="wmBtnGrpButtons"
          @addClick="addAttendance"
          @click="buttonClicked" />
      </template>
    </gat-grid>
  </div>
</template>

<script>
import CountdownTimer from '@/app-components/CountdownTimer.vue';
import { useAppStatusStore } from '@/store/appStatusStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useAttendanceStore } from '@/store/attendanceStore';
// eslint-disable-next-line import/no-cycle
import { getPreviousAttendances } from '@/services/api/api-calls/getPreviousAttendances';
import { getPortcallAttendances } from '@/services/api/api-calls/getPortcallAttendances';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'AttendanceList',
  props: {
    callId: [String, Number],
  },
  components: { CountdownTimer },
  setup() {
    const applicationStore = useApplicationStore();
    const appStatusStore = useAppStatusStore();
    const portcallStore = usePortcallStore();
    const attendanceStore = useAttendanceStore();
    return {
      attendanceStore,
      portcallStore,
      applicationStore,
      appStatusStore,
    };
  },
  data() {
    return {
      selectedAttendanceIds: [],
    };
  },

  created() {
    getPreviousAttendances();
  },

  watch: {
    callId: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.getPortcallAttendaces(newValue);
        }
      },
    },
  },

  computed: {
    wmBtnGrpButtons() {
      const result = [];
      if (this.attendanceCodes.length > 0) {
        result.push({ btnType: 'ADD', disabled: this.attendanceStore.inAttendance != null });
      }
      if (this.callId) {
        if ((!this.applicationStore.user.internal && this.userCanGenerateDoc) || this.applicationStore.user.internal) {
          result.push({
            btnType: 'CREATE_DOC',
            docType: 22,
            agencyId: this.portcallStore.callDetails.SETUP_ID,
            applicationStatus: this.applicationStatus,
            location: 'portcall',
            foreignKey: this.callId,
            disabled: !this.callId || !this.userCanGenerateDoc, // this.selectedAttendanceIds.length <= 0 ||
          });
        }
        if (this.canCheckInOnCurrentPortcall && this.attendanceCodes.length > 0 && !this.inAttendance) {
          result.push({
            btnType: 'CUSTOM',
            caption: 'Check in',
            icon: 'mdi-play',
            eventName: 'checkInPortcall',
            userRight: 'AT.WEB_ACCESS',
          });
        }
      } else if (!this.inAttendance && this.attendanceCodes.length > 0) {
        result.push({
          btnType: 'CUSTOM',
          caption: 'Check in',
          icon: 'mdi-play',
          eventName: 'checkIn',
          userRight: 'AT.WEB_ACCESS',
        });
      }

      if (this.inAttendance) {
        result.push({
          btnType: 'CUSTOM',
          caption: 'Check out',
          icon: 'mdi-stop',
          eventName: 'checkOut',
          userRight: 'AT.WEB_ACCESS',
        });
      }
      return result;
    },
    userCanGenerateDoc() {
      const isInternal = this.applicationStore.user.internal;
      if (isInternal) {
        return true;
      }
      const externalRights = this.applicationStore.userRight('CRCH.GENERATE_DOCUMENT');
      return externalRights;
    },
    applicationStatus() {
      return this.appStatusStore.getAppStatus;
      /* let result = {};
                  result.PortCall = {};
                  result.PortCall.Id = this.callId;
                  result.PortCall.AttendanceSelectedIds = this.selectedAttendanceIds
                  return result; */
    },
    previousAttendances() {
      let result;
      const userAttendances = this.attendanceStore.previousAttendances;
      const { portcallAttendances } = this.attendanceStore;
      if (this.callId) {
        result = portcallAttendances;
      } else {
        result = userAttendances;
      }
      // eslint-disable-next-line array-callback-return
      result.map((item) => {
        if (item.CHECKIN && item.CHECKOUT) {
          const startDateTime = new Date(item.CHECKIN).getTime();
          const endDate = new Date(item.CHECKOUT).getTime();
          // eslint-disable-next-line no-param-reassign
          item.TIME = endDate - startDateTime; // Result is time in milliseconds
        }
      });
      return result;
    },
    columns() {
      const result = [];
      if (this.callId) {
        result.push({
          header: 'User',
          field: 'USERNAME',
          calculated: (row) => row.USERNAME || row.EXTERNAL_USERNAME,
        });
      } else {
        result.push({
          header: 'Portcall',
          field: 'PORTCALL',
          calculated: (row) => `${row.PORTCALL_NUMBER} - ${row.VESSEL_PREFIX} ${row.VESSEL_NAME}`,
        });
      }
      result.push({ header: 'Attendance code', field: 'ATTENDANCE_CODE' });
      result.push({
        header: 'Checked in',
        field: 'CHECKIN',
        dataType: 'datetime',
        calculated: (rowData) => this.globalFormatDateTime(rowData.CHECKIN, 'DD.MMM HH:mm'),
      });
      result.push({ header: 'Checked out', field: 'CHECKOUT', dataType: 'datetime' });
      result.push({ header: 'Time spent', field: 'TIME', dataType: 'duration', footerFunction: { fn: 'sum' } });
      result.push({ header: 'Comments', field: 'REMARK' });

      return result;
    },
    berthedCalls() {
      return this.attendanceStore.berthedPortcalls;
    },
    attendanceCodes() {
      const attendanceCodes = [];
      // eslint-disable-next-line array-callback-return
      this.attendanceStore.attendanceCodes.map((item) => {
        if (item.INACTIVE === 0) {
          attendanceCodes.push(item);
        }
      });
      return attendanceCodes;
    },
    canCheckInOnCurrentPortcall() {
      let result = false;
      if (this.callId && this.berthedCalls && this.berthedCalls.length > 0) {
        this.berthedCalls.forEach((call) => {
          if (+call.PORTCALL_ID === +this.callId) {
            result = true;
          }
        });
      }
      return result;
    },
    inAttendance() {
      return this.attendanceStore.inAttendance;
    },
  },

  methods: {
    globalFormatDateTime(date, format) {
      return GlobalHelperFunctions.globalFormatDateTime(date, format);
    },
    buttonClicked(ev) {
      if (ev.button.caption === 'create doc') {
        this.selectedAttendanceIds = [];
      }
      if (ev.button.eventName === 'checkIn') {
        this.$router.push({ name: 'CheckIn' });
      }
      if (ev.button.eventName === 'checkInPortcall') {
        this.$router.push({ name: 'CheckIn', params: { callId: this.callId } });
      }
      if (ev.button.eventName === 'checkOut') {
        this.$router.push({ name: 'Checkout', params: { callId: this.callId ? this.callId : 0 } });
      }
    },
    columnHeaderPortcall(item) {
      return `${item.PORTCALL_NUMBER} - ${item.VESSEL_PREFIX} ${item.VESSEL_NAME}`;
    },
    setCombinedAttendanceCode(item) {
      return `${item.ATTENDANCE_CODE} - ${item.ATTENDANCE_CODE_DESCRIPTION}`;
    },
    getPortCallStatusColorClass(statusCode) {
      // eslint-disable-next-line default-case
      switch (statusCode) {
        case 0:
          return 'pc-status-expected';
        case 1:
          return 'pc-status-anchored';
        case 2:
          return 'pc-status-berthed';
        case 3:
          return 'pc-status-sailed';
        case 4:
          return 'pc-status-archived';
        case 5:
          return 'pc-status-cancelled';
      }
      return '';
    },
    gotoAttendanceItem(item) {
      if (item.CHECKOUT == null) {
        const userId = item.USER_ID || item.EXTERNAL_USER_ID;
        if (userId === this.applicationStore.user.userId) {
          this.$router.push({ name: 'Checkout', params: { callId: +this.callId } });
        }
      } else if (this.callId) {
        this.$router.push({ name: 'portcallAttendanceItem', params: { attendanceId: +item.ID, callId: +this.callId } });
      } else {
        this.$router.push({ name: 'attendanceItem', params: { attendanceId: item.ID, isNew: false, callId: null } });
      }
    },
    goToPortcall(id) {
      this.$router.push({ name: 'portcallAttendance', params: { callId: +id } });
    },
    addAttendance() {
      if (!this.callId) {
        this.$router.push({ name: 'attendanceItem', params: { isNew: true, attendanceId: -1 } });
      } else {
        this.$router.push({ name: 'newPortcallAttendanceItem', params: { isNew: true, callId: this.callId } });
      }
    },
    getPortcallAttendaces(callId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-param-reassign
      callId || (callId = this.callId);
      if (callId) {
        getPortcallAttendances(this.callId);
      }
    },
    attendanceSelected(attendanceIds) {
      this.selectedAttendanceIds = attendanceIds;
      this.appStatusStore.setAppStatusAttendanceSelectedIds(attendanceIds);
    },
  },
};
</script>

<style scoped lang="scss">
.table-spacing {
  table {
    border-collapse: separate !important;
    border-spacing: 0 4px !important;
    background: inherit;
    th,
    tr {
      border-spacing: 0 !important;
      background: inherit;
    }
    tr {
      border: 10px solid blue !important;
      border-right: 0;
      border-left: 0;
    }
  }
}
</style>
