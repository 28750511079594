/* eslint-disable no-prototype-builtins */
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { apiDelete, apiPost } from '@/store/api';
import { useGatComponentsStore } from '@/store/gatComponentsStore';

export function setGatGridView(data: any) {
  const gatComponentsStore = useGatComponentsStore();
  const view = GlobalHelperFunctions.duplicateViaJson(data);
  const idx = gatComponentsStore.gridViews.findIndex((item) => item.name == view.name);
  if (idx >= 0) {
    gatComponentsStore.gridViews.splice(idx, 1, view);
  } else {
    gatComponentsStore.gridViews.push(view);
  }

  const viewData = GlobalHelperFunctions.duplicateViaJson(data);
  delete viewData.isMyDefault; // skal ikke lagres på denne måten (view er felles, myDefault er per bruker)
  delete viewData.isDefault;

  let savedInfo: any = {
    TypeName: `web_grid_view.${data.viewGroupName}.${data.name}`,
    StrValue: JSON.stringify(viewData),
  };
  if (data.isGlobal) {
    savedInfo.Default = true;
  }
  apiPost('savedInfo', savedInfo);

  // my default endret?
  if (data.isMyDefault) {
    if (
      !(
        // eslint-disable-next-line no-prototype-builtins
        (
          gatComponentsStore.defaultGridViews.hasOwnProperty(data.viewGroupName) &&
          gatComponentsStore.defaultGridViews[data.viewGroupName] == data.name
        )
      )
    ) {
      savedInfo = {
        TypeName: `web_default_grid_view.${data.viewGroupName}`,
        StrValue: data.name,
        StrValue2: data.viewGroupName,
      };
      apiPost('savedInfo', savedInfo);
      gatComponentsStore.defaultGridViews[data.viewGroupName] = data.name;
    }
  } else if (
    // eslint-disable-next-line no-prototype-builtins
    gatComponentsStore.defaultGridViews.hasOwnProperty(data.viewGroupName) &&
    gatComponentsStore.defaultGridViews[data.viewGroupName] == data.name
  ) {
    savedInfo = {
      TypeName: `web_default_grid_view.${data.viewGroupName}`,
      StrValue: data.name,
      StrValue2: data.viewGroupName,
    };
    apiDelete('savedInfo', savedInfo);
    delete gatComponentsStore.defaultGridViews[data.viewGroupName];
  }
}
