// Pinia Store
import { defineStore } from 'pinia';
import { ref } from 'vue';
import { DashboardLocation } from '@/types/DashboardLocation';
import { DashboardSettingNames } from '@/types/DashboardSettingNames';
import { DashboardSortingTypes } from '@/types/DashboardSortingTypes';

interface MainDashboard {
  dashboardGroups: {
    GROUP_ID: number;
    NAME: string;
    SORT_ORDER: null | number;
    settingsRetrieved: boolean;
    reports: {
      reportName: number;
      title: string;
      freezeColumn: number;
      sorting: number;
      settings: { [key: string]: any };
      userSettings: { [key: string]: any };
      defaultSettings: { [key: string]: any };
    }[];
    sorting: SortingItem;
  }[];
}

interface SortingItem {
  initial: number[];
  user: number[];
  default: number[];
}

// interface SortingGroups {
//   groupName: string;
//   sorting: SortingItem;
// }

// interface ReportSorting {
//   main: SortingGroups[];
//   expenses: SortingGroups[];
// }

export const useDashboardStore = defineStore('dashboardStore', () => {
  const main = ref<MainDashboard>({ dashboardGroups: [] as any[] });
  const expenses = ref<{ [key: string]: any }>({
    reports: [] as any[],
  });
  const reportData = ref<any[]>([]);

  // const reportsSorting = ref<ReportSorting>({ expenses: [], main: [] });

  // function addOrUpdateReportSorting(
  //   location: DashboardLocation,
  //   dashboardGroupName: string,
  //   sorting: number[],
  //   sortingType: DashboardSortingTypes
  // ) {
  //   const result = { ...reportsSorting.value };

  //   const findSortingResult = () => {
  //     let sortingResult: SortingItem = {
  //       default: [],
  //       initial: [],
  //       user: [],
  //     };
  //     // We find the exsisting sorting.
  //     const idx = result[location].findIndex((item) => item.groupName === dashboardGroupName);
  //     if (idx >= 0) {
  //       sortingResult = result[location][idx].sorting;
  //     }
  //     // Then we add the new sorting result.
  //     sortingResult[sortingType] = sorting;
  //     return sortingResult;
  //   };

  //   const sortingResult = findSortingResult();
  //   const idx = result[location].findIndex((item) => item.groupName === dashboardGroupName);
  //   if (idx >= 0) {
  //     result[location][idx].sorting = sortingResult;
  //   } else {
  //     result[location].push({
  //       groupName: dashboardGroupName,
  //       sorting: sortingResult,
  //     });
  //   }
  //   reportsSorting.value = result;
  // }

  const agencyReports = ref<any[]>([]);

  function setAgencyReports(data: any[]) {
    agencyReports.value = data;
  }

  function setReportData(data: { reportName: number | string; reportData: any }) {
    const copy = [...reportData.value];
    const payload = data;
    const idx = copy.findIndex((item) => item.reportName === payload.reportName);
    if (idx >= 0) {
      copy[idx] = payload;
    } else {
      copy.push(payload);
    }
    reportData.value = copy;
  }

  function setDashboardGroups(data: any[]) {
    main.value.dashboardGroups = data;
  }

  function setEmbeddedDashboardReports(data: { location: DashboardLocation; reports: any; sorting: string | number }) {
    let result: any = { ...main.value };
    if (data.location === 'expenses') {
      result = { ...expenses.value };
    }
    if (!result) {
      result = { reports: undefined } as any;
    }
    result.reports = data.reports;
    if (!result.settingsRetrieved) {
      result.settingsRetrieved = false;
    }
    if (!result.sorting && data.sorting) {
      result.sorting = data.sorting;
    }

    if (data.location === 'expenses') {
      expenses.value = result;
    }
    if (data.location === 'main') {
      main.value = result;
    }
  }
  function setDashboardSorting({
    location,
    dashboardGroupName,
    sorting,
    settingName,
  }: {
    location: DashboardLocation;
    dashboardGroupName?: string;
    sorting: any[];
    settingName: DashboardSortingTypes;
  }) {
    if (!location) {
      console.error('location was not provided');
      return;
    }
    // eslint-disable-next-line no-param-reassign
    if (!settingName) settingName = 'user';
    if (dashboardGroupName && location === 'main') {
      const copy = [...main.value.dashboardGroups];
      // For dashboard groups.
      const idx = copy.findIndex(
        (group: any) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        copy[idx].sorting[settingName] = sorting;
        main.value.dashboardGroups = copy;
      }
    } else if (location === 'expenses') {
      // For embedded dashboards.
      const stateCopy = { ...expenses.value };
      stateCopy.sorting[settingName] = sorting;
      expenses.value = stateCopy;
    }
  }

  // eslint-disable-next-line consistent-return
  function setReportSettings({
    location,
    dashboardGroupName,
    reportName,
    settings,
    settingName,
  }: {
    location: DashboardLocation;
    dashboardGroupName?: string;
    reportName: string | number;
    settings: any;
    settingName: DashboardSettingNames;
  }) {
    if (!location || !reportName) {
      console.error('location and/or reportName was not provided.');
      return;
    }
    if (!settingName) {
      // eslint-disable-next-line no-param-reassign
      settingName = 'userSettings';
    }
    if (dashboardGroupName && location === 'main') {
      // For dashboard groups.
      const idx = main.value.dashboardGroups.findIndex(
        (group: any) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        const idx2 = main.value.dashboardGroups[idx].reports.findIndex(
          (report: any) => report.reportName === reportName
        );
        if (idx2 >= 0) {
          main.value.dashboardGroups[idx].reports[idx2][settingName] = settings; // set the settings.
          if (!main.value.dashboardGroups[idx].settingsRetrieved) {
            main.value.dashboardGroups[idx].settingsRetrieved = true;
          }
        }
      }
    } else if (location === 'expenses') {
      // For embedded dashboards.
      const idx = expenses.value.reports.findIndex((report: any) => report.reportName === reportName);
      if (idx >= 0) {
        expenses.value.reports[idx][settingName] = settings;
        if (!expenses.value.settingsRetrieved) {
          expenses.value.settingsRetrieved = true;
        }
      }
    }
  }

  function getDashboardSorting({
    location,
    dashboardGroupName,
    sortingName,
  }: {
    location: DashboardLocation;
    dashboardGroupName: string;
    sortingName?: boolean;
  }) {
    if (!location) {
      console.error('location was not provided');
      return [];
    }
    const result = {
      user: [] as any[],
      default: [] as any[],
      initial: [] as any[],
    };

    if (dashboardGroupName && location === 'main') {
      const idx = main.value.dashboardGroups.findIndex(
        (group: any) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        result.initial = [...main.value.dashboardGroups[idx].sorting.initial] || [];
        result.user = [...main.value.dashboardGroups[idx].sorting.user] || [];
        result.default = [...main.value.dashboardGroups[idx].sorting.default] || [];
      }
    } else if (location === 'expenses' && expenses.value.sorting) {
      // embedded dashboards
      result.initial = [...expenses.value.sorting.initial] || [];
      result.user = [...expenses.value.sorting.user] || [];
      result.default = [expenses.value.sorting.default] || [];
    }
    if (!sortingName) {
      // return the first occurance of sorting. User > Default
      if (result.user.length > 0) {
        return result.user;
      }
      if (result.default.length > 0) {
        return result.default;
      }
      return result.initial;
    }
    return result;
  }

  function getDashboardReports({
    location,
    dashboardGroupName,
  }: {
    location: DashboardLocation;
    dashboardGroupName: string;
  }) {
    if (!location) {
      console.error('location was not provided');
      return [];
    }
    let reports: any[] = [];
    if (dashboardGroupName && location === 'main') {
      // Dashboard groups.
      const idx = main.value.dashboardGroups.findIndex(
        (item: any) => item.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        reports = [...main.value.dashboardGroups[idx].reports] || [];
      }
    } else if (location === 'expenses' && expenses.value.reports) {
      reports = [...expenses.value.reports];
    }
    if (reports.length > 0) {
      const sorting = getDashboardSorting({
        location,
        dashboardGroupName,
      });
      if (Array.isArray(sorting)) {
        reports.sort((a, b) => {
          let ixB = sorting.findIndex((item: any) => item == b.reportName);
          let ixA = sorting.findIndex((item: any) => item == a.reportName);
          if (ixA < -10000) {
            ixA = 10000;
          }
          if (ixB < -10000) {
            ixB = 10000;
          }
          return ixA - ixB;
        });
      }
    }
    return reports; // for embedded dashboards
  }

  function getEmbeddedDashboardSorting({ location }: { location: DashboardLocation }) {
    if (!location) return console.error('location was not provided');
    const result = {
      user: [] as any[],
      default: [] as any[],
      initial: [] as any[],
    };
    if (location === 'expenses' && expenses.value.sorting) {
      result.initial = [...expenses.value.sorting.initial] || [];
      result.user = [...expenses.value.sorting.user] || [];
      result.default = [...expenses.value.sorting.default] || [];
    }
    return result;
  }

  function getChartSettings({
    location,
    dashboardGroupName,
    reportName,
    getSettingsName,
  }: {
    location: DashboardLocation;
    dashboardGroupName?: string;
    reportName: string | number;
    getSettingsName: boolean;
  }): any {
    const result = {
      initialSettings: {},
      userSettings: {},
      defaultSettings: {},
    };
    if (!location) {
      console.error('location was not provided');
      return result;
    }

    if (dashboardGroupName && location === 'main') {
      // Dashboard groups.
      const idx = main.value.dashboardGroups.findIndex(
        (group: any) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        const idx2 = main.value.dashboardGroups[idx].reports.findIndex(
          (report: any) => report.reportName === reportName
        );
        if (idx2 >= 0) {
          result.initialSettings = main.value.dashboardGroups[idx].reports[idx2].settings;
          result.userSettings = main.value.dashboardGroups[idx].reports[idx2].userSettings;
          result.defaultSettings = main.value.dashboardGroups[idx].reports[idx2].defaultSettings;
        }
      }
    } else if (location === 'expenses') {
      // Embedded dashboards.
      const idx = expenses.value.reports.findIndex((report: any) => report.reportName === reportName);
      if (idx >= 0) {
        result.initialSettings = expenses.value.reports[idx].settings;
        result.userSettings = expenses.value.reports[idx].userSettings;
        result.defaultSettings = expenses.value.reports[idx].defaultSettings;
      }
    }

    if (!getSettingsName) {
      if (
        Object.keys(result.userSettings).length > 0 &&
        Object.getPrototypeOf(result.userSettings) === Object.prototype
      ) {
        return result.userSettings;
      }
      if (
        Object.keys(result.defaultSettings).length > 0 &&
        Object.getPrototypeOf(result.defaultSettings) === Object.prototype
      ) {
        return result.defaultSettings;
      }
      return result.initialSettings;
    }
    // !dashboardGroupName goes here...
    return result;
  }

  function getChartData({ reportName }: { reportName: string }) {
    let result;
    const idx = reportData.value.findIndex((item) => item.reportName === reportName);
    if (idx >= 0) {
      result = reportData.value[idx].reportData;
    }
    return result;
  }

  function dashboardSettingsRetrieved({
    location,
    dashboardGroupName,
  }: {
    location: DashboardLocation;
    dashboardGroupName: string;
  }) {
    if (!location) console.error('location was not provided');
    let result = false;
    if (dashboardGroupName && location === 'main') {
      const idx = main.value.dashboardGroups.findIndex(
        (group: any) => group.NAME.trim().replace(' ', '').toLowerCase() === dashboardGroupName
      );
      if (idx >= 0) {
        result = main.value.dashboardGroups[idx].settingsRetrieved;
      }
    } else if (location === 'expenses') {
      result = expenses.value.settingsRetrieved;
    }
    return result === true;
  }

  return {
    main,
    expenses,
    reportData,
    agencyReports,
    setAgencyReports,
    setReportData,
    setDashboardGroups,
    setEmbeddedDashboardReports,
    setDashboardSorting,
    setReportSettings,
    getDashboardSorting,
    getDashboardReports,
    getEmbeddedDashboardSorting,
    getChartSettings,
    getChartData,
    dashboardSettingsRetrieved,
  };
});
