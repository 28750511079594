<template>
  <div class="pt-2">
    <v-combobox
      v-model="selectedRecipients"
      clearable
      multiple
      chips
      deletable-chips
      :items="results"
      :hide-details="hideDetails"
      validate-on-blur
      :item-text="getItemText"
      :delimiters="[';', ',']"
      :label="getLabel"
      size="xs12"
      :search-input.sync="inputText"
      :rules="emailRules"
      :hint="emailHint"
      persistent-hint
      :readonly="readonly"
      :counter="counter"
      :outlined="true"
      :dense="true"
      @change="inputText = ''">
      <template v-if="$slots.append" slot="append">
        <slot name="append" />
      </template>
    </v-combobox>
    <div class="radio-group" v-if="!category">
      <v-radio-group v-model="slotChoice" row dense>
        <v-radio label="TO" v-bind:value="'TO'" v-on:click.stop></v-radio>
        <v-radio label="CC" v-bind:value="'CC'" v-on:click.stop></v-radio>
        <v-radio label="BCC" v-bind:value="'BCC'" v-on:click.stop></v-radio>
        <template name="append">
          <gat-button caption="Add" @click="addClicked" :disabled="!canAdd" />
        </template>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
import { isValidEmail } from '@/modules/email/emailFunctions';
import { apiGet } from '../../store/api';

export default {
  name: 'EmailRecipientsManual',
  props: {
    value: Array,
    label: String,
    category: String,
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: Boolean,
      default: false,
    },
    required: Boolean,
  },
  components: {},
  data() {
    return {
      selectedRecipients: [],
      inputText: '',
      slotChoice: 'TO',
      results: [],
      isInputValid: false,
    };
  },

  watch: {
    value: {
      immidiate: true,
      handler(val) {
        this.selectedRecipients = val;
      },
    },
    category: {
      immidiate: true,
      handler(val) {
        const category = val.toUpperCase();
        // The category must be a valid value.
        if (category === 'TO' || category === 'CC' || category === 'BCC') {
          // Assign slot choice if the category is set.
          this.slotChoice = category;
        }
      },
    },
    inputText: {
      handler(text) {
        if (text && text.length > 2) {
          this.getResultsFromDatabase();
        } else {
          this.results = [];
        }
      },
    },
    selectedRecipients: {
      immidiate: true,
      handler(val) {
        this.$emit('input', val);
      },
    },
  },

  computed: {
    emailHint() {
      if (this.required && Array.isArray(this.value) && this.value.length === 0) {
        return '*required';
      }
      return undefined;
    },
    emailRules() {
      const result = [];
      if (this.required) {
        result.push((input) => {
          if (Array.isArray(input) && input.length === 0) {
            return '*required';
          }
          return true;
        });
      }
      result.push((input) => {
        if (input.length > 0) {
          for (let i = 0; i < input.length; i++) {
            // Don't validate items from backend
            if (!input[i].NAME && !input[i].CLIENT_NAME && !input[i].EMAIL) {
              // User input values
              if (!isValidEmail(input[i])) {
                this.isInputValid = false;
                return 'Invalid email in list';
              }
            }
          }
          this.isInputValid = true;
          return true;
        }
        return true;
      });
      return result;
    },
    getLabel() {
      if (this.label) {
        return this.label;
      }
      return 'Manually add recipients';
    },
    canAdd() {
      if (!this.selectedRecipients || this.selectedRecipients.length === 0) {
        return false;
      }
      return this.isInputValid;
    },
  },

  methods: {
    addClicked() {
      this.selectedRecipients.forEach((recipient, index) => {
        if (typeof recipient === 'string') {
          this.selectedRecipients[index] = {
            ID: recipient,
            EMAIL: recipient,
            CLIENT_NAME: '',
            NAME: '',
            category: this.slotChoice,
          };
        } else {
          // eslint-disable-next-line no-param-reassign
          recipient.category = this.slotChoice;
          // eslint-disable-next-line no-param-reassign
          recipient.ID = `${recipient.NAME}-${recipient.CLIENT_NAME}`;
        }
      });
      this.$emit('addManualClick', this.selectedRecipients);
      this.selectedRecipients = [];
    },
    async getResultsFromDatabase() {
      this.results = await apiGet(`portcall/emailRecipients/addManual/${this.inputText}`);
    },
    getItemText(item) {
      if (item.NAME === item.CLIENT_NAME && item.CLIENT_NAME === item.EMAIL) {
        return item.EMAIL;
      }
      if (item.NAME === item.CLIENT_NAME) {
        return `${item.NAME} <${item.EMAIL}>`;
      }

      if (!item.CLIENT_NAME) {
        return `${item.NAME} <${item.EMAIL}>`;
      }
      if (!item.NAME) {
        return `${item.CLIENT_NAME} <${item.EMAIL}>`;
      }

      return `${item.NAME} from ${item.CLIENT_NAME} <${item.EMAIL}>`;
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px !important;
  margin-left: 12px !important;
}
.radio-group {
  display: flex;
  justify-content: flex-end;
  margin-right: 12px;
}
</style>
