import Vue from 'vue';

const gatComponentsMessageBus = new Vue({});

export const GlobalComponentMixin = {
  data() {
    return {
      gatComponentsMessageBus,
    };
  },
};
