<template>
  <div>
    <v-layout wrap>
      <gat-edit label="web module only" toggle v-model="webModuleFilter" size="s" />
      <gat-edit label="expand details" toggle v-model="expanded" size="s" />
      <gat-edit label="max rows" v-model="maxRows" size="xs" />
    </v-layout>
    <gat-grid title="Event log" :columns="columns" reloadBtn :items="filteredData" @reload-clicked="loadData">
      <template v-if="props.column.field == 'VIR_DETAILS'" slot="cell" slot-scope="props">
        <event-log-details :logItem="props.row" :expanded="expanded" />
      </template>
    </gat-grid>
    <!-- <record-presenter :value="eventData" /> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet } from '@/store/api';
import EventLogDetails from './EventLogDetails.vue';

export default {
  name: 'EventLog',
  props: [],
  components: { EventLogDetails },
  data() {
    return {
      eventData: [],
      webModuleFilter: true,
      expanded: false,
      maxRows: 50,
    };
  },

  created() {
    this.loadData();
  },

  computed: {
    columns() {
      const result = [];
      result.push({ header: 'Time', field: 'LO_DATE_TIME', dataType: 'datetime' });
      result.push({
        header: 'Event',
        calculated: (rowData) => `${rowData.LO_LOGTYPE} ${rowData.LO_TABLENAME}`,
      });
      result.push({ header: 'User', field: 'LO_USERNAME' });
      result.push({ header: 'Source', field: 'LO_LOG_SOURCE' });
      result.push({ header: 'Details', field: 'VIR_DETAILS' });
      return result;
    },

    filteredData() {
      let result = this.eventData;
      if (this.webModuleFilter) {
        result = result.filter((item) => item.LO_LOG_SOURCE == 'web module');
      }
      return result;
    },
  },

  watch: {},

  methods: {
    loadData() {
      apiGet(`helper/eventLog/${this.maxRows}`).then((result) => {
        this.eventData = result;
      });
    },
  },
};
</script>

<style scoped></style>
