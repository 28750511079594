import { apiGet } from '@/store/api';
import { useDashboardStore } from '@/store/dashboardStore';
import { DashboardLocation } from '@/types/DashboardLocation';
import { SavedInfo } from '@/types/SavedInfo';

export async function getDashboardSorting({
  location,
  dashboardGroupName,
}: {
  location: DashboardLocation;
  dashboardGroupName: string;
}) {
  let urlExtension: string = location;
  if (dashboardGroupName) {
    urlExtension = `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`;
  }

  const dashboardStore = useDashboardStore();

  const userSettings = apiGet(`savedinfo/web_dashboard_sorting.${urlExtension}`).then((data: SavedInfo[]) => {
    if (data.length > 0) {
      data.forEach((savedInfo) => {
        if (savedInfo.NAME != 'DefaultSettings' && savedInfo.TYPE_NAME === `web_dashboard_sorting.${urlExtension}`) {
          const sorting = JSON.parse(savedInfo.MEMOVALUE1);
          // dashboardStore.addOrUpdateReportSorting(location, dashboardGroupName, sorting, 'user');
          dashboardStore.setDashboardSorting({
            location,
            dashboardGroupName,
            sorting,
            settingName: 'user',
          });
        }
      });
    }
  });
  const defaultSettings = apiGet(`savedinfo/default/web_dashboard_sorting.${urlExtension}`).then(
    (data: SavedInfo[]) => {
      if (data.length > 0) {
        data.forEach((savedInfo: any) => {
          const sorting = JSON.parse(savedInfo.MEMOVALUE1);
          // dashboardStore.addOrUpdateReportSorting(location, dashboardGroupName, sorting, 'default');
          dashboardStore.setDashboardSorting({
            location,
            dashboardGroupName,
            sorting,
            settingName: 'default',
          });
        });
      }
    }
  );
  await Promise.allSettled([userSettings, defaultSettings]);
}
