import { apiDelete } from '@/store/api';
import { useDashboardStore } from '@/store/dashboardStore';

export async function setAllUsersToDefaultSettings({
  location,
  dashboardGroupName,
  reportName,
}: {
  location: 'main' | 'expenses';
  dashboardGroupName: string;
  reportName: string;
}) {
  if ((!location && !reportName) || (!location && !dashboardGroupName && !reportName)) {
    console.error('location, dashboard group or report name was not provided.');
    return;
  }
  const urlExtension = dashboardGroupName
    ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}.${reportName}`
    : `${location}.${reportName}`;
  await apiDelete(`savedinfo/users/web_dashboard_${urlExtension}`);
  useDashboardStore().setReportSettings({
    location,
    dashboardGroupName,
    reportName,
    settings: {},
    settingName: 'userSettings',
  });
}
