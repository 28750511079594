import { render, staticRenderFns } from "./EmailEditor.vue?vue&type=template&id=05a3176e&scoped=true&"
import script from "./EmailEditor.vue?vue&type=script&lang=js&"
export * from "./EmailEditor.vue?vue&type=script&lang=js&"
import style0 from "./EmailEditor.vue?vue&type=style&index=0&id=05a3176e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05a3176e",
  null
  
)

export default component.exports