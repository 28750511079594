<template>
  <v-form
    ref="form"
    v-model="isValid"
    :readonly="readonly"
    :autocomplete="autocomplete"
    :data-lpignore="dataLpignore"
    :data-form-type="dataFormType">
    <v-snackbar :timeout="-1" :value="showInvalidInputAltert && !isValid" color="error">
      One or more of the input fields are invalid.
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="showInvalidInputAltert = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <slot></slot>

    <v-overlay :value="isLoading" :absolute="true" opacity="0.3">
      <v-progress-circular indeterminate size="64" color="primary"></v-progress-circular>
    </v-overlay>
  </v-form>
</template>

<script>
export default {
  name: 'GatForm',
  props: {
    isLoading: Boolean,
    readonly: Boolean,
    autocomplete: {
      type: String,
      default: 'off',
    },
    dataFormType: {
      type: String,
      default: 'other',
    },
    dataLpignore: {
      type: String,
      default: 'true',
    },
  },
  provide() {
    return {
      getGroupReadonly: () => this.readonly,
    };
  },
  components: {},
  data() {
    return {
      isValid: null,
      showInvalidInputAltert: false,
    };
  },

  computed: {},

  created() {},

  watch: {
    isValid: {
      handler(val) {
        this.$emit('input', val);
      },
      immediate: true,
    },
  },

  methods: {
    validate(showWarning = true) {
      const val = this.$refs.form.validate();
      if (!val && showWarning) {
        this.showInvalidInputAltert = true;
      }
      return val;
    },

    resetValidation() {
      // console.log('reset validation called');
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped></style>
