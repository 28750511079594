<template>
  <v-dialog v-model="dialogVisible" persistent max-width="600">
    <v-card class="pa-3" v-show="!deleteDialog">
      <v-layout wrap>
        <GatSubGroup title="Vessel communication" size="xs12">
          <gat-edit v-model="com.PHONE_NAME" label="Name" size="xs6" :autofocus="true" />
          <gat-select
            v-model="com.PHONE_TYPE"
            :items="comTypes"
            label="Type"
            textFieldName="name"
            codeFieldName="name"
            size="xs6">
          </gat-select>
          <gat-edit v-model="com.DETAILS" label="Number / Details" size="xs12" />
        </GatSubGroup>
      </v-layout>
      <v-card-actions>
        <v-btn text @click="deleteDialog = true" :disabled="readonly">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="close('cancel')">Cancel</v-btn>
        <v-btn color="success" @click="close('save')" :disabled="readonly">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="deleteDialog">
      <v-card-title class="title">Remove this communication from current vessel?</v-card-title>
      <!-- <v-card-text>If the consent has been withdrawn you should use the legal base value "Whithdawn"</v-card-text> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            deleteDialog = false;
            close('delete');
          "
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';

export default {
  name: 'VesselComunication',
  props: ['value', 'showForm', 'readonly'],
  components: {},
  data() {
    return {
      dialogVisible: false,
      deleteDialog: false,
      com: {},
      comTypes: [{ name: 'Email' }, { name: 'Mobile' }, { name: 'Other' }, { name: 'Telefax' }, { name: 'Telex' }],
    };
  },

  mounted() {
    this.com = GatUtils.makeCopyViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value(newValue) {
      this.com = GatUtils.makeCopyViaJson(newValue);
    },
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {},

  methods: {
    close(btn) {
      if (btn == 'cancel' || this.formIsValid()) {
        this.dialogVisible = false;
        this.$emit('close', { btn, value: this.com });
      }
    },

    formIsValid() {
      const result = true;

      return result;
    },
  },
};
</script>

<style scoped></style>
