<template>
  <div v-if="inAttendance == null || attendanceId">
    <gat-form v-model="isValid" ref="form" class="mt-6" :isLoading="isLoading">
      <v-layout wrap v-if="!userOwnsAttendace">
        <gat-edit
          label="Created by"
          :value="item.USERNAME || item.EXTERNAL_USERNAME"
          :disabled="true"
          size="xl4 lg6"></gat-edit>
      </v-layout>
      <v-layout wrap>
        <gat-select
          label="Port call"
          v-model="item.PORTCALL_ID"
          :items="berthedPortcalls"
          :useIcons="true"
          @item-selected="setAttendanceCode"
          size="xl4 lg6"
          textFieldName="text"
          codeFieldName="value"
          :disabled="!userOwnsAttendace || portcallId != null"
          :required="true" />
        <!-- :disabled="isNew && portcallId != null" -->
      </v-layout>
      <v-layout wrap>
        <gat-select
          label="Attendance code"
          v-model="item.ATTENDANCE_CODE"
          :items="attendanceCodeItems"
          @item-selected="setDateTime"
          size="xl4 lg6"
          :disabled="!userOwnsAttendace"
          :required="true" />
        <!-- v-show="item.PORTCALL_ID" -->
      </v-layout>
      <v-layout wrap>
        <gat-edit
          label="Check in"
          v-model="item.CHECKIN"
          dateTimeEdit
          :disabled="!userOwnsAttendace"
          :required="true"
          size="xl2 lg3 md4"
          :errorMessages="validateCheckInDate" />
        <!-- v-show="item.ATTENDANCE_CODE" -->
        <gat-edit
          v-if="!isCheckin"
          v-model="item.CHECKOUT"
          label="Check out"
          dateTimeEdit
          :disabled="!userOwnsAttendace"
          :required="true"
          size="xl2 lg3 md4"
          :errorMessages="validateCheckOutDate" />
        <!--  -->
      </v-layout>
      <v-layout wrap>
        <gat-edit
          label="Comments"
          v-model="item.REMARK"
          size="xl4 lg4"
          :rows="2"
          :disabled="!userOwnsAttendace"
          :max-chars="-1" />
        <!-- v-show="item.ATTENDANCE_CODE" -->
      </v-layout>
    </gat-form>
    <v-btn
      v-if="isCheckin"
      color="primary"
      :class="$vuetify.breakpoint.xsOnly ? 'myBtn' : 'ml-3'"
      @click="checkIn()"
      :disabled="!item.PORTCALL_ID || !item.ATTENDANCE_CODE || !item.CHECKIN || !validCheckInDate"
      >Check in</v-btn
    >
    <!-- v-show="item.ATTENDANCE_CODE" -->
    <gat-bottom-panel
      v-if="!isCheckin"
      :orgValue="orgItem"
      :newValue="item"
      :itemDescription="'attendance item'"
      :saveBtn="true"
      :deleteBtn="true"
      :buttonsEnabled="!saving"
      :deleteDisabled="!item.ID || !applicationStore.userRight('AT.WEB_ACCESS') || isNew || !userOwnsAttendace"
      :saveDisabled="
        !applicationStore.userRight('AT.WEB_ACCESS') || !validCheckInDate || !validCheckoutDate || !userOwnsAttendace
      "
      @saveClicked="saveClick"
      @deleteClicked="deleteItem(item.ID)">
    </gat-bottom-panel>
  </div>
</template>

<script>
import moment from 'moment';
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost, apiDelete, apiPut } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { useToolbarStore } from '@/store/toolbarStore';
import { useAttendanceStore } from '@/store/attendanceStore';
import { usePortcallStore } from '@/store/portcallStore';
import { getBerthedPortcalls } from '@/services/api/api-calls/getBerthedPortcalls';
import { getPreviousAttendances } from '@/services/api/api-calls/getPreviousAttendances';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'CheckIn',
  props: {
    attendanceId: {
      type: Number,
      default: null,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
    isCheckin: {
      type: Boolean,
      default: false,
    },
    callId: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    const toolbarStore = useToolbarStore();
    const attendanceStore = useAttendanceStore();
    const portcallStore = usePortcallStore();
    return {
      applicationStore,
      toolbarStore,
      attendanceStore,
      portcallStore,
    };
  },
  data() {
    return {
      portcallId: null,
      saving: false,
      orgItem: {},
      item: {
        PORTCALL_ID: null,
        ATTENDANCE_CODE: null,
        CHECKIN: null,
        CHECKOUT: null,
        REMARK: null,
      },
      errorMsg: {
        CHECKIN: null,
        CHECKOUT: null,
      },
      portcalls: [],
      isValid: true,
      isLoading: false,
    };
  },

  activated() {
    if (this.inAttendance != null && !this.attendanceId) {
      this.$router.push('/checkout');
    }
    getBerthedPortcalls();
    this.item = {}; // reset item
    this.orgItem = {}; // reset item
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.callId > 0 ? (this.portcallId = this.callId) : (this.portcallId = null);
    this.setCurrentAttendanceItem();
    this.setBackNavigation();
  },

  created() {
    if (this.previousAttendances.length <= 0) {
      getPreviousAttendances();
    }
  },
  deactivated() {
    this.portcallId = null; // reset portcall id whenever the component is not in use.
    this.toolbarStore.setBackNavigation(null);
  },

  watch: {
    callId: {
      immediate: true,
      handler(newVal) {
        this.portcallId = newVal; // set the portcallId.
      },
    },
  },

  computed: {
    userOwnsAttendace() {
      if (this.isNew) {
        return true;
      }
      if (this.attendanceId && this.userId) {
        const item = this.findAttendanceItem();
        if (this.applicationStore.user.internal) {
          if (item && item.USER_ID === this.userId) {
            return true;
          }
        } else if (item && item.EXTERNAL_USER_ID === this.userId) {
          return true;
        }
      }
      return false;
    },
    berthedPortcalls() {
      const result = [];
      const icon = 'mdi-checkbox-blank';
      if (this.portcallId) {
        result.push({
          value: this.portcallId,
          text: `${this.currentPortcall.PORTCALL_NUMBER} - ${this.currentPortcall.vessel.VESSEL_PREFIX} ${this.currentPortcall.vessel.NAME}`,
          icon,
          iconClass: this.getPortCallStatusColorClass(this.currentPortcall.STATUS),
        });
      }
      if (this.attendanceId) {
        const item = this.findAttendanceItem();
        if (item && +item.PORTCALL_ID != +this.attendanceId) {
          result.push({
            value: item.PORTCALL_ID,
            text: `${item.PORTCALL_NUMBER} - ${item.VESSEL_PREFIX} ${item.VESSEL_NAME}`,
            icon,
            iconClass: this.getPortCallStatusColorClass(item.STATUS),
          });
        }
      }
      const { berthedCalls } = this;
      if (berthedCalls) {
        for (let i = 0; berthedCalls.length > i; i++) {
          result.push({
            value: berthedCalls[i].PORTCALL_ID,
            text: `${berthedCalls[i].PORTCALL_NUMBER} - ${berthedCalls[i].VESSEL_PREFIX} ${berthedCalls[i].VESSEL_NAME}`,
            icon,
            iconClass: this.getPortCallStatusColorClass(berthedCalls[i].STATUS),
          });
        }
      }
      return result;
    },
    currentPortcall() {
      return this.portcallStore.callDetails || null;
    },
    validCheckoutDate() {
      if (typeof this.validateCheckOutDate == 'string' && this.validateCheckOutDate.length != 0) {
        return false;
      }
      return true;
    },
    validateCheckOutDate() {
      if (!this.item.CHECKOUT) {
        return '*required';
      }
      if (this.item.CHECKIN > this.item.CHECKOUT) {
        return "Check out can't be less than check in";
      }
      return '';
    },
    validCheckInDate() {
      if (typeof this.validateCheckInDate == 'string' && this.validateCheckInDate.length != 0) {
        return false;
      }
      return true;
    },
    validateCheckInDate() {
      if (!this.item.CHECKIN) {
        return '*required'; //
      }
      if (this.attendanceId) {
        if (this.item.CHECKIN > this.item.CHECKOUT) {
          return "Check in can't be greater than check out"; //
        }
        return '';
      }
      const today = moment();
      const checkin = moment(this.item.CHECKIN); //
      if (today >= checkin) {
        return ''; //
      }
      return "Check in can't be greater than the present date & time";
    },
    attendanceCodeItems() {
      const attendanceCodes = [];

      for (let i = 0; this.attendanceCodes.length > i; i++) {
        attendanceCodes.push({
          value: this.attendanceCodes[i].CODE,
          text: `${this.attendanceCodes[i].CODE} - ${this.attendanceCodes[i].DESCRIPTION}`,
        });
      }

      return attendanceCodes;
    },
    attendanceCodes() {
      const attendanceCodes = [];
      // eslint-disable-next-line array-callback-return
      this.attendanceStore.attendanceCodes.map((item) => {
        if (item.INACTIVE === 0) {
          attendanceCodes.push(item);
        }
      });
      if (this.attendanceId) {
        const currentAttendance = this.previousAttendances.find((item) => item.ID === this.attendanceId);
        if (currentAttendance) {
          const currentAttendanceCodeItem = {
            CODE: currentAttendance.ATTENDANCE_CODE,
            DESCRIPTION: currentAttendance.ATTENDANCE_CODE_DESCRIPTION,
          };
          const idx = attendanceCodes.findIndex((item) => item.CODE === currentAttendance.ATTENDANCE_CODE);
          if (idx >= 0) {
            attendanceCodes.splice(idx, 1, currentAttendanceCodeItem);
          } else {
            attendanceCodes.push(currentAttendanceCodeItem);
          }
        }
      }
      return attendanceCodes;
    },
    userId() {
      return this.applicationStore.user.userId;
    },
    getCurrentVesselBoarded() {
      const idx = this.berthedCalls.findIndex((item) => item.PORTCALL_ID == this.item.PORTCALL_ID);
      return `${this.berthedCalls[idx].VESSEL_PREFIX} ${this.berthedCalls[idx].VESSEL_NAME}`;
    },
    berthedCalls() {
      return this.attendanceStore.berthedPortcalls;
    },
    inAttendance() {
      return this.attendanceStore.inAttendance;
    },
    previousAttendances() {
      const userAttendances = this.attendanceStore.previousAttendances;
      const { portcallAttendances } = this.attendanceStore;
      if (this.portcallId) {
        return portcallAttendances;
      }
      return userAttendances;
    },
    exists() {
      for (let i = 0; this.berthedCalls.length > i; i++) {
        if (this.berthedCalls[i].PORTCALL_ID === this.orgItem.PORTCALL_ID) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    setBackNavigation() {
      const result = [];
      if (this.portcallId) {
        result.push({ text: 'port call', href: `/call/${this.portcallId}` });
        result.push({ text: 'attendance list', href: `/call/${this.portcallId}/attendance` });
      } else {
        result.push({ text: 'attendance list', href: '/attendance' });
      }
      this.toolbarStore.setBackNavigation(result);
    },
    setCurrentAttendanceItem() {
      // new attendance from portcall tab.
      if (this.isNew) {
        if (this.portcallId) {
          this.item.PORTCALL_ID = this.portcallId;
        } else {
          this.setDefaultPortcallFieldValue();
        }
        this.setDateTime(); // set start date + time
      } else if (this.attendanceId) {
        const itemFound = this.findAttendanceItem(this.attendanceId);
        if (itemFound) {
          this.setAttendanceItem(itemFound); // get item from store.
        } else {
          this.getAttendanceItem(); // get item from API.
        }
      }
    },
    findAttendanceItem(id) {
      let attendanceId; // parameter or this.attendanceId.
      let result = null;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      id ? (attendanceId = id) : (attendanceId = this.attendanceId);
      if (this.previousAttendances) {
        result = this.previousAttendances.find((item) => item.ID === attendanceId);
      }
      return result;
    },
    setAttendanceItem(item) {
      this.item = { ...item };
      this.orgItem = { ...item };
    },
    getAttendanceItem() {
      this.isLoading = true;
      apiGet(`/attendance/${this.attendanceId}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        this.setAttendanceItem(result);
      });
    },
    saveItem() {
      this.$refs.form.validate();
      if (this.isValid) {
        this.saving = true;
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        // Api params: int Id = attendanceId, int isCheckout = 0 (isCheckout is used in backend to add a minute if it's an active attendance. This should not be done if an attendance is updated.)
        apiPut(`attendance/checkout/${this.attendanceId}/${0}`, { orgValue: this.orgItem, value: this.item }).then(
          (result) => {
            // remove progressbar
            this.isLoading = false;
            this.saving = false;
            if (result.resultCategory == 1) {
              const newItem = this.updateItem(this.item, result.payload);
              if (newItem) {
                this.item = GlobalHelperFunctions.duplicateViaJson(newItem);
                this.orgItem = GlobalHelperFunctions.duplicateViaJson(newItem);
                this.attendanceStore.replaceAttendanceItem(newItem);
              } else {
                getPreviousAttendances();
              }

              this.$nextTick(() => {
                if (this.portcallId) {
                  this.portcallStore.setTabHasData({
                    tabName: 'ATTENDANCE',
                    value: this.attendanceStore.portcallAttendances.length,
                  });
                }
                this.goBack();
              });
            }
          }
        );
      }
    },
    updateItem(orgItem, newItem) {
      const result = { ...newItem };
      // Get the values for the 'Portcall' column in attendanceList.vue
      if (orgItem.PORTCALL_ID === newItem.PORTCALL_ID) {
        // If the portcall ID hasn't changed: copy the values.
        if (this.portcallId) {
          result.PORTCALL_NUMBER = this.currentPortcall.PORTCALL_NUMBER;
          result.VESSEL_PREFIX = this.currentPortcall.vessel.VESSEL_PREFIX;
          result.VESSEL_NAME = this.currentPortcall.vessel.NAME;
        } else if (orgItem.VESSEL_NAME && orgItem.VESSEL_PREFIX && orgItem.PORTCALL_NUMBER) {
          result.PORTCALL_NUMBER = orgItem.PORTCALL_NUMBER;
          result.VESSEL_PREFIX = orgItem.VESSEL_PREFIX;
          result.VESSEL_NAME = orgItem.VESSEL_NAME;
        }
      } else {
        // If the portcall ID has changed: try to find the values in the berthed calls store.
        const itemFound = this.berthedCalls.find((item) => item.PORTCALL_ID === newItem.PORTCALL_ID);
        if (itemFound) {
          result.PORTCALL_NUMBER = itemFound.PORTCALL_NUMBER;
          result.VESSEL_PREFIX = itemFound.VESSEL_PREFIX;
          result.VESSEL_NAME = itemFound.VESSEL_NAME;
        } else {
          // if not found, return null (will fetch a new attendance list via API.)
          return null;
        }
      }

      // Get the values for the 'Attendance code' column in attendanceList.vue
      for (let i = 0; this.attendanceCodes.length > i; i++) {
        if (this.attendanceCodes[i].CODE === newItem.ATTENDANCE_CODE) {
          result.ATTENDANCE_CODE_DESCRIPTION = this.attendanceCodes[i].DESCRIPTION;
        }
      }
      if (this.applicationStore.user.internal) {
        result.USERNAME = this.applicationStore.user.userName;
      } else {
        result.USERNAME = this.applicationStore.user.userNameLong;
      }
      result.USER_ID = this.userId;

      return result;
    },
    setAttendanceCode() {
      if (this.attendanceCodeItems.length == 1) {
        this.item.ATTENDANCE_CODE = this.attendanceCodeItems[0].value;
        this.setDateTime();
      }
    },
    setDateTime() {
      if (!this.item.CHECKIN) {
        const copy = { ...this.item };
        const today = GlobalHelperFunctions.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss');
        copy.CHECKIN = today;
        this.item = copy;
      }
    },
    checkIn() {
      if (this.isValid) {
        const checkinItem = { ...this.item };
        if (this.applicationStore.user.internal) {
          checkinItem.USER_ID = this.userId;
        } else {
          checkinItem.EXTERNAL_USER_ID = this.userId;
        }
        checkinItem.CHECKOUT = null;
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        apiPost('attendance/checkin/', checkinItem).then((result) => {
          // remove progressbar
          this.isLoading = false;
          if (result.resultCategory == 1) {
            checkinItem.ID = result.payload.ID;
            this.attendanceStore.setInAttendance(result.payload); // this item is currently set to "inAttendance."
            const newItem = this.updateItem(this.item, result.payload);
            this.attendanceStore.setPreviousAttendanceItem(newItem); // Object updated in the store with all needed values.
            const currentPortcall = this.portcallStore.callDetails.ID;
            if (+currentPortcall === newItem.PORTCALL_ID) {
              this.attendanceStore.setPortcallAttendaceItem(newItem);
            }
            this.$nextTick(() => {
              this.item = {};
              this.orgItem = GlobalHelperFunctions.duplicateViaJson(this.item);
              if (this.callId) {
                this.$router.push({ name: 'Checkout', params: { callId: this.callId } });
              } else {
                this.$router.push({ name: 'Checkout', params: { callId: 0 } });
              }
            });
          }
        });
      }
    },
    checkOut() {
      // this.$router.push('/checkin');
      const newItem = { ...this.inAttendance };
      const today = GlobalHelperFunctions.globalFormatDate(Date.now(), 'YYYY-MM-DDTHH:mm:ss');
      // let newDate = new Date(today);
      newItem.CHECKOUT = today;
      newItem.REMARK = this.item.REMARK;
      apiPut(`attendance/checkout/${this.inAttendance.ID}/${1}`, {
        orgValue: this.inAttendance,
        value: newItem,
      }).then((result) => {
        this.saving = false;
        if (result.resultCategory == 1) {
          this.attendanceStore.setAttendanceItemCheckout(result.payload);
          this.attendanceStore.setInAttendance({});
          if (this.callId) {
            this.$router.push({ name: 'portcallAttendance', params: { callId: this.callId } });
          } else {
            this.$router.push({ name: 'Attendance' }); // go back
          }
        }
      });
    },
    deleteItem(id) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiDelete(`/attendance/${id}`).then((result) => {
        // remove progressbar
        this.isLoading = false;
        if (result.resultCategory == 1) {
          this.item = {};
          this.orgItem = {};
          this.attendanceStore.removeAttendanceItem(id);
          this.$nextTick(() => {
            if (this.portcallId) {
              this.portcallStore.setTabHasData({
                tabName: 'ATTENDANCE',
                value: this.attendanceStore.portcallAttendances.length,
              });
            }
            this.goBack();
          });
        }
      });
    },

    goBack() {
      if (this.portcallId) {
        this.$router.push({ name: 'portcallAttendance' });
      } else {
        this.$router.push('/attendance'); // go back
      }
    },

    setDefaultPortcallFieldValue() {
      const activeCalls = this.berthedPortcalls;
      if (!this.item.PORTCALL_ID && activeCalls.length == 1) {
        this.item.PORTCALL_ID = activeCalls[0].value;
      }
      this.setAttendanceCode();
    },
    getPortCallStatusColorClass(statusCode) {
      // eslint-disable-next-line default-case
      switch (statusCode) {
        case 0:
          return 'pc-status-expected';
        case 1:
          return 'pc-status-anchored';
        case 2:
          return 'pc-status-berthed';
        case 3:
          return 'pc-status-sailed';
        case 4:
          return 'pc-status-archived';
        case 5:
          return 'pc-status-cancelled';
      }
      return '';
    },
    createNew() {
      if (this.isValid) {
        const checkinItem = { ...this.item };
        if (this.applicationStore.user.internal) {
          checkinItem.USER_ID = this.userId;
        } else {
          checkinItem.EXTERNAL_USER_ID = this.userId;
        }
        this.isLoading = true;
        apiPost('attendance/checkin/', checkinItem).then((result) => {
          this.isLoading = false;
          if (result.resultCategory == 1) {
            const newItem = this.updateItem(this.item, result.payload);
            this.item = GlobalHelperFunctions.duplicateViaJson(newItem);
            this.orgItem = GlobalHelperFunctions.duplicateViaJson(newItem);

            this.attendanceStore.setPreviousAttendanceItem(newItem);
            if (this.portcallId) {
              this.attendanceStore.setPortcallAttendaceItem(newItem);
            }
            this.attendanceStore.setAttendanceItemCheckout(result.payload);
            this.$nextTick(() => {
              if (this.portcallId) {
                this.portcallStore.setTabHasData({
                  tabName: 'ATTENDANCE',
                  value: this.attendanceStore.portcallAttendances.length,
                });
              }
              this.goBack();
            });
          }
        });
      }
    },
    saveClick() {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.isNew ? this.createNew() : this.saveItem();
    },
  },
};
</script>

<style lang="scss" scoped>
.myBtn {
  display: block;
  padding: 0 100px !important;
  margin: 0 auto;
}
h2 {
  font-weight: 400;
}
</style>
