<template>
  <gat-tab :items="items" :show-in-toolbar="showInToolbar" />
</template>

<script>
import GatTab from '@/plugins/gat-components/GatTab.vue';
import { useToolbarStore } from '@/store/toolbarStore';

export default {
  name: 'gatTabExtended',
  props: {
    items: Array,
    showInToolbar: Boolean,
  },
  components: { GatTab },
  setup() {
    const toolbarStore = useToolbarStore();
    return {
      toolbarStore,
    };
  },

  activated() {
    if (this.showInToolbar) {
      this.toolbarStore.setToolbarTabs(this.items);
    }
  },

  deactivated() {
    if (this.showInToolbar) {
      this.toolbarStore.setToolbarTabs(undefined);
    }
  },

  watch: {
    items(val) {
      if (this.showInToolbar) {
        this.toolbarStore.setToolbarTabs(val);
      }
    },
  },
};
</script>
