<template>
  <v-dialog v-model="showDialog" persistent max-width="500">
    <v-card>
      <v-card-title class="title">Warning</v-card-title>
      <v-card-text class="text-left" v-if="duplicateVesselNamesFound">
        {{ vCardWarningDescriptionName }}
        <ol>
          <li v-for="(name, index) in duplicateVesselNamesFound" :key="index">
            <router-link :to="'/vessel/' + name.ID">{{ name.NAME + ' (IMO no: ' + name.LLOYDSNR + ')' }}</router-link>
          </li>
        </ol>
      </v-card-text>
      <v-card-text class="text-left" v-if="duplicateVesselImoNumbersFound">
        {{ vCardWarningDescriptionImo }}
        <ol>
          <li v-for="(imo, index) in duplicateVesselImoNumbersFound" :key="index">
            <router-link :to="'/vessel/' + imo.ID">{{ imo.NAME }}</router-link>
          </li>
        </ol>
      </v-card-text>
      <v-card-text>Do you still want to import this as a new vessel?</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="closeClicked">Cancel</v-btn>
        <v-btn text color="primary" @click="saveClicked">Import anyway</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'VesselDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
    duplicateVesselNamesFound: {
      required: false,
    },
    duplicateVesselImoNumbersFound: {
      required: false,
    },
  },
  computed: {
    vCardWarningDescriptionImo() {
      if (this.duplicateVesselImoNumbersFound.length <= 1) {
        return 'A vessel with the same IMO number already exists:';
      }
      return `${this.duplicateVesselImoNumbersFound.length} vessels with the same IMO number already exists:`;
    },
    vCardWarningDescriptionName() {
      if (this.duplicateVesselNamesFound.length <= 1) {
        return 'A vessel with the same name already exists:';
      }
      return `${this.duplicateVesselNamesFound.length} vessels with the same name already exists:`;
    },
  },

  methods: {
    closeClicked() {
      this.$emit('close-clicked');
    },
    saveClicked() {
      this.$emit('save-clicked');
    },
  },
};
</script>

<style scoped>
a {
  color: #2196f3;
  text-decoration: none;
}
</style>
