
import Vue from 'vue';

export default Vue.extend({
  name: 'ShowVesselTrackerInfo',
  props: {
    ImoNumber: String,
  },
  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  watch: {},

  methods: {},
});
