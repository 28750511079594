import { createReportObject } from '@/modules/dashboard/helper/dashboardHelper';
import { apiGet } from '@/store/api';
import { useDashboardStore } from '@/store/dashboardStore';

export async function getEmbeddedDashboardReports({ location }: { location: 'main' | 'expenses' }) {
  const sorting: any = {
    initial: [],
    user: [],
    default: [],
  };
  const reports = await apiGet(`dashboard/user-def-reports/${location}`);

  // console.log(`%cDASHBOARD API: dashboard/user-def-reports/${location}`, 'background: #222; color: white');
  if (reports.length > 0) {
    // re-map report object.
    reports.forEach((report: any, index: number) => {
      reports[index] = createReportObject(report);
    });

    // assign sorting values.
    reports.forEach((report: any) => {
      const reportSorting = {
        reportName: report.reportName,
        sorting: report.sorting,
      };
      sorting.initial.push(reportSorting);
    });

    const filteredResult = sorting.initial.filter((item: any) => item.sorting > -10000);
    const finalResult = filteredResult.map((res: any) => res.reportName);
    sorting.initial = finalResult;
  }
  useDashboardStore().setEmbeddedDashboardReports({ location, reports, sorting });
}
