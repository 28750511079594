import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getCrewChangeTablesShow() {
  const applicationStore = useApplicationStore();
  const data = await apiGet('savedinfo/web-ccm-grid-show');
  if (data.length > 0) {
    applicationStore.setCrewChangeTablesShow(data[0].INTVALUE1);
  } else {
    applicationStore.setCrewChangeTablesShow(0);
  }
}
