<template>
  <div>
    <div @drop="drop" @dragover="dragover" @dragleave="dragleave">
      <div v-if="isDragging && !disabled" id="overlay" class="bg-none dropzone-overlay">
        <div style="position: absolute; z-index: 1000; top: 45%; bottom: 0; right: 0; left: 0">
          <input type="file" multiple name="email" id="emailInput" class="hidden-input" ref="email" />
          <div class="text-center mx-auto text-white" style="width: fit-content">
            <div>Drop emails here to upload</div>
          </div>
        </div>
        <div
          class="bg-primary"
          style="width: 100%; height: 100%"
          :style="isDragging ? 'opacity: .85' : 'opacity: 0.75'"></div>
      </div>
      <slot v-else name="content"></slot>
    </div>
  </div>
</template>

<script>
import MsgReader from '@kenjiuno/msgreader';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'EmailDragDrop',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    emails: {
      type: Array,
      default: () => [],
    },
    location: {
      type: String,
      Required: true,
    },
    parentId: {
      type: Number,
      Required: true,
    },
  },
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    return { applicationStore };
  },
  data() {
    return {
      isDragging: false,
    };
  },

  created() {},

  mounted() {},

  watch: {},

  computed: {},

  methods: {
    parseMessage(draggedEmail) {
      if (this.disabled) return null;
      const msgReader = new MsgReader(draggedEmail.buffer);
      const msgFileData = msgReader.getFileData();
      const attachments = msgFileData.attachments?.map((att, i) => {
        const file = msgReader.getAttachment(i);
        const fileUrl = new File([file.content], att.fileName, {
          type: att.mimeType ? att.mimeType : 'application/octet-stream',
        });
        return fileUrl;
      });
      return { email: msgFileData, emailFile: draggedEmail.emailFile, attachments };
    },
    dragover(e) {
      if (this.disabled) return;
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      if (this.disabled) return;
      this.isDragging = false;
    },
    async drop(e) {
      try {
        if (this.disabled) return;
        e.preventDefault();
        const emails = [...e.dataTransfer.files];
        const readEmailPromises = emails.map(
          (emailFile) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                const email = event.target.result;
                resolve({ fileName: emailFile.name, buffer: email, emailFile });
              };
              reader.onerror = (event) => {
                const errorReason = { fileName: emailFile.name, error: event.target.error };
                reject(errorReason);
              };
              reader.readAsArrayBuffer(emailFile);
            })
        );

        const readFilesResults = await Promise.allSettled(readEmailPromises);
        const emailMessages = [];
        const failedEmailMessages = [];
        readFilesResults.forEach((result) => {
          if (result.status === 'fulfilled') {
            const parsedMsgFile = this.parseMessage(result.value);
            if (!parsedMsgFile.email.error) {
              emailMessages.push(parsedMsgFile);
            } else {
              failedEmailMessages.push({ fileName: result.value.fileName, error: parsedMsgFile.email.error });
            }
          } else {
            failedEmailMessages.push({ fileName: result.reason.fileName, error: result.reason.error });
          }
        });

        this.isDragging = false;
        this.$emit('parsed-email-list', emailMessages, failedEmailMessages);
      } catch {
        this.isDragging = false;
        this.$emit('parsed-email-list', [], [{ fileName: 'Unknown error', error: 'Email parse error.' }]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dropzone-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 900;
  border: 2px solid #fb8c00; // var(--v-primary-base);
  border-radius: 0.5rem;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
</style>
