<template>
  <td :colspan="colspan" class="text-start">
    <v-btn v-if="!noGroupHeaderToggle" icon @click="props.toggle">
      <v-icon>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
    </v-btn>
    {{ groupHeader }}: <span class="text-subtitle-2">{{ props.group }} </span>
  </td>
</template>

<script>
import { GatGridFooterMixin } from './GatGridFooterMixin';
import { GatGridMixin } from './GatGridMixin';

export default {
  name: 'GatGridGroupHeader',
  props: ['columns', 'favorites', 'props', 'groupStatus', 'selectable', 'tight', 'noGroupHeaderToggle'],
  components: {},
  mixins: [GatGridFooterMixin, GatGridMixin],
  data() {
    return {};
  },

  created() {},

  computed: {
    colspan() {
      let result = this.columns.length - 1;
      if (this.selectable) {
        result++;
      }
      if (this.favorites) {
        result++;
      }
      return result;
    },
    isOpen() {
      return this.props.isOpen;
    },
    groupHeader() {
      let result = '';
      const header = this.columns.find((col) => col.field == this.props.groupBy);
      if (header) {
        result = header.header;
      }
      return result;
    },
  },

  watch: {
    isOpen: {
      handler(val) {
        this.$emit('wasToggled', { group: this.props.group, isOpen: val });
      },
      immediate: true,
    },
  },

  methods: {},
};
</script>

<style scoped></style>
