import { apiGet } from '@/store/api';
import { useGatComponentsStore } from '@/store/gatComponentsStore';

export async function getAllGatGridViews() {
  const gatComponentsStore = useGatComponentsStore();
  const getAllGridViews = apiGet('savedinfo/web_grid_view.').then((data: any) => {
    if (data.length > 0) {
      const views: any[] = [];
      data.forEach((savedInfo: any) => {
        const view = JSON.parse(savedInfo.MEMOVALUE1);
        // sort columns
        view.columns.sort((a: any, b: any) => a.index - b.index);
        views.push(view);
      });

      gatComponentsStore.setGatGridViews(views);
    }
  });
  const getAllDefaultGridViews = apiGet('savedinfo/web_default_grid_view.').then((data: any) => {
    const defaultViews: any = {};
    data.forEach((savedInfo: any) => {
      if (savedInfo && savedInfo.MEMOVALUE2) {
        defaultViews[savedInfo.MEMOVALUE2] = savedInfo.MEMOVALUE1;
      }
    });
    gatComponentsStore.setDefaultGridViews(defaultViews);
  });
  await Promise.allSettled([getAllGridViews, getAllDefaultGridViews]);
}
