import { render, staticRenderFns } from "./DrawerMenuItem.vue?vue&type=template&id=098f17ab&scoped=true&"
import script from "./DrawerMenuItem.vue?vue&type=script&lang=js&"
export * from "./DrawerMenuItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "098f17ab",
  null
  
)

export default component.exports