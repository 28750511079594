import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import { apiDelete, apiPost } from '@/store/api';
import { useDashboardStore } from '@/store/dashboardStore';

export async function setDashSorting({
  location,
  dashboardGroupName,
  sorting,
  isDelete,
  noSave,
  isDefault,
}: {
  location: 'main' | 'expenses';
  dashboardGroupName: string;
  sorting: any[];
  isDelete: boolean;
  noSave: boolean;
  isDefault: boolean;
}) {
  if (!location || (!location && !dashboardGroupName)) {
    console.error('location or dashboard group was not provided.');
    return;
  }
  const dashboardStore = useDashboardStore();
  const urlExtension = dashboardGroupName
    ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`
    : location;
  const savedInfo: any = {
    TypeName: `web_dashboard_sorting.${urlExtension}`,
    StrValue: JSON.stringify(sorting),
  };

  if (isDefault) {
    savedInfo.default = true;
  }
  // Check if the sorting is equal to initial sorting.
  let initialSorting: any[] = [];
  const sortingResult = dashboardStore.getDashboardSorting({
    location,
    dashboardGroupName,
    sortingName: true,
  });
  if (typeof sortingResult === 'object' && !Array.isArray(sortingResult)) {
    initialSorting = sortingResult.initial;
  }
  // if isDelete or it is equal to initial sorting, delete them from the DB.

  if (isDelete || GlobalHelperFunctions.globalObjectsAreAlike(initialSorting, sorting)) {
    await apiDelete('savedInfo', savedInfo);

    dashboardStore.setDashboardSorting({
      location,
      dashboardGroupName,
      sorting: [],
      settingName: isDefault ? 'default' : 'user',
    });
  } else if (!noSave) {
    await apiPost('savedInfo', savedInfo);

    dashboardStore.setDashboardSorting({
      location,
      dashboardGroupName,
      sorting,
      settingName: isDefault ? 'default' : 'user',
    });
  } else {
    dashboardStore.setDashboardSorting({
      location,
      dashboardGroupName,
      sorting,
      settingName: isDefault ? 'default' : 'user',
    });
  }
}
