import { render, staticRenderFns } from "./SelectEmailFrom.vue?vue&type=template&id=d5d8fb66&scoped=true&"
import script from "./SelectEmailFrom.vue?vue&type=script&lang=js&"
export * from "./SelectEmailFrom.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5d8fb66",
  null
  
)

export default component.exports