import { apiGet } from '@/store/api';
import { useDashboardStore } from '@/store/dashboardStore';
import { DashboardLocation } from '@/types/DashboardLocation';
import { DashboardSettingNames } from '@/types/DashboardSettingNames';

export async function getChartSettings({
  location,
  dashboardGroupName,
}: {
  location: DashboardLocation;
  dashboardGroupName: string;
}) {
  const urlExtension = dashboardGroupName
    ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`
    : `${location}`;
  const data = await apiGet(`dashboard/user-settings/web_dashboard_${urlExtension}`);
  if (data.length > 0) {
    data.forEach((savedInfo: any) => {
      let settingName: DashboardSettingNames = 'userSettings';
      let settings = {};
      const reportName = +savedInfo.REPORT_NAME;
      if (savedInfo.NAME === 'DefaultSettings') {
        settingName = 'defaultSettings';
      }
      if (savedInfo.REPORT_SETTINGS) {
        settings = JSON.parse(savedInfo.REPORT_SETTINGS);
      }
      useDashboardStore().setReportSettings({
        location,
        dashboardGroupName,
        reportName,
        settings,
        settingName,
      });
    });
  }
}
