<template>
  <div class="chartTable">
    <gat-grid
      allowHtml
      class="elevation-0"
      :class="gridClass"
      :fixedheader="true"
      :height="gridHeight"
      :columns="calcColumns"
      :items="rowData"
      :dense="true"
      :stickyFirstColumn="stickyFirstColumn === 1" />
  </div>
</template>

<script>
export default {
  name: 'ChartTable',
  props: ['data', 'columns', 'maximized', 'formating', 'stickyFirstColumn', 'headerHeight', 'tableHeight', 'settings'],
  components: {},
  data() {
    return {};
  },

  created() {},

  computed: {
    extraDense() {
      const hasDenseProperty = this.settings && this.settings.options && this.settings.options.dense;
      const isTable = this.settings && this.settings.chartType.toString().toLowerCase() === 'table';
      if (isTable && hasDenseProperty) {
        return true;
      }
      return false;
    },
    gridClass() {
      let result = '';
      if (this.maximized) {
        result = 'maxWidth ';
      }
      if (this.extraDense === true) {
        result += 'extra-dense ';
      }
      return result;
    },
    calcColumns() {
      let result = [];
      if (this.columns) {
        result = this.columns;
      } else if (this.data.length > 0) {
        for (let index = 0; index < this.data[0].length; index++) {
          const element = this.data[0][index];
          result.push({ header: element, field: `col_${index + 1}` });
        }
      }
      if (this.formating) {
        // eslint-disable-next-line array-callback-return
        this.formating.map((formatItem) => {
          const col = result.find((colItem) => colItem.header == formatItem.field);
          if (col) {
            col.numberFormat = formatItem.numberFormat;
          }
        });
      }
      return result;
    },

    gridHeight() {
      if (this.maximized) {
        return 'calc(100vh - 60px)';
      }
      const totalHeight = `${this.tableHeight - this.headerHeight - 9}px`;
      return totalHeight; // 9 is to adjust for margins etc. (can be adjusted later & must have the same value in G-Chart)
    },

    rowData() {
      const result = [];
      for (let index = 1; index < this.data.length; index++) {
        const colList = this.data[index];
        // row
        const rowObj = {};
        for (let col = 0; col < colList.length; col++) {
          const value = colList[col];
          const fieldName = `col_${col + 1}`;
          rowObj[fieldName] = value;
        }
        result.push(rowObj);
      }
      return result;
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.chartTable {
  height: auto;
}
::v-deep .extra-dense {
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    height: fit-content !important;
  }
}

.hidden {
  opacity: 0.3;
}
</style>
