import AgencyLocationList from '../components/AgencyLocationList.vue';
import AgencyLocationForm from '../components/AgencyLocationForm.vue';

function convertFromParams(route) {
  return {
    agencyLocationId: Number(route.params.agencyLocationId),
    isNew: route.params.isNew,
  };
}

const crmRoutes = [
  {
    name: 'crmAgencyLocations',
    path: '/crm/agency-locations',
    meta: { pageTitle: 'Agency locations' },
    component: AgencyLocationList,
    props: true,
  },
  {
    name: 'crmAgencyLocationForm',
    path: '/crm/agency-location/:agencyLocationId',
    meta: { pageTitle: 'Agency location' },
    component: AgencyLocationForm,
    props: convertFromParams, // to avoid console error as the url parameters are strings
  },
];

export default crmRoutes;
