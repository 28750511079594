import { ref, watch } from 'vue';
// import { useRoute } from 'vue-router/composables';

export function useDocumentTitle(defaultTitle: string) {
  const defaultDocumentTitle = ref<string>(defaultTitle);

  function setDocumentTitle(title?: string) {
    document.title = title ?? defaultDocumentTitle.value;
  }
  watch(
    () => defaultDocumentTitle.value,
    (val) => {
      setDocumentTitle(val);
    }
  );

  const titleForRoutes = ref<{ routeName: string; pageTitle?: string }[]>([]);

  /** Will save a title to the specific route name. Will overwrite any existing pagetitle for the specified route name.  */
  function addDocumentTitle(routeName: string, pageTitle?: string) {
    const newItem = { routeName, pageTitle };
    const idx = titleForRoutes.value.findIndex((i) => i.routeName === routeName);
    if (idx >= 0) {
      titleForRoutes.value.splice(idx, 1, newItem);
    } else {
      titleForRoutes.value.push(newItem);
    }
  }

  /* const route = useRoute();
  watch(
    () => route.name,
    (val) => {
      const found = titleForRoutes.value.find((i) => i.routeName === val?.toString());
      setDocumentTitle(found?.pageTitle);
    }
  ); */
  if (defaultTitle) {
    defaultDocumentTitle.value = defaultTitle;
  }

  return {
    /** Will save a title to the specific route name. This means that any time you go to this route, the document title will be updated. Will overwrite any existing pagetitle for the specified route name.  */
    addDocumentTitle,
    /** Will set the document title once. (does not save. Use addDocumentTitle to save.)  */
    setDocumentTitle,
    /** Overwrites the default document title. */
    defaultDocumentTitle,
  };
}
