<template>
  <div class="pb-6 mb-6">
    <gat-form v-model="isValid" ref="form" :readonly="!canCreatePortCall"
      :isLoading="isLoading || callsRemainingIsLoading">
      <v-alert v-if="useCreditAlert" :color="canUserApproveCreditControl ? 'orange darken-1' : 'red darken-1'" dense text
        :type="canUserApproveCreditControl ? 'warning' : 'error'" outlined>
        <span class="font-weight-medium">Credit limit!</span><br />
        <span style="white-space: pre-line">
          Credit Limit Exceeded for {{ portCallCreditLimitResult.clientName }}. Credit balance:
          {{ formatNumber(portCallCreditLimitResult.balance, '0,0') }}
          {{ applicationStore.getAgencySettings(portcall.SETUP_ID).LocalCurrency }}

          <span v-if="!canUserApproveCreditControl">Please contact your line manager.</span>
        </span>
      </v-alert>
      <template v-for="(item, index) in portCallClientWarnings">
        <v-alert :key="index" v-if="true" color="orange dargen-1" dense text type="warning" outlined>
          <span class="font-weight-medium">{{ item.name }}</span><br />
          <span style="white-space: pre-line">
            {{ item.message }}
          </span>
        </v-alert>
      </template>
      <gat-sub-group size="xs12">
        <gat-select size="xs12 sm6 md4" v-model="portcall.SETUP_ID" :items="agencyItems" label="Agency"
          :required="true" />
        <gat-select label="Type of call" v-model="portcall.extra.PCE_CALLTYPE" :items="helperStore.callTypes"
          size="xs12 sm6 md4" :required="helperStore.callTypes.length > 0" v-if="helperStore.callTypes.length > 0" />
        <gat-vessel-select v-model="portcall.VESSEL_ID" label="Vessel" size="xs12 sm6 md4" :required="true" />
      </gat-sub-group>
      <gat-sub-group size="xs12">
        <gat-edit :label="etpsCaption" v-show="applicationStore.globalSettings.SGL_ETPS_IN_NEWCALLWND === 1"
          v-model="portcall.extra.ETPS" dateTimeEdit futureDate hint="Estimated time of pilot station"
          size="xs6 sm6 md4 lg4 xl2" :required="applicationStore.globalSettings.SGL_ETPS_MANDATORY === 1"
          :errorMessages="validateEtaDate()" />
        <gat-edit label="ETA date" v-show="applicationStore.globalSettings.SGL_ETPS_IN_NEWCALLWND !== 1"
          v-model="portcall.ETA" dateTimeEdit futureDate hint="Estimated time of arrival" size="xs6 sm6 md4 lg4 xl2"
          :required="applicationStore.globalSettings.SGL_ETA_MANDATORY === 1" :errorMessages="validateEtaDate()" />
        <gat-edit label="ETD date" v-model="portcall.ETD" dateTimeEdit futureDate hint="Estimated time of departure"
          size="xs6 sm6 md4 lg4 xl2" :required="applicationStore.globalSettings.SGL_PC_ETD_MANDATORY === 1"
          :errorMessages="validateEtdDate()" />
      </gat-sub-group>
      <gat-sub-group size="xxl">
        <quay-select v-model="portcall.quayId" size="xs12 sm6 md4" terminalSize="xs12 sm6 md4" harbourSize="xs12 sm6 md4"
          :quayRequired="applicationStore.globalSettings.SGL_PC_QUAY_MANDATORY === 1"
          :showWarningIcon="quayWarningResponse" :warningMessage="quayWarningResponse ? quayWarningResponse.message : ''">
        </quay-select>
      </gat-sub-group>

      <GatSubGroup size="xs12">
        <GatHarbourSelect label="Previous port" v-model="portcall.prevPort" size="xs6 sm6 md4 lg4 xl2"
          :required="applicationStore.globalSettings.SGL_PC_ARRIVED_FROM_MANDATORY === 1" />
        <GatEdit label="ETD prev port" v-model="portcall.extra.PCE_ETDPP" dateTimeEdit futureDate
          size="xs6 sm6 md4 lg4 xl2" />
        <GatHarbourSelect label="Next port" v-model="portcall.nextPort" size="xs6 sm6 md4 lg4 xl2"
          :required="applicationStore.globalSettings.SGL_PC_SAILED_FOR_MANDATORY === 1" />
        <GatEdit label="ETA next port" v-model="portcall.extra.PCE_EDANP" dateTimeEdit futureDate
          size="xs6 sm6 md4 lg4 xl2" />
        <GatEdit label="ETD next port" v-model="portcall.extra.PCE_ETDNP" dateTimeEdit futureDate
          size="xs6 sm6 md4 lg4 xl2" />
      </GatSubGroup>

      <gat-sub-group size="xs12">
        <GatSelect label="SOF template" v-model="portcall.sof_template_id" :items="sofTemplateItems" size="xs12 sm6 md4"
          :required="applicationStore.globalSettings.SGL_PC_SOF_MANDATORY === 1" :disabled="callCategory === 3" />
        <GatSelect label="Checklist template" v-model="portcall.checklist_template_id" :items="checklistTemplateItems"
          size="xs12 sm6 md4" :required="applicationStore.globalSettings.SGL_PC_CHECKLIST_MANDATORY === 1" />
        <GatSelect label="Client template" v-model="portcall.connected_clients_template_id" :items="hotlistTemplateItems"
          size="xs12 sm6 md4" />
      </gat-sub-group>
      <GatSubGroup size="xs12">
        <ResponsibleRadioInput :portcall-setup-id="portcall.SETUP_ID" :teamIsResponsible.sync="teamIsResponsible"
          :personInCharge.sync="portcall.PERSON_IN_CHARGE" :teamInCharge.sync="portcall.TEAM_IN_CHARGE_ID" />
      </GatSubGroup>
      <gat-sub-group size="xs12" v-if="showGenerateCallNumber">
        <GatEdit label="Generate Call number" v-model="generateCallNumber" toggle size="xl2 sm6 md4 xs6" />
        <GatEdit v-show="!generateCallNumber" label="Call number" v-model="portcall.PORTCALL_NUMBER"
          size="xl2 sm6 md4 xs6" :maxChars="25" />
      </gat-sub-group>
    </gat-form>
    <gat-bottom-panel :orgValue="orgPortCall" :newValue="portcall" itemDescription="this port call" saveBtn
      :buttonsEnabled="isSaveButtonEnabled" @saveClicked="saveClicked">
      <!-- <template slot="append-left-buttons">
        <v-btn color="success" class="ml-3" :disabled="(!isSaveAndCargoNextButtonEnabled)" @click="saveCargoNextClicked">Save & Cargo next</v-btn>
      </template> -->
    </gat-bottom-panel>
    <gat-dialog @okClick="dialogOkClicked()" :value="showResponseDialog" okButtonCaption="OK" cancelButtonCaption=""
      :title="getResponseCategory === 2 ? 'Error!' : 'Warning!'">
      <span style="white-space: pre-line">{{ getResponseMessage }}</span>
    </gat-dialog>
    <gat-dialog v-if="useCreditAlert" @okClick="dialogCreditLimitOkClicked()"
      @cancelClick="dialogCreditLimitCancelClicked()" :value="showPortCallCreditLimitDialog"
      :okButtonCaption="canUserApproveCreditControl ? 'OK' : ''" cancelButtonCaption="Cancel" title="Credit limit!">
      <span style="white-space: pre-line">
        Credit Limit Exceeded for the default paying party. Credit balance:
        {{ formatNumber(portCallCreditLimitResult.balance, '0,0') }}
        {{ applicationStore.getAgencySettings(portcall.SETUP_ID).LocalCurrency }}

        <span v-if="canUserApproveCreditControl">Do you still want to create the port call?</span>
        <span v-else>Please contact your line manager.</span>
      </span>
    </gat-dialog>
    <PortcallRemainingDialog @isLoading="(val) => (callsRemainingIsLoading = val)" />
  </div>
</template>

<script>
import GatHarbourSelect from '@/app-components/GatHarbourSelect.vue';
import { DateTime } from 'luxon';
import QuaySelect from '@/app-components/QuaySelect.vue';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
// eslint-disable-next-line import/no-cycle
import { getBerthedPortcalls } from '@/services/api/api-calls/getBerthedPortcalls';
import { getPortcallDetails } from '@/services/api/api-calls/getPortcallDetails';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import SednaApiService from '@/services/api/external/sednaAPI';
import { apiGet, apiPut } from '../../store/api';
import GatVesselSelect from '../../app-components/GatVesselSelect.vue';
import PortcallRemainingDialog from './PortcallRemainingDialog.vue';
import ResponsibleRadioInput from './ResponsibleRadioInput.vue';

export default {
  name: 'NewPortCall',
  props: [],
  components: {
    GatHarbourSelect,
    QuaySelect,
    GatVesselSelect,
    PortcallRemainingDialog,
    ResponsibleRadioInput,
  },
  setup() {
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    return {
      helperStore,
      applicationStore,
      portcallStore,
    };
  },
  data() {
    return {
      orgPortCall: {
        SETUP_ID: null,
        VESSEL_ID: null,
        extra: {
          PCE_CALLTYPE: null,
        },
      },
      portcall: {
        SETUP_ID: null,
        VESSEL_ID: null,
        extra: {
          PCE_CALLTYPE: null,
        },
      },
      isValid: false,
      isLoading: false,
      callsRemainingIsLoading: false,
      saving: false,
      teamIsResponsible: false,
      generateCallNumber: true,
      sofTemplateItems: [],
      checklistTemplateItems: [],
      hotlistTemplateItems: [],
      callCategory: undefined,
      showResponseDialog: false,
      portCallResponseResult: undefined,
      portCallCreditLimitResult: null,
      showPortCallCreditLimitDialog: false,
      portCallClientWarnings: [],
      quayWarningResponse: null,
      cargoNext: false,
    };
  },

  computed: {
    // Legge inn
    etpsCaption() {
      const agencySettings = this.applicationStore.getAgencySettings(this.portcall.SETUP_ID);
      return agencySettings?.ETPS_CAPTION ?? 'ETPS';
    },
    isValidEtaDate() {
      if (this.validateEtaDate() != '') {
        return false;
      }
      return true;
    },
    isValidEtdDate() {
      if (this.validateEtdDate() != '') {
        return false;
      }
      return true;
    },
    getResponseMessage() {
      if (this.portCallResponseResult && this.portCallResponseResult.message) {
        return this.portCallResponseResult.message;
      }
      return '';
    },
    getResponseCategory() {
      if (this.portCallResponseResult && this.portCallResponseResult.resultCategory) {
        return this.portCallResponseResult.resultCategory;
      }
      return undefined;
    },
    canCreatePortCall() {
      return !this.saving && this.applicationStore.userRight('P.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    teams() {
      // eslint-disable-next-line array-callback-return, consistent-return
      return this.helperStore.allTeams.filter((team) => {
        if (team.AF_AGENCY_ID === this.portcall.SETUP_ID) return team;
        if (team.AF_AGENCY_ID === null) {
          return team;
        }
      });
    },
    users() {
      return this.helperStore.users;
    },
    agencyItems() {
      const agency = this.applicationStore.agencySettings;
      const result = agency.map((obj) => {
        const newObj = {};
        if (obj.ID) {
          newObj.text = obj.GROUP_NAME;
          newObj.value = obj.ID;
        }
        return newObj;
      });
      return result;
    },
    showGenerateCallNumber() {
      return this.applicationStore.globalSettings.SGL_PC_HIDE_GENERATE_PCNO_NEW_PC !== 1;
    },
    isSaveButtonEnabled() {
      return this.canCreatePortCall && this.isValidEtaDate && this.isValidEtdDate;
    },
    isSaveAndCargoNextButtonEnabled() {
      return (
        this.isSaveButtonEnabled &&
        !GlobalHelperFunctions.globalObjectsAreAlike(this.orgPortCall, this.portcall) &&
        this.callCategory !== 3
      );
    },
    canUserApproveCreditControl() {
      return this.applicationStore.userRight('C3.APPROVE_CREDIT_CONTROL');
    },
    useCreditAlert() {
      return (
        this.portCallCreditLimitResult &&
        this.portCallCreditLimitResult.creditLimit > 0 &&
        this.portCallCreditLimitResult.balance < 0 &&
        this.applicationStore.globalSettings.SGL_CLIENT_CREDIT_CONTROL_PORTCALL === 1
      );
    },
  },

  created() { },

  activated() {
    this.setFormDefaultValues();
    this.getDefaultValuesGenerateCallnumber();
    this.portcall.SETUP_ID = this.applicationStore.agencyIdSelected;
    this.orgPortCall = GlobalHelperFunctions.duplicateViaJson(this.portcall);
  },

  watch: {
    'portcall.quayId': {
      handler(value) {
        this.getQuayWarning(value, this.portcall.VESSEL_ID);
        this.getHotlistTemplateItems(this.portcall.SETUP_ID, value);
      },
    },
    'portcall.VESSEL_ID': {
      handler(value) {
        this.checkPortCallCreditLimit(value);
        this.getPortCallVesselClientWarning(value, -1);
        this.getQuayWarning(this.portcall.quayId, value);
      },
    },
    'portcall.connected_clients_template_id': {
      handler(value) {
        this.checkPortCallCreditLimit(value);
        this.getPortCallVesselClientWarning(this.portcall.VESSEL_ID, value);
      },
    },
    'portcall.SETUP_ID': {
      handler(value) {
        this.responsibleDefaultValues(value);
        this.getSofTemplates(value, this.portcall.extra.PCE_CALLTYPE);
        this.getChecklistTemplateItems(value);
        this.getHotlistTemplateItems(value, this.portcall.quayId);
      },
    },
    'portcall.extra.PCE_CALLTYPE': {
      handler(value) {
        this.getSofTemplates(this.portcall.SETUP_ID, value);

        let callType;
        if (value) {
          const { callTypes } = this.helperStore;
          callType = callTypes.find((item) => item.value == value);
        }
        if (callType) {
          this.callCategory = callType.PCT_CATEGORY;
        } else {
          this.callCategory = undefined;
        }
      },
    },
  },

  methods: {
    formatNumber(value, format) {
      return GlobalHelperFunctions.formatNumber(value, format);
    },
    dialogCreditLimitOkClicked() {
      this.showPortCallCreditLimitDialog = false;
      this.savePortCall(true);
    },
    dialogCreditLimitCancelClicked() {
      this.showPortCallCreditLimitDialog = false;
    },
    checkPortCallCreditLimit() {
      if (this.applicationStore.globalSettings.SGL_CLIENT_CREDIT_CONTROL_PORTCALL !== 1) {
        this.portCallCreditLimitResult = null;
        return;
      }

      const vesselId = this.portcall.VESSEL_ID;
      if (!vesselId) {
        this.portCallCreditLimitResult = null;
        return;
      }
      let hotlistTemplateId = this.portcall.connected_clients_template_id;
      if (!hotlistTemplateId) hotlistTemplateId = -1;

      apiGet(`/portcall/credit-limit?vesselId=${vesselId}&hotlistTemplateId=${hotlistTemplateId}`).then((result) => {
        this.portCallCreditLimitResult = result;
      });
    },
    validateEtaDate() {
      let eta = null;
      if (this.applicationStore.globalSettings.SGL_ETPS_IN_NEWCALLWND === 1) {
        eta = DateTime.fromISO(this.portcall.extra.ETPS);
        if (!this.portcall.ETA && this.applicationStore.globalSettings.SGL_ETPS_MANDATORY === 1) {
          return '*required';
        }
      } else {
        eta = DateTime.fromISO(this.portcall.ETA);

        if (!this.portcall.ETA && this.applicationStore.globalSettings.SGL_ETA_MANDATORY === 1) {
          return '*required';
        }
      }

      const etd = DateTime.fromISO(this.portcall.ETD);

      if (!(etd && eta)) {
        return '';
      }

      if (eta > etd) {
        if (this.applicationStore.globalSettings.SGL_ETPS_IN_NEWCALLWND === 1) {
          return "ETPS can't be after ETD";
        }
        return "ETA can't be after ETD";
      }

      return '';
    },
    validateEtdDate() {
      let eta = null;
      if (this.applicationStore.globalSettings.SGL_ETPS_IN_NEWCALLWND === 1) {
        eta = DateTime.fromISO(this.portcall.extra.ETPS);
      } else {
        eta = DateTime.fromISO(this.portcall.ETA);
      }

      if (!this.portcall.ETD && this.applicationStore.globalSettings.SGL_PC_ETD_MANDATORY === 1) {
        return '*required';
      }

      const etd = DateTime.fromISO(this.portcall.ETD);

      if (!(eta && etd)) {
        return '';
      }

      if (eta > etd) {
        if (this.applicationStore.globalSettings.SGL_ETPS_IN_NEWCALLWND === 1) {
          return "ETD can't be earlier than ETPS";
        }

        return "ETD can't be earlier than ETA";
      }

      return '';
    },
    dialogOkClicked() {
      if (this.portCallResponseResult.resultCategory === 2) {
        this.showResponseDialog = false;
        this.portCallResponseResult = undefined;
        return;
      }
      this.navigateAfterSuccessfulSave();
    },
    getSofTemplates(setupId, callType) {
      if (!callType) {
        // eslint-disable-next-line no-param-reassign
        callType = -1;
      }
      if ((setupId || setupId === 0) && (callType || callType === -1)) {
        apiGet(`sof/templates/${setupId}/${callType}`).then((result) => {
          if (Array.isArray(result) && result.length > 0) {
            let sofItems = result.map((item) => ({
              ...item,
              text: item.NAME,
              value: item.ID,
            }));
            sofItems = sofItems.filter((item) => item.ID !== -999);
            this.sofTemplateItems = sofItems;
          } else {
            this.sofTemplateItems = [];
          }
        });
      }
    },
    getChecklistTemplateItems(setupId) {
      if (setupId) {
        apiGet(`checklist/templates/${setupId}`).then((result) => {
          if (Array.isArray(result) && result.length > 0) {
            const checklistItems = result.map((item) => ({
              ...item,
              text: item.NAME,
              value: item.ID,
            }));
            this.checklistTemplateItems = checklistItems;
          } else {
            this.checklistTemplateItems = [];
          }
        });
      }
    },
    getHotlistTemplateItems(setupId, quayId) {
      if (setupId) {
        apiGet(`hotlist/template?agencyId=${setupId}&quayId=${quayId || -1}`).then((result) => {
          if (Array.isArray(result) && result.length > 0) {
            const items = result.map((item) => ({
              ...item,
              text: item.HTE_NAME,
              value: item.HTE_ID,
            }));
            this.hotlistTemplateItems = items;
          } else {
            this.hotlistTemplateItems = [];
          }
        });
      } else {
        this.hotlistTemplateItems = [];
      }
    },
    getPortCallVesselClientWarning(vesselId, hotlistTemplateId) {
      if (!vesselId) {
        // eslint-disable-next-line no-param-reassign
        vesselId = -1;
      }
      if (!hotlistTemplateId) {
        // eslint-disable-next-line no-param-reassign
        hotlistTemplateId = -1;
      }

      if (!vesselId && !hotlistTemplateId) {
        this.portCallClientWarnings = [];
        return;
      }

      apiGet(`portcall/vessel-client-warning?vesselId=${vesselId}&hotlistTemplateId=${hotlistTemplateId}`).then(
        (result) => {
          if (result) {
            this.portCallClientWarnings = result;
          } else {
            this.portCallClientWarnings = [];
          }
        }
      );

      this.portCallClientWarnings = [];
    },
    getQuayWarning(quayId, vesselId) {
      if (!vesselId || !quayId) {
        this.quayWarningResponse = null;
        return;
      }

      apiGet(`portcall/quay-warning?vesselId=${vesselId}&quayId=${quayId}`).then((result) => {
        this.quayWarningResponse = result;
      });

      this.quayWarningResponse = null;
    },
    getDefaultValuesGenerateCallnumber() {
      apiGet('portcall/default-value-generate-portcall-number').then((result) => {
        this.generateCallNumber = result === 1;
      });
    },
    responsibleDefaultValues(agencyId) {
      const agencySettings = this.applicationStore.getAgencySettings(agencyId);

      if (agencySettings.USERINCHARGE === 1) {
        this.teamIsResponsible = false;
        this.portcall.TEAM_IN_CHARGE_ID = null;
        this.portcall.PERSON_IN_CHARGE = this.applicationStore.user.userName;
      } else if (agencySettings.TEAM_IN_CHARGE_ID > 0 && this.teams.length > 0) {
        this.teamIsResponsible = true;
        this.portcall.PERSON_IN_CHARGE = null;
        this.portcall.TEAM_IN_CHARGE_ID = agencySettings.TEAM_IN_CHARGE_ID;
      } else {
        this.teamIsResponsible = false;
        this.portcall.PERSON_IN_CHARGE = null;
        this.portcall.TEAM_IN_CHARGE_ID = null;
      }
    },
    getDatePart(dateTime) {
      if (dateTime == null || dateTime == '') return null;

      // Get date part of a date time string (dd-mm-yyyyThh:nn)
      let result = dateTime.split('T')[0];

      if (result == 'null') result = null;

      return result;
    },
    getTimePart(dateTime) {
      if (dateTime == null || dateTime == '') return null;

      // Get time part of a date time string (dd-mm-yyyyThh:nn)
      let result = dateTime.split('T')[1];

      if (result == 'null' || result == null) {
        result = null;
      }

      return result;
    },
    saveClicked() {
      this.cargoNext = false;
      this.savePortCall();
    },
    saveCargoNextClicked() {
      this.cargoNext = true;
      this.savePortCall();
    },
    async savePortCall(ignoreCreditLimitWarning) {
      if (!ignoreCreditLimitWarning && this.useCreditAlert) {
        this.showPortCallCreditLimitDialog = true;
        return;
      }
      this.$refs.form.validate();
      if (this.isValid && this.applicationStore.userRight('P.NEW_AND_EDIT')) {
        this.isValid = false;
        // set isLoading to true to show progressbar if response is not immidiate
        this.isLoading = true;
        // Update default values
        const promises = [];

        promises.push(apiPut('portcall/default-value-generate-portcall-number', { VALUE: this.generateCallNumber }));

        // Convert combined date and time to separate date and time parts
        this.portcall.ETA_TIME = this.getTimePart(this.portcall.ETA);
        this.portcall.ETA_DATE = this.getDatePart(this.portcall.ETA);
        this.portcall.ETD_TIME = this.getTimePart(this.portcall.ETD);
        this.portcall.ETD_DATE = this.getDatePart(this.portcall.ETD);
        this.portcall.extra.PCE_ETPS_DATE = this.getDatePart(this.portcall.extra.ETPS);
        this.portcall.extra.PCE_ETPS_TIME = this.getTimePart(this.portcall.extra.ETPS);

        // SOF Template ID
        if (!this.portcall.sof_template_id || this.callCategory === 3) this.portcall.sof_template_id = -1;

        // Checklist template ID
        if (!this.portcall.checklist_template_id) this.portcall.checklist_template_id = -1;

        if (!this.connected_clients_template_id) this.connected_clients_template_id = -1;

        // Shall we generate port call number?
        if (this.generateCallNumber) this.generateCallNumber = 1;
        else this.generateCallNumber = 0;

        this.portcall.generate_call_number = this.generateCallNumber;

        if (this.teamIsResponsible) {
          this.portcall.PERSON_IN_CHARGE = null;
        } else {
          this.portcall.TEAM_IN_CHARGE_ID = null;
        }

        if (!this.portcall.extra.PCE_CALLTYPE) {
          this.portcall.extra.PCE_CALLTYPE = -1;
        }

        promises.push(
          apiPut('portcall', { value: this.portcall, orgValue: {} }).then((r) => ({ name: 'portcall', result: r }))
        );
        this.orgPortCall = GlobalHelperFunctions.duplicateViaJson(this.portcall);

        await Promise.allSettled(promises).then(async (result) => {
          // remove progressbar
          this.isLoading = false;

          // Find port call promise result
          const portCallResult = result.find((item) => {
            if (item.value.name === 'portcall') {
              return item;
            }
            return undefined;
          });

          if (portCallResult) {
            // Do we have an error?
            if (portCallResult.value.result.resultCategory > 2) {
              return;
            }
            this.portCallResponseResult = portCallResult.value.result;
            const sednaApi = new SednaApiService(this.portcall.SETUP_ID);
            if (sednaApi.isSednaIntegration) {
              const sednaJobId = await sednaApi.createSednaJobRef(this.portCallResponseResult.payload.ID, 'portcall');
              if (sednaJobId) {
                this.portcallStore.updateSednaJobId({ id: this.portcall.ID, sednaJobId });
              }
            }

            // If we get a worning the port call has not been created
            if (this.portCallResponseResult.resultCategory < 2) {
              getBerthedPortcalls(); // Update attendance berthed portcalls select input.
              // Update store with new port call details...
              this.portcallStore.clearCall();
              getPortcallDetails(this.portCallResponseResult.payload.ID);
              this.applicationStore.setWhiteBoardDataIsDirty(true);
            }

            // If we retrieve a message then show a dialoge with the message
            if (
              this.portCallResponseResult.resultCategory ===
              0 /* Info - Faile dto create additional port call data */ ||
              this.portCallResponseResult.resultCategory === 2 /* Warning - Failed to create port call */ ||
              this.portCallResponseResult.message
            ) {
              this.showResponseDialog = true;
            } else {
              this.navigateAfterSuccessfulSave();
            }
          }
        });
      }
    },
    navigateAfterSuccessfulSave() {
      const { cargoNext } = this;
      const portCallId = this.portCallResponseResult.payload.ID;
      if (cargoNext) {
        this.$router.push(`/call/${portCallId}/new-cargo-item`);
      } else {
        this.$router.push(`/call/${portCallId}`);
      }
    },
    setFormDefaultValues() {
      this.portcall = GlobalHelperFunctions.duplicateViaJson(this.getDefaultValuesPortCall());

      // Other data
      this.isValid = false;
      this.isLoading = false;
      this.saving = false;
      this.teamIsResponsible = false;
      this.generateCallNumber = true;
      this.sofTemplateItems = [];
      this.checklistTemplateItems = [];
      this.callCategory = undefined;
      this.showResponseDialog = false;
      this.portCallResponseResult = undefined;
      this.portCallCreditLimitResult = null;
      this.showPortCallCreditLimitDialog = false;
      this.portCallClientWarnings = [];
      this.quayWarningResponse = null;
      this.cargoNext = false;
      this.$refs.form.resetValidation();
      this.orgPortCall = GlobalHelperFunctions.duplicateViaJson(this.portcall);
    },
    getDefaultValuesPortCall() {
      const pc = {};

      // PortCall
      pc.ETA = null;
      pc.ETA_DATE = null;
      pc.ETA_TIME = null;
      pc.ETD = null;
      pc.ETD_DATE = null;
      pc.ETD_TIME = null;
      pc.PORTCALL_NUMBER = null;
      pc.VESSEL_ID = null;
      pc.SETUP_ID = null;
      pc.PERSON_IN_CHARGE = null;
      pc.TEAM_IN_CHARGE_ID = null;
      pc.STATUS = 0;
      pc.BERTH_CALCULATION = this.applicationStore.globalSettings.SGL_BERTH_CALC_DEFAULT;
      pc.quayId = null;
      pc.sof_template_id = null;
      pc.checklist_template_id = null;
      pc.generate_call_number = null;
      pc.connected_clients_template_id = null;
      pc.prevPort = null;
      pc.nextPort = null;
      pc.extra = {};
      // PortCall extra
      pc.extra.ETPS = null;
      pc.extra.PCE_ETPS_DATE = null;
      pc.extra.PCE_ETPS_TIME = null;
      pc.extra.PCE_ETDPP = null;
      pc.extra.PCE_EDANP = null;
      pc.extra.PCE_ETDNP = null;
      pc.extra.PCE_CALLTYPE = null;

      return pc;
    },
  },
};
</script>

<style scoped></style>
