import { render, staticRenderFns } from "./ExpenseFromSet.vue?vue&type=template&id=21e0e56f&scoped=true&"
import script from "./ExpenseFromSet.vue?vue&type=script&lang=js&"
export * from "./ExpenseFromSet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e0e56f",
  null
  
)

export default component.exports