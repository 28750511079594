import { apiDelete } from '@/store/api';
import { useGatComponentsStore } from '@/store/gatComponentsStore';

export function removeGatGridView(data: any) {
  const gatComponentsStore = useGatComponentsStore();
  const idx = gatComponentsStore.gridViews.findIndex((item) => item.name == data.name);
  if (idx >= 0) {
    gatComponentsStore.gridViews.splice(idx, 1);
  }
  const savedInfo: any = {
    TypeName: `web_grid_view.${data.viewGroupName}.${data.name}`,
  };
  if (data.isGlobal) {
    savedInfo.Default = true;
  }
  apiDelete('savedInfo', savedInfo);
}
