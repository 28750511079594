<template>
  <v-tooltip v-if="showTooltip" bottom style="z-index: 1050">
    <template v-slot:activator="{ on, attrs }">
      <div v-on="on" v-bind="attrs">
        <v-icon class="orange--text" :class="'mr-1'">mdi-alert</v-icon> 
      </div>
    </template>
    <span style="white-space: pre-line;">{{ tooltip }}</span>
  </v-tooltip>
  <v-icon v-else class="orange--text" :class="'mr-1'">mdi-alert</v-icon> 
</template>

<script>

export default{
    name: 'GatIcon',
    props: {
      tooltip: {
        type: String,
        default: '',
      },
      showTooltip: {
        type: Boolean,
        default: true,
      },
    },
    components: {},

    data() {
        return  {
             
        };
    },

    computed:{
    },

    created(){
    },

    watch:{
    },

    methods:{
    },
  };
</script>

<style scoped>

</style>
