import { apiGet } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function getCrewChangePeople({
  callId,
  clientId,
}: {
  callId: string | number;
  clientId: string | number;
}) {
  const result = await apiGet(`crewchange/call/${callId}/people/${clientId}`);
  usePortcallStore().setCrewChangePeople(result);
  return result;
}
