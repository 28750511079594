<template>
    <div class="pb-6 mb-6">
        <gat-dialog v-model="dialogVisible" :cancel-button-disabled="isSaving" cancelButtonCaption="CANCEL"
            okButtonCaption="SAVE" width="1000" :auto-close="false" @okClick="addExpenseFromSet()"
            @cancelClick="close(true)" :ok-button-disabled="!isValid || !canSave">
            <span class="text-h6 grey--text text--darken-1">{{ `Expense from set (${expenseSet.ESE_NAME})` }}</span>
            <v-alert v-if="selectedClient && selectedClient.CLIENT_CREDIT_FLAG > 0 && applicationStore.user.internal"
                :color="getClienCreditFlagColor(selectedClient.CLIENT_CREDIT_FLAG, true)" icon="mdi-flag" dense text
                outlined>
                <span class="font-weight-medium">{{ selectedClient.displayName }}</span><br />
                credit balance: {{ selectedClient.CLIENT_CREDIT_BALANCE }}
                {{ applicationStore.getAgencySettings(agencyId).LocalCurrency }}
            </v-alert>
            <gat-form title="Expense from set" :is-loading="isSaving || loading" ref="form" v-model="isValid">
                <gat-select :items="hotlistDebtors" textFieldName="displayName" codeFieldName="value" label="Client"
                    v-model="selectedClientId" category="0" @item-selected="selectedClient = $event" size="xs12"
                    :readonly="!canCreateNewExpense || !applicationStore.user.internal" customItem required>
                    <template v-slot:item="{ data }">
                        <div>{{ data['displayName'] }}</div>
                        <div v-if="+data.SHOW_WARNING === 1 || data.CLIENT_CREDIT_FLAG > 0" class="ml-auto">
                            <v-icon v-if="+data.SHOW_WARNING === 1" class="orange--text"
                                :class="data.CLIENT_CREDIT_FLAG > 0 ? 'mr-1' : ''">mdi-alert</v-icon>
                            <v-icon v-if="data.CLIENT_CREDIT_FLAG > 0"
                                :class="getClienCreditFlagColor(+data.CLIENT_CREDIT_FLAG)">mdi-flag</v-icon>
                        </div>
                    </template>
                </gat-select>
                <gat-grid title="Expense templates" class="elevation-0" :items="expenses" :columns="expensesColumns"
                    max-height="40vh" :hideEmptyColumns="true" selectable keyName="ID" no-footer
                    :selectedKeys="selectedExpenseIds" @selectionChanged="selectedExpenseIds = $event">
                </gat-grid>
            </gat-form>
        </gat-dialog>
    </div>
</template>
  
<script>
// eslint-disable-next-line import/no-cycle
import { apiGet, apiPost } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';
import { useApplicationStore } from '@/store/applicationStore';

export default {
    name: 'ExpenseFomSet',
    props: {
        showForm: Boolean,
        expenseSet: {
            type: Object,
            required: true
        },
    },
    components: {},
    setup() {
        const portcallStore = usePortcallStore();
        const applicationStore = useApplicationStore();
        return {
            portcallStore,
            applicationStore,
        };
    },
    data() {
        return {
            expenses: [],
            loading: false,
            isValid: false,
            isSaving: false,
            selectedClient: null,
            selectedClientId: null,
            selectedExpenseIds: [],
            dialogVisible: this.showForm,
        };
    },

    created() { },

    mounted() {
        this.loading = false;
        this.isSaving = false;
        if (this.expenseSet?.ESE_ID) {
            try {
                this.loading = true;
                apiGet(`expense/set/${this.expenseSet.ESE_ID}/templates`).then((response) => {
                    this.expenses = response
                    this.selectedExpenseIds = this.expenses.map(e => e.ID)
                    this.loading = false;
                })
            } catch {
                this.loading = false
            }
        }
    },

    watch: {
        showForm(newValue) {
            this.dialogVisible = newValue;
        },
    },

    computed: {
        agencyId() {
            return this.portcallStore.callDetails.SETUP_ID
        },
        portcallId() {
            return this.portcallStore.callDetails.ID
        },
        canCreateNewExpense() {
            return !this.isSaving && this.applicationStore.userRight('EX.NEW') && this.applicationStore.user.internal;
        },
        canSave() {
            return this.expenseSet?.ESE_ID && this.agencyId && this.portcallId && this.canCreateNewExpense && this.selectedExpenseIds.length > 0
        },
        hotlistDebtors() {
            return this.hotlistClients(0);
        },
        expensesColumns() {
            const result = [];
            // result.push({ header: 'Id', field: 'ID', hideOnPhone: true });
            result.push({ header: 'Group', field: 'NAME', hideOnPhone: false });
            result.push({ header: 'Template', field: 'ELT_NAME', hideOnPhone: false });
            result.push({ header: 'Account code', field: 'ACCOUNT_CODE', hideOnPhone: false });
            result.push({ header: 'Description', field: 'EXPENSE_TEXT', hideOnPhone: false });
            result.push({
                header: 'Sum', field: 'EXP_TOTAL_SUM', hideOnPhone: false, calculated: (rowData) => `${rowData.CURRENCY_NAME} ${rowData.EXP_TOTAL_SUM}`,
            });
            return result;
        },
        clientColumns() {
            const result = [];
            result.push({ header: 'Template', field: 'TemplateName' });
            result.push({ header: 'Group', field: 'GroupName' });
            result.push({ header: 'Cost code', field: 'CostCode' });
            return result;
        },
    },

    methods: {
        hotlistClients(category) {
            const clients = [];
            // eslint-disable-next-line array-callback-return
            this.portcallStore.hotlist.map((client) => {
                if (client.CATEGORY == category) {
                    const menuItem = {
                        value: client.CLIENT_ID,
                        displayName: client.C_NAME,
                    };
                    if (client.C_ACCOUNT_NO) {
                        menuItem.displayName = `${menuItem.displayName} (${client.C_ACCOUNT_NO})`;
                    }
                    if (client.CLIENT_CREDIT_FLAG > 0) {
                        menuItem.CLIENT_CREDIT_FLAG = client.CLIENT_CREDIT_FLAG;
                        menuItem.CLIENT_CREDIT_BALANCE = client.CLIENT_CREDIT_BALANCE;
                    }
                    if (client.SHOW_WARNING) {
                        menuItem.SHOW_WARNING = client.SHOW_WARNING;
                        menuItem.WARNING = client.WARNING;
                    }
                    clients.push(menuItem);
                }
            });
            return clients;
        },
        getClienCreditFlagColor(value, warningElement = false) {
            let result = '';
            switch (+value) {
                case 0:
                    result = 'd-none';
                    break;
                case 1:
                    result = warningElement ? 'red darken-1' : 'red--text text--darken-1';
                    break;
                case 2:
                    result = warningElement ? 'orange darken-1' : 'orange--text text--darken-1';
                    break;
                case 3:
                    result = warningElement ? 'green darken-1' : 'green--text text--darken-1';
                    break;
                default:
                    result = 'd-none';
                    break;
            }
            return result;
        },
        async addExpenseFromSet() {
            try {
                this.$refs.form.validate();
                if (this.isValid && this.canSave) {
                    this.isSaving = true;
                    const newExpenses = this.expenses.filter(exp => this.selectedExpenseIds.includes(exp.ID))
                        .map(e => ({
                            EXPENSE_ID: e.ID,
                            AGENCY_ID: this.agencyId,
                            PORTCALL_ID: this.portcallId,
                            EXCHANGE_RATE: e.EXCHANGE_RATE,
                            CLIENT_ID: this.selectedClientId,
                            EXPENSESET_ID: this.expenseSet.ESE_ID,
                        }))
                    await apiPost('expense', newExpenses);
                    this.isSaving = false;
                    this.close();
                }
            } catch {
                this.isSaving = false;
                this.close(true);
            } finally {
                this.isSaving = false;
                this.close();
            }
        },
        close(cancel) {
            this.isSaving = false;
            this.$emit('close', cancel);
        },
    },
};
</script>
  
<style lang="scss" scoped></style>
