// Pinia Store
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGatComponentsStore = defineStore('gatComponentsStore', () => {
  const gridViews = ref<any[]>([]);
  const defaultGridViews = ref<{ [key: string]: any }>({});
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const input_dense = ref(true);
  const calendarSettings = ref<{ weekNumberInCalendar: string; weekStartDay: string }>({
    weekNumberInCalendar: 'iso',
    weekStartDay: 'monday',
  });

  function setDefaultGridViews(data: any) {
    defaultGridViews.value = data;
  }

  function setGatGridViews(data: any) {
    gridViews.value = data;
  }

  function setWeekNumberInCalendar(weekNoSettings: string) {
    calendarSettings.value.weekNumberInCalendar = weekNoSettings;
  }
  function setWeekStartDay(weekStartDay: string) {
    calendarSettings.value.weekStartDay = weekStartDay;
  }

  return {
    gridViews,
    defaultGridViews,
    input_dense,
    calendarSettings,
    setDefaultGridViews,
    setGatGridViews,
    setWeekNumberInCalendar,
    setWeekStartDay,
  };
});
