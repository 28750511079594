<template>
  <v-layout wrap class="mr-1 ml-1">
    <gat-button-menu
      v-if="showMenuButton"
      icon="mdi-dots-vertical"
      :mainAction="true"
      :menuItems="menuButtons"
      @menuItemClick="buttonAsmenuItemClicked"
      @subMenuItemClick="buttonAsmenuItemSubClicked" />
    <template v-if="!showMenu">
      <gat-button
        v-for="(button, index) in visibleButtons"
        :key="index"
        :btnType="button.btnType"
        :caption="button.caption"
        :disabled="button.disabled"
        :hideCaptionOnPhone="button.hideCaptionOnPhone"
        :icon="button.icon"
        :mainAction="button.mainAction"
        :menuItems="button.menuItems"
        :openMenuOnHover="button.openMenuOnHover"
        :phoneRendering="isPhone"
        :userRight="button.userRight"
        @click="buttonClicked(button)"
        @menuItemClick="menuItemClicked($event, button)" />
    </template>
  </v-layout>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';
import GatButton from './GatButton.vue';
import GatButtonMenu from './GatButtonMenu.vue';

export default {
  name: 'GatButtonGroup',
  props: {
    showMenu: Boolean,
    buttons: {
      type: Array,
    },
    rightAligned: {
      type: Boolean,
      default: true,
    },
  },
  components: { GatButton, GatButtonMenu },
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {
    transformedButtons() {
      const result = [];
      // eslint-disable-next-line array-callback-return
      this.buttons.map((orgBtn) => {
        const btn = GlobalHelperFunctions.duplicateViaJson(orgBtn);
        btn.caption = this.getCaption(orgBtn);
        btn.icon = this.getIcon(orgBtn);
        result.push(btn);
      });
      return result;
    },

    isPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },

    menuButtons() {
      let result = [];
      if (this.isPhone) {
        result = this.transformedButtons.filter((btn) => !btn.showOnPhone);
      }
      // embedded sub menus
      result = this.embeddMenuItemIfNeeded(result);

      return result;
    },

    showMenuButton() {
      return (this.isPhone && this.visibleButtons.length < this.buttons.length) || this.showMenu;
    },

    visibleButtons() {
      let result = this.transformedButtons;
      // mobile?
      if (this.isPhone && this.transformedButtons.length > 1) {
        if (this.showMenu) {
          return [];
        }
        result = this.transformedButtons.filter((btn) => btn.showOnPhone);
      }

      // reverse order?
      if (this.rightAligned) {
        result = [...result].reverse();
      }
      return result;
    },
  },

  methods: {
    buttonClicked(btn) {
      this.$emit('click', { caption: btn.caption, button: btn });
      if (this.eventName(btn)) {
        this.$emit(this.eventName(btn), btn);
      }
    },

    menuItemClicked(menuItem, button) {
      this.$emit('click', { caption: menuItem.caption, menuItem, button });
      if (menuItem.eventName) {
        this.$emit(menuItem.eventName, menuItem);
      }
    },

    buttonAsmenuItemClicked(param) {
      const isButton = Object.prototype.hasOwnProperty.call(param, 'btnType');
      const isEmbeddedMenuItem = Object.prototype.hasOwnProperty.call(param, 'parentButton');
      if (isButton) {
        this.buttonClicked(param);
      } else if (isEmbeddedMenuItem) {
        const menuItem = GlobalHelperFunctions.duplicateViaJson(param);
        delete menuItem.parentButton;
        this.menuItemClicked(menuItem, param.parentButton);
      } else {
        this.menuItemClicked(param.item, param.root);
      }
    },

    buttonAsmenuItemSubClicked(param) {
      this.menuItemClicked(param.item, param.root);
    },

    embeddMenuItemIfNeeded(buttonList) {
      const emebbedList = [];
      for (let index = 0; index < buttonList.length; index++) {
        const button = buttonList[index];
        if (button.menuItems && button.menuItems.length > 0 && button.embeddMenuItems) {
          const preMenuItems = { subHeader: this.getSubheader(button), subHeaderDivider: index > 0 };
          if (preMenuItems.subHeader || preMenuItems.subHeaderDivider) {
            emebbedList.push(preMenuItems);
          }
          button.menuItems.forEach((menuItem) => {
            const newItem = GlobalHelperFunctions.duplicateViaJson(menuItem);
            newItem.parentButton = button;
            emebbedList.push(newItem);
          });
          if (index < buttonList.length - 1 && preMenuItems.subHeader) {
            emebbedList.push({ subHeaderDivider: true });
          }
        } else {
          emebbedList.push(button);
        }
      }
      if (emebbedList.length > buttonList.length) {
        return emebbedList;
      }
      return buttonList;
    },

    eventName(btn) {
      if (btn.eventName) {
        return btn.eventName;
      }
      return `${btn.btnType.toLowerCase()}Clicked`;
    },

    getCaption(btn) {
      if (btn.btnType == 'ADD') {
        return 'Add';
      }
      if (btn.btnType == 'DELETE') {
        return 'Delete';
      }
      return btn.caption;
    },

    getIcon(btn) {
      if (btn.btnType == 'ADD') {
        return 'mdi-plus';
      }
      if (btn.btnType == 'DELETE') {
        return 'mdi-delete';
      }
      return btn.icon;
    },

    getSubheader(button) {
      if (button.useSubTitle) {
        if (button.subHeader) {
          return button.subHeader;
        }
        return button.caption;
      }
      return undefined;
    },
  },
};
</script>

<style scoped></style>
