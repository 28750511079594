<template>
  <v-tooltip bottom v-if="flagValue > 0 && applicationStore.user.internal">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on" :color="getClienCreditFlagColor(flagValue)">mdi-flag</v-icon>
    </template>
    {{ creditBalance }} {{ applicationStore.getAgencySettings(applicationStore.agencyIdSelected).LocalCurrency }}
  </v-tooltip>
</template>

<script>
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'ClientCreditFlag',
  props: {
    creditBalance: Number,
    flagValue: Number,
  },
  components: {},
  setup() {
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
    };
  },
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {},

  methods: {
    getClienCreditFlagColor(flagValue) {
      let result = '';
      switch (+flagValue) {
        case 0:
          result = 'grey';
          break;
        case 1:
          result = 'red darken-1';
          break;
        case 2:
          result = 'orange darken-1';
          break;
        case 3:
          result = 'green darken-1';
          break;
        default:
          result = 'grey';
          break;
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
