import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getDarkModeSetting() {
  const applicationStore = useApplicationStore();
  const data = await apiGet('savedinfo/web-dark-layout');
  if (data.length > 0) {
    if (data[0].INTVALUE1 == 1) {
      applicationStore.setDarkTheme(true);
    }
  }
}
