<template>
  <div>
    <gat-dialog v-model="value" fullscreen cancelButtonDisabled>
      <br />
      <p style="text-align: center">
        Your current database version is less than the required minimum. <br />
        Please update the database to continue using the web module.
        <br />
        <br />To update: Run GAT-Ship Classic once towards the same database and wait for the automatic update to
        finish.
      </p>
    </gat-dialog>
  </div>
</template>

<script>
export default {
  name: 'DatabaseWarning',
  props: {
    value: Boolean,
  },
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
