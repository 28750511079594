/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-cycle
import router from '@/router/router';
import moment from 'moment';
import Vue from 'vue';
import { saveAs } from 'file-saver';
import { useApplicationStore } from '@/store/applicationStore';
import { isDebugMode } from '@/globalFlags';
import { removeApiTokenFromCookie, getDeviceIdFromCookie, getApiTokenFromCookie } from './cookieStorage';

const axios = require('axios');

const responseCategories = {
  Info: 0,
  Success: 1,
  Warning: 2,
  Error: 3,
};

const showApiDebug = window.location.hostname === 'localhost' ? isDebugMode : false; // && useApplicationStore().debugMode;
const dataCash = {};

async function getGsConfig() {
  return new Promise((resolve) => {
    // Fetch config file here.
    fetch(`${process.env.BASE_URL}config.json`)
      .then((response) => response.json())
      .then((config) => {
        resolve(config);
      })
      .catch(() => {
        resolve('N/A');
      });
  });
}

async function ApiUrl(path) {
  // remove starting slash
  if (path.startsWith('/')) {
    path = path.substring(1);
  }
  if (!Vue.prototype.$gsconfig) {
    // console.log('WAITING FOR CONFIG');
    Vue.prototype.$gsconfig = await getGsConfig();
    // console.log('WAITING FOR CONFIG DONE', Vue.prototype.$gsconfig);
  }

  // API URL from config file (Separate URL for backend)
  if (Vue.prototype.$gsconfig && Vue.prototype.$gsconfig.API_URL) {
    return `${Vue.prototype.$gsconfig.API_URL}/api/${path}`;
  }

  // utvikler mot localhost?
  if (window.location.href.includes('localhost')) {
    return `https://localhost:44345/api/${path}`;
  }

  // GAT-Ship crm kjører api på et annen site
  if (window.location.hostname.includes('crm.gatship.com')) {
    return `https://app-gs-crm-api.azurewebsites.net/api/${path}`;
    // return "https://gatship-crm-api.azurewebsites.net/api/" + path;  MS 27.11.2022 flyttet til ny app service plan
  }

  // default kjører api på port 8080 på samme domene
  return `https://${window.location.hostname}:8080/api/${path}`;
}

function defaultConfig() {
  return {
    headers: {
      gstoken: getApiTokenFromCookie(),
      gsdevice: getDeviceIdFromCookie(),
      gsversion: useApplicationStore().version,
    },
  };
}

function handleCustomUnhandledException(error) {
  const info = error.response.data;
  const title = 'An error occured on the server';
  const message = `If this continues, please contact GAT-Ship support.\r ${info.exceptionType} in ${info.path}: ${info.message}`;
  let traceId = '';
  if (info.traceId) {
    traceId = info.traceId;
  }
  window.messageBus.$emit('showMessage', {
    type: 'error',
    text: title,
    subText: message,
    icon: true,
    traceId,
  });
}

function isNetWorkError(error) {
  return error && error.message === 'Network Error';
}

export async function apiLogOut() {
  // Log out from backend
  try {
    const url = await ApiUrl('auth/logout');
    await axios.post(url, '', defaultConfig());
  } catch (error) {
    console.log('Api log out', error);
  }

  // Remove cookie
  removeApiTokenFromCookie();
}

function handleError(error) {
  if (error.response && error.response.status == 401) {
    apiLogOut().then(() => {
      // Route to login form
      router.push('/auth');
      router.go();
    });
  } else if (error.response && error.response.status == 403) {
    apiLogOut().then(() => {
      router.push('/nolicense');
      router.go();
    });
  } else if (error.response && error.response.status == 500) {
    if (error.response.data && error.response.data.isCustomUnhandledException) {
      console.log('Error handling: ', error.response, error);
      handleCustomUnhandledException(error);
    } else {
      console.log('Error handling: ', error.response, error);
      // todo: dette feiler dersom response.data ikke er satt eller er et objekt...
      let title = error.response.data.split(':'); // Splits the string at every colon
      let text = title[1].split('.'); // the 2nd string is used for text and split againg at every dot in order to get the first sentence
      text = text[0].toString(); // first sentence of the new split is used for text.
      title = title[0].toString(); // set the title to the first part of the initial split in order to join it and get the full error description.
      text = `${title}: ${text}.`; // join both strings to form a full error description.
      title = 'An error occured on the server'; // set title to a generic message.
      window.messageBus.$emit('showMessage', {
        type: 'error',
        text: title,
        subText: text,
      });
    }
  } else if (isNetWorkError(error)) {
    let status = '';
    if (error.response && error.response.status) {
      status = `Status:${error.response.status}`;
    }
    window.messageBus.$emit('showMessage', {
      type: 'error',
      text: 'An network error occured',
      subText: `Please check the connection to the backend and your network connection\rSee console for details\r${status}`,
    });
  } else {
    console.log('Error handling', error.response, error);
    useApplicationStore().setLastError(error);

    let status = '';
    if (error.response && error.response.status) {
      status = `Status:${error.response.status}`;
    }
    window.messageBus.$emit('showMessage', {
      type: 'error',
      text: 'An unhandled error occured',
      subText: `If this continues, please contact GAT-Ship support.\rSee console for details\r${status}`,
    });
  }
}

function handleStandardResponseMessages(response) {
  if (showApiDebug) {
    console.log(response);
  }
  if (response.resultCategory >= 0 && response.resultType > 0) {
    switch (response.resultCategory) {
      case responseCategories.Success:
        //  self.$root.$emit('showMessage', {type:"success", predefined:response.resultType});
        window.messageBus.$emit('showMessage', {
          type: 'success',
          predefined: response.resultType,
        });
        break;
      case responseCategories.Info:
        window.messageBus.$emit('showMessage', {
          type: 'info',
          predefined: response.resultType,
        });
        break;
      case responseCategories.Warning:
        window.messageBus.$emit('showMessage', {
          type: 'warning',
          predefined: response.resultType,
        });
        break;
      case responseCategories.Error:
        window.messageBus.$root.$emit('showMessage', {
          text: response.message,
          type: 'error',
          predefined: response.resultType,
        });
        break;
      default:
        break;
    }
  }
}

export async function getApiToken(userName, pwd, db, extraInfo) {
  try {
    const url = await ApiUrl('auth');
    const response = await axios.post(
      url,
      {
        credentials: {
          userName,
          password: pwd,
          db,
          extraInfo,
        },
      },
      defaultConfig()
    );
    console.log('API getApiToken', response);
    if (response.data.valid) {
      return response.data;
    }

    // We do not have a valid login
    // Throw the response.data as an object
    let err = new Error('');
    err = response.data;
    err.IsCustom = true;
    throw err;
  } catch (error) {
    if (error.IsCustom) {
      throw error;
    }

    if (!(error.response && error.response.status == 429)) {
      // Status code 429 is Throttling. Handled directly in Auth.vue
      handleError(error);
    }

    throw error;
  }
}

function convertDates(obj) {
  for (const prop in obj) {
    if (typeof obj[prop] == 'string') {
      // iso date?
      const patt = /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;

      const isDate = patt.exec(obj[prop]);
      if (isDate) {
        obj[prop] = moment(obj[prop]).toDate();
      }
    } else if (typeof obj[prop] == 'object') {
      obj[prop] = convertDates(obj[prop]);
    }
  }

  return obj;
}

export async function apiGetFile(path, fileName, save = true) {
  if (showApiDebug) {
    console.log(`get:${path}`);
  }
  const config = {
    responseType: 'blob',
    headers: {
      gstoken: useApplicationStore().user.apiToken,
      gsdevice: getDeviceIdFromCookie(),
    },
  };
  try {
    const url = await ApiUrl(path);
    const response = await axios.get(url, config || defaultConfig());
    if (save) {
      saveAs(response.data, fileName);
    }
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function apiGetFileAsInput(path /* ,fileName */) {
  if (showApiDebug) {
    console.log(`get:${path}`);
  }
  const config = {
    responseType: 'blob',
    headers: {
      gstoken: useApplicationStore().user.apiToken,
      gsdevice: getDeviceIdFromCookie(),
    },
  };
  try {
    const url = await ApiUrl(path);
    const response = await axios.get(url, config || defaultConfig());
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
  /* return new Promise((resolve, reject) => {
    axios
      .get(ApiUrl(path), config || defaultConfig())
      .then((response) => {
        resolve(response.data, fileName);
      })
      .catch((error) => {
        handleError(error);
        reject(error);
      });
  }); */
}

export async function apiGet(path, config) {
  if (showApiDebug) {
    console.log(`get:${path}`);
  }

  try {
    const url = await ApiUrl(path);
    const response = await axios.get(url, config || defaultConfig());
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export function apiCashedGet(path, config) {
  return new Promise((resolve, reject) => {
    if (dataCash.hasOwnProperty(path)) {
      resolve(dataCash[path]);
    } else {
      apiGet(path, config)
        .then((data) => {
          dataCash[path] = data;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
}

export function apiGetExternal(url, config) {
  if (showApiDebug) {
    console.log(`get:${url}`);
  }
  return new Promise((resolve, reject) => {
    axios
      .get(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function apiPostExternal(url, data, config) {
  if (showApiDebug) {
    console.log(`post:${url}`);
  }
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export async function apiPost(path, data, config) {
  if (showApiDebug) {
    if (path == 'savedInfo') {
      if (showApiDebug) {
        console.log(`post:${path} - ${data.TypeName}`);
      }
    } else if (showApiDebug) {
      console.log(`post:${path}`);
    }
  }
  if (!config) {
    config = defaultConfig();
  }
  try {
    const url = await ApiUrl(path);
    const response = await axios.post(url, data, config);
    handleStandardResponseMessages(response.data);
    return convertDates(response.data);
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function apiPut(path, data) {
  if (showApiDebug) {
    console.log(`put:${path}`);
  }
  try {
    const url = await ApiUrl(path);
    const response = await axios.put(url, data, defaultConfig());
    handleStandardResponseMessages(response.data);
    return convertDates(response.data);
  } catch (error) {
    handleError(error);
    throw error;
  }
}

export async function apiDelete(path, data) {
  if (showApiDebug) {
    console.log(`delete:${path}`);
  }
  const config = defaultConfig();
  if (data) {
    config.data = data;
  }
  try {
    const url = await ApiUrl(path);
    const response = await axios.delete(url, config);
    handleStandardResponseMessages(response.data);
    return convertDates(response.data);
  } catch (error) {
    handleError(error);
    throw error;
  }
}
