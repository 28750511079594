/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */

import { v4 as uuidv4 } from 'uuid';

// Example of use:
// setCookie('user', 'John', {secure: true, 'max-age': 3600});
export function setCookie(name, value, options = {}) {
  options = {
    path: '/',
    // add other defaults here if necessary
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
}

// returns the cookie with the given name,
// or undefined if not found
export function getCookie(name) {
  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

export function deleteCookie(name) {
  setCookie(name, '', {
    'max-age': -1,
  });
}

export function getApiTokenFromCookie() {
  const cookie = getCookie('apiToken');
  return cookie;
}

export function saveApiTokenToCookie(token) {
  const maxAge = 15 * 24 * 3600; // cookie max-age in sec
  setCookie('apiToken', token, { 'max-age': maxAge, samesite: 'lax' });
}

export function removeApiTokenFromCookie() {
  // console.log("removeApiToken");
  deleteCookie('apiToken');
}

// Somewhat "unique" device ID
export function getDeviceIdFromCookie() {
  let guid = getCookie('gs-Device');
  if (!guid) {
    guid = `${uuidv4()}`;
  }
  const maxAge = 31536000; // 1 Year
  setCookie('gs-Device', guid, { 'max-age': maxAge, samesite: 'lax' });

  guid = getCookie('gs-Device');
  return guid;
}
