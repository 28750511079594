<template>
  <div>
    <!-- Not mounted on client/:clientId/Contacts -->
    <!-- <search-panel v-if="this.$route.name != 'clientContacts'" :searchText="dataSearch" :autofocus=true v-on:search="searchForContacts" :showSearch="!clientId" :showMaxRows="!clientId" :maxRows="dataMaxRows" :submitBtn="true" /> -->
    <gat-grid
      title="Contact persons"
      class="gridmax elevation-0"
      :items="contacts"
      :columns="columns"
      :fixedheader="true"
      :height="windowHeight"
      :loading="isLoading"
      :reloadBtn="clientId != null"
      @reload-clicked="getContactByClientId(clientId)"
      @row-clicked="contactSelected">
      <template slot="btns">
        <v-btn
          v-if="clientId"
          :disabled="!applicationStore.userRight('C.NEW_CONTACT')"
          small
          color="primary"
          class="right mr-2"
          :to="'/contact/new/' + clientId">
          <v-icon small>add</v-icon>ADD
        </v-btn>
      </template>
      <template slot="cell" slot-scope="props">
        <template v-if="props.column.field == 'GDPR' || props.column.header == 'Name'">
          <div class="d-flex">
            <v-icon v-if="props.column.field == 'GDPR'" color="success">{{ gdrpIcon(props.value) }}</v-icon>
            <a
              v-else-if="props.column.header == 'Name'"
              :href="'/contact/' + props.row['ID']"
              class="gridLink gridMainLink"
              >{{ (props.row.LAST_NAME ? props.row.LAST_NAME.toUpperCase() + ', ' : '') + props.row.FIRST_NAME }}</a
            >
            <v-tooltip
              bottom
              v-if="props.row.COMMENTS && props.column.header == 'Name'"
              class="d-flex ml-2"
              z-index="5000">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" small>mdi-comment</v-icon>
              </template>
              {{ props.row.COMMENTS }}
            </v-tooltip>
          </div>
        </template>
        <div v-else-if="props.column.field == 'EMAIL'">
          <a class="gridLink" :href="`mailto:${props.value}`" @click.stop>{{ props.value }}</a>
        </div>
      </template>
    </gat-grid>
  </div>
</template>

<script>
// import SearchPanel from '@/app-components/SearchPanel.vue';
// eslint-disable-next-line import/no-cycle
import { apiPost } from '@/store/api';
import GatUtils from '@/app-components/GatUtils';
import { useToolbarStore } from '@/store/toolbarStore';
import { toggleFavorite } from '@/services/api/api-calls/toggleFavorite';
import { useApplicationStore } from '@/store/applicationStore';

export default {
  name: 'Contacts',
  components: {},
  props: {
    clientId: {
      required: false,
    },
    search: {
      required: false,
    },
    maxRows: {
      required: false,
    },
    contactPerson: {
      required: false,
    },
    parentId: {
      required: false,
    },
  },
  setup() {
    const toolbarStore = useToolbarStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      toolbarStore,
    };
  },
  data() {
    return {
      allContacts: [],
      showSideSheet: false,
      showFab: false,
      currentContact: {},
      isLoading: false,
      dataMaxRows: 100,
      dataSearch: '',
      clientHasResults: true,
    };
  },

  watch: {
    clientId(newValue) {
      if (!this.parentId) {
        this.getContactByClientId(newValue);
      }
    },
    // search(newValue){
    //   if(newValue){
    //     this.dataSearch = newValue;
    //     /* this.searchForContacts({"maxRows":this.dataMaxRows,"search":newValue}) */
    //   }
    // },
    contactPerson: {
      handler() {
        if (this.clientId) {
          this.getContactByClientId(this.clientId);
        }
        /* console.log(newValue);
        if (newValue) {
          const idx = this.allContacts.findIndex((item) => item.ID == newValue.ID);
          if (idx >= 0) {
            if (newValue.DELETE) {
              this.allContacts.splice(idx, 1);
            } else {
              this.allContacts.splice(idx, 1, newValue);
            }
          } else {
            this.allContacts.push(newValue);
          }
          this.updateClientMainContact();
        } */
      },
      deep: true,
    },
    parentId(newValue) {
      this.getContactByClientId(newValue);
    },
    maxRows(newValue) {
      if (newValue) {
        this.dataMaxRows = newValue;
      }
    },
  },

  activated() {
    if (!this.clientId) {
      this.toolbarStore.setToolbarTitle(undefined);
      this.toolbarStore.setToolbarTabs(undefined);
    }
  },

  created() {
    if (this.clientId) {
      this.getContactByClientId(this.clientId);
    } else if (this.search) {
      this.searchForContacts({ maxRows: this.dataMaxRows, search: this.search });
    } else {
      /* apiPost('contacts/',{"favoritesOnly":true}).then(data=>{
           this.allContacts = data;
         }); */
    }

    window.messageBus.$on('clientSearchValue', (searchValues) => {
      apiPost('contacts/', {
        maxRows: searchValues.max,
        searchStr: searchValues.search,
        category: searchValues.category != undefined ? searchValues.category : -1,
      }).then((data) => {
        window.messageBus.$emit('contactSearchHasResults', data.length > 0);
        this.allContacts = data;
      });
    });

    window.messageBus.$on('clientSearchHasResults', (clientSearchHasResults) => {
      this.clientHasResults = !!clientSearchHasResults;
    });
  },

  computed: {
    columns() {
      const result = [];
      result.push({
        header: 'Name',
        noClickEvent: true,
        calculated: (item) => `${(item.LAST_NAME || '').toUpperCase()}, ${item.FIRST_NAME}`,
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });

      result.push({
        header: 'Title',
        field: 'TITLE',
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      result.push({
        header: 'Department',
        field: 'DEPARTMENT',
        hideOnPhone: true,
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      if (!this.clientId) {
        result.push({
          header: 'Client',
          linkUrl: '/client/field[CLIENT_ID]',
          linkText: 'field[C_NAME]',
          calculatedClass: (rowItem) => this.getRowClass(rowItem, 'C_NAME'),
        });
      }
      result.push({
        header: 'Email',
        field: 'EMAIL',
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      result.push({
        header: 'Mobile',
        linkUrl: 'tel:field[PHONE_MOBILE]',
        linkText: 'field[PHONE_MOBILE]',
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      result.push({
        header: 'Phone',
        linkUrl: 'tel:field[PHONE_DIRECT]',
        linkText: 'field[PHONE_DIRECT]',
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      if (this.applicationStore.user.internal) {
        result.push({
          header: 'gdpr',
          field: 'GDPR',
          calculatedClass: (rowItem) => this.getRowClass(rowItem),
        });
      }
      if (!this.search) {
        // if any contacts have a PRIORITY value, display priority column
        for (let i = 0; i < this.allContacts.length; i++) {
          if (this.allContacts[i].PRIORITY != null) {
            result.push({
              header: 'Priority',
              field: 'PRIORITY',
              calculatedClass: (rowItem) => this.getRowClass(rowItem),
            });
            break;
          }
        }
      }
      result.push({
        header: 'Web user',
        field: 'WEB_USER',
        checkbox: true,
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      result.push({
        header: 'Web admin',
        field: 'WM_USER_ADM',
        checkbox: true,
        hideOnPhone: true,
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      result.push({
        header: 'Web group',
        field: 'WebGruppe',
        hideOnPhone: true,
        calculatedClass: (rowItem) => this.getRowClass(rowItem),
      });
      return result;
    },

    contacts() {
      return this.allContacts;
    },

    favorites() {
      return this.applicationStore.user.favorites
        ? this.applicationStore.user.favorites.filter((item) => item.FAV_TYPE == 'contact')
        : null;
    },
    windowHeight() {
      if (!this.clientHasResults) {
        return 'calc(100vh - 215px)';
      }
      if (this.$route.params.clientId) {
        return 'calc(100vh - 135px)';
      }
      return 'calc(50vh - 65px)';
    },
  },

  updated() {
    this.showFab = this.clientId;
  },

  methods: {
    getContactByClientId(clientId) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiPost('contacts/', {
        maxRows: 200,
        searchStr: '',
        id: -1,
        category: -1,
        clientId,
        includeInactive: true,
      })
        .then((data) => {
          // remove progressbar
          this.allContacts = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    gdrpIcon(value) {
      if (value == 1) {
        return 'check';
      }
      return '';
    },

    favClicked(item) {
      toggleFavorite({ FAV_TYPE: 'contact', FAV_KEY: item.ID });
    },

    getRowClass(contact, fieldName) {
      if (fieldName === 'C_NAME') {
        if (contact.C_INACTIVE === 1) {
          // check if client is inactive.
          return 'inactiveItem';
        }
        return '';
      }
      let result = '';
      if (contact.INACTIVE == 1) {
        result = 'inactiveItem';
      }
      return result;
    },

    searchForContacts(params) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      this.$router.push(GatUtils.makeUrlParams('contacts', params));
      apiPost('contacts/', { maxRows: params.maxRows, searchStr: params.search }).then((data) => {
        this.isLoading = false;
        this.allContacts = data;
      });
    },

    contactSelected(contact) {
      this.$router.push(`/contact/${contact.ID}`);
      this.currentContact = contact;
    },

    FindAndSortContacts(key, contacts) {
      const sortedContacts = contacts.sort((a, b) => {
        // Sort the array based on number/string.
        if (key === 'PRIORITY') {
          // PRIORITY is number (filter by negative(-))
          return a[key] - b[key];
        }
        // LAST/FIRST_NAME is string.
        return a[key].localeCompare(b[key]);
      });
      // Remove contacts without the given key value.
      const filteredContacts = sortedContacts.filter((item) => item[key] != null);
      if (filteredContacts.length > 0) {
        if (filteredContacts.length === 1) {
          // If only one contact has priority, return it.
          return filteredContacts[0];
        }
        // Else there is more than one priority contact, reduce the array to compare the first two. (which is already sorted)
        const contact1 = filteredContacts[0];
        const contact2 = filteredContacts[1];
        let value1 = contact1[key];
        let value2 = contact2[key];
        if (key != 'PRIORITY') {
          // make string values uppercase.
          value1 = value1.toUpperCase();
          value2 = value2.toUpperCase();
        }
        if (value1 === value2) {
          // If the priority is the same, check last name.
          if (key != 'LAST_NAME') {
            return this.FindAndSortContacts('LAST_NAME', filteredContacts);
          }
          if (key != 'FIRST_NAME') {
            return this.FindAndSortContacts('FIRST_NAME', filteredContacts);
          }
          return contact1;
        }
        // else return the first.
        return contact1;
      }
      if (key === 'PRIORITY') {
        // no contacts with priority, try last name.
        return this.FindAndSortContacts('LAST_NAME', contacts);
      }
      if (key === 'LAST_NAME') {
        // no contacts with last name, try first name.
        return this.FindAndSortContacts('FIRST_NAME', contacts);
      }
      // no contacts :(
      return null;
    },

    updateClientMainContact() {
      let contacts = [...this.allContacts];
      contacts = contacts.filter((item) => item.INACTIVE != 1); // filter out inactive contacts.
      if (contacts.length > 0) {
        const mainContact = this.FindAndSortContacts('PRIORITY', contacts);
        if (mainContact != null) {
          window.messageBus.$emit('updateClientMainContact', mainContact);
        }
      }
    },
  },
};
</script>

<style scoped></style>
