<template>
  <v-card
    :elevation="getElevation"
    color="grey lighten-3"
    class="ma-1 pa-1 mr-2"
    @mouseover="hover = true"
    @mouseleave="hover = false">
    <div class="d-flex align-center">
      <div class="px-2">
        {{ value.header }}
      </div>
      <div class="ml-auto">
        <v-btn class="mx-1" text x-small v-show="hover && available" @click="$emit('colAdded')">add</v-btn>
        <v-btn class="mx-1" text x-small v-show="hover && !available" @click="$emit('colRemoved')">remove</v-btn>
        <v-icon class="colGroupIcon" v-show="!value.groupedBy && hover && !available" @click="toggleGrouping"
          >mdi-ungroup</v-icon
        >
        <v-icon class="colGroupIcon" v-show="value.groupedBy && !available" @click="toggleGrouping">mdi-group</v-icon>
        <v-icon class="colPhoneIcon" v-show="value.hideOnPhone && hover && !available" @click="togglePhone"
          >mdi-cellphone</v-icon
        >
        <v-icon class="colPhoneIcon" v-show="!value.hideOnPhone && !available" @click="togglePhone">{{
          hover ? 'mdi-cellphone-off' : 'mdi-cellphone'
        }}</v-icon>
        <v-icon small class="colIconClass">{{ colIcon(value) }}</v-icon>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'GatGridViewColumnObj',
  props: ['value', 'available'],
  components: {},
  data() {
    return {
      hover: false,
    };
  },

  created() {},

  watch: {},

  computed: {
    getElevation() {
      if (this.hover) {
        return 2;
      }
      return 0;
    },
  },

  methods: {
    colIcon(col) {
      let icontype = col.dataType;
      if (col.checkbox) {
        icontype = 'boolean';
      }
      switch (icontype) {
        case 'boolean':
          return 'mdi-checkbox-marked-outline';
        case 'number':
          return 'mdi-numeric';
        case 'currency':
          return 'mdi-currency-usd';
        case ('datetime', 'time'):
          return 'mdi-clock-outline';
        case 'date':
          return 'mdi-calendar';
        case 'array':
          return 'mdi-format-list-numbered';
        case 'object':
          return 'mdi-ballot-outline';
        case 'null':
          return 'mdi-code-brackets';
        default:
          return 'mdi-format-text';
      }
    },

    toggleGrouping() {
      this.$emit('groupedBy');
    },

    togglePhone() {
      this.value.hideOnPhone = !this.value.hideOnPhone;
    },
  },
};
</script>

<style scoped>
.availableCol {
  border-style: dashed;
  border-width: 1px;
  border-color: black !important;
}
</style>
