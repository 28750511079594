import { apiPost } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export function addToLastCallUsed(portcall: any) {
  const portcallStore = usePortcallStore();
  const orgLastCalls: any[] = []; // stores the original saved info value.
  portcallStore.lastCallsUsed.forEach((item) => {
    // create the original saved info value. Used for comparison before post to savedInfo.
    const call: any = { ID: item.ID, PORTCALL_NUMBER: item.PORTCALL_NUMBER };
    call.vessel = {
      VESSEL_PREFIX: item.vessel.VESSEL_PREFIX,
      NAME: item.vessel.NAME,
    };
    orgLastCalls.push(call);
  });

  const idx = portcallStore.lastCallsUsed.findIndex((item) => item.ID == portcall.ID);
  if (idx >= 0) {
    portcallStore.lastCallsUsed.splice(idx, 1);
  }
  portcallStore.lastCallsUsed.unshift(portcall);
  if (portcallStore.lastCallsUsed.length > 3) {
    portcallStore.lastCallsUsed.pop();
  }

  const newLastCalls: any[] = [];
  portcallStore.lastCallsUsed.forEach((item) => {
    const call: any = { ID: item.ID, PORTCALL_NUMBER: item.PORTCALL_NUMBER };
    call.vessel = {
      VESSEL_PREFIX: item.vessel.VESSEL_PREFIX,
      NAME: item.vessel.NAME,
    };
    newLastCalls.push(call);
  });
  // check if there are any changes between original value and "new" value.
  if (JSON.stringify(newLastCalls) != JSON.stringify(orgLastCalls)) {
    // save to SavedInfo
    const savedInfo = {
      TypeName: 'web_last_calls',
      StrValue: JSON.stringify(newLastCalls),
    };
    apiPost('savedInfo', savedInfo);
  }
}
