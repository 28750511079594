<template>
  <div>
    <gat-grid
      title="Voyage"
      class="elevation-0"
      :items="voyageItems"
      :columns="columns"
      :fixedheader="true"
      height="calc(100vh - 135px)"
      reloadBtn
      :orgSortBy="orgSortColumns"
      @reload-clicked="retrieveVoyageItems(callId)"
      @row-clicked="editVoyage"
      :loading="isLoading">
      <template v-if="props.column.field == `TYPE`" slot="cell" slot-scope="props">
        {{ lookupCode(props.value, constantsStore.voyageTypes) }}
      </template>
      <template slot="btns">
        <wm-button-group class="right mr-2" :buttons="gridButtons" @addClick="addVoyage" />
      </template>
    </gat-grid>
  </div>
</template>

<script>
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
// eslint-disable-next-line import/no-cycle
import { getVoyage } from '@/services/api/api-calls/getVoyage';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'VoygeList',
  props: {
    callId: [Number, String],
  },
  components: {},
  setup() {
    const constantsStore = useConstantsStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    return {
      portcallStore,
      applicationStore,
      constantsStore,
    };
  },
  data() {
    return {
      orgSortColumns: [{ header: 'Date' }, { header: 'Time' }, { header: 'Type', desc: true }],
      isLoading: false,
    };
  },

  activated() {
    if (this.portcallStore.voyage && this.portcallStore.voyage.length === 0) {
      this.retrieveVoyageItems(this.callId);
    }
  },

  created() {},

  watch: {},

  computed: {
    canAddVoyage() {
      return this.applicationStore.userRight('RO.NEW_AND_EDIT');
    },

    columns() {
      const result = [];
      result.push({ header: 'Port', field: 'HarbourName' });
      result.push({ header: 'Type', field: 'TYPE' });
      result.push({ header: 'Date', field: 'LEFT_DATE', dataType: 'date' });
      result.push({ header: 'Time', field: 'LEFT_TIME', dataType: 'time' });
      result.push({ header: 'ISPS', field: 'SEQURITY_LEVEL' });
      return result;
    },

    voyageItems() {
      return this.portcallStore.voyage;
    },

    gridButtons() {
      return [{ btnType: 'ADD', eventName: 'addClick', mainAction: true, disabled: !this.canAddVoyage }];
    },
  },

  methods: {
    lookupCode(code, list, codeField, textField) {
      return GlobalHelperFunctions.lookupCode(code, list, codeField, textField);
    },
    retrieveVoyageItems(callId) {
      this.isLoading = true;
      getVoyage(callId)
        .then(() => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    editVoyage(row) {
      this.$router.push(`/call/${this.callId}/voyage-item/${row.ID}`);
    },
    addVoyage() {
      this.$router.push(`/call/${this.callId}/new-voyage-item/`);
    },
  },
};
</script>

<style scoped></style>
