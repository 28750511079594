<template>
  <v-toolbar dark color="primary">
    <v-btn :disabled="cancelButtonDisabled" icon dark @click="$emit('cancelClick')">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>{{ title }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn :disabled="okButtonDisabled" dark text @click="$emit('okClick')">
        {{ okButtonCaption }}
      </v-btn>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
export default {
  name: 'GatDialogToolbar',
  props: {
    okButtonCaption: String,
    okButtonDisabled: Boolean,
    cancelButtonDisabled: Boolean,
    title: String,
  },
  components: {},
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
