import { apiGet } from '@/store/api';
import { usePortcallStore } from '@/store/portcallStore';

export async function getCrewChangeFilter(callId: string | number) {
  const portcallStore = usePortcallStore();
  const data = await apiGet(`savedinfo/web-ccm-grid-filter-${callId}`);
  if (data.length > 0) {
    if (data[0].MEMOVALUE1.length > 0) {
      portcallStore.setCrewChangeFilter(data[0].MEMOVALUE1);
    } else {
      portcallStore.setCrewChangeFilter([]);
    }
  } else {
    portcallStore.setCrewChangeFilter([]);
  }
  return data;
}
