import { render, staticRenderFns } from "./VesselDialog.vue?vue&type=template&id=497430e8&scoped=true&"
import script from "./VesselDialog.vue?vue&type=script&lang=js&"
export * from "./VesselDialog.vue?vue&type=script&lang=js&"
import style0 from "./VesselDialog.vue?vue&type=style&index=0&id=497430e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "497430e8",
  null
  
)

export default component.exports