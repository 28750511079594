<template>
  <v-dialog v-model="dialogVisible" persistent max-width="600">
    <v-card class="pa-3" v-show="!deleteDialog">
      <v-layout wrap>
        <GatSubGroup title="Legal basis / cosent (GDPR)" size="xs12">
          <gat-select
            v-model="consent.CCO_CONSENT_PURPOSE"
            :items="purposes"
            label="Purpose"
            textFieldName="COT_NAME"
            codeFieldName="COT_ID"
            :autofocus="true"
            :required="true"
            size="xs12">
          </gat-select>
          <gat-select
            v-model="consent.CCO_LEGAL_BASE"
            :items="legalbasis"
            label="Legal base"
            textFieldName="COT_NAME"
            codeFieldName="COT_ID"
            :autofocus="true"
            :required="true"
            size="xs12">
          </gat-select>
          <gat-edit v-model="consent.CCO_REMARKS" label="Remarks (consent source)" size="xs12"> </gat-edit>
          <gat-edit v-model="consent.CCO_UPDATED" :disabled="true" label="Updated" dateEdit size="xs6"> </gat-edit>
          <gat-edit v-model="consent.CCO_UPDATED_BY" :disabled="true" label="Updated by" size="xs6"> </gat-edit>
        </GatSubGroup>
      </v-layout>
      <v-card-actions>
        <v-btn text @click="deleteDialog = true">Delete</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="close('cancel')">Cancel</v-btn>
        <v-btn color="success" @click="close('save')">Save</v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="deleteDialog">
      <v-card-title class="title">Delete this consent?</v-card-title>
      <v-card-text>If the consent has been withdrawn you should use the legal base value "Whithdawn"</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn
          text
          color="primary"
          @click="
            deleteDialog = false;
            close('delete');
          "
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import { useHelperStore } from '@/store/helperStore';

export default {
  name: 'ConsentForm',
  props: ['value', 'showForm'],
  components: {},
  setup() {
    const helperStore = useHelperStore();
    return {
      helperStore,
    };
  },
  data() {
    return {
      consent: {},
      dialogVisible: false,
      deleteDialog: false,
    };
  },

  mounted() {
    this.consent = GatUtils.makeCopyViaJson(this.value);
    this.dialogVisible = this.showForm;
  },

  watch: {
    value(newValue) {
      this.consent = GatUtils.makeCopyViaJson(newValue);
    },
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
  },

  computed: {
    purposes() {
      return this.helperStore.consentTypes.purposes ? this.helperStore.consentTypes.purposes : null;
    },

    legalbasis() {
      return this.helperStore.consentTypes.legalBases ? this.helperStore.consentTypes.legalBases : null;
    },
  },

  methods: {
    close(btn) {
      this.dialogVisible = false;
      this.$emit('close', { btn, value: this.consent });
    },
  },
};
</script>

<style scoped></style>
