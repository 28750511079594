import { apiDelete } from '@/store/api';
import { useDashboardStore } from '@/store/dashboardStore';

export async function setAllUsersToDefaultSorting({
  location,
  dashboardGroupName,
}: {
  location: 'main' | 'expenses';
  dashboardGroupName: string;
}) {
  if (!location || (!location && !dashboardGroupName)) {
    console.error('location or dashboard group was not provided.');

    return;
  }
  const urlExtension = dashboardGroupName
    ? `${location}.${dashboardGroupName.trim().replace(' ', '').toLowerCase()}`
    : location;
  await apiDelete(`savedinfo/users/web_dashboard_sorting.${urlExtension}`);
  useDashboardStore().setDashboardSorting({
    location,
    dashboardGroupName,
    sorting: [],
    settingName: 'user',
  });
}
