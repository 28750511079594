import { apiGet } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';

export async function getCrewChangeTablesDense() {
  const applicationStore = useApplicationStore();
  const data = await apiGet('savedinfo/web-ccm-grid-dense');
  if (data.length > 0) {
    if (data[0].INTVALUE1 == 1) {
      applicationStore.setCrewChangeTablesDense(true);
    }
  }
}
