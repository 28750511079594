<template>
  <div>
    <client-search :favoritesOnly="favoritesOnly" main-action />
    <!-- <search-panel v-if="!this.favoritesOnly" v-model="searchParams"
      :autofocus=true
      :fields="searchFields"
      :showMaxRows="true"
      :maxRows="maxRows"
      :submitBtn="true"
    /> -->
    <gat-grid
      v-if="(clients || (favoriteClients && favorites)) && showClientsTable"
      :rowclass="this.getRowClass"
      :items="favoritesOnly ? favoriteClients : clients"
      :orgSortBy="[{ header: 'Name', desc: false }]"
      :hideEmptyColumns="true"
      :columns="columns"
      title="Clients"
      class="elevation-0"
      :fixedheader="true"
      :height="windowHeight"
      keyField="ID"
      :isLoading="isLoading"
      :favorites="favorites"
      v-on:favClick="favClicked"
      @row-clicked="goToClient">
      <template slot="cell" slot-scope="props">
        <div v-if="props.column.field == `NAME`">
          <span class="gridLink">{{ props.row.NAME }}</span
          ><br />
          <span
            v-if="props.row.MainContact"
            class="gridLink"
            @click.stop="goToClientMainContact(props.row.MainContact_id)"
            >{{ props.row.MainContact }}</span
          >
        </div>
        <div v-else-if="props.column.field === `WARNING`">
          <div v-if="props.row.WARNING || props.row.SHOW_WARNING" @click.stop>
            <v-tooltip
              :bottom="$vuetify.breakpoint.smAndDown"
              :left="true"
              open-on-click
              :color="props.row.SHOW_WARNING ? 'primary' : 'default'"
              class="tooltip-test">
              <template v-slot:activator="{ on, attrs }">
                <v-icon :color="props.row.SHOW_WARNING ? 'primary' : 'default'" v-bind="attrs" v-on="on">{{
                  props.row.SHOW_WARNING ? 'notification_important' : 'mdi-bell-outline'
                }}</v-icon>
              </template>
              <div class="tooltip-message">
                {{ props.row.WARNING || 'N/A' }}
              </div>
            </v-tooltip>
          </div>
        </div>
        <div v-else-if="props.column.field === `NEW_INSTRUCTIONS`">
          <div v-if="props.row.NEW_INSTRUCTIONS" @click.stop>
            <v-tooltip
              :bottom="$vuetify.breakpoint.smAndDown"
              :left="true"
              open-on-click
              color="primary"
              class="tooltip-test">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" v-bind="attrs" v-on="on">mdi-alert-outline</v-icon>
              </template>
              <div class="tooltip-message">
                {{ props.row.NEW_INSTRUCTIONS }}
              </div>
            </v-tooltip>
          </div>
        </div>
        <div v-else-if="props.column.field === `NEW_COMMENTS`">
          <div v-if="props.row.NEW_COMMENTS" @click.stop>
            <v-tooltip
              :bottom="$vuetify.breakpoint.smAndDown"
              :left="true"
              open-on-click
              color="default"
              class="tooltip-test">
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="default" v-bind="attrs" v-on="on">mdi-comment-text-outline</v-icon>
              </template>
              <div class="tooltip-message">
                {{ props.row.NEW_COMMENTS }}
              </div>
            </v-tooltip>
          </div>
        </div>
      </template>
      <template slot="btns">
        <wm-button-group
          class="right mr-1"
          :buttons="[{ btnType: 'ADD', userRight: 'C.NEW', mainAction: false }]"
          @addClick="addClient" />
      </template>
    </gat-grid>
    <!-- <div v-else>No result</div> -->
    <br />
    <contacts v-if="!this.favoritesOnly" v-show="this.showContactsTable" />
  </div>
</template>

<script>
// import SearchPanel from '@/app-components/SearchPanel.vue';
// eslint-disable-next-line import/no-cycle
import { apiPost } from '@/store/api';
import { toggleFavorite } from '@/services/api/api-calls/toggleFavorite';
import { useHelperStore } from '@/store/helperStore';
import { useApplicationStore } from '@/store/applicationStore';
import ClientSearch from './ClientSearch.vue';
import Contacts from './ContactList.vue';

export default {
  name: 'ClientList',
  props: {
    // search: [String, Object],
    // maxRows: [Number, Object],
    query: [String, Number, Object],
    favoritesOnly: {
      default: false,
      type: Boolean,
    },
  },
  setup() {
    const helperStore = useHelperStore();
    const applicationStore = useApplicationStore();
    return {
      applicationStore,
      helperStore,
    };
  },
  data() {
    return {
      clients: [],
      favoriteClients: [],
      // showAddBtn : true,
      // searchParams:{},
      // searchFields:[
      //   {name:"search",caption:"Search",size:"m",value:null,maxChars:0},
      //   {name:"category",caption:"Category filter",size:"m",items:this.categories(),value:null}
      // ],
      isFavorites: null,
      isLoading: false,
      showContactsTable: true,
      showClientsTable: true,
    };
  },

  components: { ClientSearch, Contacts },

  computed: {
    columns() {
      const result = [];
      result.push({
        header: 'Name',
        field: 'NAME',
        width: 'auto',
        linkUrl: '/client/field[ID]',
        linkText: 'field[NAME]',
      });
      result.push({ header: 'Alt. name', field: 'ALPHANAME', hideOnPhone: true });
      result.push({ header: 'No', field: 'ACCOUNT_NO', hideOnPhone: true });
      result.push({ header: 'Category', field: 'CATEGORY_STR' });
      result.push({ header: 'Roles', field: 'Roles' });
      result.push({ header: 'Country', field: 'COUNTRY_NAME', hideOnPhone: true });
      result.push({ header: 'City', field: 'CITY' });
      result.push({ header: 'Alert', field: 'WARNING' });
      result.push({ header: 'Instructions', field: 'NEW_INSTRUCTIONS' });
      result.push({ header: 'Comments', field: 'NEW_COMMENTS' });
      return result;
    },

    favorites() {
      return this.applicationStore.user.favorites.filter((item) => item.FAV_TYPE == 'client');
    },
    windowHeight() {
      if (this.favoritesOnly) {
        return 'calc(100vh - 135px)';
      }
      if (this.showContactsTable) {
        return 'calc(50vh - 200px)';
      }
      return 'calc(100vh - 215px)';
    },
  },

  watch: {
    // searchParams(newValue){
    //    if(newValue.search){
    //     this.getClients(newValue)
    //    }
    // },

    favoritesOnly: {
      immediate: true,
      handler(isFavorites) {
        const noFavorites = this.favoriteClients.length == 0;
        if (isFavorites && noFavorites) {
          this.getClients({ favoritesOnly: true });
        }
      },
    },
  },

  created() {
    window.messageBus.$on('clientInsert', (clientItem) => {
      this.addClientItem(clientItem);
    });
    window.messageBus.$on('clientUpdate', (clientItem) => {
      let idx = this.findClientItem(clientItem.ID);
      if (idx >= 0) {
        this.updateClientItem(idx, clientItem);
      }

      idx = 0;
      idx = this.findFavoritesClientItem(clientItem.ID);
      if (idx >= 0) {
        this.updateFavoritesClientItem(idx, clientItem);
      }
    });
    window.messageBus.$on('clientDelete', (clientId) => {
      let idx = this.findClientItem(clientId);
      if (idx >= 0) {
        this.clients.splice(idx, 1);
      }

      idx = 0;
      idx = this.findFavoritesClientItem(clientId);
      if (idx >= 0) {
        this.favoriteClients.splice(idx, 1);
      }
    });
    window.messageBus.$on('updateClientMainContact', (mainContactItem) => {
      const mainContact = `${mainContactItem.FIRST_NAME} ${mainContactItem.LAST_NAME}` || '';
      const id = mainContactItem.ID;
      if (this.favoriteClients.length > 0) {
        const idx = this.findFavoritesClientItem(mainContactItem.CLIENT_ID);
        this.favoriteClients[idx].MainContact = mainContact;
        this.favoriteClients[idx].MainContact_id = id;
      }
      if (this.clients.length > 0) {
        const idx = this.findClientItem(mainContactItem.CLIENT_ID);
        this.clients[idx].MainContact = mainContact;
        this.clients[idx].MainContact_id = id;
      }
    });

    window.messageBus.$on('clientSearchValue', (searchValues) => {
      this.getClients(searchValues);
    });

    window.messageBus.$on('contactSearchHasResults', (contactSearchHasResults) => {
      this.showContactsTable = !!contactSearchHasResults;
    });
  },

  methods: {
    findClientItem(clientId) {
      return this.clients.findIndex((item) => item.ID == clientId);
    },

    findFavoritesClientItem(clientId) {
      return this.favoriteClients.findIndex((item) => item.ID == clientId);
    },

    updateClientItem(idx, clientItem) {
      this.clients.splice(idx, 1, clientItem);
    },

    updateFavoritesClientItem(idx, clientItem) {
      this.favoriteClients.splice(idx, 1, clientItem);
    },

    addClientItem(clientItem) {
      this.clients.push(clientItem);
    },

    addClient() {
      this.$router.push({ name: 'ClientNew', params: { value: { ID: 0 } } });
    },
    categories() {
      const result = this.helperStore.clientCategories;
      return result;
    },

    getClientNameCell() {
      return '-';
    },

    getClients(param) {
      // set isLoading to true to show progressbar if response is not immidiate
      this.isLoading = true;
      apiPost('clients/', param).then((data) => {
        // remove progressbar
        this.isLoading = false;
        if (this.favoritesOnly) {
          this.favoriteClients = data;
        } else {
          this.clients = data;
          this.showClientsTable = this.clients.length !== 0;
          window.messageBus.$emit('clientSearchHasResults', this.clients.length > 0);
        }
      });
    },

    getRowClass(rowItem) {
      return rowItem.INACTIVE ? 'ignored' : '';
    },

    goToClient(rowItem) {
      let clients;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.favoritesOnly ? (clients = this.favoriteClients) : (clients = this.clients);
      this.$router.push({ name: 'clientDetails', params: { clientId: rowItem.ID, clientList: clients } });
    },
    goToClientMainContact(contactId) {
      this.$router.push({ name: 'Contact', params: { contactId } });
    },

    favClicked(listItem) {
      toggleFavorite({ FAV_TYPE: 'client', FAV_KEY: listItem.ID });
      const idx = this.favoriteClients.findIndex((item) => item.ID == listItem.ID);
      if (this.favoriteClients.length != 0) {
        // only update favoriteClients array if it is populated.
        if (idx >= 0) {
          this.favoriteClients.splice(idx, 1);
        } else {
          this.favoriteClients.push(listItem);
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .ignored {
  td:nth-of-type(1) {
    text-decoration: none !important;
  }
}

.tooltip-message {
  max-width: 400px !important;
}
</style>
