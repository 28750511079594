import { VueConstructor } from 'vue';
import gsComponents from '@/plugins/gat-components';
import GatTab from '@/plugins/extended-gs-components/GatTab.vue';
import { enforceMaxChars } from '@/globalFlags';

export * from '@/plugins/gat-components';

export function install(vue: VueConstructor) {
  // install extended components
  vue.component('GatTab', GatTab);

  return gsComponents.install(vue, {
    enforceMaxChars,
    excludeComponents: {
      gatTab: true,
    },
  });
}
