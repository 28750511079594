<template>
  <div>
    <CrewChangeTaskList title="Transports" :fullsize="false" :callId="0" />
    <br />
    <CrewChangePeopleList :callId="0" />
  </div>
</template>

<script>
import CrewChangeTaskList from '../crewchange/CrewChangeTaskList.vue';
import CrewChangePeopleList from '../crewchange/CrewChangePeopleList.vue';

export default {
  name: 'Transports',
  props: [],
  components: {
    CrewChangeTaskList,
    CrewChangePeopleList,
  },
  data() {
    return {};
  },

  created() {},

  watch: {},

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
