<template>
  <div style="display: flex; width: 100% !important">
    <div v-if="!$vuetify.breakpoint.xs" style="min-width: 30px">
      <v-icon class="searchIcon ml-2">search</v-icon>
    </div>
    <gat-form v-model="isValid" ref="form" style="position: relative; width: 100% !important">
      <v-layout
        wrap
        style="display: inline-flex; flex-direction: row; flex-wrap: wrap; width: 100% !important"
        class="mr-2 align-top">
        <template v-for="(field, index) in fields">
          <gat-select
            v-if="field.items"
            :class="field.class"
            :style="field.style"
            :key="index"
            v-model="field.value"
            :items="field.items"
            :size="field.size"
            :autofocus="autofocus && index == 0"
            :label="field.caption"
            @input="doSearch()"
            :hide="field.hide"
            clearable
            :maxChars="field.maxChars"></gat-select>
          <gat-edit
            v-else-if="field.dataType == 'dateEdit'"
            :class="field.class"
            :style="field.style"
            dateEdit
            :key="index"
            :size="field.size"
            v-model="field.value"
            :label="field.caption"
            @keyup.enter.native="doSearch()"
            :hide="field.hide"
            clearable
            :required="field.required"
            :maxChars="field.maxChars" />
          <gat-edit
            v-else-if="field.dataType == 'dateTimeEdit'"
            :class="field.class"
            :style="field.style"
            dateTimeEdit
            :key="index"
            :size="field.size"
            v-model="field.value"
            :label="field.caption"
            @keyup.enter.native="doSearch()"
            :hide="field.hide"
            clearable
            :required="field.required"
            :maxChars="field.maxChars" />
          <gat-edit
            v-else-if="field.checkbox"
            :class="field.class"
            :style="field.style"
            checkbox
            :key="index"
            :size="field.size"
            v-model="field.value"
            :label="field.caption"
            @keyup.enter.native="doSearch()"
            :hide="field.hide"
            :required="field.required"
            :maxChars="field.maxChars" />
          <gat-edit
            v-else
            v-model="field.value"
            :class="field.class"
            :style="field.style"
            :key="index"
            :size="field.size"
            :autofocus="autofocus && index == 0"
            :label="field.caption"
            @onenterkey="doSearch()"
            :hide="field.hide"
            :clearable="field.clearable ? true : false"
            :required="field.required"
            :maxChars="field.maxChars" />
        </template>

        <gat-edit
          v-if="!fields"
          v-model="textValue"
          :maxChars="maxChars || 50"
          label="Search"
          @onenterkey="doSearch()" />

        <slot></slot>
        <gat-edit
          v-if="showMaxRows"
          dataType="number"
          size="s"
          hide="phone"
          class="adjusright pl-3"
          v-model="maxRowCount"
          label="Max rows"
          @onenterkey="doSearch()"
          :maxChars="maxChars || 4"
          :required="true" />

        <v-btn small v-if="clearBtn" class="searchBtn" color="primary" outlined @click="doReset()"
          ><v-icon small>mdi-close</v-icon>reset</v-btn
        >

        <gat-button
          v-if="submitBtn"
          caption="search"
          class="searchBtn"
          icon="mdi-magnify"
          :mainAction="mainAction"
          @click="doSearch()" />
        <v-btn
          v-else-if="submitBtnRight"
          small
          :outlined="mainAction"
          class="searchBtn primary mr-3 adjusright"
          :disabled="!isValid"
          @click="doSearch()">
          <v-icon small>mdi-magnify</v-icon>Search
        </v-btn>
        <!-- Added search button -->
      </v-layout>
    </gat-form>
  </div>
</template>

<script>
export default {
  name: 'SearchPanel',
  components: {},
  props: [
    'value',
    'maxRows',
    'showMaxRows',
    'autofocus',
    'searchText',
    'showSearch',
    'searchParam',
    'fields',
    'submitBtn',
    'submitBtnRight',
    'maxChars',
    'clearBtn',
    'mainAction',
  ],

  data() {
    return {
      isValid: null,
      textValue: '',
      maxRowCount: 25,
      showSearchField: true,
    };
  },
  methods: {
    doReset() {
      this.$emit('reset');
    },
    doSearch() {
      // console.log('search');
      this.$refs.form.validate();
      if (this.isValid) {
        if (this.fields) {
          const v = this.makeSearchParams();
          this.$emit('input', v);
        } else {
          this.$emit('search', { search: this.textValue, maxRows: this.maxRowCount });
        }
      }
    },

    makeSearchParams() {
      const result = {};
      this.fields.forEach((field) => {
        if (field.value || field.value == 0) {
          result[field.name] = field.value;
        }
      });
      if (this.showMaxRows) {
        result.max = +this.maxRowCount;
      }
      return result;
    },

    mapFieldValues(values) {
      if (this.fields) {
        // eslint-disable-next-line array-callback-return
        this.fields.map((field) => {
          if (values && values[field.name]) {
            // eslint-disable-next-line no-param-reassign
            field.value = values[field.name];
          }
        });
      }
    },
  },

  watch: {
    textValue(newValue) {
      if (!newValue) {
        this.textValue = '';
      }
    },
    searchText(newValue) {
      this.textValue = newValue;
    },
    maxRows(newValue) {
      this.maxRowCount = +newValue;
    },
    showSearch(newValue) {
      this.showSearchField = newValue;
    },
    value(newValue) {
      this.mapFieldValues(newValue);
    },
  },

  created() {
    if (this.searchText) {
      this.textValue = this.searchText;
    }
    if (typeof this.showSearch != 'undefined') {
      this.showSearchField = this.showSearch;
    }
  },

  mounted() {
    this.mapFieldValues(this.value);
    this.maxRowCount = +this.maxRows || +100;
    if (this.autofocus == 'true' && this.showSearch) {
      this.$refs.search.focus();
    }
  },
};
</script>

<style scoped>
.adjusright {
  margin-left: auto;
  margin-right: 0;
}
.searchBtn {
  margin-top: 8px;
  height: 40px !important;
}
.searchIcon {
  position: absolute;
  clear: both;
  height: 55px;
}
.fixedDateWidth {
  min-width: 158px;
  max-width: 158px;
}
.fixedMaxRowsWidth {
  min-width: 110px;
  max-width: 110px;
}
@media only screen and (max-width: 600px) {
  .searchBtn {
    margin: 0px 7.5px;
    margin-bottom: 15px;
    height: 40px !important;
    width: calc(100% - 15px);
  }
}
</style>
