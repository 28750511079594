<template>
  <gat-group
    :title="title || 'Dashboard'"
    :collapsed="true"
    class="pb-2"
    @expanded="updateChartKey = updateChartKey + 1">
    <!-- @expanded="chartUpdateKey++" -->

    <v-btn slot="btn" text color="grey darken-1" small @click="showSettings = true">
      <v-icon small class="mr-1">settings</v-icon>
    </v-btn>

    <template slot="compact">
      <slot name="compact"></slot>
    </template>
    <dashboard
      embedded
      :location="location"
      :embeddedShowSettings="showSettings"
      @closeSettingsMenu="showSettings = false"
      @data-retreived="(value) => $emit('dataRetrieved', value)"
      :updateChartKey="updateChartKey" />
  </gat-group>
</template>

<script>
import Dashboard from './DashboardGrid.vue';

export default {
  name: 'EmbeddedDashboard',
  props: {
    location: String, // name of location: main, expenses etc.
    title: String,
  },
  components: { Dashboard },
  data() {
    return {
      showSettings: false,
      updateChartKey: 0,
    };
  },

  methods: {
    dataRetrieved(data) {
      this.$emit('data-retreived', data);
    },
  },
};
</script>

<style scoped></style>
