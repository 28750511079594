<template>
  <div>
    <div v-if="allReports.length == 0">
      <gat-grid
        allowHtml
        :title="isLoading ? 'Loading' : 'No dashboard data found'"
        :loading="isLoading"
        @reload-clicked="getAllReports()"
        reloadBtn />
    </div>
    <div v-for="(report, index) in allReports" :key="index">
      <gat-grid
        v-if="report.ITEMS.length > 0"
        allowHtml
        :loading="isLoading"
        :title="report.TITLE"
        :items="report.ITEMS"
        :columns="getReportColumns(report.ITEMS[0])"
        reloadBtn
        @reload-clicked="getAllReports()"
        class="mb-2 elevation-1"
        :dense="report.dense">
        <template v-slot:cell="cellProps">
          <div v-if="isProbablyDate(cellProps.value)">
            {{ globalFormatDateTime(cellProps.value, 'DD.MMM YYYY HH:mm') }}
          </div>
          <div v-else-if="cellProps.value.includes('href=')">
            <a class="gridLink" :href="convertToLink(cellProps.value).url">{{ convertToLink(cellProps.value).text }}</a>
          </div>
          <template v-else-if="isHtmlTag(cellProps.value)">
            <template v-for="(htmlEl, index) in createHtmlElements(cellProps.value)">
              <component
                v-if="htmlEl.tag && !htmlEl.tag.includes('<') && !htmlEl.tag.includes('>')"
                :is="htmlEl.tag"
                :style="htmlEl.style"
                :class="htmlEl.class"
                :key="htmlEl.tag + index"
                >{{ htmlEl.content }}</component
              >
              <!-- <div :key="htmlEl.tag + index">{{ htmlEl }}</div> -->
            </template>
          </template>
          <div v-else>{{ cellProps.value }}</div>
        </template>
        <template slot="btns">
          <v-switch v-model="report.dense" label="dense"></v-switch>
        </template>
      </gat-grid>
    </div>
    <!-- <record-presenter :value="allReports"/> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { apiGet } from '@/store/api';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'TabDashboard',
  props: {
    clientId: Number,
    portcallId: Number,
    vesselId: Number,
    tabType: {
      type: Number,
      required: true,
    }, // 0 = report, 1 = dashboard, 2 = portcall, 3 = client, 4 = vessel, 5 = activities by time, 6 = activities by portcall, 7 = Web module / web module external
  },
  components: {
    /* RecordPresenter */
  },
  data() {
    return {
      allReports: [],
      isLoading: true,
    };
  },

  created() {},

  watch: {
    clientId: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log('TABDASH '+this.tabType+' - Client ID:', newVal);
        if (newVal && newVal != oldVal && this.tabType === 3) {
          this.getAllReports(newVal);
        }
      },
    },
    portcallId: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log('TABDASH '+this.tabType+' - Portcall ID:', newVal);
        if (newVal && newVal != oldVal && this.tabType === 2) {
          this.getAllReports(newVal);
        }
      },
    },
    vesselId: {
      immediate: true,
      handler(newVal, oldVal) {
        // console.log('TABDASH '+this.tabType+' - Vessel ID:', newVal);
        if (newVal && newVal != oldVal && this.tabType === 4) {
          this.getAllReports(newVal);
        }
      },
    },
  },

  computed: {},

  methods: {
    globalFormatDateTime(date, format) {
      return GlobalHelperFunctions.globalFormatDateTime(date, format);
    },
    createHtmlElements(textValue) {
      let result = [];
      textValue.split('>').forEach((str) => {
        // split at html tags
        if (str) {
          result.push(str);
        }
      });
      result = result.map((str) => {
        // Join them back together.
        const newRes = `${str}>`;
        return newRes;
      });
      const tempResult = [];
      result.forEach((str) => {
        if (str.includes('</')) {
          const splitted = str.split('</');
          if (splitted[0]) {
            tempResult.push(splitted[0]);
          }
          if (splitted[1]) {
            tempResult.push(`</${splitted[1]}`);
          }
        } else {
          tempResult.push(str);
        }
      });
      // The result should now be a [<htmlTag>,content,<htmlEndTag>, ...this pattern continues] array.
      result = tempResult;
      const htmlResult = [];
      const htmlElement = {
        tag: '',
        content: '',
        style: '',
        class: '',
      };
      result.forEach((tagOrContent) => {
        const isStartTag =
          typeof tagOrContent === 'string' &&
          tagOrContent.startsWith('<') &&
          tagOrContent.endsWith('>') &&
          !tagOrContent.startsWith('</');
        if (isStartTag) {
          // Extract attributes from start tag (class).
          if (tagOrContent.includes('class="')) {
            htmlElement.class = this.extractAttributeFromHtmlString(tagOrContent, 'class');
          }
          // Extract attributes from start tag (style).
          if (tagOrContent.includes('style="')) {
            htmlElement.style = this.extractAttributeFromHtmlString(tagOrContent, 'style');
          }
          let tag;
          // if the tag includes a space, we must remove it along with all attributes + <.
          if (tagOrContent.includes(' ')) {
            tag = tagOrContent.split(' ')[0].replaceAll('<', '');
          } else {
            // otherwise we just remove the < > (the tag should just be a clean string)
            tag = tagOrContent.replaceAll('<', '').replaceAll('>', '');
          }
          htmlElement.tag = tag;
          // console.log(htmlElement);
        }
        const isContent =
          typeof tagOrContent === 'string' && !tagOrContent.startsWith('<') && !tagOrContent.endsWith('>');
        if (isContent) {
          htmlElement.content += tagOrContent;
          // console.log(htmlElement);
        }
        const isEndTag =
          typeof tagOrContent === 'string' && tagOrContent.startsWith('</') && tagOrContent.endsWith('>');
        if (isEndTag) {
          // console.log('IS END:', tagOrContent);
          if (htmlElement.tag) {
            htmlResult.push({ ...htmlElement });
          }
          htmlElement.tag = '';
          htmlElement.content = '';
          htmlElement.style = '';
        }
      });
      // console.log(result);

      return htmlResult;
    },
    isHtmlTag(textValue) {
      if (textValue && typeof textValue === 'string') {
        const htmlTags = ['<v-icon', '<u', '<U', '<b', '<B', '<span']; //
        let numOfHtmlTags = 0;
        htmlTags.forEach((tag) => {
          // console.log(textValue);
          if (textValue.includes(tag)) {
            numOfHtmlTags += 1;
          }
        });
        // console.log(textValue, numOfHtmlTags);
        if (numOfHtmlTags >= 1) {
          return true;
        }
      }
      return false;
    },
    extractAttributeFromHtmlString(textValue, attribute) {
      if (textValue && typeof textValue === 'string' && textValue.includes(attribute)) {
        const splitted = textValue.split(`${attribute}="`);
        return splitted[1].split('"')[0];
      }
      return textValue;
    },
    getAllReports(id) {
      if (!id) {
        switch (this.tabType) {
          case 2:
            // eslint-disable-next-line no-param-reassign
            id = this.portcallId;
            break; // Portcall tab
          case 3:
            // eslint-disable-next-line no-param-reassign
            id = this.clientId;
            break; // Client tab
          case 4:
            // eslint-disable-next-line no-param-reassign
            id = this.vesselId;
            break; // Vessel tab
          default:
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            null;
        }
      }
      this.isLoading = true;
      this.allReports = [];
      apiGet(`dashboard/tab/${this.tabType}/${id}`).then((result) => {
        let hasItems = false;
        result.forEach((item) => {
          if (item.ITEMS.length > 0) {
            hasItems = true;
          }
        });
        // eslint-disable-next-line array-callback-return
        result.map((item) => {
          // eslint-disable-next-line no-param-reassign
          item.dense = true; // set the dense option for each grid.
        });
        this.allReports = hasItems ? result : [];
        this.isLoading = false;
      });
    },
    getReportColumns(items) {
      const result = [];
      if (items) {
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const property in items) {
          let header = property;
          header = this.trimColumnHtmlTags(header, '<b>', '</b>');
          header = this.trimColumnHtmlTags(header, '<i>', '</i>');
          header = this.trimColumnHtmlTags(header, '<u>', '</u>');
          result.push({ header, field: property });
        }
      }
      return result;
    },
    convertToLink(value) {
      const str = value;
      let type = null;
      let startUrl = null;
      let text = null;
      if (str.includes(':PORTCALL')) {
        type = ':PORTCALL';
        startUrl = '/call/';
      } else if (str.includes(':VESSEL')) {
        type = ':VESSEL';
        startUrl = '/vessel/';
      }
      if (type && startUrl) {
        // Create the link.
        const start = str.lastIndexOf(type);
        if (start >= 0) {
          let trimmed = str.substring(start, str.length);
          // eslint-disable-next-line prefer-destructuring
          trimmed = trimmed.split('"')[0];
          // eslint-disable-next-line prefer-destructuring
          trimmed = trimmed.split('=')[1];
          // eslint-disable-next-line no-param-reassign
          value = startUrl + trimmed;
        }
        // Get the text
        const textStart = str.indexOf('>') + 1;
        if (textStart >= 0) {
          const textEnd = str.lastIndexOf('<');
          if (textEnd) {
            text = str.substring(textStart, textEnd);
          }
        }
      }
      const url = value;
      return { url, text };
    },
    isProbablyDate(input) {
      const str = input;
      const sum = `${str.indexOf('-')}${str.lastIndexOf('-')}${str.indexOf('T')}${str.indexOf(':')}${str.lastIndexOf(
        ':'
      )}`;
      const isProbablyDate = 47101316; // final result of sum.
      return +sum === isProbablyDate;
    },
    trimColumnHtmlTags(str, startString, endString) {
      let trimmedResult = '';
      let startIdx = str.indexOf(startString); // get first index of startString from str(original string)
      const endIdx = str.indexOf(endString); // get first index of endString from str
      if (startIdx >= 0 && endIdx >= 0) {
        startIdx += startString.length;
        trimmedResult = str.substring(startIdx, endIdx); // remove startString from original string.
        return trimmedResult; // return trimmed string.
      }
      return str; // if not both endString and startString is found, return original string.
    },
  },
};
</script>

<style scoped></style>
